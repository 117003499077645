<template>
  <ab-flow-base-cmp class="three-object-cmp" :block="block" />
</template>

<script>
import * as THREE from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader.js';
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import {shallowRef} from 'vue';
import {pathHelper} from '@/utils/pathHelper';

export default {
  // Define the components used in this component
  components: {AbFlowBaseCmp},
  // Define the mixins used in this component
  mixins: [renderMixins],
  // Define the props passed to this component
  props: ['block'],
  // Define the name of this component
  name: "ThreeDObjectEditorCmp",
  // Define the injected properties
  inject: {
    // The canvas used for rendering
    canvas: {
      default: null
    },
    // The data injected into this component
    injectData: {
      default: {}
    },
    // The scene where the 3D object will be added
    scene: {
      default: null
    },
    // Function to add an animation mixer
    addAnimationMixer: {
      default: () => {},
    },
  },

  // Setup function for the component
  setup() {
    // Define the 3D object, animation mixer, and animation action
    const object = shallowRef(null);
    const animationMixer = shallowRef(null);
    const animationAction = shallowRef(null);

    // Return the 3D object, animation mixer, and animation action
    return { object, animationMixer, animationAction };
  },

  // Data function for the component
  data() {
    return {};
  },

  // Computed properties for the component
  computed: {
    // Compute the path of the 3D object
    objectPath() {
      const objectPath = this.block?.properties?.object;

      return pathHelper.assetPath(objectPath?.source_url || objectPath);
    },
    // Compute the path of the animation
    animationPath() {
      const animationPath = this.block?.properties?.animation;

      return pathHelper.assetPath(animationPath?.source_url || animationPath);
    },
    // Compute the position of the 3D object
    position() {
      return [
        this.block?.properties?.positionX || 0,
        this.block?.properties?.positionY || 0,
        this.block?.properties?.positionZ || 0,
      ];
    },
    // Compute the scale of the 3D object
    scale() {
      return [
        this.block?.properties?.scaleX || 1,
        this.block?.properties?.scaleY || 1,
        this.block?.properties?.scaleZ || 1,
      ];
    },
    // Compute the rotation of the 3D object
    rotation() {
      return [
        this.block?.properties?.rotationX || 0,
        this.block?.properties?.rotationY || 0,
        this.block?.properties?.rotationZ || 0,
      ];
    },
  },

  // Methods for the component
  methods: {
    // Function to load the 3D model
    async loadModel() {
      // If there is no scene, log an error and return
      if (!this.scene) {
        console.error('Scene not found');

        return;
      }

      // If there is already a 3D object, remove it from the scene and set it to null
      if (this.object) {
        this.scene.remove(this.object);
        this.object = null;
      }

      // If there is no object path, log an error and return
      if (!this.objectPath) {
        console.error('Object path not found');

        return;
      }

      // Create a new FBXLoader
      const loader = new FBXLoader();

      // Load the 3D object
      return new Promise((resolve) => {
        loader.load(this.objectPath, (object) => {
          // Set the blockId of the object
          object.userData = {
            blockId: this.block.id,
          };

          // Set the 3D object, its position, scale, and rotation
          this.object = object;
          this.object.position.set(...this.position);
          this.object.scale.set(...this.scale);
          this.object.rotation.set(...this.rotation);

          // Add the 3D object to the scene
          this.scene.add(this.object);

          // Create a new animation mixer for the 3D object
          this.animationMixer = new THREE.AnimationMixer(this.object);

          // Add the animation mixer
          this.addAnimationMixer(this.animationMixer);

          // If there are animations, play the first one
          if (object?.animations?.length) {
            this.animationAction = this.animationMixer.clipAction(object.animations[0]);
            this.animationAction.play();
          }

          // Resolve the promise
          resolve();
        });
      });
    },

    // Function to load the animation
    async loadAnimation() {
      // Create a new FBXLoader
      const loader = new FBXLoader();

      // If there is already an animation action, stop it
      if (this.animationAction) {
        this.animationAction.stop();
      }

      // Load the animation
      return new Promise((resolve) => {
        loader.load(this.animationPath, (animationObject) => {
          // Get the first animation from the animation object
          const animation = animationObject.animations[0];

          // Create a new animation action for the animation
          this.animationAction = this.animationMixer.clipAction(animation);

          // Play the animation
          this.animationAction.play();

          // Resolve the promise
          resolve();
        });
      });
    },
  },

  // Watchers for the component
  watch: {
    // When the scene changes, load the 3D model
    scene() {
      if (this.scene) {
        this.loadModel();
      }
    },
    // When the position changes, set the position of the 3D object
    position() {
      this.object.position.set(...this.position);
    },
    // When the scale changes, set the scale of the 3D object
    scale() {
      this.object.scale.set(...this.scale);
    },
    // When the rotation changes, set the rotation of the 3D object
    rotation() {
      this.object.rotation.set(...this.rotation);
    },
    // When the object path changes, load the 3D model and the animation
    async objectPath() {
      await this.loadModel();

      if (this.animationPath) {
        await this.loadAnimation();
      }
    },
    // When the animation path changes, load the animation
    animationPath() {
      this.loadAnimation();
    },
  },

  // Lifecycle hook for when the component is mounted
  async mounted() {
    // Load the 3D model
    await this.loadModel();

    // If there is an animation path, load the animation
    if (this.animationPath) {
      await this.loadAnimation();
    }
  },

  // Lifecycle hook for when the component is about to be unmounted
  beforeUnmount() {
    // If there is no scene or 3D object, return
    if (!this.scene || !this.object) {
      return;
    }

    // Remove the 3D object from the scene
    this.scene.remove(this.object);
  },
}

</script>

<style scoped lang="scss">
.three-object-cmp {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}
</style>
