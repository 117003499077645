<template>
  <q-page class="q-pa-md">

    <q-select
      class="col"
      v-model="mediaType"
      :options="globals.options.media_types"
      label="Parameter type"
      map-options
      emit-value
      filled
      style="max-width: 200px"
    />

    <q-separator class="q-my-md" />

    <media-gallery-cmp
      :product-id="app_id"
      :module-id="module_id"
      :type="mediaType"
      embedded
    />

  </q-page>
</template>

<style>
</style>

<script>
import MediaGalleryCmp from '@/components/MediaGallery/MediaGallery.vue';

export default {
  name: 'MediaGallery',
  components: {MediaGalleryCmp},
  inject: ['currentModule'],
  data() {
    return {
      mediaType: 'image',
    };
  },


  async created() {
    // Set app id
    this.app_id = this.$route.params.app_id || 0
    this.module_id = this.$route.params.module_id || 0
  },
}
</script>

