<template>

  <div>
    <div class="text-subtitle1">Gutter:</div>
    <div class="row">
      <q-select class="q-select q-px-sm" emit-value map-options :options="globals.options.sizes" v-model="gutter.x" label="Horizontal"/>
      <q-select class="q-select q-px-sm" emit-value map-options :options="globals.options.sizes" v-model="gutter.y" label="Vertical"/>
    </div>
  </div>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";

export default {
  inject: ['canvas'],
  mixins: [propertiesMixins],
  name: "UiPropGutters",
  components: {},

  data: () => ({
    gutter: {
      x: "",
      y: "",
    }
  }),

  created() {

    // Init current value
    if (this.currentValue) this.gutter = this.currentValue

    // Add watcher
    this.$watch('gutter', function (newVal) {
      this.currentValue = newVal
    }, {deep: true})
  },

  computed: {
    // Redefine prop name
    type: () => "gutter",
  }
}

</script>

<style lang="scss" scoped>
.q-select {
  flex: 1;
  width: 100%;
  min-width: 120px;
}
</style>
