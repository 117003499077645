/**
 * DbModule model
 */
import {AbOrm} from "ab-orm/src/AbOrm.js";

export class User extends AbOrm {
    static entity = 'users'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        first_name: "string",
        last_name: "string",
        email: "string",
        password: "string",
        token: "string",
        roles: "json",
    }

    /**
     * Prepare data according to users access level
     * @return {boolean}
     * @param row
     * @param mode
     */
    static async prepareData(row, mode) {
        // Get current user
        const cu = this.app().auth().getUser();

        // Admin or current user
        return this.app().auth().hasRole("admin") || cu === row.id ? row : false
    }

    /**
     * Functions list
     */
    async channels() {
        return {
            'admin-users': {
                subscribe: () => 'admin-users',
                init: async () => this.getAdminList(),
            }
        }
    }

    /**
     * Get admin users list
     */
    getAdminList() {
        // Check if user has admin access
        if (this.app().auth().hasRole("admin")) {
            return this.query([
              'id',
              'first_name',
              'last_name',
              'email',
              'roles',
              'deleted',
            ]).get()
        }
    }
}
