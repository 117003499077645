<template>
  <ab-flow-base-cmp :block="block" class="carousel-editor-cmp relative-position" :class="classesString" :style="stylesString" :is_container="true" :draggable="false">
    <q-carousel
        v-model="currentSlide"
        animated
        :arrows="hasArrows"
        :navigation="hasNavigation"
        :autoplay="slideInterval"
        infinite
        class="full-width full-height"
    >
      <q-carousel-slide v-for="(item, k) of slides" :name="k" class="column col" :class="{'no-pointer-events': !isRepeater}" :key="k">
        <template v-if="isRepeater">
          <data-provider :storage-key="block?.title" :data="{item:item}">
            <ab-flow-components-renderer :items="block.children"/>
          </data-provider>
        </template>
        <template v-else>
          <ab-flow-components-renderer v-if="item" :items="item.children"/>
        </template>
      </q-carousel-slide>

    </q-carousel>

    <ab-flow-link-connector v-if="!isRepeater" :block="block" type="output" class="connector-center" event="children"/>
  </ab-flow-base-cmp>
</template>

<script>

import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";
import AbFlowComponentsRenderer from "ab-flow-designer/src/components/Designer/AbFlowComponentsRenderer";
import DataProvider from '@/components/DiagramDesigner/Editor/components/UI/Data/DataProvider/DataProvider.vue';
import {StorageNode} from '../../../../../../../../../common/db/StorageNode';
import {DbTable} from '../../../../../../../../../common/db/DbTable';
import {DbTableField} from '../../../../../../../../../common/db/DbTableField';

export default {
  components: {DataProvider, AbFlowBaseCmp, AbFlowLinkConnector, AbFlowComponentsRenderer},
  mixins: [renderMixins],
  inject: {
    canvas: {
      default: false
    },
    animation_frame: {
      default: 0
    }
  },
  data: () => ({
    currentSlide: 0
  }),
  props: ['block'],
  name: "CarouselEditorCmp",
  computed: {

    /**
     * Slide interval
     * @return {number|number}
     */
    slideInterval() {
      return this.block?.properties?.isAutoSlide === 1 ? this.block?.properties?.autoSlideInterval * 1000 : 0
    },

    /**
     * Has arrows
     * @return {boolean}
     */
    hasArrows() {
      return this.block?.properties?.hasArrows === 1 || false
    },

    /**
     * Has navigation
     * @return {boolean}
     */
    hasNavigation() {
      return this.block?.properties?.hasNavigation === 1 || false
    },

    /**
     * Returns the current widget. If the parent widget is not defined, it returns the designer.
     * @return {Object} The parent widget or the designer.
     */
    currentWidget() {
      return this.parentWidget || this.designer;
    },

    /**
     * Returns an array of links that are children of the current widget and have a source id equal to the block id.
     * If the current widget or its children are not defined, it returns an empty array.
     * @return {Array} An array of link objects.
     */
    currentLinks() {
      return (this.currentWidget?.source?.children || []).filter(
        (v) => v.type === 'link' && v.properties?.connection?.source?.id === this.block.id
      ) || [];
    },

    /**
     * Returns an array of blocks that are children of the current widget and are not of type 'link'.
     * If the current widget or its children are not defined, it returns an empty array.
     * @return {Array} An array of block objects.
     */
    currentBlocks() {
      return (this.currentWidget?.source?.children || []).filter((v) => v.type !== 'link');
    },

    /**
     * Get slides list
     * @return {*}
     */
    slides() {
      if (this.isRepeater) {
        return this.items;
      }

      // Fragments
      const frs = []

      // Get slides list
      const slides = this.currentLinks?.map(l => l.properties?.connection?.target?.id)

      // Get slides
      for(const sl of slides) {
        const fr = this.currentBlocks.find(bl => bl.id === sl)
        if(fr) frs.push(fr)
      }

      // Get parent widget
      return frs
    },

    /**
     * Check if repeater
     * @return {boolean}
     */
    isRepeater() {
      return this.block?.properties?.slideSource === 'dataSource';
    },

    /**
     * Return items list
     * @returns {*|*[]}
     */
    items() {
      if (!this.isRepeater) {
        return [];
      }

      return this.wait("items", async () => {

        // Init items
        let items = [];

        // Load repeater storage node
        const node = await StorageNode.find(this.block?.properties?.dataSource?.nodeId);

        // Check for db record
        if(node?.type === "db-record") {
          const item = {};

          // Load table
          const table = await DbTable.find(node.db_table)

          // check if table has data
          if(table?.table_data?.length) {
            return (table.table_data || []).slice(0, 20);
          } else if(table?.test_table_data?.length) {
            return (table.test_table_data || []).slice(0, 20);
          } else if (node?.value && node?.is_test_value) {
            return this.getValue(this.block?.properties?.dataSource) || node?.value || [{}];
          }

          // Load table fields
          for(const fld of await DbTableField.query().where("table_id", node.db_table).get()) {
            item[fld.name] = fld.description || `{db:${fld.name}}`;
          }

          // Add to items
          items.push(item);

        } else {

          // get items from node
          items = this.getValue(this.block?.properties?.dataSource) || node?.value || [{}];
        }

        // Convert item elements to object
        items = (Array.isArray(items) ? items : []).map(el => {
          return typeof el === 'object' ? el : {value: el}
        })

        // Return list
        return items.length ? items.slice(0, 20) : [{}];
      }, [])
    }
  }
}

</script>

<style lang="scss">

.carousel-editor-cmp {
  .q-carousel__slide {
    padding: 0;
  }

  .q-carousel__navigation-icon, .q-carousel__arrow {
    color: var(--foreground-color-primary);
  }
}

</style>
