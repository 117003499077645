<template>
  <ui-prop-media-picker title="3D model" name="object" media-type="fbx" :block="block" />

  <ui-prop-media-picker title="Animation" name="animation" media-type="fbx" :block="block" />

  <q-card class="q-my-md">
    <q-card-section class="bg-primary text-white">
      Position (float)
    </q-card-section>

    <q-card-section class="q-pt-none flex q-gutter-sm no-wrap">
      <ui-prop-string :block="block" name="positionX" title="X" default-value="0" />
      <ui-prop-string :block="block" name="positionY" title="Y" default-value="0" />
      <ui-prop-string :block="block" name="positionZ" title="Z" default-value="0" />
    </q-card-section>
  </q-card>

  <q-card class="q-my-md">
    <q-card-section class="bg-primary text-white">
      Scale (float)
    </q-card-section>

    <q-card-section class="q-pt-none flex q-gutter-sm no-wrap">
      <ui-prop-string :block="block" name="scaleX" title="X" default-value="1" />
      <ui-prop-string :block="block" name="scaleY" title="Y" default-value="1" />
      <ui-prop-string :block="block" name="scaleZ" title="Z" default-value="1" />
    </q-card-section>
  </q-card>

  <q-card class="q-my-md">
    <q-card-section class="bg-primary text-white">
      Rotation (float)
    </q-card-section>

    <q-card-section class="q-pt-none flex q-gutter-sm no-wrap">
      <ui-prop-string :block="block" name="rotationX" title="X" default-value="0" />
      <ui-prop-string :block="block" name="rotationY" title="Y" default-value="0" />
      <ui-prop-string :block="block" name="rotationZ" title="Z" default-value="0" />
    </q-card-section>
  </q-card>
</template>

<script>
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropMediaPicker from '@/components/DiagramDesigner/Editor/properties/UiPropMediaPicker.vue';

export default {
  mixins: [propertiesMixins],
  name: "ThreeDObjectPropsCmp",
  props: ['block'],
  components: {UiPropMediaPicker, UiPropString },
}

</script>

