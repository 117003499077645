export const countryList = [
    {
        "criterion": 2004,
        "title": "Afghanistan",
        "value": "AF"
    },
    {
        "criterion": 2008,
        "title": "Albania",
        "value": "AL"
    },
    {
        "criterion": 2010,
        "title": "Antarctica",
        "value": "AQ"
    },
    {
        "criterion": 2012,
        "title": "Algeria",
        "value": "DZ"
    },
    {
        "criterion": 2016,
        "title": "American Samoa",
        "value": "AS"
    },
    {
        "criterion": 2020,
        "title": "Andorra",
        "value": "AD"
    },
    {
        "criterion": 2024,
        "title": "Angola",
        "value": "AO"
    },
    {
        "criterion": 2028,
        "title": "Antigua and Barbuda",
        "value": "AG"
    },
    {
        "criterion": 2031,
        "title": "Azerbaijan",
        "value": "AZ"
    },
    {
        "criterion": 2032,
        "title": "Argentina",
        "value": "AR"
    },
    {
        "criterion": 2036,
        "title": "Australia",
        "value": "AU"
    },
    {
        "criterion": 2040,
        "title": "Austria",
        "value": "AT"
    },
    {
        "criterion": 2044,
        "title": "The Bahamas",
        "value": "BS"
    },
    {
        "criterion": 2048,
        "title": "Bahrain",
        "value": "BH"
    },
    {
        "criterion": 2050,
        "title": "Bangladesh",
        "value": "BD"
    },
    {
        "criterion": 2051,
        "title": "Armenia",
        "value": "AM"
    },
    {
        "criterion": 2052,
        "title": "Barbados",
        "value": "BB"
    },
    {
        "criterion": 2056,
        "title": "Belgium",
        "value": "BE"
    },
    {
        "criterion": 2064,
        "title": "Bhutan",
        "value": "BT"
    },
    {
        "criterion": 2068,
        "title": "Bolivia",
        "value": "BO"
    },
    {
        "criterion": 2070,
        "title": "Bosnia and Herzegovina",
        "value": "BA"
    },
    {
        "criterion": 2072,
        "title": "Botswana",
        "value": "BW"
    },
    {
        "criterion": 2076,
        "title": "Brazil",
        "value": "BR"
    },
    {
        "criterion": 2084,
        "title": "Belize",
        "value": "BZ"
    },
    {
        "criterion": 2090,
        "title": "Solomon Islands",
        "value": "SB"
    },
    {
        "criterion": 2096,
        "title": "Brunei",
        "value": "BN"
    },
    {
        "criterion": 2100,
        "title": "Bulgaria",
        "value": "BG"
    },
    {
        "criterion": 2104,
        "title": "Myanmar (Burma)",
        "value": "MM"
    },
    {
        "criterion": 2108,
        "title": "Burundi",
        "value": "BI"
    },
    /*{
        "criterion": 2112,
        "title": "Belarus",
        "value": "BY"
    },*/
    {
        "criterion": 2116,
        "title": "Cambodia",
        "value": "KH"
    },
    {
        "criterion": 2120,
        "title": "Cameroon",
        "value": "CM"
    },
    {
        "criterion": 2124,
        "title": "Canada",
        "value": "CA"
    },
    {
        "criterion": 2132,
        "title": "Cape Verde",
        "value": "CV"
    },
    {
        "criterion": 2140,
        "title": "Central African Republic",
        "value": "CF"
    },
    {
        "criterion": 2144,
        "title": "Sri Lanka",
        "value": "LK"
    },
    {
        "criterion": 2148,
        "title": "Chad",
        "value": "TD"
    },
    {
        "criterion": 2152,
        "title": "Chile",
        "value": "CL"
    },
    {
        "criterion": 2156,
        "title": "China",
        "value": "CN"
    },
    {
        "criterion": 2162,
        "title": "Christmas Island",
        "value": "CX"
    },
    {
        "criterion": 2166,
        "title": "Cocos (Keeling) Islands",
        "value": "CC"
    },
    {
        "criterion": 2170,
        "title": "Colombia",
        "value": "CO"
    },
    {
        "criterion": 2174,
        "title": "Comoros",
        "value": "KM"
    },
    {
        "criterion": 2178,
        "title": "Republic of the Congo",
        "value": "CG"
    },
    {
        "criterion": 2180,
        "title": "Democratic Republic of the Congo",
        "value": "CD"
    },
    {
        "criterion": 2184,
        "title": "Cook Islands",
        "value": "CK"
    },
    {
        "criterion": 2188,
        "title": "Costa Rica",
        "value": "CR"
    },
    {
        "criterion": 2191,
        "title": "Croatia",
        "value": "HR"
    },
    {
        "criterion": 2196,
        "title": "Cyprus",
        "value": "CY"
    },
    {
        "criterion": 2203,
        "title": "Czechia",
        "value": "CZ"
    },
    {
        "criterion": 2204,
        "title": "Benin",
        "value": "BJ"
    },
    {
        "criterion": 2208,
        "title": "Denmark",
        "value": "DK"
    },
    {
        "criterion": 2212,
        "title": "Dominica",
        "value": "DM"
    },
    {
        "criterion": 2214,
        "title": "Dominican Republic",
        "value": "DO"
    },
    {
        "criterion": 2218,
        "title": "Ecuador",
        "value": "EC"
    },
    {
        "criterion": 2222,
        "title": "El Salvador",
        "value": "SV"
    },
    {
        "criterion": 2226,
        "title": "Equatorial Guinea",
        "value": "GQ"
    },
    {
        "criterion": 2231,
        "title": "Ethiopia",
        "value": "ET"
    },
    {
        "criterion": 2232,
        "title": "Eritrea",
        "value": "ER"
    },
    {
        "criterion": 2233,
        "title": "Estonia",
        "value": "EE"
    },
    {
        "criterion": 2239,
        "title": "South Georgia and the South Sandwich Islands",
        "value": "GS"
    },
    {
        "criterion": 2242,
        "title": "Fiji",
        "value": "FJ"
    },
    {
        "criterion": 2246,
        "title": "Finland",
        "value": "FI"
    },
    {
        "criterion": 2250,
        "title": "France",
        "value": "FR"
    },
    {
        "criterion": 2258,
        "title": "French Polynesia",
        "value": "PF"
    },
    {
        "criterion": 2260,
        "title": "French Southern and Antarctic Lands",
        "value": "TF"
    },
    {
        "criterion": 2262,
        "title": "Djibouti",
        "value": "DJ"
    },
    {
        "criterion": 2266,
        "title": "Gabon",
        "value": "GA"
    },
    {
        "criterion": 2268,
        "title": "Georgia",
        "value": "GE"
    },
    {
        "criterion": 2270,
        "title": "The Gambia",
        "value": "GM"
    },
    {
        "criterion": 2276,
        "title": "Germany",
        "value": "DE"
    },
    {
        "criterion": 2288,
        "title": "Ghana",
        "value": "GH"
    },
    {
        "criterion": 2296,
        "title": "Kiribati",
        "value": "KI"
    },
    {
        "criterion": 2300,
        "title": "Greece",
        "value": "GR"
    },
    {
        "criterion": 2308,
        "title": "Grenada",
        "value": "GD"
    },
    {
        "criterion": 2316,
        "title": "Guam",
        "value": "GU"
    },
    {
        "criterion": 2320,
        "title": "Guatemala",
        "value": "GT"
    },
    {
        "criterion": 2324,
        "title": "Guinea",
        "value": "GN"
    },
    {
        "criterion": 2328,
        "title": "Guyana",
        "value": "GY"
    },
    {
        "criterion": 2332,
        "title": "Haiti",
        "value": "HT"
    },
    {
        "criterion": 2334,
        "title": "Heard Island and McDonald Islands",
        "value": "HM"
    },
    {
        "criterion": 2336,
        "title": "Vatican City",
        "value": "VA"
    },
    {
        "criterion": 2340,
        "title": "Honduras",
        "value": "HN"
    },
    {
        "criterion": 2348,
        "title": "Hungary",
        "value": "HU"
    },
    {
        "criterion": 2352,
        "title": "Iceland",
        "value": "IS"
    },
    {
        "criterion": 2356,
        "title": "India",
        "value": "IN"
    },
    {
        "criterion": 2360,
        "title": "Indonesia",
        "value": "ID"
    },
    {
        "criterion": 2368,
        "title": "Iraq",
        "value": "IQ"
    },
    {
        "criterion": 2372,
        "title": "Ireland",
        "value": "IE"
    },
    {
        "criterion": 2376,
        "title": "Israel",
        "value": "IL"
    },
    {
        "criterion": 2380,
        "title": "Italy",
        "value": "IT"
    },
    {
        "criterion": 2384,
        "title": "Cote d'Ivoire",
        "value": "CI"
    },
    {
        "criterion": 2388,
        "title": "Jamaica",
        "value": "JM"
    },
    {
        "criterion": 2392,
        "title": "Japan",
        "value": "JP"
    },
    {
        "criterion": 2398,
        "title": "Kazakhstan",
        "value": "KZ"
    },
    {
        "criterion": 2400,
        "title": "Jordan",
        "value": "JO"
    },
    {
        "criterion": 2404,
        "title": "Kenya",
        "value": "KE"
    },
    {
        "criterion": 2410,
        "title": "South Korea",
        "value": "KR"
    },
    {
        "criterion": 2414,
        "title": "Kuwait",
        "value": "KW"
    },
    {
        "criterion": 2417,
        "title": "Kyrgyzstan",
        "value": "KG"
    },
    {
        "criterion": 2418,
        "title": "Laos",
        "value": "LA"
    },
    {
        "criterion": 2422,
        "title": "Lebanon",
        "value": "LB"
    },
    {
        "criterion": 2426,
        "title": "Lesotho",
        "value": "LS"
    },
    {
        "criterion": 2428,
        "title": "Latvia",
        "value": "LV"
    },
    {
        "criterion": 2430,
        "title": "Liberia",
        "value": "LR"
    },
    {
        "criterion": 2434,
        "title": "Libya",
        "value": "LY"
    },
    {
        "criterion": 2438,
        "title": "Liechtenstein",
        "value": "LI"
    },
    {
        "criterion": 2440,
        "title": "Lithuania",
        "value": "LT"
    },
    {
        "criterion": 2442,
        "title": "Luxembourg",
        "value": "LU"
    },
    {
        "criterion": 2450,
        "title": "Madagascar",
        "value": "MG"
    },
    {
        "criterion": 2454,
        "title": "Malawi",
        "value": "MW"
    },
    {
        "criterion": 2458,
        "title": "Malaysia",
        "value": "MY"
    },
    {
        "criterion": 2462,
        "title": "Maldives",
        "value": "MV"
    },
    {
        "criterion": 2466,
        "title": "Mali",
        "value": "ML"
    },
    {
        "criterion": 2470,
        "title": "Malta",
        "value": "MT"
    },
    {
        "criterion": 2478,
        "title": "Mauritania",
        "value": "MR"
    },
    {
        "criterion": 2480,
        "title": "Mauritius",
        "value": "MU"
    },
    {
        "criterion": 2484,
        "title": "Mexico",
        "value": "MX"
    },
    {
        "criterion": 2492,
        "title": "Monaco",
        "value": "MC"
    },
    {
        "criterion": 2496,
        "title": "Mongolia",
        "value": "MN"
    },
    {
        "criterion": 2498,
        "title": "Moldova",
        "value": "MD"
    },
    {
        "criterion": 2499,
        "title": "Montenegro",
        "value": "ME"
    },
    {
        "criterion": 2504,
        "title": "Morocco",
        "value": "MA"
    },
    {
        "criterion": 2508,
        "title": "Mozambique",
        "value": "MZ"
    },
    {
        "criterion": 2512,
        "title": "Oman",
        "value": "OM"
    },
    {
        "criterion": 2516,
        "title": "Namibia",
        "value": "NA"
    },
    {
        "criterion": 2520,
        "title": "Nauru",
        "value": "NR"
    },
    {
        "criterion": 2524,
        "title": "Nepal",
        "value": "NP"
    },
    {
        "criterion": 2528,
        "title": "Netherlands",
        "value": "NL"
    },
    {
        "criterion": 2531,
        "title": "Curacao",
        "value": "CW"
    },
    {
        "criterion": 2534,
        "title": "Sint Maarten",
        "value": "SX"
    },
    {
        "criterion": 2535,
        "title": "Caribbean Netherlands",
        "value": "BQ"
    },
    {
        "criterion": 2540,
        "title": "New Caledonia",
        "value": "NC"
    },
    {
        "criterion": 2548,
        "title": "Vanuatu",
        "value": "VU"
    },
    {
        "criterion": 2554,
        "title": "New Zealand",
        "value": "NZ"
    },
    {
        "criterion": 2558,
        "title": "Nicaragua",
        "value": "NI"
    },
    {
        "criterion": 2562,
        "title": "Niger",
        "value": "NE"
    },
    {
        "criterion": 2566,
        "title": "Nigeria",
        "value": "NG"
    },
    {
        "criterion": 2570,
        "title": "Niue",
        "value": "NU"
    },
    {
        "criterion": 2574,
        "title": "Norfolk Island",
        "value": "NF"
    },
    {
        "criterion": 2578,
        "title": "Norway",
        "value": "NO"
    },
    {
        "criterion": 2580,
        "title": "Northern Mariana Islands",
        "value": "MP"
    },
    {
        "criterion": 2581,
        "title": "United States Minor Outlying Islands",
        "value": "UM"
    },
    {
        "criterion": 2583,
        "title": "Federated States of Micronesia",
        "value": "FM"
    },
    {
        "criterion": 2584,
        "title": "Marshall Islands",
        "value": "MH"
    },
    {
        "criterion": 2585,
        "title": "Palau",
        "value": "PW"
    },
    {
        "criterion": 2586,
        "title": "Pakistan",
        "value": "PK"
    },
    {
        "criterion": 2591,
        "title": "Panama",
        "value": "PA"
    },
    {
        "criterion": 2598,
        "title": "Papua New Guinea",
        "value": "PG"
    },
    {
        "criterion": 2600,
        "title": "Paraguay",
        "value": "PY"
    },
    {
        "criterion": 2604,
        "title": "Peru",
        "value": "PE"
    },
    {
        "criterion": 2608,
        "title": "Philippines",
        "value": "PH"
    },
    {
        "criterion": 2612,
        "title": "Pitcairn Islands",
        "value": "PN"
    },
    {
        "criterion": 2616,
        "title": "Poland",
        "value": "PL"
    },
    {
        "criterion": 2620,
        "title": "Portugal",
        "value": "PT"
    },
    {
        "criterion": 2624,
        "title": "Guinea-Bissau",
        "value": "GW"
    },
    {
        "criterion": 2626,
        "title": "Timor-Leste",
        "value": "TL"
    },
    {
        "criterion": 2634,
        "title": "Qatar",
        "value": "QA"
    },
    {
        "criterion": 2642,
        "title": "Romania",
        "value": "RO"
    },
    /*{
        "criterion": 2643,
        "title": "Russia",
        "value": "RU"
    },*/
    {
        "criterion": 2646,
        "title": "Rwanda",
        "value": "RW"
    },
    {
        "criterion": 2652,
        "title": "Saint Barthelemy",
        "value": "BL"
    },
    {
        "criterion": 2654,
        "title": "Saint Helena, Ascension and Tristan da Cunha",
        "value": "SH"
    },
    {
        "criterion": 2659,
        "title": "Saint Kitts and Nevis",
        "value": "KN"
    },
    {
        "criterion": 2662,
        "title": "Saint Lucia",
        "value": "LC"
    },
    {
        "criterion": 2663,
        "title": "Saint Martin",
        "value": "MF"
    },
    {
        "criterion": 2666,
        "title": "Saint Pierre and Miquelon",
        "value": "PM"
    },
    {
        "criterion": 2670,
        "title": "Saint Vincent and the Grenadines",
        "value": "VC"
    },
    {
        "criterion": 2674,
        "title": "San Marino",
        "value": "SM"
    },
    {
        "criterion": 2678,
        "title": "Sao Tome and Principe",
        "value": "ST"
    },
    {
        "criterion": 2682,
        "title": "Saudi Arabia",
        "value": "SA"
    },
    {
        "criterion": 2686,
        "title": "Senegal",
        "value": "SN"
    },
    {
        "criterion": 2688,
        "title": "Serbia",
        "value": "RS"
    },
    {
        "criterion": 2690,
        "title": "Seychelles",
        "value": "SC"
    },
    {
        "criterion": 2694,
        "title": "Sierra Leone",
        "value": "SL"
    },
    {
        "criterion": 2702,
        "title": "Singapore",
        "value": "SG"
    },
    {
        "criterion": 2703,
        "title": "Slovakia",
        "value": "SK"
    },
    {
        "criterion": 2704,
        "title": "Vietnam",
        "value": "VN"
    },
    {
        "criterion": 2705,
        "title": "Slovenia",
        "value": "SI"
    },
    {
        "criterion": 2706,
        "title": "Somalia",
        "value": "SO"
    },
    {
        "criterion": 2710,
        "title": "South Africa",
        "value": "ZA"
    },
    {
        "criterion": 2716,
        "title": "Zimbabwe",
        "value": "ZW"
    },
    {
        "criterion": 2724,
        "title": "Spain",
        "value": "ES"
    },
    {
        "criterion": 2728,
        "title": "South Sudan",
        "value": "SS"
    },
    {
        "criterion": 2736,
        "title": "Sudan",
        "value": "SD"
    },
    {
        "criterion": 2740,
        "title": "Surititle",
        "value": "SR"
    },
    {
        "criterion": 2748,
        "title": "Eswatini",
        "value": "SZ"
    },
    {
        "criterion": 2752,
        "title": "Sweden",
        "value": "SE"
    },
    {
        "criterion": 2756,
        "title": "Switzerland",
        "value": "CH"
    },
    {
        "criterion": 2762,
        "title": "Tajikistan",
        "value": "TJ"
    },
    {
        "criterion": 2764,
        "title": "Thailand",
        "value": "TH"
    },
    {
        "criterion": 2768,
        "title": "Togo",
        "value": "TG"
    },
    {
        "criterion": 2772,
        "title": "Tokelau",
        "value": "TK"
    },
    {
        "criterion": 2776,
        "title": "Tonga",
        "value": "TO"
    },
    {
        "criterion": 2780,
        "title": "Trinidad and Tobago",
        "value": "TT"
    },
    {
        "criterion": 2784,
        "title": "United Arab Emirates",
        "value": "AE"
    },
    {
        "criterion": 2788,
        "title": "Tunisia",
        "value": "TN"
    },
    {
        "criterion": 2792,
        "title": "Türkiye",
        "value": "TR"
    },
    {
        "criterion": 2795,
        "title": "Turkmenistan",
        "value": "TM"
    },
    {
        "criterion": 2798,
        "title": "Tuvalu",
        "value": "TV"
    },
    {
        "criterion": 2800,
        "title": "Uganda",
        "value": "UG"
    },
    /*{
        "criterion": 2804,
        "title": "Ukraine",
        "value": "UA"
    },*/
    {
        "criterion": 2807,
        "title": "North Macedonia",
        "value": "MK"
    },
    {
        "criterion": 2818,
        "title": "Egypt",
        "value": "EG"
    },
    {
        "criterion": 2826,
        "title": "United Kingdom",
        "value": "GB"
    },
    {
        "criterion": 2831,
        "title": "Guernsey",
        "value": "GG"
    },
    {
        "criterion": 2832,
        "title": "Jersey",
        "value": "JE"
    },
    {
        "criterion": 2834,
        "title": "Tanzania",
        "value": "TZ"
    },
    {
        "criterion": 2840,
        "title": "United States",
        "value": "US"
    },
    {
        "criterion": 2854,
        "title": "Burkina Faso",
        "value": "BF"
    },
    {
        "criterion": 2858,
        "title": "Uruguay",
        "value": "UY"
    },
    {
        "criterion": 2860,
        "title": "Uzbekistan",
        "value": "UZ"
    },
    {
        "criterion": 2862,
        "title": "Venezuela",
        "value": "VE"
    },
    {
        "criterion": 2876,
        "title": "Wallis and Futuna",
        "value": "WF"
    },
    {
        "criterion": 2882,
        "title": "Samoa",
        "value": "WS"
    },
    {
        "criterion": 2887,
        "title": "Yemen",
        "value": "YE"
    },
    {
        "criterion": 2894,
        "title": "Zambia",
        "value": "ZM"
    }
]
