<template>

  <div>

    <ui-prop-string name="title" title="Selection title" :block="block"/>
    <ui-prop-select :block="block" name="dbId" title="Database" :options="dbsList"/>
    <ui-prop-select :block="block" name="tableId" title="Table" :options="tablesList"/>
    <ui-prop-checkbox :block="block" title="Query single row" name="singleRow"/>
    <ui-prop-checkbox :block="block" title="Raw result" name="rawResult"/>
    <q-separator/>
    <ui-prop-dynamic-string  :multiple="true" v-if="block?.properties?.dbId" :block="block" name="query" title="DB Query"/>
    <ui-prop-value-selector only-var-type="object,db-record" :block="block" title="Variable to save result" name="variableToSave" value-type="setter" :parent-diagram-id="diagram_id"/>

    <ui-prop-custom-list-props name="mapping" title="Results mapping" :block="block">
      <template v-slot:item="data">
        <q-input v-model="data.item.name" label="Property name"/>
        <div class="row">
          <q-select class="col" v-model="data.item.type" :options="globals.options.data_types" label="Property type" map-options emit-value/>
        </div>
        <value-selector v-model="data.item.value" :app-id="product_id" :block-id="block.id" :parent-diagram-id="diagram_id" :module-id="module_id"/>
      </template>
    </ui-prop-custom-list-props>

  </div>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import {DbModule} from "@/../../common/db/DbModule";
import {DbTable} from "@/../../common/db/DbTable";
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";
import UiPropDynamicString from "@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import UiPropCheckbox from "@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue";
import ValueSelector from "@/components/ValueSelector/ValueSelector.vue";
import UiPropCustomListProps from "@/components/DiagramDesigner/Editor/properties/UiPropCustomListProps.vue";

export default {
  mixins: [propertiesMixins],
  components: {
    UiPropCustomListProps,
    ValueSelector,
    UiPropCheckbox, UiPropValueSelector, UiPropDynamicString, UiPropSelect, UiPropString},
  props: ['block'],
  name: "DbQueryPropsCmp",
  inject: ["currentModule"],

  data: () => ({
  }),

  computed: {

    /**
     * Get dbs list
     * @return {*}
     */
    dbsList() {
      return this.wait("dbsList", async () => {
        return (await DbModule.query().where({app_id: this.product_id}).get())
          .filter((d) => parseInt(d.module_id) === parseInt(this.module_id) || this.currentModule.modules[d.module_id]?.type === "server")
          .map(d => ({
            value: d.id,
            label: d.name + " (" + this.currentModule.modules[d.module_id]?.name + ")"
          }));
      }, []);
    },

    /**
     * Get tables list
     * @return {*}
     */
    tablesList() {
      return this.wait("tablesList", async () => {
        return (await DbTable.query().where({db_id: this.block?.properties?.dbId || 0}).get()).map(d => ({
          value: d.id,
          label: d.name
        }));
      }, []);
    },
  }
}

</script>
