/**
 * DbRelease model
 */
import {AccessModel} from './AccessModel.js';

export class SchemaReleaseHistory extends AccessModel {
    static entity = 'schema_release_history'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        release_key: "string",
        module_id: "int",
        app_id: "int",
        code_version: "int",
        schema_source: "json",
        schema_version: "int",
        schema_update_time: "int",
    }

    /**
     * Functions list
     */
    async channels() {
        return {
            'schema-release-history': {
                subscribe: ({module_id, code_version, schema_version}) => `${module_id}-${code_version}-${schema_version}`,
                init: async ({module_id, code_version, schema_version}) => this.getVersion(module_id, code_version, schema_version),
            },
            'schema-releases': {
                subscribe: ({module_id}) => `${module_id}`,
                init: async ({module_id}) => this.getList(module_id),
            }
        }
    }

    /**
     * Get version
     */
    async getVersion(module_id, code_version, schema_version) {
        return this.query(["id", "app_id", "schema_source"]).where({module_id, code_version, schema_version}).first()
    }

    /**
     * Get list
     */
    async getList(module_id) {
        return this.query(["id", "app_id", "module_id", "code_version", "schema_version"]).where({module_id}).get()
    }
}
