<template>

    <ui-prop-select :block="block" title="Vibration type" name="vibrationType" :options="globals.options.vibrationTypes"/>

</template>

<script>

import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";

export default {
  inject: ['product_id'],
  components: {UiPropSelect},
  props: ['block'],
  name: "PlaySoundPropsCmp",
  computed: {
  }
}

</script>
