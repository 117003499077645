<template>
  <div class="analytics-filter q-gutter-x-md q-mb-sm" v-if="isReady">

    <q-dialog ref="linkProperties">
      <q-card class="full-width">
        <q-toolbar class="bg-primary text-white">
          <q-toolbar-title>
            Link statistics: #{{selectedEvent?.event_id}}
          </q-toolbar-title>
          <q-btn flat round icon="close" @click="$refs.linkProperties.hide()"/>
        </q-toolbar>
        <q-card-section>
          <q-form @submit="saveEvent" ref="eventForm">
            <q-input v-model="selectedEvent.title" label="Event title" hint="Type event title for future analytics"
                     lazy-rules
                     :rules="[ val => val && val.length > 0 || 'Please type something']"
            />
            <q-checkbox v-model="selectedEvent.is_kpi" label="Included to KPIs" :true-value="1" :false-value="0"/>
            <q-separator spaced/>

            <div class="row">
              <q-btn label="Save" type="submit" color="primary" flat/>
              <q-space/>
              <q-btn label="Delete" color="red" icon="delete" flat @click="deleteEvent"/>
            </div>
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>

    <div class="row q-gutter-x-sm">
      <date-picker v-model="dateFrom" label="Date from" class="col"/>
      <date-picker v-model="dateTo" label="Date to" class="col"/>
      <q-select class="col" label="Platform" v-model="appPlatform" emit-value map-options :options="platformList" popup-no-route-dismiss/>
      <q-select class="col" label="Code version" v-model="codeVersion" emit-value map-options :options="codeVersions" popup-no-route-dismiss/>
      <q-checkbox v-if="showAppRemove" v-model.number="appRemove" label="App remove" :true-value="1" :false-value="0" />
      <!--q-select class="col" label="Schema version" v-model="schemaVersion" emit-value map-options :options="schemaVersions"/!-->
    </div>
    <div class="row q-gutter-x-sm">
      <q-select
        class="col"
        :use-input="true"
        multiple
        label="Countries"
        @filter="filterFnCountries"
        v-model="countries"
        :options="countriesOptions"
        popup-no-route-dismiss
      />

      <q-select
        class="col"
        ref="requiredEvents"
        :use-input="true"
        multiple
        label="Required events"
        @filter="filterFn"
        v-model="requiredEvents"
        emit-value
        map-options
        :options="selectEventsListOptions"
        popup-no-route-dismiss
      >
        <template #option="{opt, itemProps}">
          <div :key="opt.value">
            <q-item v-if="!opt.group">
              <q-item-section class="cursor-pointer" v-bind="itemProps">
                <q-item-label class="q-pl-md">{{ opt.label }}</q-item-label>
              </q-item-section>

              <q-btn v-if="opt?.editable" @click="editEvent(opt.value)" flat size="sm" icon="edit" />
            </q-item>
            <q-item v-else>
              <q-item-section>
                <q-item-label overline>{{ opt.group }}</q-item-label>
              </q-item-section>
            </q-item>
          </div>
        </template>

        <template #no-option>
          <q-item>
            <q-item-section class="text-grey">
              No results
            </q-item-section>
          </q-item>
        </template>
      </q-select>

      <q-select v-model="currentTemplateId" label="Events template" style="width: 150px" emit-value map-options :options="eventTemplates" popup-no-route-dismiss/>
      <q-btn :label="currentTemplateId ? 'Save template' : 'Create template' " icon="save" color="secondary" flat class="bg-grey-2" @click.prevent.stop="saveAsTemplate"/>
      <q-btn v-if="currentTemplateId" icon="delete" flat class="bg-red-1" @click.prevent.stop="deleteTemplate"/>
      <q-btn label="Apply filter" class="bg-grey-2" icon="send" flat @click="applyFilter"/>
    </div>

    <div v-if="showRevenue" class="row q-gutter-x-sm">
      <q-select class="col" label="Revenue type" v-model="revenue" emit-value map-options :options="revenueList" popup-no-route-dismiss/>
    </div>

    <div v-if="['web', 'mobile'].includes(currentModule?.type)" class="row q-gutter-x-sm">
      <q-input class="col" v-model="utmSource" label="UTM Source" />
      <q-input class="col" v-model="utmMedium" label="UTM Medium" />
      <q-input class="col" v-model="utmCampaign" label="UTM Campaign" />
      <q-input class="col" v-model="utmContent" label="UTM Content" />
    </div>
  </div>
</template>

<script>

import {SchemaReleaseHistory} from "../../../../../common/db/SchemaReleaseHistory";
import {AppRelease} from "../../../../../common/db/AppRelease";
import DatePicker from "@/components/DatePicker/DatePicker.vue";
import moment from "moment";
import {AnalyticsEvent} from "../../../../../common/db/AnalyticsEvent";
import {AnalyticsEventTemplate} from "../../../../../common/db/AnalyticsEventTemplate";
import {Diagram} from '../../../../../common/db/Diagram';
import {AppModule} from '../../../../../common/db/AppModule';

// Filtration fields
const FILTER_FIELDS = [
  'currentTemplateId', 'appPlatform', 'codeVersion', 'schemaVersion',
  'dateFrom', 'dateTo', 'requiredEvents', 'countries', 'bigQuery', 'appRemove',
  'utmSource', 'utmMedium', 'utmCampaign', 'utmContent'
];

export default {
  name: "AnalyticsFilter",
  components: {DatePicker},
  emits: [
    "update:codeVersion",
    "update:schemaVersion",
    "update:appPlatform",
    "update:dateFrom",
    "update:dateTo",
    "update:requiredEvents",
    "update:countries",
    "update:bigQuery",
    "update:appRemove",
    "update:utmSource",
    "update:utmMedium",
    "update:utmCampaign",
    "update:utmContent",
    "apply-filter",
  ],
  props: [
    "moduleId",
    "modelValue:codeVersion",
    "modelValue:schemaVersion",
    "modelValue:appPlatform",
    "modelValue:dateFrom",
    "modelValue:dateTo",
    "modelValue:requiredEvents",
    "modelValue:countries",
    "modelValue:bigQuery",
    "modelValue:appRemove",
    "modelValue:revenue",
    "modelValue:utmSource",
    "modelValue:utmMedium",
    "modelValue:utmCampaign",
    "modelValue:utmContent",
    'hideBigQuery',
    'showRevenue',
    'showAppRemove',
  ],
  inject: ['main'],
  data: () => ({
    dateFrom: false,
    dateTo: false,
    codeVersion: null,
    appPlatform: null,
    selectEventsListOptions: [],
    currentTemplateId: "",
    schemaVersion: null,
    requiredEvents: [],
    isReady: false,
    countriesOptions: [],
    countries: [],
    bigQuery: 1,
    appRemove: 0,
    revenueList: [
      {value: "", label: "All"},
      {value: "ad_revenue", label: "Ad revenue"},
      {value: "iap_revenue", label: "IAP revenue"},
    ],
    revenue: '',
    utmSource: '',
    utmMedium: '',
    utmCampaign: '',
    utmContent: '',
    selectedEvent: false,
  }),
  async created() {

    // Get initial values from current session settings first
    this.currentTemplateId = this.main.getSettings(`${this.moduleId}.currentTemplateId`)
    this.appPlatform = this.main.getSettings(`${this.moduleId}.appPlatform`) || ""
    this.codeVersion = this.main.getSettings(`${this.moduleId}.codeVersion`)
    this.schemaVersion = this.main.getSettings(`${this.moduleId}.schemaVersion`, 1)
    this.dateFrom = this.main.getSettings(`${this.moduleId}.dateFrom`, moment().subtract(1, "week").format("YYYY/MM/DD"))
    this.dateTo = this.main.getSettings(`${this.moduleId}.dateTo`, moment().format("YYYY/MM/DD"))
    this.requiredEvents = this.main.getSettings(`${this.moduleId}.requiredEvents`, [])
    this.countries = this.main.getSettings(`${this.moduleId}.countries`, [])
    this.bigQuery = this.main.getSettings(`${this.moduleId}.bigQuery`, 1)
    this.appRemove = this.main.getSettings(`${this.moduleId}.appRemove`, 0)
    this.revenue = this.main.getSettings(`${this.moduleId}.revenue`, '')
    this.utmSource = this.main.getSettings(`${this.moduleId}.utmSource`, '')
    this.utmMedium = this.main.getSettings(`${this.moduleId}.utmMedium`, '')
    this.utmCampaign = this.main.getSettings(`${this.moduleId}.utmCampaign`, '')
    this.utmContent = this.main.getSettings(`${this.moduleId}.utmContent`, '')

    // Subscribe to releases
    await AppRelease.remote().subscribe("module-releases", {module_id: this.moduleId})

    // Subscribe to events
    await AnalyticsEvent.remote().subscribe("module-events", {module_id: this.moduleId});

    // Subscribe to diagrams
    await Diagram.remote().subscribe("app-module-diagrams", {module_id: this.moduleId});

    // Subscribe to event templates
    await AnalyticsEventTemplate.remote().subscribe("module-event-templates", {module_id: this.moduleId})

    // Subscribe to schema releases history
    await SchemaReleaseHistory.remote().subscribe("schema-releases", {module_id: this.moduleId})

    // Subscribe to app module
    await AppModule.remote().subscribe("module", {id: this.moduleId})

    // Watch for changes and emit results
    this.$watch("eventsOptions", (val) => {
      this.selectEventsListOptions = this.flatEventsOptions(val);
    }, {immediate: true})
    // Watch for changes and emit results
    this.$watch("codeVersion", () => {
      this.main.setSettings(`${this.moduleId}.codeVersion`, this.codeVersion)
      //this.applyFilter();
    }, {immediate: true})
    this.$watch("appPlatform", () => {
      this.main.setSettings(`${this.moduleId}.appPlatform`, this.appPlatform)
      //this.applyFilter();
    }, {immediate: true})
    /*this.$watch("schemaVersion", () => {
      this.main.setSettings(`${this.moduleId}.schemaVersion`, this.schemaVersion)
      //this.applyFilter();
    }, {immediate: true})*/
    this.$watch("dateFrom", () => {
      this.main.setSettings(`${this.moduleId}.dateFrom`,this.dateFrom)
    }, {immediate: true})
    this.$watch("dateTo", () => {
      this.main.setSettings(`${this.moduleId}.dateTo`,this.dateTo)
    }, {immediate: true})
    this.$watch("currentTemplate", () => {
      this.requiredEvents = this.currentTemplate?.events || []
    }, {deep: true})
    this.$watch("currentTemplateId", (val) => {
      this.main.setSettings(`${this.moduleId}.currentTemplateId`, val)
    }, {deep: true})
    this.$watch("requiredEvents", () => {
      this.main.setSettings(`${this.moduleId}.requiredEvents`,this.requiredEvents)
    }, {immediate: true})
    this.$watch("countries", () => {
      this.main.setSettings(`${this.moduleId}.countries`,this.countries)
    }, {immediate: true});
    this.$watch("bigQuery", () => {
      this.main.setSettings(`${this.moduleId}.bigQuery`,this.bigQuery)
    }, {immediate: true});
    this.$watch("appRemove", () => {
      this.main.setSettings(`${this.moduleId}.appRemove`,this.appRemove)
    }, {immediate: true});
    this.$watch("revenue", () => {
      this.main.setSettings(`${this.moduleId}.revenue`,this.revenue)
    }, {immediate: true});
    this.$watch("utmSource", () => {
      this.main.setSettings(`${this.moduleId}.utmSource`,this.utmSource)
    }, {immediate: true});
    this.$watch("utmMedium", () => {
      this.main.setSettings(`${this.moduleId}.utmMedium`,this.utmMedium)
    }, {immediate: true});
    this.$watch("utmCampaign", () => {
      this.main.setSettings(`${this.moduleId}.utmCampaign`,this.utmCampaign)
    }, {immediate: true});
    this.$watch("utmContent", () => {
      this.main.setSettings(`${this.moduleId}.utmContent`,this.utmContent)
    }, {immediate: true});

    // Restore filter values from query params
    FILTER_FIELDS.forEach(key => {
      let value = this.$route.query[key];

      if (value === undefined) return;

      // Convert string to array if needed
      if (['requiredEvents', 'countries'].includes(key) && !Array.isArray(value)) {
        value = [value];
      }

      // Filter countries
      if (key === 'countries') {
        value = value.filter(v => this.allCountriesOptions.includes(v));
      }

      this[key] = value;
    });

    // Watch for changes in filterQueryParams and update the route
    this.$watch("filterQueryParams", () => {
      this.$router.push({ query: this.filterQueryParams }, { replace: true });
    }, {immediate: true});

    // Ready
    this.isReady = true;
  },
  computed: {

    platformList() {
      return [{value: "", label: "All"}, {value: "Android", label: "Android"}, {value: "iOS", label: "iOS"}, {value: "Web", label: "Web"}];
    },

    /**
     * Get event templates
     * @return {*}
     */
    eventTemplates() {
      return this.wait("eventTemplates", async () => {
        return [...[{value: null, label: "No template"}], ...(await AnalyticsEventTemplate.query().where({module_id: this.moduleId}).get()).map(e => ({
          value: e.id,
          label: e.title
        }))]
      }, [])
    },

    /**
     * Get events list
     * @return {{}|MediaTrackSettings|*}
     */
    eventsList() {
      return this.wait("eventsList", async () => {
        return (await AnalyticsEvent.query().where({module_id: this.moduleId}).get()).map(e => ({
          value: e.event_id,
          label: e.title
        }))
      }, [])
    },

    /**
     * Computed property that retrieves A/B test events.
     * It fetches diagrams, extracts A/B tests and their events, and links them.
     *
     * @returns {Array} An array of objects representing A/B test groups and their events.
     */
    abTestsEvents() {
      return this.wait('abTestsEvents', async () => {
        const components = (await Diagram.query().where({module_id: this.moduleId, status: 'active'}).get())
          .map(d => d?.source?.children || [])
          .flat();

        const activeComponents = new Set(
          components.filter(c => c?.type === 'link')
            .map(c => c?.properties?.connection?.target?.id)
            .filter(Boolean)
        );

        const tests = components.filter(c => c?.type === 'AbTest' && activeComponents.has(c?.id)).map((c) => ({
          id: c.id,
          title: c?.properties?.title || c.title,
          events: (c?.properties?.tests || []).map(t => t.name)
        }));

        const testsId = new Set(tests.map(t => t.id));

        const links = components.filter(c => c?.type === 'link' && testsId.has(c?.properties?.connection?.source?.id))
          .map(c => ({
            id: c.id,
            source_id: c?.properties?.connection?.source?.id,
            event: c?.properties?.connection?.source?.event,
          }));

        return tests.map(t => ({
          group: `A/B Test: ${t.title}`,
          options: t.events.map(e => {
            const link = links.find(l => l.event === e && l.source_id === t.id);

            return link ? {value: link.id, label: e} : null;
          }).filter(Boolean)
        }));
      }, [])
    },

    /**
     * Computed property that returns a list of event options.
     * It combines A/B test events and regular events into a single list.
     *
     * @returns {Array} An array of event options grouped by type.
     */
    eventsOptions() {
      return [
        ...this.abTestsEvents,
        {
          group: 'Events',
          options: this.eventsList,
        },
      ];
    },

    /**
     * Get code versions
     * @return {*}
     */
    codeVersions() {

      // Dependent on app platform
      this.appPlatform;

      // Load versions
      return this.wait("codeVersions", async () => {

        // Get options
        //const opts = (await SchemaReleaseHistory.query("code_version").where({module_id: this.moduleId}).order("code_version desc").get()).map(e => ({value: e.code_version, label: e.code_version}))

        // Add any code version
        let opts = []
        opts.push({value: "", label: "Any"})

        // Get from AppReleases
        opts.push(...(await AppRelease.query(["code_version", "platforms"]).
        where({module_id: this.moduleId, status: "released"}).
        order("code_version desc").get()).
        filter(e => !this.appPlatform || e.platforms?.[this.appPlatform]).
        map(e => ({value: e.code_version, label: e.code_version})))

        // Reset version if not exists
        if( !opts.find(e => e.value === this.codeVersion) ) this.codeVersion = null

        // Calculate default value of code version as max value
        if( !this.codeVersion && opts.length > 0 ) {
          this.codeVersion = opts.reduce((a, b) => a.value > b.value ? a : b).value
        }

        // Return options
        return opts
      }, [])
    },

    /**
     * Get schema versions
     * @return {*}
     */
    schemaVersions() {
      return this.wait("schemaVersions", async () => {
        /*const opts = (await SchemaReleaseHistory.query("schema_version").where({module_id: this.moduleId, code_version: this.codeVersion}).order("schema_version desc").get()).map(e => ({
          value: e.schema_version,
          label: e.schema_version
        }))*/

        // Load from AppReleases
        const opts = (await AppRelease.query("schema_version").where({module_id: this.moduleId, code_version: this.codeVersion, status: "released"}).order("schema_version desc").get()).map(e => ({value: e.schema_version, label: e.schema_version}))

        // Add any code version
        opts.push({value: "", label: "Any"})

        // Reset version if not exists
        if( !opts.find(e => e.value === this.schemaVersion) ) this.schemaVersion = ""

        // Calculate default value of code version as max value
        /*if( !this.schemaVersion  && opts.length > 0 ) {
          this.schemaVersion = opts.reduce((a, b) => a.value > b.value ? a : b).value
        }*/

        // Return options
        return opts;
      }, [])
    },

    /**
     * Get current template
     * @return {*}
     */
    currentTemplate() {
      if(!this.currentTemplateId) return {}
      return this.wait("currentTemplate", AnalyticsEventTemplate.find(this.currentTemplateId), {})
    },

    /**
     * This computed property returns an array of country labels.
     * It maps over the global options for countries, extracting the label for each country.
     * @returns {Array} An array of country labels.
     */
    allCountriesOptions() {
      return this.globals.options.countries.map((v) => v.label);
    },

    /**
     * This method generates an object that represents the current filter settings.
     * It iterates over the keys defined in FILTER_FIELDS and checks if the corresponding property in the Vue component instance is truthy and not an empty array.
     * If the check passes, it adds the key-value pair to the params object.
     * @returns {Object} An object that represents the current filter settings.
     */
    filterQueryParams() {
      return FILTER_FIELDS.reduce((params, key) => {
        if (this[key] && (Array.isArray(this[key]) ? this[key].length : true)) {
          params[key] = this[key];
        }
        return params;
      }, {});
    },

    /**
     * Computed property that returns the application ID from the route parameters.
     * @return {string} The application ID.
     */
    appId() {
      return this.$route.params.app_id;
    },

    currentModule() {
      return this.wait("currentTemplate", AppModule.find(this.moduleId), {});
    },
  },
  methods: {

    /**
     * Delete current template
     */
    deleteTemplate() {

      // Prompt user
      this.$q.dialog({
        title: 'Warning',
        message: 'Are you sure you want to delete this template?',
        cancel: true,
        persistent: true
      }).onOk(async () => {
        // Delete template
        await AnalyticsEventTemplate.remote().delete(this.currentTemplateId)

        // Reset current template
        this.currentTemplateId = ""
      })

    },

    /**
     * Save current required events as template
     */
    saveAsTemplate() {

      // Ask for template name
      this.$q.dialog({
        title: 'Template name',
        message: 'Please enter template name',
        prompt: {
          model: this.currentTemplate?.title || "",
          type: 'text'
        },
        cancel: true,
        persistent: true
      }).onOk(async (name) => {
        // Save template
        const newTmpl = await AnalyticsEventTemplate.remote().save({
          id: this.currentTemplate?.id,
          module_id: this.moduleId,
          app_id: this.appId,
          title: name,
          events: this.requiredEvents
        })

        // Set as current template
        this.currentTemplateId = newTmpl.id
      })
    },

    /**
     * Filter options
     * @param val
     * @param update
     */
    filterFn(val, update) {
      update(() => {
        const needle = val.toLowerCase();

        this.selectEventsListOptions = this.flatEventsOptions(
          needle ? this.eventsOptions.map((g) => ({
            group: g.group,
            options: g.options.filter((v) => v?.label?.toLowerCase()?.includes(needle))
          })) : this.eventsOptions
        );
      })
    },

    /**
     * This method is used to filter the country options based on the user's input.
     * It updates the countriesOptions data property with the filtered results.
     * If the user's input is empty, it resets the countriesOptions to the full list of countries.
     *
     * @param {string} val - The user's input value.
     * @param {function} update - The function to update the countriesOptions data property.
     */
    filterFnCountries(val, update) {
      update(() => {
        const needle = val.toLowerCase();
        this.countriesOptions = needle ? this.allCountriesOptions.filter(v => v.toLowerCase().includes(needle)) : this.allCountriesOptions
      })
    },

    /**
     * Apply filter
     */
    applyFilter() {
      this.$emit("update:codeVersion", this.codeVersion)
      this.$emit("update:schemaVersion", this.schemaVersion)
      this.$emit("update:dateFrom", this.dateFrom)
      this.$emit("update:dateTo", this.dateTo)
      this.$emit("update:requiredEvents", this.requiredEvents)
      this.$emit("update:appPlatform", this.appPlatform)
      this.$emit("update:countries", this.countries)
      this.$emit("update:bigQuery", this.bigQuery)
      this.$emit("update:appRemove", this.appRemove)
      this.$emit("update:revenue", this.revenue)
      this.$emit("update:utmSource", this.utmSource)
      this.$emit("update:utmMedium", this.utmMedium)
      this.$emit("update:utmCampaign", this.utmCampaign)
      this.$emit("update:utmContent", this.utmContent)
      this.$emit("apply-filter")
    },

    /**
     * Flattens the event options into a single array.
     *
     * @param {Array} options - The array of event options grouped by type.
     * @returns {Array} - A flattened array of event options.
     */
    flatEventsOptions(options) {
      return options.filter((v) => v.options?.length).reduce((acc, group) => {
        acc.push({
          group: group.group,
        }, ...group.options.map((v) => ({...v, editable: group.group === 'Events'})));

        return acc;
      }, [])
    },

    /**
     * Edit an event by loading its details and showing the dialog.
     *
     * @param {number} event_id - The ID of the event to be edited.
     */
    async editEvent(event_id) {
      // Load event details
      this.selectedEvent = await AnalyticsEvent.query().where({event_id, module_id: this.moduleId}).first();

      if (!this.selectedEvent) {
        this.$q.notify({type: 'negative', message: 'Event not found'});
      } else {
        // Show dialog
        this.$refs.linkProperties.show();

        this.$refs.requiredEvents.hidePopup();
      }
    },

    /**
     * Save the current event.
     * If the event is marked as a KPI and does not have a position, it assigns the next available position.
     * If the event is not a KPI, it sets the position to 0.
     * After saving, it hides the link properties dialog.
     */
    async saveEvent() {
      if (this.selectedEvent?.is_kpi === 1 && !this.selectedEvent?.position) {
        const {maxPosition} = await AnalyticsEvent.query()
          .select('MAX(position) as maxPosition')
          .where({module_id: this.moduleId, is_kpi: 1})
          .firstRaw();

        this.selectedEvent.position = (maxPosition || 0) + 1;
      } else if (this.selectedEvent?.is_kpi !== 1) {
        this.selectedEvent.position = 0;
      }

      // Save event
      await AnalyticsEvent.remote().save(this.selectedEvent)

      // Close form
      this.$refs.linkProperties.hide();
    },

    /**
     * Deletes the currently selected event.
     * Prompts the user for confirmation before deletion.
     * If confirmed, deletes the event from the remote database and updates the required events list.
     * Finally, hides the link properties dialog.
     */
    async deleteEvent() {
      // Prompt user
      this.$q.dialog({
        title: 'Delete event',
        message: 'Are you sure you want to delete this event?',
        cancel: true,
        persistent: true
      }).onOk(async () => {
        // Delete event
        await AnalyticsEvent.remote().delete(this.selectedEvent.id);

        this.requiredEvents = this.requiredEvents.filter(e => e !== this.selectedEvent.event_id);

        // Close form
        this.$refs.linkProperties.hide();
      });
    },
  },

  async beforeUnmount() {
    // Subscribe to app module
    await AppModule.remote().unsubscribe("module");
  },
}

</script>

<style lang="scss">

.analytics-filter {

}

</style>
