<template>
  <div>
    <q-linear-progress
      v-for="progress in translationProgress"
      class="q-my-sm"
      :key="progress.lang"
      size="25px"
      :value="progress.percent / 100"
      :color="progressColor(progress.percent)"
    >
      <div class="absolute-full flex flex-center">
        <q-badge color="white" text-color="accent" :label="`${progress.lang}: ${progress.percent}%`" />
      </div>
    </q-linear-progress>
  </div>
</template>

<script>
import {AppIntegration} from '@/../../common/db/AppIntegration';
import {Localization} from '@/../../common/db/Localization';
import {LocalizationMessage} from '@/../../common/db/LocalizationMessage';

export default {
  name: 'TranslationProgress',

  inject: ['module_id'],

  data() {
    return {
      properties: {},
    };
  },

  computed: {
    /**
     * Returns a list of languages that are available in the current application.
     *
     * This method performs the following steps:
     * - It retrieves the list of languages from the global options.
     * - It filters the list of languages to only include those that are either the main locale or included in the `additionalLocales` of the current application.
     *
     * @returns {Array} An array of languages that are available in the current application.
     */
    appLanguages() {
      return this.globals.options.languages.filter(
        (v) => v.value === this.properties?.mainLocale
          || (this.properties?.additionalLocales || []).includes(v.value)
      );
    },

    /**
     * Returns a list of localizations for the current module.
     *
     * This method performs the following steps:
     * - It queries the `Localization` model for localizations where the `module_id` matches the current `module_id`.
     *
     * @returns {Promise<Array>} A promise that resolves to an array of localizations for the current module.
     */
    localizations() {
      return this.wait("localizations", Localization.query().where({module_id: this.module_id}).get(), []);
    },

    /**
     * Returns an object where each key is a locale and each value is the number of translated messages for that locale.
     *
     * This method performs the following steps:
     * - It retrieves a list of localization messages for the current `module_id`.
     * - It reduces the list of messages into an object where each key is a locale and each value is the number of translated messages for that locale.
     *
     * @returns {Promise<Object>} A promise that resolves to an object where each key is a locale and each value is the number of translated messages for that locale.
     */
    messagesTranslated() {
      return this.wait("messages", async () => {
        const messages = await LocalizationMessage.getList(this.module_id);

        return messages.reduce((res, message) => {
          if (!res[message.locale]) {
            res[message.locale] = 0;
          }

          if (!message.message) {
            return res;
          }

          res[message.locale] += 1;

          return res;
        }, {});
      }, {});
    },

    /**
     * Returns a list of objects where each object represents the translation progress for a language.
     *
     * This method performs the following steps:
     * - It retrieves a list of languages available in the current application.
     * - It maps each language to an object where:
     *   - `lang` is the label of the language.
     *   - `percent` is the percentage of messages that have been translated for that language.
     *
     * @returns {Array} An array of objects where each object represents the translation progress for a language.
     */
    translationProgress() {
      return this.appLanguages.map((lang) => {
        const total = this.localizations.length;
        const translated = this.messagesTranslated[lang.value] || 0;

        return {
          lang: lang.label,
          percent: Math.round((translated / total) * 100),
        };
      });
    },
  },

  methods: {
    /**
     * Returns a color based on the translation progress percentage.
     *
     * This method performs the following steps:
     * - If the percentage is less than 50, it returns 'red'.
     * - If the percentage is less than 90, it returns 'orange'.
     * - If the percentage is 90 or more, it returns 'green'.
     *
     * @param {number} percent - The translation progress percentage.
     * @returns {string} The color representing the translation progress.
     */
    progressColor(percent) {
      if (percent < 50) {
        return 'red';
      }

      if (percent < 90) {
        return 'orange';
      }

      return 'green';
    }
  },

  async created() {
    // Subscribe to the remote data sources
    await AppIntegration.remote().subscribe('app-integration-by-name', {
      module_id: this.module_id,
      name: 'localizations',
    });

    // Retrieve the properties for the current module
    this.propertiesObj = await AppIntegration.query().where({module_id: this.module_id, name: 'localizations'}).first();

    // Set the properties to the props of the properties object
    this.properties = this.propertiesObj?.props || {};

    // Subscribe to the localizations and localization messages
    await Localization.remote()
      .subscribe("module-localizations", {module_id: this.module_id});

    await LocalizationMessage.remote()
      .subscribe("module-localization-messages", {module_id: this.module_id});
  },

  async beforeUnmount() {
    // Unsubscribe from the remote data sources
    await Localization.remote().unsubscribe("module-localizations");
    await LocalizationMessage.remote().unsubscribe("module-localization-messages");
    await AppIntegration.remote().unsubscribe("app-integration-by-name");
  },
}
</script>

<style scoped lang="scss">

</style>
