import {BaseComponentProcessor} from "@/components/DiagramDesigner/Editor/components/BaseComponentProcessor";

export class DbDeleteProcessor extends BaseComponentProcessor {

    /**
     * Processor constructor
     * @param props
     */
    constructor(...props) {
        super(...props);
    }

    /**
     * Get arguments
     */

    async getArguments() {
        return [{
            title: "Record id",
            type: "string",
            name: "recordId",
        }];
    }

}
