/**
 * DbRelease model
 */
import {AccessModel} from './AccessModel.js';

export class AppRelease extends AccessModel {
    static entity = 'app_releases'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        release_key: "string",
        module_id: "int",
        name: "string",
        description: "string",
        app_id: "int",
        status: "string",
        code_version: "int",
        message: "string",
        files: "json",
        platforms: "json",
        type: "string",
        schema_source: "text",
        schema_min_code_version: "int",
        schema_version: "int",
        schema_rollout_percent: "int",
        schema_update_time: "int",
        released_at: "int",
    }

    /**
     * Functions list
     */
    async channels() {
        return {
            'module-releases': {
                subscribe: ({module_id}) => module_id,
                init: async ({module_id}) => this.getList(module_id),
            }
        }
    }

    /**
     * Get list
     */
    getList(module_id) {
        return this.query().where({module_id}).get()
    }
}
