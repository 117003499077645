export const componentsMixin = {
    inject: {
        canvas: {
            default: false
        }
    },
    computed: {

        /**
         * Source vars containers
         * @return {*}
         */
        parentVarContainers() {

            // Get component parents
            const parents = this.canvas.getNodeParentsById(this.block?.id);

            // Remove last element
            parents.pop();

            // Get parent fragment
            return Object.fromEntries(parents.map(b => ([b.id, {
                id: b.id,
                title: b.title,
                type: "page"
            }])));
        },

        /**
         * Parent frame id
         * @return {*|boolean}
         */
        parentLinkedFrame() {

            // Parent links list
            for (const bId of this.canvas?.getParentLinkedBlocks(this.block.id)) {

                // Check the block type
                const parentFragment = this.canvas.getNodeParentsById(bId).find(b => b.type === 'Fragment');

                // Return frame if found
                if (parentFragment) return parentFragment.id;
            }

            // Return parent links
            return false;
        }
    }
}
