<template>

  <div>
    <ui-prop-string name="channel" title="Channel" :block="block" hint="All will be listened to if not specified"/>
  </div>

</template>

<script>

import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";

export default {
  components: {UiPropString},
  props: ['block'],
  name: "WebSocketListenerPropsCmp",
  computed: {
  }
}

</script>
