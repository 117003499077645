<template>
  <ab-flow-base-cmp :movable="true" :block="block" class="system-alarm-editor-cmp">

    <ab-flow-link-connector :block="block" type="input" class="ev-run" event="run"/>

    <q-card>
      <q-card-section class="bg-blue-10 text-white" style="max-width: 200px">
        <div class="text-no-wrap ellipsis">Context: {{content}}</div>
      </q-card-section>
    </q-card>

    <div class="results">
      <ab-flow-link-connector class="q-mt-sm" :block="block" type="output" event="success"/>
    </div>

  </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

export default {
  mixins: [renderMixins],
  components: { AbFlowBaseCmp, AbFlowLinkConnector },
  inject: ['canvas'],
  props: ['block'],
  name: "ChatBotAiContextEditorCmp",
  computed: {
    content() {
      return this.interpretString(this.block?.properties?.context) || 'n/a';
    },
  }
}

</script>
