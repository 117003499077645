
export const propertiesMixins = {

    emits: ['changed', 'update:modelValue'],
    inject: {
        canvas: {
            default: null
        },
        product_id: {
            default: null
        },
        module_id: {
            default: null
        },
        diagram_id: {
            default: null
        },
        currentModule: {
            default: null
        }
    },

    // Common props
    props: {
        defaultValue: {},
        block: {
            type: Object,
            default: () => ({id: 0})
        },
        name: {
            type: String,
            default: ""
        },
        title: {
            type: String,
            default: null
        },
        placeholder: {
            type: String,
            default: null
        },
        hint: {
            type: String,
            default: null
        }
    },

    // Computed props
    computed: {

        /**
         * Getting block id
         */
        blockId() {
            return this.block ? this.block.id : 0
        },

        /**
         * Getting type of prop
         */
        type() {
            return this.name ? this.name : this.$options.name
        },

        /**
         * Current property link
         */
        currentValue: {
            get: function () {
                return this.block.properties && this.type in this.block.properties ? this.block.properties[this.type] : ''
            },
            set: async function (newVal) {
                if (!this.block.properties) this.block.properties = {}
                this.block.properties[this.type] = newVal
                this.$emit('changed', newVal)
            }
        },

        /**
         * Computed property that returns the colors from the current module's settings styles.
         * It uses optional chaining to safely access nested properties.
         * If any of the properties in the chain is undefined, it will return an empty array.
         *
         * @returns {Array} - An array of colors or an empty array if not defined.
         */
        moduleColors() {
            return [
                {value: "none", label: "None"},
              ...(this.currentModule?.currentModule?.settings?.styles?.colors || [])
            ];
        },
    },

    //Lifecycle hook
    created() {
        this.$emit('changed', this.currentValue);

        // Retrieve the true and false values from the props, if they exist
        const { trueValue, falseValue } = this.$props;

        // Check if the current value is not one of the true or false values
        const checkboxValues = [trueValue, falseValue].filter((v) => v !== undefined);

        //Set default value
        if (checkboxValues.length) {
            if (!checkboxValues.includes(this.currentValue)) {
                this.currentValue = this.defaultValue;
            }
        } else if ((this.defaultValue || this.defaultValue === 0) && (!this.currentValue && this.currentValue !== 0)) {
            this.currentValue = this.defaultValue;
        }
    },

    methods: {
        /**
         * Get variable info
         * @param variable
         */
        getVariableInfo(variable) {
            return variable;
        }
    }
}
