import {AbOrm} from 'ab-orm/src/AbOrm.js';
import {AccessManager} from '../utils/AccessManager.js';

export class AccessModel extends AbOrm {
  /**
   * Method to get the access ID associated with a given row.
   * The access ID is determined by the `accessField` parameter, which defaults to 'app_id'.
   * If the row object has a property matching `accessField`, it returns that.
   * Otherwise, it performs a query on the current model to get the `accessField` where the `id` matches the `id` of the row.
   * @param {Object} row - The row object from which to extract the access ID.
   * @param {string} accessField - The field name to use for access ID extraction. Defaults to 'app_id'.
   * @return {Promise<number>} The access ID associated with the row.
   */
  static async getAccessId(row, accessField = 'app_id') {
      if (row[accessField]) {
          return row[accessField];
      }

      row = await this.query([accessField]).where({id: row.id}).firstRaw() || {};

      return row[accessField];
  }

  /**
   * Prepare data according to users access level
   * @return {boolean}
   * @param row
   * @param mode
   */
  static async prepareData(row, mode) {
    const accessManager = new AccessManager(this);

    return await accessManager.checkAppAccess(await this.getAccessId(row)) ? row : false
  }
}
