<template>

  <div>
    <ui-prop-string name="title" title="Delay title" :block="block"/>
    <ui-prop-string name="delay" title="Delay, ms" :block="block"/>
  </div>

</template>

<script>

import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";

export default {
  components: {UiPropString},
  props: ['block'],
  name: "DelayPropsCmp",
  computed: {
  }
}

</script>
