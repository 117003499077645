<template>

  <q-card class="args-props" v-if="Object.keys(targetBlockVarList || {})?.length">
    <q-card-section class="bg-primary text-white">{{title}}</q-card-section>

    <q-card-section v-for="(p, k) of targetBlockVarList" :key="k">
      <value-selector :only-var-type="p.type" :title="`${p.is_reference ? '➤ ' : ''}${p.name} (${p.title})`" v-model="params[p.name]" :block-id="diagramOnly ? false : block.id"
                      :app-id="product_id" :module-id="module_id" :source-var-id="p.nodeId"
                      :is-reference="p.is_reference"
                      :parent-diagram-id="diagram_id"/>

    </q-card-section>

  </q-card>


</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import ValueSelector from "@/components/ValueSelector/ValueSelector.vue";

export default {
  mixins: [propertiesMixins],
  props: {
    diagramOnly: {
      default: false
    },
    targetBlockVars: {
      default: null
    },
    block: {},
    title: {},
    name: {
      default: "arguments"
    }
  },
  name: "UiPropArguments",
  components: {ValueSelector},
  data: () => ({
    params: {}
  }),
  created() {

    // Init value
    if(this.currentValue && !Array.isArray(this.currentValue)) this.params = this.currentValue

    // Add watcher
    this.$watch('params', function (newVal) {
      this.currentValue = newVal
    }, {deep: true})

  },

  computed: {


    /**
     * Get target block vars
     */
    targetBlockVarList() {
      return this.targetBlockVars || this.wait("targetBlockVars", async () => {

        // Get target block by id
        const node = this.canvas.getNodeById(this.block.id)
        const cmp = this.canvas.getComponentByType(node?.type)

        // Get processor
        const cmpProcessor = cmp?.processor;

        //console.error("tb", cmpProcessor, node, this.canvas.getComponentByType(node?.type))

        // Get args list
        return cmpProcessor ? await new cmpProcessor(this, node)?.getArguments?.() || [] : []
      }, []);
    },


  },

  methods: {


  },
}

</script>
