<template>
  <q-page class="q-pa-md" v-if="isReady">

    <div class="text-h6">
      In app purchase sync settings
    </div>
    <q-form @submit="saveProperties">

      <div class="q-ma-sm">

        <q-toggle
          v-model="properties.enable"
          color="green"
          label="Enable"
          :true-value="1"
          :false-value="0"
        />

        <template v-if="properties.enable === 1">
          <q-card class="q-mt-lg">
            <q-card-section class="bg-primary text-white">
              Google
            </q-card-section>

            <q-card-section>
              <media-picker
                :product-id="app_id"
                :module-id="module_id"
                media-type="json"
                label="Service account file"
                v-model="properties.googleAuthFile"
              />
            </q-card-section>
          </q-card>
        </template>

      </div>

      <q-separator/>
      <q-btn type="submit" label="Save" color="primary" class="q-mt-md"/>
    </q-form>

  </q-page>
</template>

<script>
import {integrationsMixin} from "@/pages/workspace/integrations/integrationsMixin";
import MediaPicker from '@/components/MediaGallery/MediaPicker.vue';

export default {
  components: {MediaPicker},
  mixins: [integrationsMixin],
  name: "InAppPurchaseSyncIntegrationSettings",

  computed: {

    /**
     * Return integration name
     */
    integration: () => ({name: "iap-sync", type: "payments"}),
  }
}

</script>
