<template>

  <ab-flow-base-cmp :block="block" :transparent="true" class="select-box-editor-cmp"  :class="classesString" :style="stylesString">

    <ab-flow-link-connector :block="block" type="output" event="changed"/>
    <q-select
        class="full-width"
        v-model="selectModel"
        :label="label"
        :hint="hint"
        :options="options"
        emit-value
        map-options
    />
  </ab-flow-base-cmp>

</template>

<script>

import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

export default {
  components: {AbFlowBaseCmp, AbFlowLinkConnector},
  mixins: [renderMixins],
  props: ['block'],
  name: "SelectEditorCmp",
  data: ()=>({
    selectModel: "",
  }),
  computed: {
    /**
     * Label
     * @return {string}
     */
    label() {
      return this.interpretString(this.block.properties?.label);
    },

    /**
     * Hint
     * @return {string}
     */
    hint() {
      return this.interpretString(this.block.properties?.hint) || undefined;
    },

    /**
     * Select options
     * @return {*}
     */
    options() {
      return this.block?.properties?.options?.items || [];
    },

    /**
     * Select name
     * @return {*|string}
     */
    selectName() {
      return this.block.properties?.name || "default"
    }
  }
}

</script>

<style lang="scss">

.select-box-editor-cmp {
  position: relative;

  .q-field {
    height: 100%;

    .q-field__append {
      height: 100%;
      color: inherit;
    }

    .q-field__control {
      color: var(--control-focus-color, transparent);
      height: 100%;
      margin-left: 5px;
      margin-right: 5px;

      .q-field__native, .q-field__label {
        color: var(--control-text-color) !important;
      }

      &:before {
        border-color: currentColor !important;
      }
    }

    .q-field__messages {
      color: var(--control-text-color);
    }

    &:not(.q-field--focused) {
      .q-field__control {
        &:before {
          border-color: var(--control-unfocused-color, transparent) !important;
        }
      }
    }
  }

  .q-icon {
    color: inherit;
  }

  .link-connector {
    position: absolute;
    top: calc(50% - 5px);
    right: 50%;
    z-index: 1;
  }
}

</style>
