<template>

  <div>
    <ui-prop-string name="title" title="Operation description" :block="block"/>
    <ui-prop-value-selector name="variable" value-type="getter" :block="block" :parent-diagram-id="diagram_id"  title="Variable to get"/>
  </div>

</template>

<script>

import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import {componentsMixin} from "@/components/DiagramDesigner/Editor/components/componentsMixin";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";

export default {
  mixins: [componentsMixin, propertiesMixins],
  inject: {
    canvas: {
      default: false
    }
  },
  components: {UiPropString, UiPropValueSelector},
  props: ['block'],
  name: "StorageGetValuePropsCmp",
}

</script>
