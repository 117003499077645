<template>
  <q-page class="q-pa-md" v-if="isReady">

    <div class="text-h6">
      Adapty settings
    </div>
    <q-form @submit="saveProperties">
      <q-toggle
        v-model="properties.enable"
        :true-value="1"
        :false-value="0"
        color="green"
        label="Enable Adapty integration"
      />

      <template v-if="properties.enable">
        <q-input label="Public SDK Key" v-model="properties.sdkKey" />
        <q-input label="User id" v-model="properties.userId" />
      </template>

      <q-separator/>
      <q-btn type="submit" label="Save" color="primary" class="q-mt-md"/>
    </q-form>

  </q-page>
</template>

<script>

import {integrationsMixin} from "@/pages/workspace/integrations/integrationsMixin";

export default {
  components: {},
  mixins: [integrationsMixin],
  name: "AdaptyIntegrationSettings",
  data: () => ({
    placement: {},
  }),

  computed: {
    /**
     * Return integration name
     */
    integration: () => ({name: "adapty", type: "payments"}),
  }
}

</script>
