<template>
  <q-page class="q-pa-md" v-if="isReady">

    <div class="text-h6 q-mb-md">
      Stripe settings
    </div>
    <q-form @submit="saveProperties">

      <q-input
        label="Publishable key (stage)"
        v-model="properties.apiKeyStage"
      />

      <q-input
        label="Publishable key (release)"
        v-model="properties.apiKeyRelease"
      />

      <media-picker
        :product-id="app_id"
        :module-id="module_id"
        media-type="apple-developer-merchantid-domain-association"
        label="Apple developer merchantid domain association"
        v-model="properties.appleDeveloperAssociation"
      />

      <q-separator/>

      <div class="row">
        <q-btn type="submit" label="Save" color="primary" class="q-mt-md"/>
      </div>
    </q-form>

  </q-page>
</template>

<script>
import {integrationsMixin} from "@/pages/workspace/integrations/integrationsMixin";
import MediaPicker from '@/components/MediaGallery/MediaPicker.vue';

export default {
  components: {MediaPicker},
  mixins: [integrationsMixin],

  name: "StripeIntegrationSettings",

  computed: {
    /**
     * Return integration name
     */
    integration: () => ({name: "stripe-web", type: "stripe"}),
  },
}

</script>
