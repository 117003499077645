<template>
    <ab-flow-base-cmp :movable="true" :block="block" class="code-function-editor-cmp">

        <ab-flow-link-connector :block="block" type="input" class="ev-run" event="run"/>

        <q-card>

            <q-card-section class="bg-secondary text-white">
                {{title}}
            </q-card-section>
        </q-card>

        <div class="results">
            <ab-flow-link-connector v-for="(e, k) of outEvents" class="q-mt-sm" :block="block" type="output" :event="e.name" :key="k"/>
            <ab-flow-link-connector class="q-mt-sm" :block="block" type="output" event="else" key="else"/>
        </div>

    </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

export default {
    components: { AbFlowBaseCmp, AbFlowLinkConnector},
    inject: ['canvas'],
    props: ['block'],
    name: "LogicConditionEditorCmp",
    computed: {

        /**
         * Return name
         * @return {{}}
         */
        title() {
            return this.block.properties?.title || "NoName"
        },

        /**
         * Events list
         * @return {*|*[]}
         */
        outEvents() {
            return this.block.properties && this.block.properties.events ? this.block.properties.events : []
        }
    }
}

</script>

<style lang="scss">

.logic-condition-editor-cmp {
  .ev-run {
    left:-12px;
    position: absolute;
    top:calc(50% - 5px);
  }

  .results {
    position: absolute;
    right: -12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

</style>
