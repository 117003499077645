<template>
  <q-layout view="lHh Lpr lFf" v-if="isReady">
    <q-header >
      <q-toolbar>
        <q-toolbar-title class="row items-center app-toolbar">
          {{$t("common.application.name")}}
        </q-toolbar-title>

        <q-btn label="Services" flat  @click="$router.push({name: 'services'})"></q-btn>

        <q-btn label="Apps" flat  @click="$router.push({name: 'workspace'})"></q-btn>
        <q-btn-dropdown label="Management" flat v-if="isAdmin">
          <q-list>
            <q-item clickable v-close-popup @click="$router.push({name: 'admin-users'})">
              <q-item-section>
                <q-item-label>Users</q-item-label>
              </q-item-section>
            </q-item>

          </q-list>
        </q-btn-dropdown>

      </q-toolbar>
    </q-header>

    <q-page-container>
      <router-view/>
    </q-page-container>
  </q-layout>
</template>

<script>
import {App} from "@/../../common/db/App";

export default {
  name: 'WorkspaceTemplate',
  data: () => ({
    isReady: false,
  }),

  /**
   * On page created
   */
  async created() {
    // Fetch all products
    await App.remote().subscribe('users-apps', {user_id: this.app.auth().getUserId()})

    // Loaded
    this.isReady = true

  },

  computed: {
    isAdmin() {
      return this.app.auth().hasRole("admin")
    }
  }
}
</script>
