
export const dataHelper = {

    /**
     * Convert base64 string to array buffer
     * @return {Promise<unknown>}
     * @param bBuffer
     */
    arrayBufferToBase64(bBuffer) {
        const buffer = new Uint8Array(bBuffer);
        return new Promise((resolve, reject) => {
            const blob = new Blob([buffer], { type: 'application/octet-stream' });
            const reader = new FileReader();

            reader.onloadend = function() {
                resolve(reader.result?.split(",")?.[1]);
            };

            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    },

    // Function to fetch an image and convert it to a byte array
    async fetchImageAsByteArray(imageUrl) {
        try {
            // Fetch the image
            const response = await fetch(imageUrl);
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            // Read the response as a blob
            const blob = await response.blob();

            // Convert the blob to an ArrayBuffer
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    const arrayBuffer = reader.result;

                    // Create a byte array from the ArrayBuffer
                    const byteArray = new Uint8Array(arrayBuffer);
                    resolve(byteArray);
                };
                reader.onerror = reject;
                reader.readAsArrayBuffer(blob);
            });
        } catch (error) {
            console.error('Error fetching image:', error);
        }
    },

    /**
     * Generate string hash
     * @param str
     * @return {string}
     */
    stringHash(str) {
        let hash = 0;
        if (str.length == 0) {
            return hash;
        }
        for (let i = 0; i < str.length; i++) {
            let char = str.charCodeAt(i);
            hash = ((hash << 5) - hash) + char;
            hash = hash & hash; // Convert to 32bit integer
        }
        return hash.toString(16);
    }
}
