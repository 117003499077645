<template>
  <div>
    <ui-prop-select :block="block" name="adType" title="Fullscreen ad type" :options="adTypes"/>
    <ui-prop-select v-if="fullscreenPlacementsList?.length" :block="block" name="placement" title="Placement" :options="fullscreenPlacementsList"/>
    <ui-prop-string v-if="['interstitial', 'appopen'].includes(currentAdType)" :block="block" name="showTimeout" title="Minimal timeout to show, sec"/>
    <ui-prop-hidden :block="block" name="placementTitle" :value="placementTitle"/>
    <ui-prop-checkbox name="waitLoading" title="Wait for loading" :block="block" :true-value="1" :false-value="0" :default-value="1"/>
    <ui-prop-string v-if="block.properties?.waitLoading === 1" name="waitTimeout" title="Wait timeout" :block="block" hint="Timeout in seconds" default-value="30"/>
  </div>

</template>

<script>
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";
import {adMixins} from "@/components/DiagramDesigner/Editor/components/UI/Monetization/adMixins";
import UiPropHidden from "@/components/DiagramDesigner/Editor/properties/UiPropHidden.vue";
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropCheckbox from "@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue";

export default {
  mixins: [adMixins, propertiesMixins],
  components: {UiPropCheckbox, UiPropString, UiPropHidden, UiPropSelect},
  props: ['block'],
  name: "FullscreenAdPropsCmp",
  computed: {

    /**
     * Return placement title
     * @return {*}
     */
    placementTitle() {
      return this.fullscreenPlacementsList?.find(p => p.value === this.block?.properties?.placement)?.label;
    },

    /**
     * Return current ad type
     * @return {Document.adType|(function(): {})|*}
     */
    currentAdType() {
      return this.block?.properties?.adType
    },

    /**
     * Return possible ad types
     */
    adTypes() {
      return this.globals.options.adTypes?.filter(t => t.place === 'fullscreen')
    },

    /**
     * Return placements list
     * @return {*}
     */
    fullscreenPlacementsList() {
      return this.placementsList?.filter(p => p.type === this.block?.properties?.adType)?.map(p => ({label: p.name, value: p.id}))
    }
  }
}

</script>
