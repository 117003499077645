<template>
  <q-page class="q-pa-md" v-if="isReady">

    <div class="text-h6">
      Firebase settings
    </div>
    <q-form @submit="saveProperties">
      <template v-if="currentModule.type === 'server'">
        <media-picker :product-id="app_id" :module-id="module_id" media-type="firebase-cred" label="Firebase Admin SDK config" v-model="properties.firebaseAdminSdkCredFile"/>
      </template>
      <template v-else>
        <template v-if="currentModule.type !== 'web'">
          <media-picker :product-id="app_id" :module-id="module_id" media-type="firebase-cred" label="Firebase Android config" v-model="properties.firebaseCredFile"/>
          <media-picker :product-id="app_id" :module-id="module_id" media-type="firebase-cred" label="Firebase iOS config" v-model="properties.firebaseIOSCredFile"/>
        </template>

        <media-picker :product-id="app_id" :module-id="module_id" media-type="firebase-cred" label="Firebase Web config" v-model="properties.firebaseWebCredFile"/>
      </template>

      <q-separator/>
      <q-btn type="submit" label="Save" color="primary" class="q-mt-md"/>
    </q-form>

  </q-page>
</template>

<script>
import {AppModule} from '../../../../../../common/db/AppModule';
import {integrationsMixin} from "@/pages/workspace/integrations/integrationsMixin";
import MediaPicker from "@/components/MediaGallery/MediaPicker.vue";

export default {
  components: {MediaPicker},
  mixins: [integrationsMixin],
  name: "FirebaseIntegrationSettings",
  data: () => ({
    placement: {},
  }),

  computed: {

    /**
     * Return integration name
     */
    integration: () => ({name: "firebase", type: "tools"}),

    /**
     * Current module
     * @return {*}
     */
    currentModule() {
      return this.wait("currentModule", AppModule.find(this.module_id), {})
    },
  }
}

</script>
