/**
 * App model
 */
import {AccessModel} from './AccessModel.js';

export class CodeFunction extends AccessModel {
    static entity = 'code_functions'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        package: "string",
        name: "string",
        description: "string",
        source: "string",
        app_id: "int",
        module_id: "int",
        unique_id: "string",
        type: "string",
        diagram_id: "int",
        source_code: "string",
    }

    /**
     * Clears the cache for the current app\_id using the schemaCache plugin.
     * If the cacheManager is not available, the function returns without performing any action.
     */
    serverEvent() {
        const cacheManager = this.constructor?.applicationClient?.plugins?.schemaCache || null;

        if (!cacheManager) {
            return;
        }

        cacheManager.clearCache(this.app_id);
    }

    /**
     * Functions list
     */
    async channels() {
        return {
            'app-functions': {
                subscribe: ({app_id}) => app_id,
                init: async ({app_id}) => this.getList(app_id),
            }
        }
    }

    /**
     * Get list
     */
    getList(app_id) {
        return this.query().where({app_id}).get()
    }
}
