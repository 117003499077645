<template>
  <ui-prop-value-selector :block="block" title="Field variable" name="field" value-type="setter" :parent-diagram-id="diagram_id"/>
  <ui-prop-value-selector :block="block" title="Default value" name="defaultValue" only-var-type="string" :parent-diagram-id="diagram_id"/>

  <ui-prop-string name="title" title="Title" :block="block"/>
  <ui-prop-select name="color" title="Color" :block="block" :options="moduleColors"/>
  <ui-prop-select name="controlTextColor" title="Text color" :block="block" :options="moduleColors" default-value="primary"/>
  <ui-prop-select name="controlFocusColor" title="Focus color" :block="block" :options="moduleColors" default-value="info"/>

  <ui-prop-checkbox
    class="q-mt-sm"
    title="Disable manual input"
    name="disableManualInput"
    :block="block"
    :default-value="0"
  />

  <ui-data-props
    name="validationRules"
    title="Validation rules"
    :default-schema="validationSchema"
    :block="block"
    :key="validationRulesKey"
  />

  <ui-dynamic-props :block="block" :product_id="product_id" :module_id="module_id"/>
</template>

<script>

import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect";
import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps";
import UiPropValueSelector from '@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue';
import {propertiesMixins} from '@/components/DiagramDesigner/Editor/properties/propertiesMixins';
import validatableFormFieldMixin
  from '@/components/DiagramDesigner/Editor/components/UI/Form/validatableFormFieldMixin';
import UiDataProps from '@/components/DiagramDesigner/Editor/properties/UiDataProps.vue';
import UiPropCheckbox from '@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue';
export default {
  name: "DatePickerPropsCmp",
  mixins: [propertiesMixins, validatableFormFieldMixin],
  props: ['block'],
  components: {UiPropCheckbox, UiDataProps, UiPropValueSelector, UiDynamicProps, UiPropSelect, UiPropString}
}

</script>

