<template>

  <ui-prop-custom-list-props :name="name" :title="title" :block="block">
    <template v-slot:item="data">
      <q-input v-model="data.item.name" label="Parameter name"/>
      <div class="row">
        <q-select class="col" v-model="data.item.type" :options="globals.options.data_types" label="Parameter type" map-options emit-value/>
        <q-checkbox label="Is array" v-model="data.item.isArray"/>
      </div>
      <value-selector v-model="data.item.value" :is-array="data.item.isArray" :title="data.item.name" :app-id="product_id" :block-id="block.id" :parent-diagram-id="diagram_id" :module-id="module_id"/>
    </template>
  </ui-prop-custom-list-props>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropCustomListProps from "@/components/DiagramDesigner/Editor/properties/UiPropCustomListProps.vue";
import ValueSelector from "@/components/ValueSelector/ValueSelector.vue";

export default {
  components: {ValueSelector, UiPropCustomListProps},
  mixins: [propertiesMixins],
  name: "UiPropParamsList",
}

</script>
