export const languageList = [
    {
        "title": "Arabic",
        "value": "ar",
        "criterion": 1019
    },
    {
        "title": "Bengali",
        "value": "bn",
        "criterion": 1056
    },
    {
        "title": "Bulgarian",
        "value": "bg",
        "criterion": 1020
    },
    {
        "title": "Catalan",
        "value": "ca",
        "criterion": 1038
    },
    {
        "title": "Chinese (simplified)",
        "value": "zh_CN",
        "criterion": 1017
    },
    {
        "title": "Chinese (traditional)",
        "value": "zh_TW",
        "criterion": 1018
    },
    {
        "title": "Croatian",
        "value": "hr",
        "criterion": 1039
    },
    {
        "title": "Czech",
        "value": "cs",
        "criterion": 1021
    },
    {
        "title": "Danish",
        "value": "da",
        "criterion": 1009
    },
    {
        "title": "Dutch",
        "value": "nl",
        "criterion": 1010
    },
    {
        "title": "English",
        "value": "en",
        "criterion": 1000
    },
    {
        "title": "Estonian",
        "value": "et",
        "criterion": 1043
    },
    {
        "title": "Filipino",
        "value": "tl",
        "criterion": 1042
    },
    {
        "title": "Finnish",
        "value": "fi",
        "criterion": 1011
    },
    {
        "title": "French",
        "value": "fr",
        "criterion": 1002
    },
    {
        "title": "German",
        "value": "de",
        "criterion": 1001
    },
    {
        "title": "Greek",
        "value": "el",
        "criterion": 1022
    },
    {
        "title": "Gujarati",
        "value": "gu",
        "criterion": 1072
    },
    {
        "title": "Hebrew",
        "value": "iw",
        "criterion": 1027
    },
    {
        "title": "Hindi",
        "value": "hi",
        "criterion": 1023
    },
    {
        "title": "Hungarian",
        "value": "hu",
        "criterion": 1024
    },
    {
        "title": "Icelandic",
        "value": "is",
        "criterion": 1026
    },
    {
        "title": "Indonesian",
        "value": "id",
        "criterion": 1025
    },
    {
        "title": "Italian",
        "value": "it",
        "criterion": 1004
    },
    {
        "title": "Japanese",
        "value": "ja",
        "criterion": 1005
    },
    {
        "title": "Kannada",
        "value": "kn",
        "criterion": 1086
    },
    {
        "title": "Korean",
        "value": "ko",
        "criterion": 1012
    },
    {
        "title": "Latvian",
        "value": "lv",
        "criterion": 1028
    },
    {
        "title": "Lithuanian",
        "value": "lt",
        "criterion": 1029
    },
    {
        "title": "Malay",
        "value": "ms",
        "criterion": 1102
    },
    {
        "title": "Malayalam",
        "value": "ml",
        "criterion": 1098
    },
    {
        "title": "Marathi",
        "value": "mr",
        "criterion": 1101
    },
    {
        "title": "Norwegian",
        "value": "no",
        "criterion": 1013
    },
    {
        "title": "Persian",
        "value": "fa",
        "criterion": 1064
    },
    {
        "title": "Polish",
        "value": "pl",
        "criterion": 1030
    },
    {
        "title": "Portuguese",
        "value": "pt",
        "criterion": 1014
    },
    {
        "title": "Punjabi",
        "value": "pa",
        "criterion": 1110
    },
    {
        "title": "Romanian",
        "value": "ro",
        "criterion": 1032
    },
    {
        "title": "Russian",
        "value": "ru",
        "criterion": 1031
    },
    {
        "title": "Serbian",
        "value": "sr",
        "criterion": 1035
    },
    {
        "title": "Slovak",
        "value": "sk",
        "criterion": 1033
    },
    {
        "title": "Slovenian",
        "value": "sl",
        "criterion": 1034
    },
    {
        "title": "Spanish",
        "value": "es",
        "criterion": 1003
    },
    {
        "title": "Swedish",
        "value": "sv",
        "criterion": 1015
    },
    {
        "title": "Tamil",
        "value": "ta",
        "criterion": 1130
    },
    {
        "title": "Telugu",
        "value": "te",
        "criterion": 1131
    },
    {
        "title": "Thai",
        "value": "th",
        "criterion": 1044
    },
    {
        "title": "Turkish",
        "value": "tr",
        "criterion": 1037
    },
    {
        "title": "Ukrainian",
        "value": "uk",
        "criterion": 1036
    },
    {
        "title": "Urdu",
        "value": "ur",
        "criterion": 1041
    },
    {
        "title": "Vietnamese",
        "value": "vi",
        "criterion": 1040
    }
]
