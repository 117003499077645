<template>

  <div>
    <ui-prop-select name="permission" title="Permission name" :block="block" :options="globals.options.permissionsList"/>
  </div>

</template>

<script>

import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";

export default {
  components: {UiPropSelect},
  props: ['block'],
  name: "CheckPermissionPropsCmp",
  computed: {
  }
}

</script>
