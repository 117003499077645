<template>

  <div class="q-gutter-sm">
    <div v-for="(sub, k) of currentSubs" :key="sub.id || sub.subId || k" class="row">
      <ui-db-subscription :module_id="module_id" v-model="currentSubs[k]" :app-id="product_id" :module-id="module_id" :parent-diagram-id="diagram_id"/>
      <q-btn flat icon="delete" @click="deleteSub(k)"/>
    </div>

    <q-btn @click="addSub" icon="add" label="Add sub" flat/>
  </div>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiDbSubscription from "@/components/DiagramDesigner/Editor/properties/DbSubscription/UiDbSubscription.vue";
import {nanoid} from "nanoid";

export default {
  mixins: [propertiesMixins],
  props: {
    block: {},
    title: {},
    name: {
      default: "subs"
    }
  },
  name: "UiDbSubscriptionsProps",
  components: {UiDbSubscription},
  data: () => ({
    currentSubs: []
  }),
  created() {

    // Init value
    if(this.currentValue) this.currentSubs = this.currentValue

    // Add watcher
    this.$watch('currentSubs', function (newVal) {
      this.currentValue = newVal
    }, {deep: true})

  },

  methods: {

    /**
     * Add sub
     * @param e
     */
    addSub() {
      // Add to subs list
      this.currentSubs.push({
        id: nanoid(10),
        dbId: "",
        tableId: "",
        subId: "",
        params: []
      })
    },

    /**
     * Delete sub
     * @param k
     */
    deleteSub(k) {
      console.log("!", k)
      // Delete sub by index k
      this.currentSubs.splice(k, 1)
    },
  },
}

</script>
