<template>
  <div class="col">
    <q-select v-model="variableData.value.type" label="Collection" map-options emit-value :options="globals.options.optionsCollections"/>
  </div>
</template>

<script>

export default {
  name: "OptionsConstants",
  components: {},
  props: ["modelValue"],
  data: () => ({
    variableData: {}
  }),
  created() {

    // Set the valueData to the modelValue
    this.variableData = this.modelValue

    // Set the type
    this.variableData.type = 'options'

    // Check for empty value
    if(typeof this.variableData?.value !== 'object') this.variableData.value = {}

    // Add watcher to update the modelValue when the valueData changes
    this.$watch('variableData', () => {
      this.$emit('update:modelValue', this.variableData);
    }, {deep: true})
  }
}

</script>
