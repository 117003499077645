<template>
  <q-badge :color="color" text-color="white" :label="status" />
</template>

<script>
export default {
  name: 'HealthCheckStatus',

  props: {
    status: {
      required: true,
      type: String,
    }
  },

  computed: {
    // Get color based on status
    color() {
      switch (this.status) {
        case 'critical':
          return 'red';
        case 'warning':
          return 'orange';
        case 'error':
          return 'deep-orange';
        case 'success':
          return 'green';
        default:
          return 'grey';
      }
    }
  },
}
</script>
