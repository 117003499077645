export const pathHelper = {

    /**
     * Get asset path
     * @param url
     */
    assetPath: (url) => {

        // No url
        if(!url) return false

        // Parse url
        const [source, path] = url?.split?.(":") || []

        // Construct path according to source
        switch (source) {
            case "gallery":
                return process.env.VUE_APP_MEDIA_PATH + path
            case "storage":
            case "remote":
                return process.env.VUE_APP_STORAGE_PATH + path
            case "http":
            case "https":
                return url
            default:
                return false;

        }
    },

    /**
     * Return html5 banner path
     * @param url
     */
    html5Path(url) {
        return process.env.VUE_APP_MEDIA_PATH + url
    }
}
