<template>
  <div class="column col">
    <q-select emit-value map-options v-model="sub.dbId" label="Database" :options="dbsList"/>
    <q-select emit-value map-options v-if="sub?.dbId" v-model="sub.tableId" label="Table" :options="tablesList"/>
    <q-select emit-value map-options v-if="sub?.tableId" v-model="sub.subId" label="Channel" :options="channelsList"/>
    <dynamic-string :key="selectedChannel?.id" v-model="sub.query" :value="selectedChannel?.channel_query"
                    :app-id="appId" :module-id="moduleId" :parent-diagram-id="parentDiagramId"/>
  </div>
</template>

<script>

import {DbModule} from "@/../../common/db/DbModule";
import {DbTable} from "@/../../common/db/DbTable";
import {DbTableChannel} from "@/../../common/db/DbTableChannel";
import DynamicString from "@/components/DynamicString/DynamicString.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";

export default {
  components: {DynamicString},
  mixins: [propertiesMixins],
  inject: ["currentModule"],
  props: {
    modelValue: {},
    moduleId: {},
    appId: {},
    parentDiagramId: {}
  },
  data: () => ({
    sub: {},
  }),
  name: "UiDbSubscription",
  created() {

    // Init value
    if (this.modelValue) this.sub = this.modelValue

    // Add watcher
    this.$watch('sub', function (newVal) {
      this.$emit('update:modelValue', newVal)
    }, {deep: true})

  },

  computed: {

    /**
     * Return selected channel
     * @return {*}
     */
    selectedChannel() {
      return this.wait('selectedChannel', DbTableChannel.find(this.sub.subId), {})
    },

    /**
     * Get dbs list
     * @return {*}
     */
    dbsList() {
      return this.wait("dbsList", async () => {
        return (await DbModule.query().where({app_id: this.product_id}).get()).filter(d => this.currentModule.modules[d.module_id]?.type === 'server').map(d => ({
          value: d.id,
          label: d.name + " (" + this.currentModule.modules[d.module_id]?.name + ")"
        }));
      }, []);
    },

    /**
     * Get tables list
     * @return {*}
     */
    tablesList() {
      return this.wait("tablesList", async () => {
        return (await DbTable.query().where({db_id: this.sub?.dbId || 0}).get()).map(d => ({
          value: d.id,
          label: d.name
        }));
      }, []);
    },

    /**
     * Get channels list
     * @return {*}
     */
    channelsList() {
      return this.wait("channelsList", async () => {
        return (await DbTableChannel.query().where({table_id: this.sub?.tableId || 0}).get()).map(d => ({
          value: d.id,
          label: d.title
        }));
      }, []);
    },
  }
}

</script>
