<template>

  <ab-flow-base-cmp class="checkbox-editor-cmp" :block="block" :class="checkClassesString" :style="stylesString">
    <!--q-checkbox v-if="checkType === 'regular'" :label="label" :left-label="labelPosition === 'left'" v-model="test"
                :checked-icon="checkedIcon" :unchecked-icon="uncheckedIcon" :true-value="true" :false-value="false"
                 /!-->

    <div class="control" :class="{right: labelPosition !== 'left'}">

      <label v-if="label">{{label}}</label>

      <q-icon  v-if="checkType === 'regular'" :name="test ? checkedIcon : uncheckedIcon"  @click="test = !test" :style="iconStyle"/>
      <template v-else>
        <img class="image-check" :class="imageClass" :src="test ? checkedImage : uncheckedImage" @click="test = !test"/>
      </template>

    </div>

    <div class="links-wrapper">
      <ab-flow-link-connector :block="block" type="output" event="changed" class="q-mr-sm"/>
    </div>
  </ab-flow-base-cmp>

</template>

<script>

import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import {pathHelper} from "@/utils/pathHelper";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

export default {
  components: {AbFlowBaseCmp, AbFlowLinkConnector},
  mixins: [renderMixins],
  inject: {
    canvas: {
      default: false
    },
    animation_frame: {
      default: 0
    }
  },
  props: ['block'],
  name: "CheckBoxEditorCmp",
  data: () => ({
    test: false
  }),
  computed: {

    /**
     * Classes string
     * @return {*}
     */
    checkClassesString() {
      return this.getClasses(false, {size: 1})
    },

    /**
     * Icon style
     * @return {{"font-size": string}}
     */
    iconStyle() {
      return {'font-size': this.globals.sizes_to_pixels[this.block?.properties?.size || "md"]+"px"}
    },

    /**
     * Image style
     * @return {{"font-size": string}}
     */
    imageClass() {
      return this.getClasses({size: 1})
    },

    /**
     * Checkbox type
     * @return {*|string}
     */
    checkType() {
      return this.block?.properties?.type || "regular"
    },

    /**
     * Checked icon
     * @return {*|string}
     */
    checkedIcon() {
      return this.block?.properties?.checkedIcon || "check_box"
    },

    /**
     * Unchecked icon
     * @return {*|string}
     */
    uncheckedIcon() {
      return this.block?.properties?.uncheckedIcon || "check_box_outline_blank"
    },

    /**
     * Button label
     * @return {*|string}
     */
    label() {
      return this.block.properties ? this.interpretString(this.block.properties.label) : "No label"
    },

    /**
     * Label position
     * @return {string}
     */
    labelPosition() {
      return this.block.properties?.labelPosition || "right"
    },

    /**
     * Return type
     * @return {*|string}
     */
    value() {
      return this.block.properties && this.block.properties.check_value ? this.block.properties.check_value : "1"
    },


    /**
     * Checked image
     * @return {boolean|*|boolean}
     */
    checkedImage() {
      // Image path
      return pathHelper.assetPath(this.getValue(this.block?.properties?.checkedImage?.source_url)) || require("@/assets/plugs/default-image.png");
    },

    /**
     * Unchecked image
     * @return {boolean|*|boolean}
     */
    uncheckedImage() {
      // Image path
      return pathHelper.assetPath(this.getValue(this.block?.properties?.uncheckedImage?.source_url)) || require("@/assets/plugs/default-image.png");
    },
  }
}

</script>

<style lang="scss">

.checkbox-editor-cmp {
  position: relative;
  .image-check {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .control {
    display: flex;
    flex-direction: row;
    align-items: center;

    label {
      padding-right: 10px;
    }

    &.right {
      flex-direction: row-reverse;
      label { padding-left: 5px;}
    }
  }

}

</style>
