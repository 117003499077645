<template>
    <ab-flow-base-cmp :movable="true" :block="block" class="math-operation-editor-cmp">

        <ab-flow-link-connector :block="block" type="input" class="ev-run" event="run"/>
        <ab-flow-link-connector :block="block" type="input" class="ev-a" event="a"/>
        <ab-flow-link-connector :block="block" type="input" class="ev-b" event="b"/>

        <q-card>

            <q-card-section class="bg-secondary text-white">
                a {{operationTitle}} b
            </q-card-section>

        </q-card>

        <div class="results">
            <ab-flow-link-connector class="q-mt-sm" :block="block" type="output" event="result"/>
        </div>

    </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

export default {
    components: { AbFlowBaseCmp, AbFlowLinkConnector},
    inject: ['canvas'],
    props: ['block'],
    name: "VarOperationEditorCmp",
    computed: {

        /**
         * Return variable name
         * @return {{}}
         */
        operationTitle() {
            return this.block.properties?.operation || "?"
        },
    }
}

</script>

<style lang="scss">

.math-operation-editor-cmp {
  .ev-a {
    left:-12px;
    position: absolute;
    top:0;
  }
  .ev-b {
    left:-12px;
    position: absolute;
    bottom: 0;
  }
  .ev-run {
    left:-12px;
    top: calc(50% - 5px);
    position: absolute;
    bottom: 0;
  }

  .results {
    position: absolute;
    right: -12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

</style>
