<template>

  <ab-flow-base-cmp :block="block" :class="' widget-cmp ' + (isExpanded ? ' expanded ' : '') + classesString" :style="stylesString" v-if="widgetBlocks">

    <div class="hide-child-connectors flex column full-height">
      <ab-flow-components-renderer :items="widgetBlocks"/>
    </div>

    <div class="widget-links-wrapper">
      <div class="out">
        <ab-flow-link-connector v-for="(e, k) of outEvents" class="q-mr-xs" :block="block" type="output" :event="e" :key="k"/>
      </div>

      <div class="in">
        <ab-flow-link-connector v-for="(e, k) of inEvents" class="q-mr-xs" :block="block" type="input" :event="e" :key="k"/>
      </div>
    </div>

  </ab-flow-base-cmp>

</template>

<script>

import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowComponentsRenderer from "ab-flow-designer/src/components/Designer/AbFlowComponentsRenderer";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";
import _ from 'lodash'
import {StorageNode} from '@/../../common/db/StorageNode'

export default {
  components: {AbFlowLinkConnector, AbFlowBaseCmp, AbFlowComponentsRenderer},
  mixins: [renderMixins],
  props: {
    block: {}
  },
  name: "WidgetEditorCmp",
  provide: function () {
    return {
      parentWidget: this
    }
  },
  data: () => ({
    widgetId: false,
  }),
  async created() {

    // Get id from type
    const widgetId = this.block.type?.split(":")?.[1]

    // Traverse all parents and find widget id
    let parent = this.$parent;
    while(parent) {
      if(parent?.block?.type === this.block.type) {
        console.error("Widget ", this.block, " has a recursive parent", parent.block)
        return
      }
      parent = parent.$parent;
    }


    // Set widget id
    this.widgetId = widgetId

  },

  computed: {

    /**
     * Get storage data
     * @return {*}
     */
    storageData() {
      return this.wait("storageData", StorageNode.getTree(this.module_id, this.widgetId), {})
    },


    /**
     * Widget storage
     * @return {{get: (function(*): never)}}
     */
    storage() {
      // Result data
      const resData = this.storageData//StorageNode.getTree(this.module_id, this.widgetId);

      // Get arguments and store to storage
      for(const [name, value] of Object.entries(this.block.properties?.arguments || {})) {

        // Set to target value
        _.set(resData, name, this.getValue(value));
      }

      return {
        get: (key) => {
          return _.get(resData, key)
        }
      }
    },

    /**
     * Block is expanded
     */
    isExpanded() {
      return this.block?.properties?.extended
    },

    /**
     * Outgoing events list
     * @return {*|*[]}
     */
    outEvents() {
      // Find events list
      return this.source?.children?.filter(c => c.type === 'CustomEvent' && c.properties?.eventType === 'outgoing').map(c => (c.properties?.name)) || []
    },


    /**
     * Incoming events list
     * @return {*|*[]}
     */
    inEvents() {
      // Find events list
      return this.source?.children?.filter(c => c.type === 'CustomEvent' && c.properties?.eventType === 'incoming').map(c => (c.properties?.name)) || []
    },


    /**
     * Return widget params
     */
    widgetParams() {
      return this.block.properties?.widgetParams || {}
    },

    /**
     * Widget blocks
     * @return {*}
     */
    widgetBlocks() {
      return this.source?.children?.find(c => c.properties?.fragmentType === 'widget')?.children;
    },

    /**
     * Widget source
     * @return {*}
     */
    source() {
      return this.wait("widgetSource", async () => {
        return this.widgetId ? (await this.designer.getDiagram(this.widgetId))?.source : false
      }, {})
    }
  },
}

</script>

<style lang="scss">

.widget-cmp {
  position: relative;
  flex-direction: column;

  &.expanded {
    width: 100%;
    height: 100%;
  }

  .widget-links-wrapper {
    z-index: 2;
    .in {
      position: absolute;
      top: 50%;
      right: auto;
      left: 0;
    }
    .out {
      position: absolute;
      top: 50%;
      left: auto;
      right: 50%;
    }
  }
}

.widget-cmp > .editor-cmp {
  pointer-events: none;
  .links-wrapper {
    display: none;
  }
}
</style>
