<template>

  <ab-flow-base-cmp :draggable="false" :block="block" :is_container="true" class="column col" :class="classesString" :style="stylesString">
    <ab-flow-link-connector :block="block" type="output" event="submit"/>
    <ab-flow-components-renderer :items="block.children" />
  </ab-flow-base-cmp>

</template>

<script>

import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";
import AbFlowComponentsRenderer from "ab-flow-designer/src/components/Designer/AbFlowComponentsRenderer";

export default {
  components: {AbFlowBaseCmp, AbFlowLinkConnector, AbFlowComponentsRenderer},
  mixins: [renderMixins],
  inject: {
    canvas: {
      default: false
    },
    animation_frame: {
      default: 0
    }
  },
  props: ['block'],
  name: "FormEditorCmp",
  computed: {
  }
}

</script>
