<template>
  <q-page class="q-pa-md" v-if="isReady">

    <div class="text-h6">
      Singular settings
    </div>
    <q-form @submit="saveProperties">

      <q-input label="SDK Key" v-model="properties.sdkKey"/>
      <q-input label="SDK Secret" v-model="properties.sdkSecret"/>

      <q-separator/>
      <q-btn type="submit" label="Save" color="primary" class="q-mt-md"/>
    </q-form>

  </q-page>
</template>

<script>

import {integrationsMixin} from "@/pages/workspace/integrations/integrationsMixin";

export default {
  components: {},
  mixins: [integrationsMixin],
  name: "SingularIntegrationSettings",
  data: () => ({
    placement: {},
  }),

  computed: {

    /**
     * Return integration name
     */
    integration: () => ({name: "singular", type: "mmp"}),
  }
}

</script>
