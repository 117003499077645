<template>

  <ui-prop-string name="title" title="Operation title" :block="block"/>
  <ui-db-subscriptions-props :block="block" name="subscriptions"/>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiDbSubscriptionsProps
  from "@/components/DiagramDesigner/Editor/properties/DbSubscription/UiDbSubscriptionsProps.vue";
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";

export default {
  mixins: [propertiesMixins],
  components: {UiPropString, UiDbSubscriptionsProps},
  props: ['block'],
  name: "DbSubscribePropsCmp",
}

</script>
