export const hasFiltersMixin = {
  data() {
    return {
      codeVersion: null,
      appPlatform: null,
      dateFrom: null,
      dateTo: null,
      requiredEvents: [],
      schemaVersion: null,
      countries: [],
      bigQuery: 1,
      utmSource: null,
      utmMedium: null,
      utmCampaign: null,
      utmContent: null,
    };
  },

  created() {
    this.$watch("codeVersion", () => this.updateStatistics())
    this.$watch("schemaVersion", () => this.updateStatistics())
    this.$watch("dateFrom", () => this.updateStatistics())
    this.$watch("dateTo", () => this.updateStatistics())
    this.$watch("requiredEvents", () => this.updateStatistics())
    this.$watch("countries", () => this.updateStatistics())
    this.$watch("bigQuery", () => this.updateStatistics())
    this.$watch("appPlatform", () => this.updateStatistics())
    this.$watch("utmSource", () => this.updateStatistics())
    this.$watch("utmMedium", () => this.updateStatistics())
    this.$watch("utmCampaign", () => this.updateStatistics())
    this.$watch("utmContent", () => this.updateStatistics())
  },

  methods: {
    updateStatistics() {
      console.error('updateStatistics method is not implemented');
    },
  },
}
