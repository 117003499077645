<template>

  <div>
    <ui-prop-dynamic-string :block="block" title="Message" name="message"/>
    <ui-prop-dynamic-string :block="block" title="Description" name="description"/>
    <ui-prop-dynamic-string :block="block" title="Good title" name="goodTitle"/>
    <ui-prop-dynamic-string :block="block" title="Amount" name="amount"/>
    <ui-prop-select :block="block" title="Currency" name="currency" :options="currencies"/>
  </div>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropDynamicString from "@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";

export default {
  mixins: [propertiesMixins],
  components: {UiPropSelect, UiPropDynamicString},
  props: ['block'],
  name: "ChatBotPurchasePropsCmp",

  data() {
    return {
      currencies: [
        {value: "coins", label: "Coins"},
      ],
    }
  },
}

</script>
