<template>

  <div>

    <ui-prop-dynamic-string :block="block" title="Channel" name="channel" untranslatable/>

    <div class="row q-gutter-sm">
      <ui-prop-select :block="block" name="dbId" title="Database"  :options="dbsList"/>
      <ui-prop-select cl v-if="block?.properties?.dbId"  class="chat-table-select col" :block="block" name="tableId" title="Table" :options="tablesList"/>
    </div>

    <ui-prop-select :block="block" name="sendAction" title="Action" :options="actionList"/>

    <template v-if="['command'].includes(currentAction)">

      <ui-prop-select :block="block" name="sendCommand" title="Chat command" :options="commandList"/>

    </template>

    <template v-if="['message'].includes(currentAction)">

      <ui-prop-select :block="block" name="direction" title="Message direction" :options="messageDirections"/>
      <ui-prop-dynamic-string :block="block" title="Message text" name="message"/>

    </template>

    <template v-if="['widget'].includes(currentAction)">

      <ui-prop-select :block="block" name="widgetComponentId" title="Component to send" :options="componentList"/>
      <ui-prop-arguments v-if="block.properties?.widgetComponentId" :block="block" name="widgetArgs" title="Widget params" :target-block-vars="widgetArguments"/>

    </template>

    <div class="q-mt-sm">
      <ui-prop-checkbox :block="block" name="sendLocally" title="Send locally" :default-value="1"/>
      <ui-prop-checkbox :block="block" name="sendToServer" title="Send to AbChat Server" :default-value="0"/>
    </div>

    <template  v-if="block.properties?.sendToServer">
      <ui-prop-dynamic-string  :block="block" title="ABChat Bot id" name="abChatBotId" untranslatable/>
      <ui-prop-params-list :block="block" title="Component parameters" name="chatArgs"/>
    </template>

  </div>

</template>

<script>

import UiPropDynamicString from '@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue';
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import {DbModule} from "../../../../../../../../../common/db/DbModule";
import {DbTable} from "../../../../../../../../../common/db/DbTable";
import UiPropArguments from "@/components/DiagramDesigner/Editor/properties/UiPropArguments.vue";
import {StorageNode} from "../../../../../../../../../common/db/StorageNode";
import UiPropCheckbox from "@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue";
import UiPropParamsList from "@/components/DiagramDesigner/Editor/properties/UiPropParamsList.vue";
import {Diagram} from "../../../../../../../../../common/db/Diagram";

export default {
  mixins: [propertiesMixins],
  components: {UiPropParamsList, UiPropCheckbox, UiPropArguments, UiPropSelect, UiPropDynamicString},
  props: ['block'],
  name: "ChatSendMessagePropsCmp",
  data: () => ({
    actionList: [
      {value: "message", label: "Single message"},
      {value: "command", label: "Chat action"},
      {value: "widget", label: "Widget"},
    ],
    commandList: [
      {value: "delete-chat", label: "Delete chat"},
      {value: "clear-history", label: "Clear history"},
    ],
    attachmentTypes: [
      {label: 'No', value: 'no'},
      {label: 'Image', value: 'image'},
    ],

    messageDirections: [
      {label: 'Incoming', value: 'incoming'},
      {label: 'Outgoing', value: 'outgoing'},
    ],

  }),
  computed: {

    /**
     * Send action
     * @return {HTMLElement|string}
     */
    currentAction() {
      return this.block.properties?.sendAction || ""
    },

    /**
     * Get components list
     * @return {*}
     */
    componentList() {
      return this.wait("components", async () => {
        return (await Diagram.query().where("app_id", this.canvas.product_id).where("status", "active").get()).
        filter(e => e.id !== this.diagram_id && e.diagram_type==='widget').
        map(el => ({
          label: el.title,
          value: el.id
        }))
      }, [])
    },

    /**
     * Get dbs list
     * @return {*}
     */
    dbsList() {
      return this.wait("dbsList", async () => {
        return (await DbModule.query().where({app_id: this.product_id}).get())
            .filter((d) => parseInt(d.module_id) === parseInt(this.module_id) || this.currentModule.modules[d.module_id]?.type === "server")
            .map(d => ({
              value: d.id,
              label: d.name + " (" + this.currentModule.modules[d.module_id]?.name + ")"
            }));
      }, []);
    },

    /**
     * Get tables list
     * @return {*}
     */
    tablesList() {
      return this.wait("tablesList", async () => {
        return (await DbTable.query().where({db_id: this.block?.properties?.dbId || 0}).get()).map(d => ({
          value: d.id,
          label: d.name
        }));
      }, []);
    },

    /**
     * Widget arguments
     * @return {*}
     */
    widgetArguments() {
      return this.wait("widArgs", async () => {
        // Load child diagram
        return await StorageNode.getArguments(this.module_id, 'diagram-'+this.block.properties?.widgetComponentId);
      }, [])
    }

  },
}

</script>
