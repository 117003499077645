<template>

  <q-input
      :label="title"
      v-model="colorValue"
      hide-bottom-space
      :rules="[ (val, rules) => (!val) || rules.anyColor(val) || 'Please type color']"
  >
    <template v-slot:append>
      <q-icon name="colorize" class="cursor-pointer">
        <q-popup-proxy cover ref="proxy" transition-show="scale" transition-hide="scale">
          <q-color v-model="colorValue"/>
        </q-popup-proxy>
      </q-icon>
    </template>

  </q-input>

</template>

<script>

export default {
  components: {},
  props: {
    title: {},
    modelValue: {
      type: String,
    }
  },
  data: () => ({
    colorValue: "#00000000"
  }),
  name: "ColorPicker",
  created() {
    this.colorValue = this.modelValue  ? (this.modelValue.length > 7 ?  this.modelValue :  this.modelValue + "ff") : "#000000ff"

    // Watch color value
    this.$watch('colorValue', function (newVal) {
      this.currentValue = newVal
    }, {deep: true})
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue
      },
      // setter
      set: function (newVal) {

        // Regular model
        this.$emit('update:modelValue', newVal)
        this.$emit('changed', newVal)
      }
    }
  }
}

</script>
