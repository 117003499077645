<template>
  <q-card>
    <q-card-section class="q-pa-sm">
      <div class="flex no-wrap items-center">
        <div class="text-subtitle2" v-text="details.type" />

        <q-separator spaced vertical/>

        <div>
          <health-check-status :status="details.status" />

          <div class="text-caption" v-text="details.message" />
        </div>
      </div>
    </q-card-section>

    <template v-if="formattedRows.length">
      <q-separator spaced />

      <q-card-section class="q-pa-sm">
        <template v-for="(row, idx) in formattedRows" :key="idx">
          <q-separator v-if="idx > 0" spaced />

          <q-card>
            <q-card-section class="q-pa-sm">
              <health-check-status :status="row.status" />

              <div class="text-caption" v-text="row.captured_at" />
              <div class="text-caption truncate-text" v-text="row.message" />

              <template v-if="row.stack">
                <q-separator spaced />

                <pre class="text-caption q-ma-none">{{ row.stack }}</pre>
              </template>
            </q-card-section>
          </q-card>
        </template>
      </q-card-section>
    </template>
  </q-card>
</template>

<script>
import HealthCheckStatus from '@/pages/workspace/health-check/HealthCheckStatus.vue';
import moment from 'moment/moment';

export default {
  name: 'LogsCard',
  components: {HealthCheckStatus},

  props: {
    details: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      jsonData: JSON.stringify({
        test: 'asd'
      }, null, 2),
    };
  },

  computed: {
    // Get log rows
    logRows() {
      return this.details.payload || [];
    },

    // Format rows
    formattedRows() {
      return this.logRows.map((row) => ({
        ...row,
        captured_at: moment.utc(row.captured_at).local().format('YYYY-MM-DD HH:mm'),
      }));
    },
  },
}
</script>

<style scoped lang="scss">
.truncate-text {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Количество строк */
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
