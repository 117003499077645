<template>
  <q-page class="q-pa-md" v-if="isReady">

    <div class="text-h6">
      Android app settings
    </div>
    <q-form @submit="saveProperties">

      <q-select label="Application layout" v-model="properties.layout" map-options emit-value :options="globals.options.moduleLayouts"/>

      <q-select label="Status bar mode" v-model="properties.statusBarMode" map-options emit-value :options="globals.options.statusBarModes"/>

      <q-separator/>
      <q-btn type="submit" label="Save" color="primary" class="q-mt-md"/>
    </q-form>

  </q-page>
</template>

<script>

import {integrationsMixin} from "@/pages/workspace/integrations/integrationsMixin";

export default {
  components: {},
  mixins: [integrationsMixin],
  name: "LayoutIntegrationSettings",
  data: () => ({
    placement: {},
    appId: false
  }),

  created() {
    this.appId = this.$route.params.app_id || 0
    this.moduleId = this.$route.params.module_id || 0
  },

  computed: {

    /**
     * Return integration name
     */
    integration: () => ({name: "layout", type: "device"}),
  }
}

</script>
