<template>
  <q-page class="q-pa-sm analytics-kpi">
    <div class="text-h6">Analytics KPI</div>

    <analytics-filter
      :module-id="moduleId"
      v-model:codeVersion="codeVersion"
      v-model:appPlatform="appPlatform"
      v-model:required-events="requiredEvents"
      v-model:schemaVersion="schemaVersion"
      v-model:date-from="dateFrom"
      v-model:date-to="dateTo"
      v-model:countries="countries"
      v-model:bigQuery="bigQuery"
      v-model:utm-source="utmSource"
      v-model:utm-medium="utmMedium"
      v-model:utm-campaign="utmCampaign"
      v-model:utm-content="utmContent"
    />

    <div class="col" v-if="isReady">
      <q-table
          :columns="columns"
          :rows="kpiStatistics"
          :pagination="{
            rowsPerPage:0
          }"
      >
        <template #body-cell-title="{ value, row }">
          <td>
            <a v-if="!['ad_revenue', 'iap_revenue'].includes(row.event_id)" href="#" @click.prevent="goToPath(row)">{{value}}</a>
            <template v-else>{{value}}</template>
          </td>
        </template>

        <template v-slot:body-cell-actions="props">
          <q-td :props="props">
              <q-btn flat @click="deleteKpi(props.row.event_id)" icon="delete"/>

            <div v-if="!['ad_revenue', 'iap_revenue'].includes(props.row.event_id)" class="sort-buttons">
              <q-btn size="xs" flat square icon="arrow_upward" @click="moveEvent(props.row.id, 'up')" />
              <q-btn size="xs" flat square icon="arrow_downward" @click="moveEvent(props.row.id, 'down')" />
            </div>
          </q-td>
        </template>
      </q-table>
    </div>

  </q-page>
</template>

<script>

import AnalyticsFilter from "@/pages/workspace/analytics/AnalyticsFilter.vue";
import {AnalyticsEvent} from "../../../../../common/db/AnalyticsEvent";
import {Diagram} from '../../../../../common/db/Diagram';
import _ from "lodash";
import {hasFiltersMixin} from '@/pages/workspace/analytics/hasFiltersMixin';

export default {
  name: "AnalyticsKpis",
  components: {AnalyticsFilter},

  mixins: [hasFiltersMixin],

  data: () => ({
    moduleId: false,
    columns: [
      {name: "actions", label: "Actions", field: "actions", align: "left"},
      {name: "title", label: "Title", field: "title", align: "left", sortable: true, sort: (a, b) => a.title.localeCompare(b.title)},
      {name: "user_percent", label: "User affected, %", field: "user_percent", align: "left", sortable: true, sort: (a, b) => a.user_percent - b.user_percent},
      {name: "events_per_user", label: "Events per user", field: "events_per_user", align: "left", sortable: true, sort: (a, b) => a.events_per_user - b.events_per_user},
      {name: "total_users", label: "Total users count", field: "total_users", align: "left"},
      {name: "users", label: "Users count", field: "users", align: "left", sortable: true, sort: (a, b) => a.users - b.users},
      {name: "events", label: "Events count", field: "events", align: "left", sortable: true, sort: (a, b) => a.events - b.events},
    ],
    appId: null,
    versionSource: null,
    isReady: false,
    kpiStatistics: []
  }),

  async created() {

    // Set module id
    this.moduleId = this.$route.params.module_id
    this.appId = this.$route.params.app_id

    // Watch params
    this.$watch("kpiEventsList", () => this.updateStatistics())

    // Update statistics
    await this.updateStatistics()
    this.isReady = true;
  },

  computed: {

    /**
     * Get events list
     * @return {{}|MediaTrackSettings|*}
     */
    kpiEventsList() {
      return this.wait("eventsList", async () => {
        const evs = (await AnalyticsEvent.query().where({module_id: this.moduleId, is_kpi: 1}).get()) || []

        // Add default events
        evs.push({
          event_id: "ad_revenue",
          title: "Ad revenue",
          is_kpi: 1,
          module_id: this.moduleId,
          position: evs.length + 10,
        });

        evs.push({
          event_id: "iap_revenue",
          title: "IAP revenue",
          is_kpi: 1,
          module_id: this.moduleId,
          position: evs.length + 11,
        });

        return evs;
      }, [])
    },
  },
  methods: {

    /**
     * Delete kpi
     * @param eventId
     */
    async deleteKpi(event_id) {

      // Ask user
      this.$q.dialog({
        title: 'Delete KPI',
        message: 'Are you sure you want to delete this KPI?',
        cancel: true,
        persistent: true
      }).onOk(async () => {

        // Load event kpi
        const event = await AnalyticsEvent.query().where({event_id, module_id: this.moduleId}).first();

        // Delete event
        if(event) await event.remote().save({
          is_kpi: 0
        });

        // Remove event from required events
        this.kpiStatistics = this.kpiStatistics.filter(e => e.event_id !== event_id)
      })
    },

    /**
     * Update statistics
     * @return {Promise<void>}
     */
    updateStatistics: _.debounce(async function () {

      try {

        if (!(this.dateFrom && this.dateTo && this.moduleId && this.kpiEventsList?.length)) return
        this.$q.loading.show({message: "Updating statistics..."});
        const stat = {};
        (await this.app.client.call(
          "app-statistics",
          "getLinksStatistics",
          this.appPlatform,
          this.kpiEventsList?.map(e => e.event_id),
          this.dateFrom,
          this.dateTo,
          this.moduleId,
          this.codeVersion,
          this.schemaVersion,
          this.requiredEvents,
          this.countries,
          this.bigQuery,
          this.utmSource,
          this.utmMedium,
          this.utmCampaign,
          this.utmContent,
        ) || [])?.map(e => {
          this.totalUsers = e.total_users
          stat[e.id] = e
        });

        // Apply statistics to kpi statistics
        this.kpiStatistics = this.kpiEventsList?.map(e => {
          const eventsPerUser = stat[e.event_id]?.events_per_user || 0;
          const eventsPerTotalUsers = (stat[e?.event_id]?.events || 0) / Math.max(1, stat[e.event_id]?.total_users || 0)

          return {
            id: e.id,
            event_id: e.event_id,
            title: e.title,
            position: e.position,
            events: stat[e?.event_id]?.events || 0,
            users: stat[e.event_id]?.users || 0,
            events_per_user: `${eventsPerTotalUsers.toFixed(2)} (${eventsPerUser})`,
            user_percent: stat[e.event_id]?.users_percent || 0,
            total_users: stat[e.event_id]?.total_users || 0,
          };
        }).sort((a, b) => a.position - b.position);


      } finally {
        this.$q.loading.hide()
      }
    }, 100),

    /**
     * Navigates to the analytics path of a given event.
     *
     * @param {Object} event - The event to navigate to the analytics path of.
     * @returns {Promise<void>} A promise that resolves when the navigation is complete.
     */
    async goToPath(event) {
      // It queries the `Diagram` model for a diagram where the `module_id` matches
      // the current `moduleId` and the `source` contains the `event_id` of the given event.
      const diagram = (await Diagram.query().where(
        { module_id: this.moduleId },
        Diagram.sql().like('source', `%${event.event_id}%`)
      ).first()) || undefined;

      // If no diagram is found, it logs a warning to the console and returns.
      if (!diagram?.id) {
        console.warn(`No diagram found for event: ${event.event_id}`);

        return;
      }

      // If a diagram is found, it navigates to the "analytics-paths" route with
      // the `id` of the diagram and the `event_id` of the event as route parameters,
      // and `forceFilterApply` set to 1 in the query parameters.
      this.$router.push({
        name: "analytics-paths",
        params: {
          id: diagram.id,
          component_id: event.event_id,
        },
        query: {
          forceFilterApply: 1,
        },
      });
    },

    /**
     * Moves a KPI event up or down in the list.
     *
     * @param {string} event_id - The ID of the event to move.
     * @param {string} direction - The direction to move the event ('up' or 'down').
     * @returns {Promise<void>} A promise that resolves when the event has been moved.
     */
    async moveEvent(event_id, direction) {
      try {
        this.$q.loading.show();

        const positions = await this.app.client.call("app-statistics", "moveKpiEvent", event_id, direction);

        this.updateEventsPositions(positions);
      } catch (e) {
        console.error('Failed to move event', e);

        this.$q.notify({
          message: 'Failed to move event',
          color: 'negative',
          position: 'bottom',
          timeout: 2000,
        });
      } finally {
        this.$q.loading.hide();
      }
    },

    /**
     * Updates the positions of KPI events in the list.
     *
     * @param {Object} positions - An object mapping event IDs to their new positions.
     */
    updateEventsPositions(positions) {
      for (const event of this.kpiEventsList) {
        event.position = positions[event.id] || event.position;
      }

      this.kpiStatistics = this.kpiStatistics.map(e => {
        e.position = positions[e.id] || e.position;
        return e;
      }).sort((a, b) => a.position - b.position);
    },
  }
}

</script>

<style lang="scss">

.analytics-kpi {
  .event-cell {
    display: flex;
    align-items: center;
  }

  .sort-buttons {
    display: inline-flex;
    flex-direction: column;
    margin-right: .5rem;
  }
}

</style>
