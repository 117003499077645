<template>

  <ab-flow-base-cmp class="rating-cmp" :block="block" :class="classesString" :style="stylesString">
    <q-rating
      v-model="ratingModel"
      color="grey"
      :size="iconSize"
      color-selected="warning"
    />
    <div class="links-wrapper">
      <ab-flow-link-connector :block="block" type="output" event="rated" class="q-mr-sm"/>
    </div>
  </ab-flow-base-cmp>

</template>

<script>
import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

export default {
  components: {AbFlowBaseCmp, AbFlowLinkConnector},
  mixins: [renderMixins],
  props: ['block'],
  name: "RatingEditorCmp",
  data: () => ({
    ratingModel: 0
  }),
  computed: {
    iconSize() {
      const sz = this.block?.properties?.iconSize || "sm";
      return 2*this.globals.sizes_to_pixels[sz] + 'px'
    }
  },
}

</script>

<style lang="scss" scoped>
.rating-cmp {
  position: relative;
  justify-content: center;
  .links-wrapper {
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
  }
}
</style>
