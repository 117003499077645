<template>

  <ab-flow-base-cmp :block="block" class="date-editor-cmp" :class="classesString" :style="stylesString">
    <div class="date-weekly-picker q-pa-sm">
      <div class="date-weekly-picker__header flex items-center justify-between">
        <div class="overflow-hidden relative-position">
          <transition :name="`q-transition--slide-${transitionDirection}`">
            <div class="date-weekly-picker__title" :key="currentMonth">{{ currentMonth }}</div>
          </transition>
        </div>

        <div class="date-weekly-picker__nav flex items-center">
          <q-btn
            class="non-selectable no-outline"
            icon="navigate_before"
            flat
            round
            dense
            size="sm"
            @click="prevWeek"
          />

          <q-btn
            class="non-selectable no-outline"
            icon="navigate_next"
            flat
            round
            dense
            size="sm"
            @click="nextWeek"
          />
        </div>
      </div>

      <div class="date-weekly-picker__grid q-mt-xs">
        <div class="date-weekly-picker__row">
          <div v-for="day in weekDays" :key="day" class="date-weekly-picker__day">
            <div>{{ day }}</div>
          </div>
        </div>

        <div class="overflow-hidden relative-position">
          <transition :name="`q-transition--slide-${transitionDirection}`">
            <div class="date-weekly-picker__row" :key="currentDate.unix()">
              <div
                v-for="date in currentWeekDates"
                :key="date.unix()"
                class="date-weekly-picker__date"
                :class="{
                  'date-weekly-picker__date--today': date.isSame(nowDate, 'day') && !date.isSame(selectedDate, 'day'),
                }"
              >
                <q-btn
                  class="no-outline non-selectable"
                  :class="getDateClasses(date)"
                  round
                  dense
                  unelevated
                  @click="selectDate(date)"
                >{{ date.format("D") }}</q-btn>
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </ab-flow-base-cmp>

</template>

<script>
import moment from "moment";
import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";

export default {
  components: {AbFlowBaseCmp},
  mixins: [renderMixins],
  props: ['block'],
  name: "DateWeeklyPickerEditorCmp",
  data: () => ({
    currentMonth: moment().format("MMMM YYYY"),
    currentDate: moment(),
    currentWeekDates: [],
    transitionDirection: null,
    nowDate: moment(),
    selectedDate: moment().add(1, 'day'),
  }),
  computed: {
    /**
     * Returns an array of abbreviated week day names.
     * @returns {Array<string>} An array of abbreviated week day names
     */
    weekDays() {
      return Array.from({length: 7}, (_, i) => moment().day(i).format("dd"));
    },

    selectedDateColor() {
      return this.block?.properties?.selectedDateColor || 'light';
    },

    selectedDateBgColor() {
      return this.block?.properties?.selectedDateBgColor || 'info';
    },

    unselectedDateColor() {
      return this.block?.properties?.unselectedDateColor || 'dark';
    },
  },

  methods: {
    /**
     * Set the selected date.
     * @param {Moment} date - The selected date
     */
    selectDate(date) {
      this.selectedDate = date;
    },

    /**
     * Update the picker grid based on the current date.
     */
    updatePickerGrid() {
      const startOfWeek = this.currentDate.clone().startOf("week");

      this.currentMonth = startOfWeek.format("MMMM YYYY");

      this.currentWeekDates = Array.from({length: 7}, (_, i) => startOfWeek.clone().add(i, "days"));
    },

    /**
     * Navigate to the previous week.
     */
    prevWeek() {
      this.transitionDirection = 'right';
      this.currentDate.subtract(1, "week");

      this.updatePickerGrid();
    },

    /**
     * Navigate to the next week.
     */
    nextWeek() {
      this.transitionDirection = 'left';
      this.currentDate.add(1, "week");

      this.updatePickerGrid();
    },

    /**
     * Get the classes for the specified date.
     * @param {Moment} date - The date for which to get the classes
     * @returns {Array} An array of classes for the specified date
     */
    getDateClasses(date) {
      if (date.isSame(this.nowDate, 'day') || date.isSame(this.selectedDate, 'day')) {
        return [
          `dg-foreground-${this.selectedDateColor}`,
          `dg-background-${this.selectedDateBgColor}`,
        ];
      }

      return [
        `dg-foreground-${this.unselectedDateColor}`,
      ];
    },
  },

  mounted() {
    this.updatePickerGrid();
  },
}

</script>

<style lang="scss">
.date-weekly-picker {
  width: 100%;

  &__header {
    column-gap: 20px;
  }

  &__title {
    color: var(--control-text-color);
    min-width: 110px;
  }

  &__nav {
    column-gap: 4px;
  }

  &__grid {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  &__row {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-column-gap: 4px;
    width: 100%;
  }

  &__day, &__date {
    padding: 1px;
  }

  &__day {
    opacity: .38;
    font-size: 12px;

    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
    }
  }

  &__date {
    &--today {
      opacity: .5;
    }

    button {
      width: 30px;
      height: 30px;

      &.q-btn--dense.q-btn--round {
        min-width: 30px;
        min-height: 30px;
      }
    }
  }
}
</style>
