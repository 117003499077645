<template>
  <q-dialog ref="editChannel">
    <table-channel-form :edit-channel="editChannel" @saved="$refs.editChannel.hide()"/>
  </q-dialog>

  <div class="">
    <template v-for="(channel, k) of channels" :key="k">

      <div class="row items-center col">
        <strong>{{channel.title}}</strong>
        <q-space/>
        <div>
          <q-btn flat @click="editChannel = channel.data();$refs.editChannel.show()" icon="edit" size="sm" round/>
          <q-btn flat @click="deleteChannel(channel)" icon="delete" size="sm" round/>
        </div>

      </div>

    </template>

  </div>

  <q-separator/>

  <div class="column items-end q-pt-sm">
    <q-btn icon="add" flat label="Add channel" size="sm" @click="editChannel={table_id: tableId};$refs.editChannel.show()"/>
  </div>

</template>

<style>
</style>

<script>
import TableChannelForm from "./TableChannelForm";
import {DbTableChannel} from "@/../../common/db/DbTableChannel";

export default {
  name: 'TableChannelsList',
  components: {TableChannelForm},
  props: {
    tableId: {
      type: Number,
      required: true
    }
  },
  data: () => ({
    editChannel: false,
    module_id: false
  }),

  async created() {
    // Subscribe to channels
    await DbTableChannel.remote().subscribe("table-channels",{table_id: this.tableId});
  },

  computed: {
    // Return all channels from the store
    channels() {
      return this.wait("table-channels", DbTableChannel.query().where({table_id: this.tableId}).get(), [])
    }
  },

  methods: {

    /**
     * Delete channel
     * @param channel
     * @return {Promise<void>}
     */
    async deleteChannel(channel) {
      this.$q.dialog({
        title: 'Delete confirmation',
        message: `Are you sure want to delete ${channel.title} ?`,
        cancel: true,
        persistent: true
      }).onOk(() => {
        DbTableChannel.remote().delete(channel.id)
      })
    },
  }
}
</script>
