<template>
  <ui-prop-select name="position" title="Position" default-value="center" :block="block" :options="globals.options.positionsFAB"/>
  <ui-prop-select name="direction" title="Expandable direction" default-value="" :block="block" :options="globals.options.expandableDirection"/>
  <ui-data-props v-if="this.block.properties?.direction !== ''" name="items" label="FAB actions" :default-schema="defaultSchema" :block="block" />
  <ui-dynamic-props :block="block"/>
</template>

<script>

import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect";
import UiDataProps from "@/components/DiagramDesigner/Editor/properties/UiDataProps";
import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps";

export default {
  name: "FABPropsCmp",
  props: ['block'],
  components: {UiDynamicProps, UiPropSelect, UiDataProps},
  data: () => ({
    defaultSchema: [
      {
        name: "name",
        type: "string"
      },
      {
        name: "backgroundColor",
        type: "color"
      },
      {
        name: "foregroundColor",
        type: "color"
      },
      {
        name: "icon",
        type: "icon"
      },
    ]
  }),
  computed: {
    isDirection() {
      return !!this.block.properties?.direction;
    }
  }
}

</script>

