<template>

  <div>
    <ui-prop-select :block="block" title="Channel" name="soundChannel" :options="globals.options.soundManagerChannels"/>
    <ui-prop-select :block="block" title="Operation to perform" name="operation" :options="globals.options.soundManagerOperations"/>

    <ui-prop-value-selector v-if="managerOperation === 'volume'" :block="block" name="volume" title="Channel volume" hint="Volume value from 0% to 100%"/>
    <ui-prop-value-selector v-if="managerOperation === 'mute'" :block="block" name="muted" title="Channel is muted" only-var-type="bool"/>

  </div>

</template>

<script>

import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";

export default {
  inject: ['product_id'],
  components: {UiPropSelect, UiPropValueSelector},
  props: ['block'],
  name: "VibratePropsCmp",
  computed: {

    /**
     * Sound operation
     * @return {Document.operation|(function(): *)|JSONPatchOperation|*}
     */
    managerOperation() {
      return this.block?.properties?.operation
    }
  }
}

</script>
