import {AccessModel} from './AccessModel.js';

/**
 * AnalyticsFunnel model
 */
export class AnalyticsFunnel extends AccessModel {
    static entity = 'analytics_funnels'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        app_id: "int",
        module_id: "int",
        title: "string",
        funnel_schema: "json",
    }

    /**
     * Partitions list
     */
    async channels() {
        return {
            'module-funnels': {
                subscribe: ({module_id}) => module_id,
                init: async ({module_id}) => AnalyticsFunnel.getList(module_id),
            }
        }
    }

    /**
     * Get list
     */
    static async getList(module_id) {
        return this.query().where({module_id}).get()
    }
}
