<template>

  <div>
    <ui-prop-string name="roles" title="User roles to check" :block="block"/>
  </div>

</template>

<script>

import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString";

export default {
  components: {UiPropString},
  props: ['block'],
  name: "CheckRolePropsCmp",
  computed: {
  }
}

</script>
