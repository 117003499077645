<template>

  <ab-flow-base-cmp class="wid-ren-panel" :block="block" :class="classesString" :style="stylesString">
    <div class="links-wrapper">
      <ab-flow-link-connector v-for="(e, k) of block.properties?.events || []" class="q-mr-xs" :block="block" type="output" :event="e.name" :key="k"/>
    </div>
  </ab-flow-base-cmp>
</template>

<script>
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";

export default {
  components: {AbFlowBaseCmp, AbFlowLinkConnector},
  mixins: [renderMixins],
  props: ['block'],
  name: "WidgetRendererEditorCmp",
}

</script>

<style lang="scss">
.wid-ren-panel {
  position: relative;
  width: 100%;
  height: 100%;
  background: repeating-linear-gradient(
          30deg,
          #eee,
          #eee 10px,
          #ddd 10px,
          #ddd 20px
  );

}

</style>
