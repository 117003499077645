export const currenciesList = [
    {
        "label": "USD",
        "value": "USD"
    },
    {
        "label": "EUR",
        "value": "EUR"
    },
    {
        "label": "NGN",
        "value": "NGN"
    },

]
