<template>
  <q-card class="diagram-emulator">
    <q-card-section class="bg-primary text-white row q-ma-none q-pa-none">
      <!--div>
          <q-select v-model="mode" emit-value map-options filled :options="emuModes"/>
      </div!-->
      <q-space/>
      <q-btn flat size="sm" icon="window" @click="openInTab"/>
      <q-btn flat size="sm" icon="recycling" @click="clearStorage"/>
      <q-btn flat size="sm" icon="restart_alt" @click="updateKey++"/>
      <q-btn flat size="sm" icon="close" @click="$emit('close')"/>
    </q-card-section>
    <div>
      <iframe v-if="clearMode" @load="storageCleared" frameborder="0" :key="updateKey" :src="appResetUrl" :width="size.width" :height="size.height"></iframe>
      <iframe v-else frameborder="0" :key="updateKey" :src="appUrl" :width="size.width" :height="size.height"></iframe>
    </div>
  </q-card>
</template>

<script>

export default {
  name: "DiagramEmulator",
  emits: ['close'],
  props: ["moduleId", "runMode"],
  data: () => ({
    width: 370,
    height: 570,
    updateKey: 0,
    clearMode: false,
    mode: "mobile",
    emuModes:[{value: "mobile", label: "Mobile", width: 370, height: 570}, {value: "desktop", label: "Desktop", width: 800, height: 600}]
  }),
  methods: {
    openInTab() {
      window.open(this.appUrl, '_blank');
    },

    /**
     * Reload app after storage cleared
     */
    storageCleared() {
      setTimeout(() => this.clearMode = false, 1000);
    },

    /**
     * Clear storage
     */
    clearStorage() {
      // Prompt user
      this.$q.dialog({
        title: 'Clear storage',
        message: 'Are you sure you want to clear storage?',
        cancel: true,
        persistent: true
      }).onOk(() => {
        this.clearMode = true;
        this.updateKey++
      })
    }
  },
  computed: {

    /**
     * App url
     * @return {string}
     */
    appUrl() {
      return process.env.VUE_APP_EMULATOR_URL + "/" + this.moduleId + "/" + (this.runMode || "debug");
    },

    /**
     * App reset url
     * @return {string}
     */
    appResetUrl() {
      return process.env.VUE_APP_EMULATOR_URL + "/" + this.moduleId + "/reset";
    },

    /**
     * Emulator size
     * @return {width, height}
     */
    size() {
      return this.emuModes.find(mode => mode.value === this.mode)
    }
  }
}

</script>

<style lang="scss">

.diagram-emulator {
  max-width: none !important;
  i, span {
    color:white;
  }
}

</style>
