<template>

  <div>
    <div class="text-subtitle1">{{title}}</div>

    <div class="column q-col-gutter-md">

      <div class="row col q-gutter-sm">
        <q-select class="col" emit-value map-options :options="globals.options.sizes" v-model="border.radiusTopLeft" label="TL"/>
        <q-select class="col" emit-value map-options :options="globals.options.sizes" v-model="border.radiusTopRight" label="TR"/>
        <q-select class="col" emit-value map-options :options="globals.options.sizes" v-model="border.radiusBottomLeft" label="BL"/>
        <q-select class="col" emit-value map-options :options="globals.options.sizes" v-model="border.radiusBottomRight" label="BR"/>
      </div>

      <!--q-input label="Border radius, px" v-model="border.radius"/!-->

      <q-input label="Border weight, px" v-model="border.weight"/>
      <color-picker title="Border color" v-model="border.color"/>
    </div>
  </div>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import ColorPicker from "@/components/ColorPicker/ColorPicker";

export default {
  mixins: [propertiesMixins],
  name: "UiPropBorder",

  components: {ColorPicker},

  props: {
    defaultValue: {
      type: String,
      radius: 0
    }
  },
  data: () => ({
    border: {
      size: 1,
      radius: 1,
      color: "#000000",
    }
  }),

  created() {

    // Init current value
    if(this.currentValue) this.border = this.currentValue

    // Add watcher
    this.$watch('border', function (newVal) {
      this.currentValue = newVal
    }, {deep: true})
  },

  computed: {
    // Redefine prop name
    type: () => "border"
  }
}

</script>
