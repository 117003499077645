export const systemFunctionList = {
    math: {
        round: {
            arguments: {
                value: {
                    type: "float",
                    description: "Float value to round"
                },
                digits: {
                    type: "integer",
                    description: "Number of digits"
                },
            },
            returns: "Integer",
            description: "Round float value"
        },
        leadingZeros: {
            arguments: {
                value: {
                    type: "integer",
                    description: "Integer value"
                },
                digits: {
                    type: "integer",
                    description: "Number of digits"
                },
            },
            returns: "string",
            description: "Add leading zeros to integer value"
        },
        random: {
            arguments: {
                min: {
                    type: "integer",
                    description: "Minimum value"
                },
                max: {
                    type: "integer",
                    description: "Maximum value"
                },
            },
            returns: "Integer",
            description: "Generate random number"
        },
        evaluate: {
            arguments: {
                expression: {
                    type: "string",
                    description: "Expression"
                },
            },
            returns: "float",
            description: "Evaluate math expression"
        },
    },
    file: {
        base64Encode: {
            arguments: {
                file: {
                    type: "file",
                    description: "File to encode"
                },
            },
            returns: "string",
            description: "Encode local file as base64 string"
        }
    },
    string: {
        unique: {
            arguments: {
                letters: {
                    type: "integer",
                    description: "Number of letters in string"
                },
            },
            returns: "String",
            description: "Generate unique string"
        },
        split: {
            arguments: {
                string: {
                    type: "string",
                    description: "String to split"
                },
                separator: {
                    type: "string",
                    description: "Separator. Default: \",\""
                },
            },
            returns: "array",
            description: "Split string"
        },
        parseJson: {
            arguments: {
                string: {
                    type: "string",
                    description: "String to parse"
                },
            },
            returns: "Object",
            description: "Parse JSON string to object"
        },
        capitalizeSentence: {
            arguments: {
                sentence: {
                    type: "string",
                    description: "String to capitalize"
                },
            },
            returns: "String",
            description: "Capitalize sentence"
        },
    },
    auth: {
        hasRole: {
            arguments: {
                roles: {
                    type: "string",
                    description: "Comma separated roles"
                },
            },
            returns: "boolean",
            description: "Check if user has role"
        },
        loggedUserId: {
            arguments: {},
            returns: "Integer",
            description: "Return logged in user id"
        },
        getUserProperty: {
            arguments: {
                property: {
                    type: "string",
                    description: "Property name"
                },
            },
            returns: "string",
            description: "Return logged in user property"
        }
    },
    datetime: {
        format: {
            arguments: {
                datetimeValue: {
                    type: "datetime,int",
                    description: "Datetime value"
                },
                format: {
                    type: "string",
                    description: "Datetime format, for ex: YYYY/MM/DD h:mm:ss"
                }
            },
            returns: "string",
            description: "Format datetime"
        },
        now: {
            arguments: {},
            returns: "datetime",
            description: "Return current time"
        },
        fromNow: {
            arguments: {
                datetimeValue: {
                    type: "datetime",
                    description: "Datetime value"
                },
            },
            returns: "string",
            description: "Time from now"
        },
        createDate: {
            arguments: {
                years: {
                    type: "int",
                    description: "Number of years"
                },
                months: {
                    type: "int",
                    description: "Number of months (1-12)"
                },
                days: {
                    type: "int",
                    description: "Number of days (1-31)"
                },
                hours: {
                    type: "int",
                    description: "Number of hours (0-23)"
                },
                minutes: {
                    type: "int",
                    description: "Number of minutes (0-59)"
                },
                seconds: {
                    type: "int",
                    description: "Number of seconds (0-59)"
                },
            },
            returns: "datetime",
            description: "Create datetime"
        },
        userTimezoneOffset: {
            arguments: {},
            returns: "int",
            description: "Return user timezone offset"
        },
    },
    array: {
        count: {
            arguments: {
                arrayValue: {
                    type: "array",
                    description: "Array value"
                },
            },
            returns: "int",
            description: "Elements count in array"
        },
        contains: {
            arguments: {
                array: {
                    type: "array",
                    description: "Array"
                },
                value: {
                    type: "",
                    description: "Value"
                },
            },
            returns: "boolean",
            description: "Check if value inside array"
        },
        toString: {
            arguments: {
                array: {
                    type: "array",
                    description: "Array"
                },
                separator: {
                    type: "string",
                    description: "Separator"
                },
            },
            returns: "string",
            description: "Convert array to string"
        },
        findByValue: {
            arguments: {
                array: {
                    type: "array",
                    description: "Array"
                },
                field: {
                    type: "string",
                    description: "Field name to search"
                },
                value: {
                    type: "string",
                    description: "Value to search"
                },
            },
            returns: "boolean",
            description: "Check if value inside array"
        },
        filterByValue: {
            arguments: {
                array: {
                    type: "array",
                    description: "Array"
                },
                field: {
                    type: "string",
                    description: "Field name to search"
                },
                operation: {
                    type: "string",
                    description: "Operation to compare"
                },
                value: {
                    type: "string",
                    description: "Value to search"
                },
            },
            returns: "Array",
            description: "Return array without value"
        },
    },
    app: {
        currentLanguage: {
            arguments: {},
            returns: "string",
            description: "Return current language"
        },
    },
    range: {
        numbers: {
            arguments: {
                min: {
                    type: "int",
                    description: "Minimum value"
                },
                max: {
                    type: "int",
                    description: "Maximum value"
                },
            },
            returns: "array",
            description: "Return array of numbers from min to max"
        },
    },
    iap: {
        checkIfProductPurchased: {
            arguments: {
                productId: {
                    type: "string",
                    description: "Product ID"
                },
            },
            returns: "boolean",
            description: "Return true if product purchased"
        },
        checkIfGroupPurchased: {
            arguments: {
                groupId: {
                    type: "string",
                    description: "Group ID"
                },
            },
            returns: "boolean",
            description: "Returns true if at least one product in the group is purchased"
        },
    },
}
