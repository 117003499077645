<template>
  <q-dialog ref="translations" @before-show="initializeModel" persistent>
    <q-card class="full-width">
      <q-card-section class="bg-primary text-white row justify-center items-center text-h6">
        Translation
        <q-space/>
        <q-btn icon="close" label="close" v-close-popup flat />
      </q-card-section>

      <q-card-section>
        <q-input
          class="q-mb-md"
          label="Context"
          type="text"
          filled
          dense
          v-model="localizationModel.context"
        />

        <q-input
          class="q-mb-md"
          label="Max length"
          type="number"
          filled
          dense
          v-model.number="localizationModel.max_length"
        />

        <div
          v-for="locale in languages"
          :key="locale.value"
          class="row no-wrap items-center"
        >
          <localization-editor-field
            class="q-mb-md"
            v-model="model[locale.value]"
            :current-value="model[locale.value]"
            :label="locale.label"
            :multiple="multiple"
            :type="type"
          />

          <q-btn
            v-if="locale.value !== mainLocale"
            class="q-ml-md"
            color="white"
            text-color="primary"
            dense
            icon="g_translate"
            @click="phraseTranslate(locale.value)"
          />
        </div>
      </q-card-section>

      <q-card-actions>
        <q-space/>
        <q-btn class="q-mr-sm" flat icon="g_translate" label="Translate all" @click="translateAll" />
        <q-btn label="Save" flat icon="save" @click="applyValue(false)" />
      </q-card-actions>
    </q-card>

  </q-dialog>
  <slot name="activator" :show="showDialog">
    <q-btn icon="translate" round size="sm" flat @click="showDialog"/>
  </slot>
</template>

<script>
import {Localization} from '@/../../common/db/Localization';
import {LocalizationMessage} from '@/../../common/db/LocalizationMessage';
import LocalizationEditorField from '@/components/Localizations/LocalizationEditorField.vue';

export default {
  name: 'LocalizationEditor',
  components: {LocalizationEditorField},

  inject: ['module_id', 'diagram_id', 'designer'],

  props: ['modelValue', 'multiple', 'alias', 'type'],

  emits: ['update:modelValue'],

  data() {
    return {
      model: {},
      localizationModel: {
        context: '',
        max_length: 0,
      },
    };
  },

  computed: {
    /**
     * Returns a list of languages that are available in the current media designer.
     *
     * This method performs the following steps:
     * - It retrieves the list of languages from the global options.
     * - It filters the list of languages to only include those that are included in the `appLocales` of the current media designer.
     * - If `appLocales` is not defined, it defaults to an empty array.
     *
     * @returns {Array} An array of languages that are available in the current media designer.
     */
    languages() {
      return this.globals.options.languages.filter(
        (v) => (this.app.currentMediaDesigner?.appLocales || []).includes(v.value),
      );
    },
    /**
     * Returns the main locale of the current media designer.
     *
     * This method retrieves the main locale from the current media designer object.
     * The main locale is the primary language used in the media designer.
     * If the current media designer is not defined, it returns undefined.
     *
     * @returns {string|undefined} The main locale of the current media designer, or undefined if the current media designer is not defined.
     */
    mainLocale() {
      return this.app.currentMediaDesigner?.mainLocale;
    },
  },

  methods: {
    /**
     * Initializes the model for each language.
     *
     * This method performs the following steps:
     * - It iterates over each language available in the current media designer.
     * - If the language value matches the main locale of the current media designer, it sets the model value for that language to the current model value and continues to the next iteration.
     * - It logs the localizations of the current media designer to the console.
     * - It sets the model value for the language to the localization message for the locale alias in the current media designer, or an empty string if no such message exists.
     */
    initializeModel() {
      for (const lang of this.languages) {
        if (lang.value === this.app.currentMediaDesigner?.mainLocale) {
          this.model[lang.value] = this.modelValue;

          continue;
        }

        this.model[lang.value] = this.app.currentMediaDesigner?.localizations[lang.value]?.[this.alias] || "";
      }
    },

    /**
     * Applies the current model values to the localizations.
     *
     * This method performs the following steps:
     * - It queries the `Localization` model for a localization where the `module_id` matches the current `module_id` and the alias matches the locale alias.
     * - If no such localization exists, it creates a new localization with the current `module_id` and locale alias.
     * - It iterates over each entry in the model, where each key is a locale and each value is a localization message.
     * - It queries the `LocalizationMessage` model for a message where the `localization_id` matches the id of the localization and the locale matches the current locale.
     * - If no such message exists, it creates a new message with the `localization_id`, locale, and message value.
     * - If such a message does exist, it updates the message with the new value.
     * - It updates the localizations of the current media designer.
     * - It emits an event to update the model value to the model value for the main locale of the current media designer, or the current model value if no such value exists.
     * - It hides the translations dialog.
     *
     * @param {boolean} notCloseDialog - A flag indicating whether to close the dialog after applying the value.
     * @returns {Promise<void>} A promise that resolves when the model values have been applied to the localizations.
     */
    async applyValue(notCloseDialog = false) {
      let localization = await Localization.query().where({
        module_id: this.module_id,
        alias: this.alias,
      }).first();

      if (!localization?.id) {
        localization = await Localization.remote().save({
          module_id: this.module_id,
          alias: this.alias,
          type: this.type || 'plain',
        });
      }

      await Localization.remote().save({
        ...localization,
        ...this.localizationModel,
      });

      for (const [locale, value] of Object.entries(this.model)) {
        let message = await LocalizationMessage.query().where({
          localization_id: localization.id,
          locale,
        }).first();

        if (!message?.id) {
          await LocalizationMessage.remote().save({
            localization_id: localization.id,
            locale,
            message: value,
          });
        } else {
          await LocalizationMessage.remote().save({
            ...message,
            message: value,
          });
        }
      }

      await this.app.currentMediaDesigner.updateLocalizations();

      // Update model
      this.$emit('update:modelValue', this.model[this.app.currentMediaDesigner?.mainLocale] || this.modelValue);

      if (!notCloseDialog) {
        this.$refs.translations.hide();
      }
    },

    /**
     * Shows the translations dialog.
     *
     * This method is used to display the translations dialog when the user clicks on the translate button.
     * It uses the Vue.js $refs system to directly access the 'translations' dialog component and call its 'show' method.
     * The 'show' method is part of the Quasar 'q-dialog' component API and is used to programmatically open the dialog.
     */
    showDialog() {
      this.$refs.translations.show();
    },

    /**
     * This method is responsible for translating a phrase to all available languages.
     * It first prompts the user for confirmation using a dialog box.
     * If the user confirms, it then proceeds with the translation process.
     *
     * The translation process involves the following steps:
     * - It shows a loading indicator.
     * - It applies the current model values to the localizations.
     * - It retrieves the localization for the current module and alias.
     * - It makes a remote call to the "translatePhrase" method of the "localizations" service, passing the module id, localization id, and main locale of the current media designer.
     * - It updates the localizations of the current media designer.
     * - It notifies the user that the phrase has been translated.
     *
     * If an error occurs at any point during this process, it notifies the user of the error and hides the loading indicator.
     */
    translateAll() {
      this.$q.dialog({
        title: 'Translate phrase',
        message: 'Are you sure you want to translate phrase to all languages?',
        cancel: true,
        persistent: true,
      }).onOk(async () => {
        try {
          this.$q.loading.show();

          // Apply current value
          await this.applyValue(true);

          // Get localization
          const localization = await Localization.query().where({
            module_id: this.module_id,
            alias: this.alias,
          }).first();

          // Translate phrase
          await LocalizationMessage.remote().call(
            "localizations",
            "translatePhrase",
            this.module_id,
            localization.id,
            this.app.currentMediaDesigner?.mainLocale,
          );

          // Update localizations
          await this.app.currentMediaDesigner.updateLocalizations();

          // Re-initialize model
          this.initializeModel();

          this.$q.notify('Phrase translated', 'positive');
        } catch (e) {
          this.$q.notify(e.message, 'error');
        } finally {
          this.$q.loading.hide();
        }
      });
    },

    /**
     * This method is responsible for translating a phrase to a specific language.
     * It first prompts the user for confirmation using a dialog box.
     * If the user confirms, it then proceeds with the translation process.
     *
     * The translation process involves the following steps:
     * - It shows a loading indicator.
     * - It applies the current model values to the localizations.
     * - It retrieves the localization for the current module and alias.
     * - It makes a remote call to the "translatePhraseToLang" method of the "localizations" service, passing the module id, localization id, main locale of the current media designer, and the target language.
     * - It updates the localizations of the current media designer.
     * - It notifies the user that the phrase has been translated.
     *
     * If an error occurs at any point during this process, it notifies the user of the error and hides the loading indicator.
     *
     * @param {string} to - The target language to which the phrase will be translated.
     */
    phraseTranslate(to) {
      this.$q.dialog({
        title: 'Translate phrase',
        message: `Are you sure you want to translate phrase to ${this.languages.find((v) => v.value === to)?.label || 'n/a'}?`,
        cancel: true,
        persistent: true,
      }).onOk(async () => {
        try {
          this.$q.loading.show();

          // Apply current value
          await this.applyValue(true);

          // Get localization
          const localization = await Localization.query().where({
            module_id: this.module_id,
            alias: this.alias,
          }).first();

          // Translate phrase
          await LocalizationMessage.remote().call(
            "localizations",
            "translatePhraseToLang",
            this.module_id,
            localization.id,
            this.app.currentMediaDesigner?.mainLocale,
            to,
          );

          // Update localizations
          await this.app.currentMediaDesigner.updateLocalizations();

          // Re-initialize model
          this.initializeModel();

          this.$q.notify('Phrase translated', 'positive');
        } catch (e) {
          this.$q.notify(e.message, 'error');
        } finally {
          this.$q.loading.hide();
        }
      });
    },
  },

  async created() {
    // Subscribe to the localization and localization message channels
    await Localization.remote()
      .subscribe("module-localizations", {module_id: this.module_id});

    await LocalizationMessage.remote()
      .subscribe("module-localization-messages", {module_id: this.module_id});

    const localization = await Localization.query().where({
      module_id: this.module_id,
      alias: this.alias,
    }).first();

    if (localization?.id) {
      this.localizationModel.context = localization.context;
      this.localizationModel.max_length = localization.max_length;
    }
  },
}
</script>

<style scoped lang="scss">

</style>
