<template>
  <div class="code-editor">
    <v-ace-editor
      :readonly="readonly"
      v-model:value="currentValue"
      :lang="language"
      @init="editorInit"
      theme="chrome"
      style="height: 100%;width: 100%;"
    />
  </div>
</template>

<script>
import { VAceEditor } from "vue3-ace-editor";
import "ace-builds/webpack-resolver";
import "ace-builds/src-noconflict/mode-javascript";
import "ace-builds/src-noconflict/mode-html";

export default {
  components: {
    VAceEditor,
  },
  props:{
    readonly: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: String,
      default: ""
    },
    language: {
      type: String,
      default: "javascript"
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      isMounted: false,
    };
  },

  computed: {
    currentValue: {
      get: function () {
        return this.modelValue
      },
      // setter
      set: function (val) {
        this.$emit('update:modelValue', val)
      }
    }
  },

  methods: {
    editorInit(editor) {
      editor.setValue(this.modelValue);
      editor.setTheme('ace/theme/chrome');
      editor.setOption('mode', `ace/mode/${this.language}`);
    },
  },
};
</script>

<style>
.code-editor {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
</style>
