<template>

  <div>
    <ui-prop-select
      name="event"
      title="Event"
      :block="block"
      :options="options"
    />
  </div>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropSelect from '@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue';

export default {
  inject: {
    product_id: {
      default: false
    }
  },
  mixins: [propertiesMixins],
  components: {UiPropSelect},
  props: ['block'],
  name: 'MarketingEventPropsCmp',

  computed: {
    // Get options
    options() {
      return this.globals.options.collections.marketingPixelEvents
        .filter((o) => !o?.hidden)
        .map((o) => ({
          label: o.title,
          value: o.value,
        }))
    },
  },
}

</script>
