<template>

  <q-input v-model="selectedDate" mask="date" :rules="['date']">
    <template v-slot:append>
      <q-icon name="event" class="cursor-pointer">
        <q-popup-proxy cover transition-show="scale" transition-hide="scale">
          <q-date v-model="selectedDate" >
            <div class="row items-center justify-end">
              <q-btn v-close-popup label="Close" color="primary" flat />
            </div>
          </q-date>
        </q-popup-proxy>
      </q-icon>
    </template>
  </q-input>

</template>

<script>

export default {
  components: {},
  props: {
    title: {},
    modelValue: {
      type: String,
    }
  },
  data: () => ({
    selectedDate: ""
  }),
  name: "DatePicker",
  created() {

    // Set the valueData to the modelValue
    this.selectedDate = this.modelValue

    // Add watcher to update the modelValue when the valueData changes
    this.$watch('selectedDate', () => {
      this.$emit('update:modelValue', this.selectedDate);
    }, {deep: true})
  },
}

</script>
