<template>
  <ab-flow-base-cmp :movable="true" :block="block" class="forgot-password-editor-cmp">

    <ab-flow-link-connector :block="block" type="input" class="ev-run" event="run"/>

    <q-card class="card">

      <q-card-section class="bg-secondary text-white">
        {{ title }}
      </q-card-section>

    </q-card>

    <div class="results">
      <ab-flow-link-connector :block="block" type="output" event="success"/>
      <ab-flow-link-connector :block="block" type="output" event="error"/>
      <ab-flow-link-connector :block="block" type="output" event="not-found"/>
    </div>

  </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

export default {
  components: {AbFlowBaseCmp, AbFlowLinkConnector},
  inject: ['canvas'],
  props: ['block'],
  name: "ForgotPasswordEditorCmp",

  computed: {
    // Get the channel property from the block
    title() {
      return this.block?.properties?.title || 'Forgot password';
    },
  },
}

</script>

<style lang="scss">

.forgot-password-editor-cmp {
  position: relative;

  .card {
    max-width: 200px;
    word-wrap: break-word;
  }
  .ev-run {
    left: -12px;
    position: absolute;
    z-index: 1;
    top: calc(50% - 5px);
  }
  .results {
    right: -12px;
    position: absolute;
    z-index: 1;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    row-gap: 6px;
  }
}

</style>
