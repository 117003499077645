<template>
  <ui-prop-select :block="block" title="Separator direction" name="direction" :options="globals.options.directions"/>
  <ui-prop-select :block="block" title="Separator style" name="style" :options="globals.options.separatorStyles"/>
  <ui-prop-select :block="block" title="Separator color" name="color" :options="moduleColors"/>
  <ui-dynamic-props :block="block"/>
</template>

<script>

import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";

export default {
  name: "SeparatorPropsCmp",
  props: ['block'],
  components: {UiPropSelect, UiDynamicProps},

  inject: {
    currentModule: {
      default: null
    },
  },

  computed: {
    /**
     * Computed property that returns the colors from the current module's settings styles.
     * It uses optional chaining to safely access nested properties.
     * If any of the properties in the chain is undefined, it will return an empty array.
     *
     * @returns {Array} - An array of colors or an empty array if not defined.
     */
    moduleColors() {
      return [
        {value: "", label: "None"},
        ...(this.currentModule?.currentModule?.settings?.styles?.colors || [])
      ];
    },
  },
}

</script>

