<template>
  <div class="media-picker">

    <q-dialog ref="image_gallery">
      <q-card class="full-width ab-mw-9 full-height column">

        <q-card-section class="bg-primary text-white text-h6 row">
          Media gallery
          <q-space/>
          <q-btn flat icon="close" @click="$refs.image_gallery.hide(); $emit('closed')">Close</q-btn>
        </q-card-section>

        <q-card-section class="col">
          <media-gallery @selected="onItemSelect" :product-id="productId" :type="mediaType" :module-id="moduleId"/>
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-input class="image-preview" :model-value="currentValue?.title" :readonly="true" :label="label" :error="!!errorMessage" :error-message="errorMessage">
      <template v-slot:prepend>
        <template v-if="currentValue">
          <q-icon v-if="['sound', 'lottie', 'video', 'subtitles'].includes(mediaType)" :name="previewIcon" />
          <img v-else :src="imageUrl"/>
        </template>
      </template>
      <template v-slot:append>
        <q-btn flat icon="upload" @click="$refs.image_gallery.show()">Choose</q-btn>
      </template>
    </q-input>

  </div>

</template>

<script>

import MediaGallery from "@/components/MediaGallery/MediaGallery";
import {pathHelper} from "@/utils/pathHelper";

export default {
  components: {MediaGallery},
  props: ['modelValue', 'label', 'productId', 'mediaType', 'moduleId', 'errorMessage'],
  emits: ['closed', 'changed', 'update:modelValue'],
  name: "MediaPicker",
  data: () => ({
    file: null,
    internalCurrentValue: null
  }),
  created() {
    // Save model value
    if(this.modelValue) this.internalCurrentValue = this.modelValue
  },
  methods: {

    /**
     * On select item in gallery
     * @param itm
     */
    onItemSelect(itm) {
      // Set item and hide dialog
      if(itm) {
        this.currentValue = itm
        this.$refs.image_gallery.hide();
      }
    },
  },

  computed: {

    /**
     * Current property link
     */
    currentValue: {
      get: function () {
        return this.internalCurrentValue
      },
      set: async function (newVal) {
        this.internalCurrentValue = newVal
        this.$emit('update:modelValue', newVal)
        this.$emit('changed', newVal)
      }
    },

    /**
     * Get image url
     * @return {string|*}
     */
    imageUrl() {

      // Get path
      const path = this.mediaType === 'image' && this.currentValue ? pathHelper.assetPath(this.currentValue.source_url) : false

      // Return path
      return path ? path : require("@/assets/plugs/default-image.png");
    },

    /**
     * This method is used to get the appropriate icon for a given media type.
     * It takes an image object as an argument and returns a string representing the icon name.
     *
     * @returns {string} The name of the icon corresponding to the image type.
     * 'audio_file' for 'sound', 'animation' for 'lottie', 'video_file' for 'video', 'subtitles' for 'subtitles', and 'draft' for any other type.
     */
    previewIcon() {
      switch (this.mediaType) {
        case 'sound':
          return 'audio_file';
        case 'lottie':
          return 'animation';
        case 'video':
          return 'video_file';
        case 'subtitles':
          return 'subtitles';
        case 'fbx':
          return '3d_rotation';
        default:
          return 'draft';
      }
    },
  }
}

</script>

<style lang="scss">

.media-picker {

  .image-preview {

    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #ccc;

    img {
      object-fit: cover;
      width: 40px;
      height: 40px;
      border:1px solid #ddd;
      padding: 3px;
    }
  }
}

</style>
