<template>
  <q-layout view="lHh Lpr lFf">
    <q-header >
      <q-toolbar>
        <q-btn
            flat
            dense
            round
            @click="leftDrawerOpen = !leftDrawerOpen"
            aria-label="Menu"
            icon="menu"
        />

        <q-toolbar-title class="row items-center app-toolbar">Services</q-toolbar-title>

        <component v-if="$route.meta.toolbar" :is="$route.meta.toolbar"/>

      </q-toolbar>
    </q-header>

    <q-drawer
        v-model="leftDrawerOpen"
        show-if-above
        bordered
        elevated
        class="bg-grey-2"
    >
      <q-toolbar class="bg-primary text-white text-subtitle1">
        <q-btn label="Back to apps list" icon="arrow_back" flat @click="$router.replace({name: 'workspace'})"/>
      </q-toolbar>

      <div class="q-pa-md">
        <q-tree
          :nodes="nodes"
          v-model:expanded="expandedNodes"
          v-model:selected="selectedNode"
          @update:selected="onNodeSelected"
          node-key="key"
          no-selection-unset
        />
      </div>
    </q-drawer>

    <q-page-container>
      <router-view :key="upKey + $route.path"/>
    </q-page-container>
  </q-layout>
</template>

<script>
import {ref} from 'vue';

export default {
  name: 'ServicesTemplate',
  data: () => ({
    upKey: 0,
    expandedNodes: ['_health-check'],
    selectedNode: false,
    nodes: [
      {
        label: 'Health check',
        key: "_health-check",
        children: [
          {
            label: "CDN server",
            key: "services/health-check/a2u-cdn-server",
          },
          {
            label: "Tools backend",
            key: "services/health-check/a2u-tools-backend",
          },
        ],
      },
    ],
  }),

  setup () {
    return {
      leftDrawerOpen: ref(false)
    }
  },

  methods: {
    onNodeSelected(nodeId) {
      if (nodeId && nodeId.indexOf("_") !== 0) this.$router.push("/workspace/" + nodeId)
    },
  }
}
</script>

<style lang="scss">

.app-toolbar {
  .version-selector {
    height: 40px;
    background: white;
    padding-left:10px;
    padding-right:10px;
    border-radius: 10px;

    .q-field__control, .q-field__marginal, .q-field__native {
      height: 40px;
      min-height: 40px;
    }
  }
}

</style>
