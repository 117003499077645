<template>

  <div>
    <ui-prop-string name="title" title="Component title" :block="block"/>
    <ui-prop-string name="name" title="Component name" :block="block"/>
    <ui-prop-string :multiple="true" name="description" title="Component description" :block="block"/>
    <ui-prop-media-picker class="col" name="previewImage" media-type="image" :block="block" />

    <ui-prop-custom-list-props name="parameters" title="Component parameters" :block="block">
      <template v-slot:item="data">
        <q-input v-model="data.item.name" label="Parameter name"/>
        <div class="row">
        <q-select class="col" v-model="data.item.type" :options="globals.options.data_types" label="Parameter type" map-options emit-value/>
        <q-checkbox label="Is array" v-model="data.item.isArray"/>
        </div>
        <value-selector v-model="data.item.value" :is-array="data.item.isArray" :title="data.item.name" :app-id="product_id" :block-id="block.id" :parent-diagram-id="diagram_id" :module-id="module_id"/>
      </template>
    </ui-prop-custom-list-props>

    <!--q-card class="q-mt-sm">
      <q-card-section class="bg-primary text-white">
        Component argument values
      </q-card-section>
      <q-card-section>
        <template v-for="(param, k) of params" :key="k">
          <value-selector :title="param.name" v-model="nativeProps[param.name]" :app-id="product_id" :only-var-type="param.type" :parent-diagram-id="diagram_id" :module-id="module_id"/>
        </template>
      </q-card-section>
    </q-card!-->

    <ui-events-props name="outgoing" :manual="true" title="Outgoing events" :block="block"/>

    <ui-events-props name="incoming" :manual="true" title="Incoming events" :block="block"/>

    <ui-prop-dynamic-string :block="block" title="Component key" name="componentKey" untranslatable />

    <ui-dynamic-props :block="block" :product_id="product_id" :module_id="module_id"/>
  </div>

</template>

<script>

import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString";
import UiEventsProps from "@/components/DiagramDesigner/Editor/properties/UiEventsProps.vue";
import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropCustomListProps from "@/components/DiagramDesigner/Editor/properties/UiPropCustomListProps.vue";
import ValueSelector from "@/components/ValueSelector/ValueSelector.vue";
import UiPropDynamicString from "@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue";
import UiPropMediaPicker from "@/components/DiagramDesigner/Editor/properties/UiPropMediaPicker.vue";

export default {
  mixins: [propertiesMixins],
  inject: ['canvas', 'product_id'],
  components: {
    UiPropMediaPicker,
    UiPropDynamicString, ValueSelector, UiPropCustomListProps, UiDynamicProps, UiEventsProps, UiPropString},
  props: ['block'],
  name: "NativeComponentPropsCmp",
  data: () => (
      {
        nativeProps: {}
      }
  ),

  /**
   * On created
   */
  created() {

    // Init current value
    if(this.currentValue) this.nativeProps = this.currentValue

    // Add watcher
    this.$watch('nativeProps', function (newVal) {
      this.currentValue = newVal
    }, {deep: true})
  },


  computed: {

    /**
     * Widget type
     * @return {string}
     */
    type: () => "nativeParams",

    /**
     * Get parent fragment
     * @return {*}
     */
    parentFragment() {
      // Return parent frame id
      return this.canvas.getNodeParentsById(this.block.id).find(b => b.type === 'Fragment');
    },

    /**
     * Get params
     * @return {*}
     */
    params() {
      return this.block?.properties?.arguments?.items;
    }
  }
}

</script>
