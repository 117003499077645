<template>
  <ui-prop-select :block="block" name="placement" title="Placement" :options="fullscreenPlacementsList"/>
  <ui-prop-select :block="block" name="bannerPosition" title="Banner position" :options="bannerPosition"/>
  <ui-prop-string name="width" title="Banner width" :block="block" default-value="100%"/>
  <ui-prop-string name="height" title="Banner height" :block="block" default-value="50px"/>
  <ui-prop-string name="defaultTime" title="Default banner: minimum display time (seconds)" :block="block" default-value="5"/>

  <ui-dynamic-props :block="block" :product_id="product_id" :module_id="module_id"/>
</template>

<script>
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString";
import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps";
import {adMixins} from "@/components/DiagramDesigner/Editor/components/UI/Monetization/adMixins";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";

export default {
  mixins: [propertiesMixins, adMixins],
  name: "BannerPropsCmp",
  props: ['block'],
  components: {UiPropSelect, UiDynamicProps, UiPropString},
  data: () => ({
    bannerPosition: [{value: "top", label: "Page top"}, {value: "bottom", label: "Page bottom"}]
  }),
  computed: {

    /**
     * Return placement title
     * @return {*}
     */
    placementTitle() {
      return this.fullscreenPlacementsList?.find(p => p.value === this.block?.properties?.placement)?.label;
    },


    /**
     * Return possible ad types
     */
    adTypes() {
      return this.globals.options.adTypes?.filter(t => t.place === 'page')
    },

    /**
     * Return placements list
     * @return {*}
     */
    fullscreenPlacementsList() {
      return this.placementsList?.filter(p => p.type === "banner")?.map(p => ({label: p.name, value: p.id}))
    }
  }
}

</script>

