<template>
  <q-page class="q-pa-md" v-if="isReady">

    <q-dialog v-model="tableInfo">
      <q-card style="width: 800px; max-width: 80vw;">
        <q-card-section>
          <div class="text-h6">The necessary structure of the table</div>
        </q-card-section>

        <q-card-section style="max-height: 70vh" class="q-pt-none scroll">
          <q-markup-table bordered>
            <thead>
              <tr>
                <th>Column</th>
                <th>Type</th>
                <th>Structure</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><q-badge color="blue">id</q-badge></td>
                <td>Autogenerated</td>
                <td>-</td>
              </tr>
              <tr>
                <td><q-badge color="blue">title</q-badge></td>
                <td>String</td>
                <td>-</td>
              </tr>
              <tr>
                <td><q-badge color="blue">price</q-badge></td>
                <td>Float</td>
                <td>-</td>
              </tr>
              <tr>
                <td><q-badge color="blue">url</q-badge></td>
                <td>String</td>
                <td>-</td>
              </tr>
              <tr>
                <td><q-badge color="blue">main_image</q-badge></td>
                <td>String</td>
                <td>-</td>
              </tr>
              <tr>
                <td><q-badge color="blue">images</q-badge></td>
                <td>JSON</td>
                <td><pre v-text="structures.images"/></td>
              </tr>
              <tr>
                <td><q-badge color="blue">videos</q-badge></td>
                <td>JSON</td>
                <td><pre v-text="structures.videos"/></td>
              </tr>
              <tr>
                <td><q-badge color="blue">description</q-badge></td>
                <td>Text</td>
                <td>-</td>
              </tr>
              <tr>
                <td><q-badge color="blue">description_html</q-badge></td>
                <td>Text</td>
                <td>-</td>
              </tr>
            </tbody>
          </q-markup-table>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="OK" color="primary" v-close-popup />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <div class="text-h6">
      Shopify settings
    </div>
    <q-form @submit="saveProperties">

      <q-input
        label="Store name"
        v-model="properties.storeName"
      />

      <q-input
        label="Access token"
        v-model="properties.accessToken"
      />

      <q-select
        label="Database"
        v-model="properties.dbId"
        :options="dbsList"
        emit-value
        map-options
        option-value="value"
      />

      <div v-if="properties.dbId" class="row items-center full-width no-wrap q-gutter-sm">
        <q-select
          class="full-width"
          label="Table"
          v-model="properties.tableId"
          :options="tablesList"
          emit-value
          map-options
          option-value="value"
        />

        <q-btn fab-mini dense icon="question_mark" @click="tableInfo = true" />
      </div>

      <q-separator/>

      <div class="row">
        <q-btn type="submit" label="Save" color="primary" class="q-mt-md"/>

        <q-space />

        <q-btn label="Copy example JSON" class="q-mt-md" @click="copyExample" />
      </div>
    </q-form>

  </q-page>
</template>

<script>
import { copyToClipboard } from 'quasar';
import {DbModule} from '../../../../../../common/db/DbModule';
import {DbTable} from '../../../../../../common/db/DbTable';
import {integrationsMixin} from "@/pages/workspace/integrations/integrationsMixin";

const IMAGE_LINK = 'https://example.com/image.jpg';

const VIDEO_LINK = 'https://example.com/video.mp4';

// Example products JSON
const EXAMPLE_JSON = JSON.stringify([
  {
    "title": "Product 1",
    "url": "https://example.com",
    "main_image": "https://picsum.photos/800",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris maximus risus ut erat posuere, a molestie eros auctor. Vestibulum odio elit, blandit id semper id, blandit nec dui. Interdum et malesuada fames ac ante ipsum primis in faucibus. Vestibulum tellus mauris, porttitor vel finibus at, congue vitae metus. Suspendisse elit arcu, pharetra vel condimentum eu, semper eu nisl. Curabitur at fermentum tellus. Suspendisse accumsan fermentum elementum. Pellentesque quis rutrum metus. Integer et interdum libero. Aliquam feugiat enim eu mi condimentum, at porta eros viverra.",
    "description_html": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris maximus risus ut erat posuere, a molestie eros auctor. Vestibulum odio elit, blandit id semper id, blandit nec dui. Interdum et malesuada fames ac ante ipsum primis in faucibus. Vestibulum tellus mauris, porttitor vel finibus at, congue vitae metus. Suspendisse elit arcu, pharetra vel condimentum eu, semper eu nisl. Curabitur at fermentum tellus. Suspendisse accumsan fermentum elementum. Pellentesque quis rutrum metus. Integer et interdum libero. Aliquam feugiat enim eu mi condimentum, at porta eros viverra.</p>",
    "price": 17.99,
    "images": [
      "https://picsum.photos/800",
      "https://picsum.photos/500",
      "https://picsum.photos/700"
    ],
    "videos": [
      "https://placehold.co/1920x1080.mp4",
      "https://placehold.co/1920x1080/red/white.mp4"
    ]
  },
  {
    "title": "Product 2",
    "url": "https://example.com",
    "main_image": "https://picsum.photos/800",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris maximus risus ut erat posuere, a molestie eros auctor. Vestibulum odio elit, blandit id semper id, blandit nec dui. Interdum et malesuada fames ac ante ipsum primis in faucibus. Vestibulum tellus mauris, porttitor vel finibus at, congue vitae metus. Suspendisse elit arcu, pharetra vel condimentum eu, semper eu nisl. Curabitur at fermentum tellus. Suspendisse accumsan fermentum elementum. Pellentesque quis rutrum metus. Integer et interdum libero. Aliquam feugiat enim eu mi condimentum, at porta eros viverra.",
    "description_html": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris maximus risus ut erat posuere, a molestie eros auctor. Vestibulum odio elit, blandit id semper id, blandit nec dui. Interdum et malesuada fames ac ante ipsum primis in faucibus. Vestibulum tellus mauris, porttitor vel finibus at, congue vitae metus. Suspendisse elit arcu, pharetra vel condimentum eu, semper eu nisl. Curabitur at fermentum tellus. Suspendisse accumsan fermentum elementum. Pellentesque quis rutrum metus. Integer et interdum libero. Aliquam feugiat enim eu mi condimentum, at porta eros viverra.</p>",
    "price": 19.99,
    "images": [
      "https://picsum.photos/800",
      "https://picsum.photos/500",
      "https://picsum.photos/700"
    ],
    "videos": [
      "https://placehold.co/1920x1080.mp4",
      "https://placehold.co/1920x1080/red/white.mp4"
    ]
  },
  {
    "title": "Product 3",
    "url": "https://example.com",
    "main_image": "https://picsum.photos/800",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris maximus risus ut erat posuere, a molestie eros auctor. Vestibulum odio elit, blandit id semper id, blandit nec dui. Interdum et malesuada fames ac ante ipsum primis in faucibus. Vestibulum tellus mauris, porttitor vel finibus at, congue vitae metus. Suspendisse elit arcu, pharetra vel condimentum eu, semper eu nisl. Curabitur at fermentum tellus. Suspendisse accumsan fermentum elementum. Pellentesque quis rutrum metus. Integer et interdum libero. Aliquam feugiat enim eu mi condimentum, at porta eros viverra.",
    "description_html": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris maximus risus ut erat posuere, a molestie eros auctor. Vestibulum odio elit, blandit id semper id, blandit nec dui. Interdum et malesuada fames ac ante ipsum primis in faucibus. Vestibulum tellus mauris, porttitor vel finibus at, congue vitae metus. Suspendisse elit arcu, pharetra vel condimentum eu, semper eu nisl. Curabitur at fermentum tellus. Suspendisse accumsan fermentum elementum. Pellentesque quis rutrum metus. Integer et interdum libero. Aliquam feugiat enim eu mi condimentum, at porta eros viverra.</p>",
    "price": 40.99,
    "images": [
      "https://picsum.photos/800",
      "https://picsum.photos/500",
      "https://picsum.photos/700"
    ],
    "videos": [
      "https://placehold.co/1920x1080.mp4",
      "https://placehold.co/1920x1080/red/white.mp4"
    ]
  },
  {
    "title": "Product 4",
    "url": "https://example.com",
    "main_image": "https://picsum.photos/800",
    "description": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris maximus risus ut erat posuere, a molestie eros auctor. Vestibulum odio elit, blandit id semper id, blandit nec dui. Interdum et malesuada fames ac ante ipsum primis in faucibus. Vestibulum tellus mauris, porttitor vel finibus at, congue vitae metus. Suspendisse elit arcu, pharetra vel condimentum eu, semper eu nisl. Curabitur at fermentum tellus. Suspendisse accumsan fermentum elementum. Pellentesque quis rutrum metus. Integer et interdum libero. Aliquam feugiat enim eu mi condimentum, at porta eros viverra.",
    "description_html": "<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris maximus risus ut erat posuere, a molestie eros auctor. Vestibulum odio elit, blandit id semper id, blandit nec dui. Interdum et malesuada fames ac ante ipsum primis in faucibus. Vestibulum tellus mauris, porttitor vel finibus at, congue vitae metus. Suspendisse elit arcu, pharetra vel condimentum eu, semper eu nisl. Curabitur at fermentum tellus. Suspendisse accumsan fermentum elementum. Pellentesque quis rutrum metus. Integer et interdum libero. Aliquam feugiat enim eu mi condimentum, at porta eros viverra.</p>",
    "price": 99.99,
    "images": [
      "https://picsum.photos/800",
      "https://picsum.photos/500",
      "https://picsum.photos/700"
    ],
    "videos": [
      "https://placehold.co/1920x1080.mp4",
      "https://placehold.co/1920x1080/red/white.mp4"
    ]
  }
], null, 2);

export default {
  mixins: [integrationsMixin],

  name: "ShopifyIntegrationSettings",

  data() {
    return {
      tableInfo: false,
      structures: {
        images: JSON.stringify([IMAGE_LINK, IMAGE_LINK], null, 2),
        videos: JSON.stringify([VIDEO_LINK, VIDEO_LINK], null, 2),
      }
    };
  },

  computed: {
    /**
     * Return integration name
     */
    integration: () => ({name: "shopify", type: "shopify"}),

    /**
     * Get dbs list
     * @return {*}
     */
    dbsList() {
      return this.wait("dbsList", async () => {
        return (await DbModule.query().where({module_id: this.module_id}).get())
          .map(d => ({
            value: d.id,
            label: d.name,
          }));
      }, []);
    },

    /**
     * Get tables list
     * @return {*}
     */
    tablesList() {
      return this.wait("tablesList", async () => {
        return (await DbTable.query().where({db_id: this.properties?.dbId || 0}).get()).map(t => ({
          value: t.id,
          label: t.name
        }));
      }, []);
    },
  },

  methods: {
    /**
     * This method is used to copy the example JSON to the clipboard.
     * It uses the 'copyToClipboard' function from the 'quasar' library to copy the JSON.
     * If the copy operation is successful, it shows a notification with a positive message.
     * If the copy operation fails, it logs the error to the console and shows a notification with a negative message.
     */
    copyExample() {
      copyToClipboard(EXAMPLE_JSON)
        .then(() => {
          // Notify the user that the copy operation was successful
          this.$q.notify({
            color: 'positive',
            message: 'Example JSON copied to clipboard',
            icon: 'done',
            position: 'top'
          })
        })
        .catch((e) => {
          // Log the error to the console
          console.error('Failed to copy example JSON to clipboard', e)

          // Notify the user that the copy operation failed
          this.$q.notify({
            color: 'negative',
            message: 'Failed to copy example JSON to clipboard',
            icon: 'report_problem',
            position: 'top'
          })
        });
    },
  },
}

</script>
