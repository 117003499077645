/**
 * User app model
 */
import {AbOrm} from "ab-orm/src/AbOrm.js";

export class UserApp extends AbOrm {
    static entity = 'user_apps'
    static primaryKey = ['user_id', 'app_id']
    static fields = {
        user_id: "int",
        app_id: "int",
        roles: "json"
    }

    /**
     * Prepare data according to users access level
     * @return {boolean}
     * @param row
     * @param mode
     */
    static async prepareData(row, mode) {
        // Admin or current user
        return this.app().auth().hasRole("admin") ? row : false
    }

    /**
     * Functions list
     */
    async channels() {
        return {
            'user-apps': {
                subscribe: ({user_id}) => user_id,
                init: async ({user_id}) => this.getUserApps(user_id),
            }
        }
    }

    /**
     * Get users apps list
     */
    getUserApps(user_id) {
        // Check if user has admin access
        if (this.app().auth().hasRole("admin")) {
            return this.query().where({user_id}).get()
        }
    }

}
