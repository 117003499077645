<template>
  <div>
    <ui-prop-string name="title" title="Block title" :block="block"/>

    <ui-prop-value-selector
      :block="block"
      title="Variable to save result"
      name="variableToSave"
      value-type="setter"
      :parent-diagram-id="diagram_id"
    />

    <ui-prop-checkbox
      class="q-mt-sm"
      :block="block"
      name="uploadToServer"
      title="Upload to server"
      :default-value="0"
    />

    <ui-prop-select
      v-if="block?.properties?.uploadToServer"
      :block="block"
      name="uploadModuleId"
      title="Backend module to upload to"
      :options="moduleList"
    />

    <q-card class="q-my-sm">
      <q-card-section class="bg-primary text-white">
        Size (Android/iOS only)
      </q-card-section>

      <q-card-section class="q-pt-none">
        <ui-prop-string name="quality" title="Quality" :block="block" hint="From 0 to 100" default-value="90"/>

        <ui-prop-string name="maxWidth" title="Max width" :block="block" hint="In pixels"/>
        <ui-prop-string name="maxHeight" title="Max height" :block="block" hint="In pixels"/>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import {AppModule} from '../../../../../../../../../common/db/AppModule';
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropString from '@/components/DiagramDesigner/Editor/properties/UiPropString.vue';
import UiPropCheckbox from '@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue';
import UiPropSelect from '@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue';

export default {
  mixins: [propertiesMixins],
  name: "ImagePickerPropsCmp",
  props: ['block'],
  components: {UiPropSelect, UiPropCheckbox, UiPropString, UiPropValueSelector},

  computed: {
    // Get list of modules
    moduleList() {
      return this.wait("moduleList", async () => {
        return (await AppModule.query().where("app_id", this.product_id).get())
          .filter(m => m.type === 'server')
          .map(m => ({value: m.id, label: m.name}))
      }, []);
    }
  },
}

</script>

