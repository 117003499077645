/**
 * DbModule model
 */
import {AccessModel} from './AccessModel.js';

export class AppModule extends AccessModel {
    static entity = 'app_modules'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        name: "string",
        description: "string",
        features: "text",
        app_id: "int",
        type: "string",
        language: "string",
        endpoint: "json",
        start_diagram_id: "int",
        persistent_storage: "int",
        unique_id: "string",
        settings: "json",
        cdn_server: "int",
    }

    /**
     * Clears the cache for the current app module.
     * Utilizes the cache manager from the application client plugins.
     */
    serverEvent() {
        const cacheManager = this.constructor?.applicationClient?.plugins?.schemaCache || null;

        if (!cacheManager) {
            return;
        }

        cacheManager.clearCache(this.app_id);
    }

    /**
     * Functions list
     */
    async channels() {
        return {
            'app-modules': {
                subscribe: ({app_id}) => app_id,
                init: async ({app_id}) => this.getList(app_id),
            },
            'module': {
                subscribe: ({id}) => id,
                init: async ({id}) => this.find(id),
            },
        }
    }

    /**
     * Get list
     */
    getList(app_id) {
        return this.query().where({app_id}).get()
    }
}
