<template>

  <div></div>
  <!--ui-prop-arguments :block="block" name="arguments" title="Target arguments" :target-block-id="targetBlockId"/!-->
  <!--ui-prop-string :block="block" title="Send event when passing this link" name="linkEventName"/!-->
  <!--ui-prop-string :block="block" title="Link title" hint="Title for analytics" name="title"/!-->
  <ui-prop-custom-list-props name="arguments" title="Data to pass by link" :block="block">
    <template v-slot:item="data">
      <q-input v-model="data.item.name" label="Variable name"/>
      <value-selector v-model="data.item.value" :title="data.item.name" :app-id="product_id" :only-var-type="data.item.type" :block-id="block.id" :parent-diagram-id="diagram_id" :module-id="module_id"/>
    </template>
  </ui-prop-custom-list-props>

  <ui-prop-checkbox name="excludeFromAnalytics" title="Exclude from analytics" :block="block"/>

  <template v-if="allowTransition">
    <ui-prop-select
      title="Transition animation"
      name="transitionName"
      default-value="jump-up"
      :block="block"
      :options="globals.options.transitions"
    />

    <ui-prop-string
      name="transitionDuration"
      title="Transition duration (milliseconds)"
      :block="block"
      :default-value="300"
    />
  </template>

</template>

<script>


import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropCustomListProps from "@/components/DiagramDesigner/Editor/properties/UiPropCustomListProps.vue";
import ValueSelector from "@/components/ValueSelector/ValueSelector.vue";
import UiPropCheckbox from "@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue";
import UiPropSelect from '@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue';
import UiPropString from '@/components/DiagramDesigner/Editor/properties/UiPropString.vue';

export default {
  mixins: [propertiesMixins],
  name: "LinkPropsCmp",
  components: {UiPropString, UiPropSelect, UiPropCheckbox, ValueSelector, UiPropCustomListProps},
  props: ['block'],
  inject: {
    canvas: {
      default: null
    }
  },
  data: () => ({
    params: []
  }),
  created() {

    // Init current value
    if (this.currentValue) this.params = this.currentValue

    // Add watcher
    this.$watch('params', function (newVal) {
      this.currentValue = newVal
    }, {deep: true})

  },
  methods: {

    /**
     * Delete argument from array
     * @param itemKey
     */
    deleteArgument(itemKey) {
      this.$q.dialog({
        title: 'Warning',
        message: 'Are you sure you want to delete this argument?',
        cancel: true,
        persistent: true
      }).onOk(() => {
        this.params.splice(itemKey, 1)
      })
    },

    /**
     * Add new argument
     */
    addArgument() {
      this.params.push({
        target: null,
        source: null
      })
    }
  },

  computed: {

    /**
     * Target block id
     * @return {*}
     */
    targetBlockId() {
      return this.block?.properties?.connection?.target?.id;
    },

    /**
     * Source vars containers
     * @return {*}
     */
    targetDiagramId() {
      // Get target block by id
      const targetBlock = this.canvas.getNodeById(this.block?.properties?.connection?.target?.id);

      // Check if target block is exists and it is DiagramComponent
      if (!targetBlock || targetBlock.type !== "DiagramComponent") return false;

      // Return target block variable containers
      return targetBlock?.properties?.diagramComponentId;
    },

    /**
     * Arguments to pass
     * @return {string}
     */
    type() {
      return "arguments"
    },

    /**
     * Determines if the transition is allowed based on the target block type.
     * @returns {boolean} True if the target block type is 'DiagramComponent' or 'Fragment', otherwise false.
     */
    allowTransition() {
      const targetBlock = this.canvas.getNodeById(this.targetBlockId);

      return ['DiagramComponent', 'Fragment'].includes(targetBlock?.type);
    },
  }
}

</script>

<style lang="scss">

.link-props {
  .target, .source {
    border: 1px solid #ddd;
  }
}
</style>
