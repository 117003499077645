<template>

  <q-card>
    <q-card-section class="bg-primary text-white q-mt-sm">
      {{title}}
    </q-card-section>
    <q-card-section>
      <div class="q-gutter-sm">
        <div v-for="(event, k) of currentEvents" :key="k" class="column q-pa-sm">

          <q-input v-model="event.name" label="Event name" class="col" v-if="manual">
            <template v-slot:append>
              <q-btn icon="delete" size="sm" flat round @click="deleteEvent(k)"/>
            </template>
          </q-input>

          <q-select v-else v-model="event.name" label="Event name" class="col" map-options emit-value :options="globals.options.touchEvents">
            <template v-slot:append>
              <q-btn icon="delete" size="sm" flat round @click="deleteEvent(k)"/>
            </template>
          </q-select>

          <q-input v-if="0" type="textarea" v-model="event.mock_data" label="Event mock data"/>
        </div>

        <q-btn @click="addEvent" icon="add" label="Add event" flat/>
      </div>
    </q-card-section>
  </q-card>



</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";

export default {
  mixins: [propertiesMixins],
  props: {
    manual: {
      default: false
    },
    block: {},
    title: {},
    name: {
      default: "events"
    }
  },
  name: "UiEventsProps",
  components: {},
  data: () => ({
    currentEvents: []
  }),
  created() {

    // Init value
    if(this.currentValue) this.currentEvents = this.currentValue

    // Add watcher
    this.$watch('currentEvents', function (newVal) {
      this.currentValue = newVal
    }, {deep: true})

  },

  methods: {

    /**
     * Add event
     * @param e
     */
    addEvent() {
      // Add to events list
      this.currentEvents.push({
        name: "",
        mock_data: "{}"
      })
    },

    /**
     * Delete event
     * @param k
     */
    deleteEvent(k) {
      console.log(k)
      this.currentEvents.splice(k,1);
    },
  },
}

</script>
