
<template>
  <ButtonEditorCmp :block="block" />
</template>

<script>
import ButtonEditorCmp from "@/components/DiagramDesigner/Editor/components/UI/Basic/Button/ButtonEditorCmp.vue";

export default {
  name: "SubmitEditorCmp",
  props: ['block'],
  components: {ButtonEditorCmp}
}

</script>
