<template>
  <ab-flow-base-cmp :movable="true" :block="block" class="open-email-editor-cmp">
    <ab-flow-link-connector :block="block" type="input" class="ev-run" event="run"/>
    <q-card>

      <q-card-section class="bg-yellow-8 text-black">
        e-mail: {{email}}
      </q-card-section>

    </q-card>

  </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";
import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";

export default {
  mixins: [renderMixins],
  components: { AbFlowBaseCmp, AbFlowLinkConnector},
  inject: ['canvas'],
  props: ['block'],
  name: "OpenEmailEditorCmp",
  computed: {

    /**
     * Email
     * @return {string}
     */
    email() {
      return this.getValue(this.block.properties?.email) || "test@apptoup.com";
    },
  }
}

</script>

<style lang="scss">

.open-email-editor-cmp {
  .ev-run {
    left:-12px;
    position: absolute;
    top:calc(50% - 5px);
  }
}

</style>
