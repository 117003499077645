<template>
    <ab-flow-base-cmp :movable="true" :block="block" class="sound-man-editor-cmp">
        <ab-flow-link-connector :block="block" type="input" class="ev-run" event="run"/>
        <q-card>

            <q-card-section class="bg-secondary text-white">
              Sound manager
            </q-card-section>

          <q-card-section>
            <div>Channel: {{soundChannel}}</div>
            <div>{{operation}}: {{value}}</div>
          </q-card-section>
        </q-card>

    </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

export default {
    components: { AbFlowBaseCmp, AbFlowLinkConnector},
    inject: ['canvas'],
    props: ['block'],
    name: "SoundManagerEditorCmp",
    computed: {

        /**
         * Return name
         * @return {{}}
         */
        soundChannel() {
            return this.block.properties?.soundChannel || "n/a"
        },

        /**
         * Volume value
         * @return {{}}
         */
        value() {

          switch (this.operation) {
            case "volume":
              return `${this.block?.properties?.volume}%`
            case "mute":
              return this.block?.properties?.muted?.value
          }

          return "?"
        },

        /**
         * Operation
         * @return {{}}
         */
        operation() {
            return this.block.properties?.operation || ''
        },
    }
}

</script>

<style lang="scss">

.sound-man-editor-cmp {
  .ev-run {
    left:-12px;
    position: absolute;
    top:calc(50% - 5px);
  }

  .results {
    position: absolute;
    right: -12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

</style>
