<template>
  <div>
    <q-dialog
      v-model="dialog"
      :full-width="!!csvData.length"
      @hide="resetState"
    >
      <q-card style="min-width: 500px;">
        <q-card-section>
          <div class="text-h6">Import CSV file</div>
        </q-card-section>

        <q-card-section v-if="!csvData.length">
          <q-file
            v-model="csvFile"
            outlined
            label="Choose a csv file"
            accept=".csv"
          >
            <template #prepend>
              <q-icon class="no-pointer-events" name="attach_file" />
            </template>
          </q-file>

          <q-input class="q-mt-md" v-model="delimiter" outlined label="CSV delimiter"/>
        </q-card-section>

        <q-card-section v-if="csvData.length">
          <q-markup-table separator="cell" flat bordered>
            <thead>
            <tr>
              <th/>
              <th
                v-for="field in fields"
                :key="field.name"
                class="text-left"
              >
                <q-icon v-if="field.key_field === 1" name="key" />
                {{ field.name }}
              </th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(data, idx) in tableData" :key="idx">
              <td class="text-center bg-grey-3">
                <q-chip v-if="data.status === 'update'" color="primary" text-color="white" icon="refresh" size="sm" :ripple="false" label="Update" />
                <q-chip v-else-if="data.status === 'exist'" icon="block" size="sm" :ripple="false" label="Existing" />
                <q-chip v-else color="teal" text-color="white" icon="add" size="sm" :ripple="false" label="New" />
              </td>
              <td v-for="(item, j) in data.cols" :key="j" style="max-width: 180px;" class="text-no-wrap ellipsis" :class="{'bg-blue-2': item.changed}">
                {{ item.value }}
              </td>
            </tr>
            </tbody>
          </q-markup-table>
        </q-card-section>

        <q-card-section v-if="csvData.length" class="row justify-end q-gutter-sm">
          <q-badge outline align="middle" color="secondary" :label="`Total: ${stats.total}`" />
          <q-badge v-if="stats.new > 0" outline align="middle" color="positive" :label="`New: ${stats.new}`" />
          <q-badge v-if="stats.update > 0" outline align="middle" color="primary" :label="`Update: ${stats.update}`" />
          <q-badge v-if="stats.exist > 0" outline align="middle" color="grey" :label="`Skip: ${stats.exist}`" />
        </q-card-section>

        <q-card-actions>
          <q-space/>

          <q-btn flat label="Cancel" @click="closeDialog"/>

          <q-btn v-if="!csvData.length" :disable="!csvFile" color="primary" flat label="Parse file" @click="importCsv"/>

          <q-btn v-if="csvData.length" icon="add" color="primary" flat label="Add to table" @click="addToTable"/>
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-btn icon="download" color="primary" flat label="Import CSV file" @click="showDialog"/>
  </div>
</template>

<script>
import Papa from 'papaparse';
import _ from "lodash";
import {nanoid} from 'nanoid';

/**
 * Asynchronously parses a CSV file using the Papa Parse library.
 *
 * @param {File} file - The CSV file to parse.
 * @param {string} delimiter - The delimiter used in the CSV file.
 * @returns {Promise<Array<Object>>} A promise that resolves with an array of objects, where each object represents a row in the CSV file.
 * @throws {Error} If the CSV file is invalid or an error occurs during parsing, the promise is rejected with an error.
 */
async function parseFile(file, delimiter) {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      delimiter,
      skipEmptyLines: 'greedy',
      complete: (result) => {
        resolve(result?.data || []);
      },
      error: (e) => {
        reject(e?.message || 'Invalid CSV file');
      },
    });
  });
}

/**
 * Checks if a string is a valid JSON.
 *
 * @param {string} str - The string to check.
 * @returns {boolean} Returns true if the string is a valid JSON, otherwise false.
 */
function isValidJSON(str) {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
}

/**
 * This function is used to get the value of a localized field.
 * If the value is an object, it returns the value property of the object.
 * If the value is a string that can be parsed into a JSON, it parses the string and returns the value property of the parsed object.
 * Otherwise, it returns the value as it is.
 *
 * @param {Object|string} value - The value to get the localized value from.
 * @returns {string} The localized value.
 */
function getLocalizedValue(value) {
  if (typeof value === 'object') {
    return value?.value || '';
  }

  return isValidJSON(value) ? JSON.parse(value)?.value : value;
}

/**
 * Converts a value to a boolean and then to an integer.
 * If the value is a string and equals 'true' (case insensitive), it returns 1.
 * If the value is truthy (not false, 0, '', null, undefined, or NaN), it returns 1.
 * Otherwise, it returns 0.
 *
 * @param {any} value - The value to convert.
 * @returns {number} Returns 1 if the value is truthy or 'true' (case insensitive), otherwise 0.
 */
function toBoolean(value) {
  const boolVal = typeof value === 'string' ? value.toLowerCase() === 'true' : Boolean(value);

  return boolVal ? 1 : 0;
}

export default {
  name: 'TableCsvImport',

  inject: ['fields', 'tableContent'],

  emits: ['import'],

  data() {
    return {
      dialog: false, // show dialog
      csvFile: null, // csv file
      delimiter: ',', // csv delimiter
      csvData: [], // csv data
    };
  },

  computed: {
    /**
     * This computed property returns an array of field names that are marked as key fields.
     * A field is considered a key field if its 'key_field' property equals 1.
     *
     * @returns {Array} An array of field names that are key fields.
     */
    keyFields() {
      return this.fields.filter((field) => field.key_field === 1).map((field) => field.name);
    },

    /**
     * This computed property returns an array of field names that are of type 'localizedString'.
     * A field is considered of type 'localizedString' if its 'type' property equals 'localizedString'.
     *
     * @returns {Array} An array of field names that are of type 'localizedString'.
     */
    localizedFields() {
      return this.fields.filter((field) => field.type === 'localizedString').map((field) => field.name);
    },

    /**
     * This computed property returns an array of field names that are of type 'bool'.
     * A field is considered of type 'bool' if its 'type' property equals 'bool'.
     *
     * @returns {Array} An array of field names that are of type 'bool'.
     */
    boolFields() {
      return this.fields.filter((field) => field.type === 'bool').map((field) => field.name);
    },

    /**
     * This computed property returns an array of field names that are of media type.
     * A field is considered of media type if its 'type' property equals 'lottie', 'image', 'sound', or 'file'.
     *
     * @returns {Array} An array of field names that are of media type.
     */
    mediaFields() {
      return this.fields.filter((field) => ['lottie', 'image', 'sound', 'file'].includes(field.type))
        .map((field) => field.name);
    },

    /**
     * This computed property returns an object where the keys are the field names and the values are the corresponding field types.
     * It uses the Array.prototype.reduce method to transform the array of fields into an object.
     *
     * @returns {Object} An object where the keys are the field names and the values are the corresponding field types.
     */
    fieldsTypes() {
      return this.fields.reduce((acc, field) => {
        acc[field.name] = field.type;
        return acc;
      }, {});
    },

    /**
     * This computed property returns a Map where the keys are generated from the rows of the table content and the values are the corresponding rows.
     * It uses the lodash's cloneDeep function to create a deep copy of the table content to avoid mutating the original data.
     * The keys are generated using the generateKey method.
     *
     * @returns {Map} A Map where the keys are generated from the rows of the table content and the values are the corresponding rows.
     */
    currentContent() {
      const result = new Map();

      for (const row of _.cloneDeep(this.tableContent)) {
        result.set(this.generateKey(row), row);
      }

      return result;
    },

    /**
     * This computed property returns an array of objects, where each object represents a row in the CSV data.
     * Each object has the field names as keys and the corresponding field values as values.
     * If a field value is not present in the CSV data for a particular row, it defaults to an empty string.
     *
     * @returns {Array} An array of objects, where each object represents a row in the CSV data.
     */
    allData() {
      return this.csvData.map((row) => {
        const data = {};

        for (const field of this.fields) {
          data[field.name] = row[field.name] || '';
        }

        return data;
      });
    },

    /**
     * This computed property returns an object containing statistics about the CSV data.
     * It calculates the total number of rows, the number of new rows, the number of rows to update, and the number of existing rows.
     * A row is considered new if its status is 'new'.
     * A row is considered to update if its status is 'update'.
     * A row is considered existing if its status is 'exist'.
     *
     * @returns {Object} An object containing the following properties:
     * - total: The total number of rows in the CSV data.
     * - new: The number of new rows in the CSV data.
     * - update: The number of rows to update in the CSV data.
     * - exist: The number of skip rows in the CSV data.
     */
    stats() {
      return {
        total: this.allData.length,
        new: this.tableData.filter((row) => row.status === 'new').length,
        update: this.tableData.filter((row) => row.status === 'update').length,
        exist: this.tableData.filter((row) => row.status === 'exist').length,
      };
    },

    /**
     * This computed property returns an array of objects, where each object represents a row in the CSV data.
     * Each object has two properties:
     * - cols: An array of objects, where each object represents a column in the row and has two properties:
     *   - value: The value of the column.
     *   - changed: A boolean indicating whether the value has changed compared to the current content.
     * - status: A string indicating the status of the row. It can be 'new', 'exist', or 'update'.
     *
     * The status of a row is determined as follows:
     * - If the row does not exist in the current content, its status is 'new'.
     * - If the row exists in the current content and none of its values have changed, its status is 'exist'.
     * - If the row exists in the current content and at least one of its values has changed, its status is 'update'.
     *
     * A value is considered changed if it is not equal to the corresponding value in the current content.
     * The comparison is done differently depending on the type of the field:
     * - For 'bool' fields, the value is converted to a boolean and then to an integer before comparison.
     * - For 'localizedString' fields, the value is compared with the value property of the current value.
     * - For other fields, the value is compared as it is.
     *
     * @returns {Array} An array of objects, where each object represents a row in the CSV data.
     */
    tableData() {
      return this.allData.map((row) => {
        // Status: new, exist, update
        let status = 'new';
        // Columns
        const cols = [];
        // Key
        const key = this.generateKey(row);

        // Check if row exists
        if (this.currentContent.has(key)) {
          status = 'exist';
        }

        // Prepare columns
        for (const [field, value] of Object.entries(row)) {
          // Changed flag
          let changed = false;

          // Check if value has changed
          if (status !== 'new') {
            // Current value
            const currentValue = this.currentContent.get(key)[field] || undefined;

            // Compare values
            switch (this.fieldsTypes[field]) {
              case 'bool':
                changed = currentValue !== toBoolean(value);
                break;
              case 'localizedString':
                changed = currentValue?.value !== getLocalizedValue(value);
                break;
              default:
                changed = this.currentContent.get(key)[field] !== value;
            }
          }

          cols.push({ value, changed });

          // Update status
          if (changed) {
            status = 'update';
          }
        }

        return {
          cols,
          status, // new, exist, update
        };
      });
    },

    /**
     * This computed property returns an array of rows that are ready to be imported into the table.
     * It first creates a deep copy of the current content of the table.
     * Then, for each row in the CSV data, it does the following:
     * - Generates a key for the row using the generateKey method.
     * - Retrieves the current row from the content using the generated key.
     * - For each field that is of type 'localizedString', it checks if the current row exists and if the field is localizable.
     *   - If so, it updates the value of the field in the row with the localized value from the CSV data.
     *   - Otherwise, it sets the field in the row to an object with the localized value from the CSV data, a flag indicating that the field is localizable, and a locale alias generated using the nanoid function.
     * - For each field that is of type 'bool', it converts the value of the field in the row to a boolean and then to an integer.
     * - Sets the row in the content using the generated key.
     * Finally, it returns the values of the content as an array.
     *
     * @returns {Array} An array of rows that are ready to be imported into the table.
     */
    importData() {
      // Deep copy of the current content
      const content = _.cloneDeep(this.currentContent);

      // Update rows
      for (const row of this.allData) {
        // Key
        const key = this.generateKey(row);
        // Current row
        const currentRow = content.get(key);

        for (const field of this.localizedFields) {
          // Update localized value
          if (currentRow && currentRow[field] && currentRow[field]?.isLocalizable) {
            row[field] = {
              ...currentRow[field],
              value: getLocalizedValue(row[field]),
            };
          } else {
            row[field] = {
              value: getLocalizedValue(row[field]),
              isLocalizable: true,
              localeAlias: nanoid(10),
            };
          }
        }

        // Convert bool fields to integers
        for (const field of this.boolFields) {
          row[field] = toBoolean(row[field]);
        }

        // Parse media fields
        for (const field of this.mediaFields) {
          if (isValidJSON(row[field])) {
            row[field] = JSON.parse(row[field]);
          }
        }

        // Set row
        content.set(key, row);
      }

      return Array.from(content.values());
    },
  },

  methods: {
    /**
     * Resets the state of the dialog by setting the csvFile, csvData, and delimiter to their initial values.
     */
    resetState() {
      this.csvFile = null;
      this.csvData = [];
      this.delimiter = ',';
    },

    /**
     * Opens the dialog by resetting the state and setting the dialog property to true.
     */
    showDialog() {
      this.resetState();
      this.dialog = true;
    },

    /**
     * Closes the dialog by resetting the state and setting the dialog property to false.
     */
    closeDialog() {
      this.resetState();
      this.dialog = false;
    },

    /**
     * Generates a unique key for a row in the CSV data.
     * If there are no key fields, it generates a random key using the nanoid function.
     * If there are key fields, it generates a key by joining the values of the key fields in the row with a hyphen.
     *
     * @param {Object} row - An object representing a row in the CSV data.
     * @returns {string} A unique key for the row.
     */
    generateKey(row) {
      if (!this.keyFields.length) {
        return nanoid(10);
      }

      return this.keyFields.map((field) => row[field]).join('-');
    },

    /**
     * Asynchronously imports a CSV file.
     * It first shows a loading spinner.
     * Then, it parses the CSV file using the parseFile function with the selected file and delimiter as arguments.
     * If the CSV data is empty, it throws an error.
     * It then gets the field names from the first row of the CSV data.
     * If there are no field names or none of the field names match the fields in the table, it throws an error.
     * Finally, it sets the csvData property to the CSV data.
     * If an error occurs during the process, it closes the dialog and shows a notification with the error message.
     * Regardless of whether an error occurs or not, it hides the loading spinner at the end.
     *
     * @throws {Error} If the CSV file is empty or invalid.
     */
    async importCsv() {
      try {
        this.$q.loading.show();

        const csvData = await parseFile(this.csvFile, this.delimiter || ',');

        if (!csvData.length) {
          throw new Error('CSV file is empty');
        }

        const csvFields = Object.keys(csvData[0] || {});

        if (!csvFields.length || !this.fields.some((field) => csvFields.includes(field.name))) {
          throw new Error('CSV file is invalid');
        }

        this.csvData = csvData;
      } catch (e) {
        this.closeDialog();

        this.$q.notify({
          color: 'negative',
          message: e?.message || 'Invalid CSV file',
        });
      } finally {
        this.$q.loading.hide();
      }
    },

    /**
     * This method is used to add the imported data to the table and close the dialog.
     * It emits an 'import' event with the imported data as the payload.
     * The imported data is obtained from the 'importData' computed property.
     * After emitting the event, it sets the 'dialog' property to false to close the dialog.
     */
    addToTable() {
      this.$emit('import', this.importData);

      this.dialog = false;
    },
  },
}
</script>
