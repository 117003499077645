<template>

  <q-card>
    <q-card-section>
      <div class="text-h6">Edit app</div>
    </q-card-section>

    <q-card-section class="q-pt-none">

      <q-form ref="appForm" @submit="onSaveApp" class="q-gutter-md">
        <q-input
            filled
            v-model="mutate(editApp).title"
            label="App title *"
            hint="title of app"
            lazy-rules
            :rules="[ val => val && val.length > 0 || 'Please type something']"
        />

        <div>
          <q-btn label="Save" type="submit" color="primary"/>
        </div>
      </q-form>

    </q-card-section>

  </q-card>

</template>

<script>

import {App} from "@/../../common/db/App";

export default {
  name: "AppForm",
  props: ['editApp'],
  emits: ['saved'],
  methods: {

    /**
     * On form submit
     * @return {Promise<void>}
     */
    async onSaveApp() {

      // Update
      await App.remote().save(this.editApp)

      // Hide dialog
      this.$emit('saved')
    }
  }
}

</script>
