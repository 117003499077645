<template>

  <div>
    <ui-prop-value-selector
      :block="block"
      title="Resource"
      name="resource"
      value-type="getter"
      :parent-diagram-id="diagram_id"
    />

    <ui-prop-value-selector
      :block="block"
      title="Timeout"
      name="timeout"
      value-type="getter"
      hint="Default 30 sec"
      :parent-diagram-id="diagram_id"
    />

    <ui-prop-value-selector
      :block="block"
      title="Value to set"
      name="resultValue"
      value-type="setter"
      :parent-diagram-id="diagram_id"
    />
  </div>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropValueSelector from '@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue';

export default {
  mixins: [propertiesMixins],
  components: {UiPropValueSelector},
  props: ['block'],
  name: "ResourcePreloaderPropsCmp",

  data() {
    return {};
  },
}

</script>
