<template>

  <ab-flow-base-cmp :draggable="false" class="repeater-cmp" :block="block" :is_container="true">

    <template v-for="(item, k) of items" :key="k">
      <data-provider :storage-key="block?.title" :data="{item:item}">
        <ab-flow-components-renderer :items="block.children"/>
      </data-provider>
    </template>

  </ab-flow-base-cmp>

</template>

<script>

import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowComponentsRenderer from "ab-flow-designer/src/components/Designer/AbFlowComponentsRenderer";
import DataProvider from "@/components/DiagramDesigner/Editor/components/UI/Data/DataProvider/DataProvider.vue";
import {StorageNode} from "@/../../common/db/StorageNode.js"
import {DbTableField} from "@/../../common/db/DbTableField.js"
import {DbTable} from "../../../../../../../../../common/db/DbTable";

export default {
  components: {DataProvider, AbFlowBaseCmp, AbFlowComponentsRenderer},
  mixins: [renderMixins],
  props: ['block'],
  name: "RepeaterEditorCmp",
  computed: {
    items() {
      return this.wait("items", async () => {

        // Init items
        let items = [];

        // Load repeater storage node
        const node = await StorageNode.find(this.block?.properties?.dataSource?.nodeId);

        // Check for db record
        if(node?.type === "db-record") {
          const item = {};

          // Load table
          const table = await DbTable.find(node.db_table)

          // check if table has data
          if(table?.table_data?.length) {
            return (table.table_data || []).slice(0, 20);
          } else if(table?.test_table_data?.length) {
            return (table.test_table_data || []).slice(0, 20);
          } else if (node?.value && node?.is_test_value) {
            return this.getValue(this.block?.properties?.dataSource) || node?.value || [{}];
          }

          // Load table fields
          for(const fld of await DbTableField.query().where("table_id", node.db_table).get()) {
            item[fld.name] = fld.description || `{db:${fld.name}}`;
          }

          // Add to items
          items.push(item);

        } else {

          // get items from node
          items = this.getValue(this.block?.properties?.dataSource) || node?.value || [{}];
        }

        // Convert item elements to object
        items = (Array.isArray(items) ? items : []).map(el => {
          return typeof el === 'object' ? el : {value: el}
        })

        // Return list
        return items.length ? items.slice(0, 20) : [{}];
      }, [])
    }
  }
}

</script>


<style lang="scss">

.repeater-cmp {
  flex-direction: inherit;
  flex-wrap: inherit;
  justify-content: inherit;
  align-items: inherit;
  width: 100%;

  .repeater-events {
    position: absolute;
    z-index: 1;
  }

  [class*="dg-gutter-x-"] > & {
    column-gap: var(--dg-gutter-x, 0px);
  }

  [class*="dg-gutter-y-"] > & {
    row-gap: var(--dg-gutter-y, 0px);
  }
}

</style>
