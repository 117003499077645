<template>
  <ab-flow-base-cmp :movable="true" :block="block" class="system-alarm-editor-cmp rounded-borders">

    <ab-flow-link-connector :block="block" type="input" class="ev-run" event="run"/>

    <q-card>
      <q-card-section class="bg-red text-white">
        Finish Chat
      </q-card-section>
    </q-card>
  </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

export default {
  mixins: [renderMixins],
  components: { AbFlowBaseCmp, AbFlowLinkConnector },
  inject: ['canvas'],
  props: ['block'],
  name: "FinishChatEditorCmp",
  computed: {}
}

</script>

<style lang="scss">

</style>
