<template>
  <q-checkbox v-model="currentValue" right-label :label="title" :true-value="trueValue" :false-value="falseValue" />
</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";

export default {
  mixins: [propertiesMixins],
  name: "UiPropCheckbox",
  props: {
    trueValue: {
      default: 1
    },
    falseValue: {
      default: 0
    }
  }
}

</script>
