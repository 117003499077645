<template>
  <q-splitter v-model="splitter" class="full-height diagrams-backup">
    <template v-slot:before>
      <q-scroll-area style="height: 100%">
        <div v-for="(b,k) of backups" :key="k" class="row shadow-4 q-mb-sm q-pa-sm">
          <div>#{{b.id}}, </div>
          <div>time: {{getTime(b.timestamp)}}</div>
          <q-space/>
          <q-btn flat @click="previewVersion(b.id)">Preview</q-btn>
          <q-btn v-if="b.id===currentVersion" flat @click="restoreVersion()">Restore</q-btn>
        </div>
      </q-scroll-area>
    </template>
    <template v-slot:after>
      <div class="full-width full-height diagram-preview dg-media">
        <ab-flow-designer v-if="source" :key="source?.id" ref="editor" class="full-height full-width" :root="source" :componentsList="componentsList"
                          :preview-mode="true"
                          :canvas_size="{width:1000000, height: 1000000}"
                          :product_id="diagramId"
        />
      </div>
    </template>
  </q-splitter>
</template>

<script>

import {ComponentBackup} from "../../../../../common/db/ComponentBackup";
import moment from "moment";
import {designerComponentsList} from "@/components/DiagramDesigner/Editor/components/designerComponentsList";
import AbFlowDesigner from "ab-flow-designer/src/components/Designer/AbFlowDesigner"

export default {
  name: "DiagramBackupList",
  components: {AbFlowDesigner},
  props: ['diagramId'],
  emits: ['restore'],
  data: () => ({
    splitter: 30,
    currentVersion: false,
    source: false
  }),
  setup() {
    return {
      componentsList: designerComponentsList
    }
  },
  created() {
    ComponentBackup.remote().subscribe("component-backups", {component_id: this.diagramId})
  },
  methods: {

    /**
     * Preview version
     * @param id
     */
    async previewVersion(id) {
      try {
        this.source = JSON.parse((await ComponentBackup.remote().subscribe("backup-version", {id}))?.[0]?.source)?.source;
        this.currentVersion = id;
      } catch (e) {
        console.error(e)
        this.$q.dialog({
          title: "Error",
          message: "Error while loading backup version",
          ok: "Close"
        })
      }
    },

    /**
     * Restore version
     */
    restoreVersion() {
      // Ask user
      this.$q.dialog({
        title: "Restore version",
        message: "Are you sure you want to restore this version?",
        ok: "Yes",
        cancel: "No"
      }).onOk(async () => {
        this.$emit('restore', this.source)
      })
    },

    /**
     * Get time
     * @param tm
     */
    getTime(tm) {
      return tm ? moment(tm * 1000).format("DD.MM.YYYY HH:mm:ss") : "-"
    }
  },
  computed: {

    /**
     * Backups list
     * @return {*}
     */
    backups() {
      return this.wait("backups", ComponentBackup.query().where({component_id: this.diagramId}).order("id desc").get(), []);
    }
  }
}

</script>

<style lang="scss">

.diagrams-backup {
  svg {
    overflow: visible;
  }
}

</style>
