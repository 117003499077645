<template>

  <ab-flow-base-cmp class="container-editor-cmp" :draggable="false" :block="block" :is_container="true" :class="containerClasses" :style="stylesString">
    <q-scroll-area v-if="isScrollable" class="scrollable-parent" style="height: 100%;width: 100%" @scroll="containerScrolled">
      <div class="scroll-content" :class="scrollableClasses">
      <ab-flow-components-renderer :items="children"/>
      </div>
    </q-scroll-area>
    <ab-flow-components-renderer v-else :items="children" />
  </ab-flow-base-cmp>
</template>

<script>
import AbFlowComponentsRenderer from "ab-flow-designer/src/components/Designer/AbFlowComponentsRenderer";
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";

export default {
  components: {AbFlowComponentsRenderer, AbFlowBaseCmp},
  mixins: [renderMixins],
  props: ['block'],
  name: "ContainerEditorCmp",
  inject: {
    canvas: {
      default: null
    },
    injectData: {
      default: {}
    },
  },

  methods: {
    containerScrolled() {
      this.canvas.updateLinks();
    }
  },

  computed: {

    /**
     * Container styles
     * @return {string}
     */
    containerClasses() {

      // Init styles
      let cntClasses = [];

      // Style according to direction
      switch (this.block?.properties?.direction || 'column') {
        case "column":
          cntClasses.push('dg-direction-column')
          break;
        case "row":
          cntClasses.push('dg-direction-row')
          break;
        case "stack":
          cntClasses.push('dg-direction-stack')
          break;
      }

      // Check for expanded mode
      if(this.block?.properties?.extended) cntClasses.push('col');

      // Hide scrollbar
      if (this.hideScrollbar) {
        cntClasses.push('_hide-scrollbar');
      }

      // Add overflow hidden if not scrollable
      if (!this.isScrollable) {
        // Bad solution, leads to unpredictable resizing, need to rethink
        //cntClasses.push('overflow-hidden');
      } else {
        cntClasses.push(`_direction-${this.scrollDirection}`);
      }

      // Return classes
      return `${cntClasses.join(" ")} ${this.classesString}`;
    },

    /**
     * Scrollable classes
     * @return {string}
     */
    scrollableClasses() {

      // Init styles
      let cntClasses = [];


      // Style according to direction
      switch (this.block?.properties?.direction || 'column') {
        case "column":
          cntClasses.push('dg-direction-column')
          break;
        case "row":
          cntClasses.push('dg-direction-row')
          break;
      }

      // Check for expanded mode
      if(this.block?.properties?.itemsAlign) cntClasses.push(`dg-items-${this.block?.properties?.itemsAlign}`)
      if(this.block?.properties?.contentAlign) cntClasses.push(`dg-justify-${this.block?.properties?.contentAlign}`)

      // Return classes
      return `${cntClasses.join(" ")} ${this.classesString}`;
    },

    /**
     * Children list
     * @return {*}
     */
    children() {
      // Return regular children
      return this.block.children
    },

    /**
     * Check if block is visible in editor
     * @return boolean
     */
    visibleInEditor() {
      return this.block.properties?.visibility?.showInEditor !== undefined ? this.block.properties?.visibility?.showInEditor : true
    },

    /**
     * Is scrollable
     * @return boolean
     */
    isScrollable() {
      return !!this.block.properties?.scrollable;
    },

    /**
     * Check if block has visibility condition
     * @return {*}
     */
    hasVisibilityCondition() {
      return this.block.properties?.visibility?.active
    },

    /**
     * Determines if the scrollbar should be hidden.
     *
     * @return {boolean} True if the scrollbar should be hidden, otherwise false.
     */
    hideScrollbar() {
      return this.isScrollable && this.block.properties?.hideScrollbar === 1;
    },

    /**
     * Get the scroll direction.
     *
     * @return {string} The scroll direction, either 'vertical' or the value specified in block properties.
     */
    scrollDirection() {
      return this.block?.properties?.scrollDirection || 'vertical';
    },
  }
}

</script>

<style lang="scss">
.container-editor-cmp {
  position: relative;
  display: flex;

  .scroll-content {
    display: flex;
    align-items: inherit;
    justify-content: inherit;
  }

  &._hide-scrollbar {
    .q-scrollarea__thumb, .q-scrollarea__bar {
      display: none !important;
    }
  }

  ._direction-vertical {
    .scrollable-parent {
      .q-scrollarea__content {
        max-width: 100%;
      }
    }
  }
}

.container-editor-cmp.dg-direction-stack {
  overflow: hidden;
}
.container-editor-cmp.dg-direction-stack > .editor-cmp {
  position: absolute;
  //width: 100%;
  //height: 100%;
}

// Expanded inside the stack container should take all the size of parent
.container-editor-cmp.dg-direction-stack > .col {
  width: 100%;
  height: 100%;
}

</style>
