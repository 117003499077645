<template>

  <div class="row">
    <!--ui-prop-checkbox title="Extended block" name="extended" :block="block"/>
    <q-space/!-->
    <q-btn icon="edit" size="sm" label="Edit" flat @click="$router.push({params: {id: getIds(widgetId)}})"/>
  </div>

  <!--div class="row q-gutter-sm" v-if="!block.properties?.extended">
    <ui-prop-string name="width" title="Width" :block="block"/>
    <ui-prop-string name="height" title="Height" :block="block"/>
  </div!-->

  <ui-prop-arguments :block="block" name="arguments" title="Widget arguments" :target-block-id="block.id"/>
  <ui-dynamic-props :default-value="{}" :block="block" :product_id="product_id" :module_id="module_id" />

</template>

<script>
import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps";
import {Diagram} from "@/../../common/db/Diagram.js"
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropArguments from "@/components/DiagramDesigner/Editor/properties/UiPropArguments.vue";

export default {
  inject: {
    canvas: {
      default: false
    }
  },
  mixins: [propertiesMixins],
  name: "WidgetPropsCmp",
  props: ['block'],
  components: {UiPropArguments, UiDynamicProps},
  data: () => ({
    widget: false,
    widgetProps: {}
  }),

  /**
   * On created
   * @return {Promise<void>}
   */
  async created() {

    // Init current value
    if(this.currentValue) this.widgetProps = this.currentValue

    // Add watcher
    this.$watch('widgetProps', function (newVal) {
      this.currentValue = newVal
    }, {deep: true})

    // Get id from type
    this.widgetId = this.block.type?.split(":")?.[1]

    // Load widget
    this.widget = this.widgetId ? await Diagram.find(this.widgetId) : false
  },

  methods: {

    /**
     * Get ids list for route
     * @param id
     * @returns {string}
     */
    getIds(id) {
      return [...this.$route.params.id?.split(','), ...[id]].join(",")
    },

  },

  computed: {

    /**
     * Get parent frame
     */
    parentFrameId() {
      // Check the block type
      return this.canvas?.getNodeParentsById(this.block.id).find(b => b.type === 'Fragment')?.id;
    },
  }
}

</script>

