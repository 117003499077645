<template>

  <div>
    <div class="text-subtitle1">{{title}}</div>

    <div class="column">
      <q-select
          v-model="background.type"
          label="Background type"
          map-options
          emit-value
          :options="filteredTypes"
      />

      <template v-if="background.type === 'image'">

        <value-selector v-if="background.type === 'image'" title="Background image" only-var-type="image" v-model="background.image" :app-id="product_id" :module-id="module_id" :parent-diagram-id="diagram_id" :block-id="block.id"/>
        <q-select label="Image fitting" :options="globals.options.bgImageSize" map-options emit-value v-model="background.backgroundSize" default-value="contain"/>
        <q-select label="Image position" :options="globals.options.mediaPosition" map-options emit-value v-model="background.backgroundPosition" default-value="center"/>
      </template>

      <color-picker v-if="background.type === 'color'" title="Background color" v-model="background.color"/>
      <div v-if="background.type === 'gradient'" class="row no-wrap q-col-gutter-md">
        <color-picker title="From" v-model="background.fromColor"/>
        <color-picker title="To" v-model="background.toColor"/>
        <q-input label="Radius, 0-360°" v-model="background.radius"/>
      </div>

    </div>
  </div>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import ColorPicker from "@/components/ColorPicker/ColorPicker";
import ValueSelector from "@/components/ValueSelector/ValueSelector.vue";

export default {
  inject: ['canvas'],
  mixins: [propertiesMixins],
  name: "UiPropBgColor",

  components: { ValueSelector, ColorPicker},

  props: {
    defaultValue: {
      type: String,
      radius: 0
    },
    product_id: {
      default: null
    },
    module_id: {
      default: null
    },
    excludeTypes: {
      type: [Array, String],
      default: () => [],
    },
  },
  data: () => ({
    background: {
      type: "color",
      fromColor: "#ffffff",
      toColor: "#000000",
      radius: "180",
      backgroundSize: "contain",
      backgroundPositionX: "center",
      backgroundPositionY: "center",
      videoFitting: "none"
    }
  }),

  created() {

    // Init current value
    if(this.currentValue) this.background = this.currentValue

    // Add watcher
    this.$watch('background', function (newVal) {
      this.currentValue = newVal
    }, {deep: true})
  },

  computed: {
    /**
     * This computed property generates a list of background types after excluding the types specified in `excludeTypes`.
     * It first checks if `excludeTypes` is an array. If not, it converts `excludeTypes` into an array.
     * Then, it filters out the excluded types from `globals.options.backgroundType`.
     * @return {Array} The filtered list of background types.
     */
    filteredTypes() {
      const excludedTypes = Array.isArray(this.excludeTypes) ? this.excludeTypes : [this.excludeTypes];

      return this.globals.options.backgroundType.filter((type) => !excludedTypes.includes(type.value));
    },
  }
}

</script>
