/**
 * DbRelease model
 */
import {AccessModel} from './AccessModel.js';

export class WebBuild extends AccessModel {
    static entity = 'web_builds'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        module_id: "int",
        branch: "string",
        url: "string",
        app_id: "int",
        status: "string",
        build_time: "int",
        platform: "string",
        logs: "text"
    }

    /**
     * Functions list
     */
    async channels() {
        return {
            'module-builds': {
                subscribe: ({module_id}) => module_id,
                init: async ({module_id}) => this.getList(module_id),
            }
        }
    }

    /**
     * Get list
     */
    getList(module_id) {
        return this.query().where({module_id}).get()
    }
}
