<template>

  <ui-prop-string name="title" title="Block title" :block="block"/>
  <ui-prop-string :multiple="true" name="description" title="Logic description" :block="block"/>
  <ui-events-props name="events" :block="block" :manual="true"/>
</template>

<script>

import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiEventsProps from "@/components/DiagramDesigner/Editor/properties/UiEventsProps.vue";

export default {
  mixins: [propertiesMixins],
  components: {UiEventsProps, UiPropString},
  props: ['block'],
  name: "FakeLogicPropsCmp",
  data: () => ({
  }),
}

</script>
