<template>
    <q-page class="column">
      <storage-tree :app-id="$route.params.app_id" :module-id="0" block_id="constants" :key="$route.params.app_id"/>
    </q-page>
</template>

<script>

import StorageTree from "@/pages/workspace/storage/StorageTree.vue";

export default {
  name: "ConstantsPage",
  components: {StorageTree},
  data: () => ({
    systemSettings: [{

    }]
  }),
}

</script>
