<template>
    <ab-flow-base-cmp :movable="true" :block="block" class="log-out-editor-cmp">

        <ab-flow-link-connector :block="block" type="input" class="ev-run" event="run"/>

        <q-card>

            <q-card-section class="bg-secondary text-white">
                Logout
            </q-card-section>
        </q-card>

        <div class="results">
            <ab-flow-link-connector class="q-mt-sm" :block="block" type="output" event="success" key="success"/>
            <ab-flow-link-connector class="q-mt-sm" :block="block" type="output" event="error" key="error"/>
        </div>

    </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

export default {
    components: { AbFlowBaseCmp, AbFlowLinkConnector},
    inject: ['canvas'],
    props: ['block'],
    name: "LogoutEditorCmp",
}

</script>

<style lang="scss">

.log-out-editor-cmp {
  .ev-run {
    left:-12px;
    position: absolute;
    top:calc(50% - 5px);
  }

  .results {
    position: absolute;
    right: -12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

</style>
