<template>

  <div>
    <ui-prop-string :block="block" name="title" title="Title"/>
    <ui-prop-dynamic-string
      :block="block"
      title="Instructions"
      name="instruction"
      untranslatable
      :multiple="true"
      allow-outgoing-bindings
    />

    <ui-prop-dynamic-string :block="block" title="Context window size" name="contextWindowSize" untranslatable default-value="100"/>
    <ui-prop-checkbox :block="block" name="dontSaveToContext" title="Don't store this conversation to the context" :default-value="0"/>
    <ui-prop-checkbox :block="block" name="breakMessages" title="Break long messages to sentences" :default-value="0"/>

    <ui-data-props
        class="q-mt-md"
        name="varsToCollect"
        title="Variables to collect"
        :default-schema="varsToCollectSchema"
        :block="block"
    />

    <ui-data-props
        class="q-mt-md"
        name="outgoingEvents"
        title="Navigation events"
        :default-schema="outgoingEventsSchema"
        :block="block"
    />

    <ui-data-props
        class="q-mt-md"
        name="functions"
        title="Assistant's functions"
        hint="Assistant will call these functions when the event is triggered"
        :default-schema="functionsSchema"
        :block="block"
    />

    <ui-prop-select :block="block" name="instructionPriority" title="Instruction priority" :options="globals.options.priorities" default-value="medium"/>

    <!--ui-data-props
        class="q-mt-md"
        name="triggers"
        title="Assistant's triggers"
        hint="Assistant will call these functions when the event is triggered"
        :default-schema="functionsSchema"
        :block="block"
    /!-->

  </div>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropDynamicString from "@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue";
import {AppIntegration} from "../../../../../../../../../common/db/AppIntegration";
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";
import UiDataProps from "@/components/DiagramDesigner/Editor/properties/UiDataProps.vue";
import UiPropCheckbox from "@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";

export default {
  mixins: [propertiesMixins],
  components: {UiPropSelect, UiPropCheckbox, UiDataProps, UiPropString, UiPropDynamicString},
  props: ['block'],
  name: "ChatAssistantPropsCmp",
  data() {
    return {
      varsToCollectSchema: [
        {
          type: 'string',
          name: 'instructions',
          title: 'What information to ask',
        },
        {
          type: 'variable',
          name: 'varToSave',
          title: "Where to store the information",
        },
      ],
      outgoingEventsSchema: [
        {
          type: 'string',
          name: 'instructions',
          title: 'When to call',
        },
        {
          type: 'string',
          name: 'event',
          title: 'Event name',
        },
      ],
      functionsSchema: [
        {
          type: 'string',
          name: 'instructions',
          title: 'Action description',
        },
        {
          type: 'string',
          name: 'argument',
          title: 'Function argument',
        },
        {
          type: 'string',
          name: 'event',
          title: 'Event name',
        },

      ],
    }
  },
  created() {
    // Subscribe for integration
    AppIntegration.remote().subscribe('app-integration-by-name', {
      module_id: this.module_id,
      name: "ai-assistants"
    })

  },
  beforeUnmount() {
    // Unsubscribe for integration
    AppIntegration.remote().unsubscribe('app-integration-by-name', {
      module_id: this.module_id,
      name: "ai-assistants"
    })
  },
  computed: {

    /**
     * Functions list
     * @return {*|*[]}
     */
    functionList() {
      return this.block.properties?.functions?.items || [];
    },
  }
}

</script>
