<template>

  <div>

    <ui-prop-string name="title" title="Operation title" :block="block"/>

    <ui-prop-checkbox class="q-my-sm" name="physicalDelete" title="Physically delete" :block="block" :default-value="0"/>

    <ui-prop-select :block="block" name="dbId" title="Database" :options="dbsList"/>
    <ui-prop-select v-if="block?.properties?.dbId" :block="block" name="tableId" title="Table" :options="tablesList"/>

    <template v-if="block?.properties?.tableId">
      <ui-prop-value-selector :block="block" name="recordId" only-var-type="string" title="Record id" :parent-diagram-id="diagram_id"/>
    </template>

  </div>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import {DbModule} from "@/../../common/db/DbModule";
import {DbTable} from "@/../../common/db/DbTable";
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import UiPropCheckbox from '@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue';

export default {
  mixins: [propertiesMixins],
  components: {UiPropCheckbox, UiPropValueSelector, UiPropSelect, UiPropString},
  inject: ["currentModule"],
  props: ['block'],
  name: "DbDeletePropsCmp",

  computed: {

    /**
     * Get dbs list
     * @return {*}
     */
    dbsList() {
      return this.wait("dbsList", async () => {
        return (await DbModule.query().where({app_id: this.product_id}).get())
          .filter((d) => parseInt(d.module_id) === parseInt(this.module_id) || this.currentModule.modules[d.module_id]?.type === "server")
          .map(d => ({
            value: d.id,
            label: d.name + " (" + this.currentModule.modules[d.module_id]?.name + ")"
          }));
      }, []);
    },

    /**
     * Get tables list
     * @return {*}
     */
    tablesList() {
      return this.wait("tablesList", async () => {
        return (await DbTable.query().where({db_id: this.block?.properties?.dbId || 0}).get()).map(d => ({
          value: d.id,
          label: d.name
        }));
      }, []);
    },
  }
}

</script>
