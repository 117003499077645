<template>
  <q-splitter class="full-height" v-model="splitter">
    <template #before>
      <div class="q-pa-sm">
        <div class="text-subtitle1">Choose the relevant section</div>

        <div class="column q-gutter-md q-mt-sm">
          <q-list bordered separator>
            <q-item
              v-for="section in sections"
              :key="section"
              clickable
              v-ripple
              :active="section === selectedSection"
              @click="selectSection(section)"
            >
              <q-item-section>
                <q-item-label>{{ section }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </div>
      </div>
    </template>

    <template #after>
      <div class="q-px-md q-pt-sm">
        <div class="text-subtitle1">Choose the desired fragment (click to paste)</div>

        <div class="row q-gutter-x-md q-gutter-y-lg q-mt-sm">
          <div
            v-for="fragment in fragments"
            :key="fragment"
            class="ui-template-preview dg-media cursor-pointer"
            @click="pasteBlock(fragment)"
          >
            <ab-flow-components-renderer
              :items="[fragment.source]"
              preview-mode
            />
          </div>
        </div>
      </div>
    </template>
  </q-splitter>
</template>

<script>
import AbFlowComponentsRenderer from 'ab-flow-designer/src/components/Designer/AbFlowComponentsRenderer';
import {Diagram} from '../../../../../common/db/Diagram';

export default {
  name: 'UiTemplatesFragmentsList',

  components: {AbFlowComponentsRenderer},

  emits: ['paste-ui-template'],

  data() {
    return {
      splitter: 20,
      selectedSection: null,
      templatesProjectId: process.env.VUE_APP_UI_TEMPLATES_MODULE_ID,
    };
  },

  computed: {
    /**
     * Templates list
     * @return {*}
     */
    templates() {
      if (!this.templatesProjectId) {
        return [];
      }

      return this.wait("templates", async () => {
        // Get all diagrams by module id
        const diagrams = await Diagram.query().where({module_id: this.templatesProjectId}).get() || [];

        return diagrams.filter((diagram) => !(diagram?.title || '').includes('[template]'))
          .map((diagram) => {
            // Get all fragments from diagram
            const fragments = (diagram?.source?.children || []).filter((fragment) => fragment.type === 'Fragment');

            if (!fragments.length) {
              return false;
            }

            return fragments.map((fragment) => ({
              fragment_id: fragment.id,
              section: diagram.title,
              name: fragment?.properties?.title || fragment.title,
              source: fragment,
              deleted: 0,
            }));
          }).flat().filter(Boolean);
      }, []);
    },

    /**
     * Diagrams map
     * key: section name
     * value: list of templates
     *
     * @return {Map<any, any>}
     */
    diagrams() {
      return this.templates.reduce((result, template) => {
        const section = template.section;

        const group = result.get(section) || [];

        group.push(template);

        result.set(section, group);

        return result;
      }, new Map);
    },

    /**
     * Sections list
     * @return {any[]}
     */
    sections() {
      return Array.from(this.diagrams.keys());
    },

    /**
     * Fragments list
     * @return {any[]}
     */
    fragments() {
      return this.diagrams.get(this.selectedSection) || [];
    },
  },

  methods: {
    /**
     * Select section
     * @param section
     */
    selectSection(section) {
      this.selectedSection = section;
    },

    /**
     * Paste UI template
     * @param fragment
     */
    pasteBlock(fragment) {
      this.$emit('paste-ui-template', fragment.source);
    },
  },

  created() {
    if (this.templatesProjectId) {
      // Subscribe to UI templates
      Diagram.remote().subscribe('app-module-diagrams', {module_id: this.templatesProjectId});
    }
  },

  beforeUnmount() {
    if (this.templatesProjectId) {
      // Unsubscribe from UI templates
      Diagram.remote().unsubscribe('app-module-diagrams', {module_id: this.templatesProjectId});
    }
  },
}
</script>

<style scoped lang="scss">
.ui-template-preview {
  &:deep(.fragment-editor-cmp) {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;

    // Firefox not working: https://caniuse.com/css-zoom
    zoom: 0.7;
  }
}
</style>
