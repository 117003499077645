<template>
  <ab-flow-base-cmp :movable="true" :block="block" class="code-function-editor-cmp">

    <ab-flow-link-connector :block="block" type="input" class="ev-run" event="run"/>

    <q-card class="card">

      <q-card-section class="bg-green-9 text-white">
        {{title}}
      </q-card-section>

    </q-card>

    <div class="results">
      <ab-flow-link-connector class="q-mt-sm" :block="block" type="output" event="result"/>
    </div>

  </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

export default {
  components: {AbFlowBaseCmp, AbFlowLinkConnector},
  inject: ['canvas'],
  props: ['block'],
  name: "StorageSetValueEditorCmp",
  computed: {


    /**
     * Return title
     * @return {{}}
     */
    title() {
      return this.block.properties?.title || "..."
    },

  }
}

</script>

<style lang="scss">

.code-function-editor-cmp {

  .card {
    max-width: 200px;
    word-wrap: break-word;
  }

  .ev-run {
    left: -12px;
    position: absolute;
    z-index: 1;
    top: calc(50% - 5px);
  }

  .results {
  }
}

</style>
