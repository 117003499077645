<template>

  <ab-flow-base-cmp :draggable="false" class="in-app-list" :block="block" :is_container="true" :key="filteredProductId">
    <div v-for="(item, k) of filteredItems" :key="k">
      <data-provider :storage-key="block?.title" :data="{item:item}">
        <ab-flow-components-renderer :items="block.children"/>
      </data-provider>
    </div>

    <div class="results">
      <ab-flow-link-connector class="q-mt-sm" :block="block" type="output" event="selected"/>
    </div>

  </ab-flow-base-cmp>

</template>

<script>

import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowComponentsRenderer from "ab-flow-designer/src/components/Designer/AbFlowComponentsRenderer";
import DataProvider from "@/components/DiagramDesigner/Editor/components/UI/Data/DataProvider/DataProvider.vue";
import {AppIntegration} from "@/../../common/db/AppIntegration.js";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

export default {
  components: {DataProvider, AbFlowBaseCmp, AbFlowComponentsRenderer, AbFlowLinkConnector},
  mixins: [renderMixins],

  inject: {
    schemaSource: {
      default: null,
    },
  },

  props: ['block'],
  name: "InAppProductsListEditorCmp",

  async created() {
    await AppIntegration.remote().subscribe('app-integration-by-name', {module_id: this.module_id, name: 'in-app-purchase'});
  },

  computed: {

    /**
     * Filtered product id
     * @return {*}
     */
    filteredProductId() {
      return this.getValue(this.block.properties?.productId)
    },

    /**
     * Computed property that retrieves the list of in-app products from the schema source.
     * It uses optional chaining to safely access nested properties.
     * If the products are not available, it returns false.
     *
     * @returns {Array|boolean} - An array of products if available, otherwise false.
     */
    productsFromSchema() {
      return this.schemaSource?.iap?.products || false;
    },

    /**
     * Computed property that retrieves the list of in-app products.
     * If the products are available from the schema source, it returns them.
     * If not, it queries the 'in-app-purchase' integration for the products.
     * If the products are still not available, it returns an empty array.
     *
     * @returns {Array} - An array of products if available, otherwise an empty array.
     */
    items() {
      if (this.productsFromSchema && this.productsFromSchema.length) {
        return this.productsFromSchema;
      }

      return this.wait("items", async () => {
        // Get in-app-purchase info
        const iapInfo = await AppIntegration.query().where({module_id: this.module_id, name: 'in-app-purchase'}).first();

        return iapInfo?.props?.products || []
      }, [])
    },

    /**
     * Computed property that retrieves the list of in-app products after applying a filter.
     * It first retrieves the filtered product id.
     * If no id is provided, it returns all the products.
     * If an id is provided, it splits the id string by comma (assuming multiple ids can be provided) and maps each id to the corresponding product in the items list.
     * It then filters out any undefined values (in case a product id does not match any product in the items list).
     *
     * @returns {Array} - An array of filtered products if id is provided, otherwise all products.
     */
    filteredItems() {
      // Get filtered product id
      const fId = this.filteredProductId;

      // Filter list by product id
      if (!fId) {
        return this.items;
      }

      return  fId.split(',').map(
        (id) => this.items.find((item) => item.productId === id)
      ).filter((v) => !!v);
    },
  }
}

</script>


<style lang="scss">

.in-app-list {
  flex-direction: inherit;
  flex-wrap: inherit;
  justify-content: inherit;
  align-items: inherit;
  width: 100%;
  position: relative;

  .results {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 50%;
  }
}

</style>
