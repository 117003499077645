<template>
    <ab-flow-base-cmp :movable="true" :block="block" class="cl-mod-editor-cmp justify-center">

        <ab-flow-link-connector :block="block" type="input" class="ev-start" event="start"/>

        <q-card>
            <q-card-section class="bg-yellow-10 text-white">
                Close modal fragment

              <div class="results">
                <ab-flow-link-connector class="ev-closed" :block="block" type="output" event="closed"/>
              </div>

            </q-card-section>

        </q-card>

    </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

export default {
    components: { AbFlowBaseCmp, AbFlowLinkConnector},
    inject: ['canvas'],
    props: ['block'],
    name: "CloseModalEditorCmp",
}

</script>

<style lang="scss">

.cl-mod-editor-cmp {
  .ev-start {
    left:-12px;
    position: absolute;
    top:calc(50% - 5px);
  }
  .ev-closed {
    right:-12px;
    position: absolute;
    top:calc(50% - 5px);
  }
}

</style>
