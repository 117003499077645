<template>

  <q-card style="width: 500px">
    <q-card-section>
      <div class="text-h6">Edit funnel</div>
    </q-card-section>

    <q-card-section class="q-pt-none">

      <q-form ref="funcForm" @submit="onSaveFunc" class="q-gutter-md">
        <q-input
            v-model="mutate(editFunnel).title"
            label="Funnel title *"
            hint="title of funnel"
            lazy-rules
            :rules="[ val => val && val.length > 0 || 'Please type something']"
        />

        <div>
          <q-btn label="Save" type="submit" color="primary"/>
        </div>
      </q-form>

    </q-card-section>

  </q-card>

</template>

<script>

import {AnalyticsFunnel} from "@/../../common/db/AnalyticsFunnel"

export default {
  name: "FunnelForm",
  props: ['editFunnel'],
  emits: ['saved'],
  data: () => ({
    product: false
  }),

  computed: {
  },

  methods: {

    /**
     * On form submit
     * @return {Promise<void>}
     */
    async onSaveFunc() {

      // Update or created (depends on id field existence)
      await AnalyticsFunnel.remote().save(this.editFunnel)

      // Hide dialog
      this.$emit('saved')
    }
  }
}

</script>
