<template>
  <div>
    <ui-prop-select
      title="Modal window position"
      name="modalPosition"
      default-value="center"
      :block="block"
      :options="globals.options.modalPosition"
    />

    <ui-prop-select v-if="block.properties?.modalPosition !== 'full'"
        title="Modal width"
        name="modalWidth"
        default-value="auto"
        :block="block"
        :options="globals.options.modalWidths"
    />

    <ui-prop-select v-if="block.properties?.modalPosition !== 'full'"
        title="Modal height"
        name="modalHeight"
        default-value="auto"
        :block="block"
        :options="globals.options.modalHeights"
    />

    <ui-prop-checkbox title="Persistent window" name="isPersistent" :block="block" :true-value="1" :false-value="0"/>

    <ui-prop-checkbox title="No backdrop overlay" name="noBackdrop" :block="block" :true-value="1" :false-value="0"/>

    <ui-prop-color
      v-if="block?.properties?.noBackdrop !== 1"
      :block="block"
      title="Overlay color"
      name="overlayColor"
      default-value="rgba(0,0,0,0.4)"
    />

    <ui-prop-select
      title="Transition show"
      name="transitionShow"
      default-value="jump-up"
      :block="block"
      :options="globals.options.transitions"
    />

    <ui-prop-select
      title="Transition hide"
      name="transitionHide"
      default-value="jump-down"
      :block="block"
      :options="globals.options.transitions"
    />

    <ui-prop-string
      name="transitionDuration"
      title="Transition duration (milliseconds)"
      :block="block"
    />
  </div>
</template>

<script>
import UiPropString from '@/components/DiagramDesigner/Editor/properties/UiPropString.vue';
import UiPropCheckbox from '@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue';
import UiPropColor from '@/components/DiagramDesigner/Editor/properties/UiPropColor.vue';
import UiPropSelect from '@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue';

export default {
  name: 'UiModalProps',
  components: {UiPropSelect, UiPropColor, UiPropCheckbox, UiPropString},

  props: {
    block: {
      type: Object,
      required: true
    }
  },
}
</script>
