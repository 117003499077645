<template>

  <ab-flow-base-cmp :block="block" class="text-cmp" :class="classesString" :style="stylesString">
    <div v-if="allowHtml" class="text-content" v-html="content"/>
    <div v-else class="text-content" v-text="content" />
  </ab-flow-base-cmp>

</template>

<script>

import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";

export default {
  components: {AbFlowBaseCmp},
  mixins: [renderMixins],
  props: ['block'],
  name: "TextEditorCmp",
  computed: {

    /**
     * Allow html
     * @return {boolean}
     */
    allowHtml() {
      return this.block.properties?.allowHtml === 1;
    },

    /**
     * Value
     * @return {string}
     */
    content() {
      return this.interpretString(this.block.properties?.content) || 'No text';
    },
  },
}

</script>

<style lang="scss">
.text-cmp {
  display: flex;
  .text-content {
    width: 100%;
  }
}
</style>
