<template>

  <div>
    <ui-prop-value-selector name="panelStyle" title="Panel style variable" :block="block" :parent-diagram-id="diagram_id" :return-options="panelStyles"/>
  </div>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";

export default {
  inject: ['designer'],
  mixins: [propertiesMixins],
  name: "UiPropPanelStyle",
  components: {UiPropValueSelector},
  computed: {

    /**
     * Get panel styles
     * @return {(function(): *)|*|[]|*[]}
     */
    panelStyles() {
      const panels = this.designer.currentStyle?.source?.panelStyles || [];

      if (!Array.isArray(panels)) {
        return [];
      }

      return panels.map(ps => ({value: ps.properties?.id, label: ps.properties?.name}));
    }
  }
}

</script>
