<template>
  <div>
    <ui-prop-value-selector :block="block" type="setter" name="productVariable" title="Selected product" :parent-diagram-id="diagram_id"/>
    <ui-prop-value-selector :block="block" type="getter" name="productId" title="Products id (separated by commas)" :parent-diagram-id="diagram_id"/>
    <ui-prop-checkbox title="Select automatically (if single product)" name="autoSelect" :block="block" />

    <q-separator spaced/>
    "Selected product" variable will be filled with following properties: <br/>
    <ul>
      <li>id</li>
      <li>title</li>
      <li>priceTitle</li>
      <li>priceValue</li>
      <li>currency</li>
      <li>billingPeriodTitle</li>
      <li>trialDescription</li>
      <li>trialPeriodTitle</li>
      <li>ctaTitle</li>
      <li>subscriptionDetails</li>
      <li>subscriptionRules</li>
    </ul>

  </div>

</template>

<script>
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import UiPropCheckbox from "@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";

export default {
  mixins: [propertiesMixins],
  components: {UiPropValueSelector, UiPropCheckbox},
  props: ['block'],
  name: "InAppProductsListPropsCmp",
}

</script>
