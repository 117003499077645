<template>
  <router-view/>
</template>

<script>

import _ from "lodash";

export default {
  name: 'App',
  provide() {
    return {
      main: this
    }
  },
  data: () => ({
    copiedBlock: false,
    editorMode: "editor",
    settings: {}
  }),
  components: {
  },
  methods: {
    /**
     * Get settings
     * @param key
     * @param defaultValue
     * @return {{}}
     */
    getSettings(key, defaultValue = null) {
      return _.get(this.settings, key) || defaultValue
    },

    /**
     * Set settings
     * @param key
     * @param value
     */
    setSettings(key, value) {
      _.set(this.settings, key, value)
    },
  }
}
</script>
