<template>

  <div>
    <ui-prop-value-selector :block="block" title="First operand" name="operandA" value-type="getter" :parent-diagram-id="diagram_id"/>
    <ui-prop-select name="operation" title="Operation" :block="block" :options="globals.options.varOperations"/>
    <ui-prop-value-selector :block="block" title="Second operand" name="operandB" value-type="getter" :parent-diagram-id="diagram_id"/>

    <!-- Types processing !-->
    <date-time-operation :block="block" v-if="operandA?.type === 'datetime'"/>

    <ui-prop-value-selector :block="block" title="Variable to save result" name="variableToSave" value-type="setter" :parent-diagram-id="diagram_id"/>
  </div>

</template>

<script>

import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import DateTimeOperation
  from "@/components/DiagramDesigner/Editor/components/UI/Logic/LogicOperation/Types/DateTimeOperation.vue";

export default {
  mixins: [propertiesMixins],
  components: {DateTimeOperation, UiPropValueSelector, UiPropSelect},
  props: ['block'],
  name: "VarOperationPropsCmp",
  computed: {
    operandA() {
      // get A block
      return this.block?.properties?.operandA
    },

    operandB() {
      // get B block
      return this.block?.properties?.operandB
    }
  }
}

</script>
