/**
 * App model
 */
import {AbOrm} from "ab-orm/src/AbOrm.js";
import {AccessManager} from '../utils/AccessManager.js';

export class App extends AbOrm {
    static entity = 'apps'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        title: "string",
        description: "text",
    }

    /**
     * Prepare data according to users access level
     * @return {boolean}
     * @param row
     * @param mode
     */
    static async prepareData(row, mode) {
        const accessManager = new AccessManager(this);

        return await accessManager.checkAppAccess(row?.id) ? row : false
    }


    /**
     * Partitions list
     */
    async channels() {
        return {
            'admin-apps': {
                subscribe: () => 1,
                init: async () => this.getAdminList(),
            },
            'app': {
                subscribe: ({id}) => id,
                init: async ({id}) => App.find(id),
            },
            'users-apps': {
                subscribe: ({user_id}) => user_id,
                init: async ({user_id}) => this.getUserApps(user_id),
            }
        }
    }

    /**
     * Get list
     */
    getAdminList() {
        if (this.app().auth().hasRole("admin")) {
            return this.query().get()
        }
    }

    /**
     * Check user access to app
     * @return {boolean}
     */
    hasAccess() {
        // TODO check user access to app
        return true;
    }

    /**
     * Get users apps list
     */
    getUserApps(user_id) {
        // Check if user has admin access
        if (this.app().auth().hasRole("admin")) {
            return this.getAdminList();
        }

        // Check if user has admin access
        return this.query().join('user_apps')
          .on("apps.id", "user_apps.app_id")
          .where("user_apps.user_id", user_id)
          .where("user_apps.deleted", 0)
          .get()

    }
}
