<template>

  <q-input
      :label="title"
      v-model="currentValue"
      :rules="[ (val, rules) => (!val) || rules.anyColor(val) || 'Please type color']"
  >
    <template v-slot:append>
      <q-icon name="colorize" class="cursor-pointer">
        <q-popup-proxy cover transition-show="scale" transition-hide="scale">
          <q-color v-model="currentValue" />
        </q-popup-proxy>
      </q-icon>
    </template>

  </q-input>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";

export default {
  components: {},
  props: {
    defaultValue: {
      type: String,
    }
  },
  mixins: [propertiesMixins],
  name: "UiPropColor",
}

</script>
