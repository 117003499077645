<template>

  <ab-flow-base-cmp ref="chartCnt" class="chart-view-cmp" :block="block" :class="classesString" :style="stylesString">

    <component
      v-if="isReady"
      :is="chartComponent"
      :height="chartBounds.height"
      :width="chartBounds.width"
      :chartData="chartData"
      :options="chartOptions"
    />

  </ab-flow-base-cmp>

</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import {renderMixins} from "../../../renderMixins";
import { BarChart, LineChart } from "vue-chart-3";
import { Chart, registerables } from 'chart.js';
Chart.register(...registerables);

export default {
  components: {AbFlowBaseCmp, BarChart, LineChart},
  inject: ['canvas'],
  mixins: [renderMixins],
  props: ['block'],
  name: "ChartViewEditorCmp",
  data: () => ({
    isReady: false,
    chartBounds: {},
  }),
  mounted() {
    // Get container size
    this.chartBounds = this.$refs.chartCnt.$el.getBoundingClientRect();

    // Initiated
    this.isReady = true
  },
  computed: {
    chartOptions() {
      const suggestedMax = this.getValue(this.block.properties?.yAxisMinSize) || 0;

      const labelAxisX = this.getValue(this.block.properties?.labelAxisX) || '';
      const labelAxisY = this.getValue(this.block.properties?.labelAxisY) || '';

      return {
        plugins: {
          legend: {
            display: false
          },
        },
        scales: {
          x: {
            title: {
              display: !!labelAxisX,
              text: labelAxisX,
            },
          },
          y: {
            // The maximum item to display if there is no datapoint behind it.
            suggestedMax,
            title: {
              display: !!labelAxisY,
              text: labelAxisY,
            },
          }
        }
      }
    },

    /**
     * Get chart data
     * @return {{datasets: [{backgroundColor: string[], data: number[]}], labels: string[]}}
     */
    chartData() {

      // Get values
      const values = this.getValue(this.block.properties?.values) || []
      const labels = this.getValue(this.block.properties?.labels) || []

      const barColor = this.designer.currentStyle.getColor(this.block?.properties?.barColor, "background") || "#a00";

      return {
        labels,
        datasets: [
          {
            data: values,
            backgroundColor: [
              barColor,
            ],
            borderColor: [
              barColor,
            ],
          }
        ]
      }
    },

    /**
     * Computes the chart type based on the block properties.
     *
     * @return {string} The type of the chart, defaults to 'bar' if not specified.
     */
    chartType() {
      return this.getValue(this.block.properties?.chartType) || 'bar';
    },

    /**
     * Determines the chart component to use based on the chart type.
     *
     * @return {Object} The chart component, either LineChart or BarChart.
     */
    chartComponent() {
      switch (this.chartType) {
        case 'line':
          return LineChart;
        default:
          return BarChart;
      }
    },
  },
}
</script>

<style lang="scss">

.chart-view-cmp {
  flex-direction: column;
  position: relative;

  .events {
    position: absolute;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    z-index: 1;
  }
}

</style>
