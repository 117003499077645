<template>

  <ui-prop-value-selector :block="block" name="labels" title="Labels" :is-array="true" only-var-type="string" :parent-diagram-id="diagram_id"/>
  <ui-prop-value-selector :block="block" name="values" title="Values" :is-array="true" only-var-type="float" :parent-diagram-id="diagram_id"/>
  <ui-prop-select :block="block" title="Type" name="chartType" :options="chartTypes" default-value="bar"/>
  <ui-prop-select :block="block" title="Bar color" name="barColor" :options="moduleColors"/>
  <ui-prop-string name="labelAxisX" title="X-axis label" :block="block"/>
  <ui-prop-string name="labelAxisY" title="Y-axis label" :block="block"/>
  <ui-prop-string name="yAxisMinSize" title="Y-axis min size" :block="block"/>
  <ui-prop-string name="width" title="Width" :block="block"/>
  <ui-prop-string name="height" title="Height" :block="block"/>

  <ui-dynamic-props :default-value="{}" :block="block" :product_id="product_id" :module_id="module_id" />

</template>

<script>
import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";

export default {
  mixins: [propertiesMixins],
  name: "ChartViewPropsCmp",
  props: ['block'],
  components: {UiPropString, UiPropValueSelector, UiDynamicProps, UiPropSelect},

  data() {
    return {
      chartTypes: [
        {value: 'bar', label: 'Bar'},
        {value: 'line', label: 'Line'},
      ],
    };
  },
}

</script>

