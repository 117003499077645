<template>
  <ui-prop-value-selector :block="block" name="currentProgress" title="Progress" only-var-type="int" :parent-diagram-id="diagram_id"/>
  <ui-prop-select name="backColor" title="Back color" :block="block" :options="moduleColors"/>
  <ui-prop-select name="trackColor" title="Track color" :block="block" :options="moduleColors"/>
  <ui-prop-string name="width" title="Width" :block="block"/>
  <ui-prop-string name="height" title="Height" :block="block"/>
  <ui-dynamic-props :block="block" :product_id="product_id" :module_id="module_id"/>
</template>

<script>

import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect";
import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";

export default {
  mixins: [propertiesMixins],
  name: "LinearProgressPropsCmp",
  props: ['block'],
  components: {UiPropString, UiPropValueSelector, UiDynamicProps, UiPropSelect}
}

</script>

