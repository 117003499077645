<template>

  <div>
    <ui-prop-dynamic-string :block="block" title="Set a title for any message" name="title"/>

    <ui-prop-dynamic-string :block="block" title="Set some text to share" name="text"/>

    <ui-prop-value-selector :block="block" name="url" title="Set a URL to share" :parent-diagram-id="diagram_id"/>

    <ui-prop-value-selector :block="block" name="files" title="Set a image URL to share" only-var-type="image" :parent-diagram-id="diagram_id"/>

    <ui-prop-dynamic-string :block="block" title="Set a title for the share modal" name="dialogTitle"/>
  </div>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropDynamicString from "@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue";
import UiPropValueSelector from '@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue';

export default {
  mixins: [propertiesMixins],
  components: {UiPropValueSelector, UiPropDynamicString},
  props: ['block'],
  name: "ShareDialogPropsCmp",
}

</script>
