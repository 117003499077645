<template>
  <div
    ref="lottieContainer"
    class="q-mx-auto full-width full-height"
  />
</template>

<script>
export default {
  /**
   * Component props
   * @property {Object} animationData - The animation data object.
   * @property {String} animationUrl - The URL to fetch the animation data from.
   * @property {Boolean} loop - Whether the animation should loop.
   * @property {Boolean} autoplay - Whether the animation should autoplay.
   */
  props: {
    animationData: Object,
    animationUrl: String,
    loop: {
      type: Boolean,
      default: true,
    },
    autoplay: {
      type: Boolean,
      default: true,
    },
    fit: {
      type: String,
      default: 'contain',
    },
    position: {
      type: String,
      default: 'center',
    },
  },
  data() {
    return {
      lottieInstance: null,
    };
  },

  computed: {
    /**
     * Computes the SVG position based on the `position` prop.
     * @returns {String} The SVG position value.
     */
    svgPosition() {
      switch (this.position) {
        case 'left top':
          return 'xMinYMin';
        case 'left bottom':
          return 'xMinYMax';
        case 'right top':
          return 'xMaxYMin';
        case 'right bottom':
          return 'xMaxYMax';
        case 'center top':
          return 'xMidYMin';
        case 'center bottom':
          return 'xMidYMax';
        default: // center
          return 'xMidYMid';
      }
    },

    /**
     * Computes the SVG fit based on the `fit` prop.
     * @returns {String} The SVG fit value.
     */
    svgFit() {
      switch (this.fit) {
        case 'fill':
          return 'none';
        case 'cover':
          return `${this.svgPosition} slice`;
        default:
          return `${this.svgPosition} meet`;
      }
    },
  },

  methods: {
    /**
     * Loads the animation using the provided animation data or URL.
     * Destroys the existing animation instance if it exists.
     */
    loadAnimation() {
      if (this.lottieInstance) {
        this.lottieInstance.destroy();
      }

      const options = {
        container: this.$refs.lottieContainer,
        renderer: 'svg',
        loop: this.loop,
        autoplay: this.autoplay,
        animationData: this.animationData || null,
        rendererSettings: {
          preserveAspectRatio: this.svgFit,
        },
      };

      if (this.animationUrl) {
        fetch(this.animationUrl)
          .then(response => response.json())
          .then(data => {
            options.animationData = data;
            this.lottieInstance = window.lottie.loadAnimation(options);
          })
          .catch(error => {
            console.error('Error loading animation:', error);
          });
      } else if (this.animationData) {
        this.lottieInstance = window.lottie.loadAnimation(options);
      }
    },
  },
  watch: {
    /**
     * Watcher for animationUrl prop.
     * Reloads the animation when the URL changes.
     */
    animationUrl() {
      this.loadAnimation();
    },
    /**
     * Watcher for animationData prop.
     * Reloads the animation when the data changes.
     */
    animationData() {
      this.loadAnimation();
    },
  },
  /**
   * Lifecycle hook called when the component is mounted.
   * Loads the animation if Lottie is defined.
   */
  mounted() {
    if (typeof window.lottie === undefined) {
      console.error('Lottie is not defined');
      return;
    }

    this.loadAnimation();
  },
  /**
   * Lifecycle hook called before the component is unmounted.
   * Destroys the animation instance if it exists.
   */
  beforeUnmount() {
    if (this.lottieInstance) {
      this.lottieInstance.destroy();
    }
  },
};
</script>
