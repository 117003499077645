import {StorageNode} from '../db/StorageNode.js';

export class SchemaVarsValidator {
  validationResult = [];

  staticVarsMap = new Map();
  argumentsMap = new Map();
  dynamicTypes = new Map();
  storageSetters = new Set();
  skipStaticVars = new Set();

  compatibleTypes = {
    string: ["int", "float"],
    image: ["string"],
    lottie: ["string"],
    video: ["string"],
    sound: ["string"],
    float: ["int"],
    int: ["float"]
  };

  /**
   * Creates an instance of SchemaVarsValidator.
   *
   * @param {Object} diagram - The diagram object to validate.
   */
  constructor(diagram) {
    this.diagram = diagram;
  }

  /**
   * Generates maps for static variables and arguments by traversing the diagram tree.
   *
   * @private
   * @returns {Promise<void>}
   */
  async _generateVarsMap() {
    const goDeep = async (tree) => {
      const block = this._blockData(tree);
      const callStack = this._buildCallStack(tree);

      if (tree.propValue && 'valueType' in tree.propValue) {
        if (tree.propValue.valueType === 'static') {
          this.staticVarsMap.set(`${block.id}:${tree.propName}`, {
            propName: tree.propName,
            propValue: tree.propValue,
            block,
            callStack,
          });
        }
      }

      const storageBlockId = this._getStorageBlockId(tree.propValue);

      if (storageBlockId) {
        this.argumentsMap.set(block.id, {
          propName: tree.propName,
          propValue: tree.propValue,
          block,
          callStack,
          storageBlockId,
        });
      }

      if (tree?.propValue?.type === 'StorageSetValue') {
        this.storageSetters.add({
          block,
          callStack,
          component: tree.propValue,
        })
      }

      for (const [propName, propValue] of Object.entries(tree.propValue || {})) {
        if (typeof propValue === 'object') {
          await goDeep({
            propName: propName,
            propValue: propValue,
            parent: tree,
          });
        }
      }
    };

    await goDeep({
      propName: 'root',
      propValue: this.diagram,
      parent: null,
    });
  }

  /**
   * Builds a call stack for the given tree node.
   *
   * @private
   * @param {Object} parent - The parent tree node.
   * @returns {Array<string>} The call stack.
   */
  _buildCallStack(parent) {
    const callStack = [];

    const goDeep = (tree) => {
      const {title, alias, type, diagram_type, id} = tree?.propValue || {};

      if (title || alias) {
        callStack.push(`${title || alias}${type || diagram_type ? `: ${type || diagram_type}` : ''}${id ? ` (${id})` : ''}`);
      }

      if (tree?.parent) {
        goDeep(tree.parent);
      }
    };

    goDeep(parent);

    return callStack.reverse();
  }

  /**
   * Retrieves block data for the given tree node.
   *
   * @private
   * @param {Object} parent - The parent tree node.
   * @returns {Object|null} The block data or null if not found.
   */
  _blockData(parent) {
    const goDeep = (tree) => {
      const {type, id} = tree?.propValue || {};

      if (type && id) {
        return tree.propValue;
      }

      if (tree?.parent) {
        return goDeep(tree.parent);
      }
    };

    return goDeep(parent) || null;
  }

  /**
   * Checks a static variable for validity.
   *
   * @private
   * @param {Object} param0 - The static variable details.
   * @param {string} param0.propName - The property name.
   * @param {Object} param0.propValue - The property value.
   * @param {Object} param0.block - The block data.
   * @param {Array<string>} param0.callStack - The call stack.
   */
  _checkStaticVar({propName, propValue, block, callStack}) {
    const value = propValue.value;

    if (value === undefined || value === null || value === '') {
      return;
    }

    let result;

    const type = this.dynamicTypes.get(`${block.id}:${propName}`) || propValue.type;

    switch (type) {
      case 'int':
        if (!/^[+-]?\d+$/.test(value)) {
          result = 'The entered value is not an integer.';
        }
        break;
      case 'float':
        if (!/^[+-]?\d+(\.\d+)?$/.test(value)) {
          result = 'The entered value is not a float.';
        }
        break;
      case 'bool':
        if ((typeof value === 'string' && !['true', 'false'].includes(String(value).toLowerCase())) || typeof !!value !== 'boolean') {
          result = 'The entered value is not a boolean.';
        }
        break;
      case "object":
      case "db-record":
        if (typeof value !== 'object') {
          result = 'The entered value is not an object.';
        }
        break;
    }

    if (result) {
      this.validationResult.push({
        type: 'Constant',
        message: result,
        data: {
          value,
          type: propValue.type,
          block,
        },
        callStack,
      });
    }
  }

  /**
   * Checks all static variables for validity.
   *
   * @private
   */
  _checkStaticVars() {
    for (const item of this.staticVarsMap.values()) {
      if (this.skipStaticVars.has(`${item.block.id}:${item.propName}`)) {
        continue;
      }

      this._checkStaticVar(item);
    }
  }

  /**
   * Retrieves the storage block ID for a given node.
   *
   * @private
   * @param {Object} node - The node to retrieve the storage block ID for.
   * @returns {string|null} The storage block ID or null if not found.
   */
  _getStorageBlockId(node) {
    if (!node?.type) {
      return null;
    }

    if (node.type.startsWith?.('Widget:')) {
      return `diagram-${node.type.split(':')[1]}`;
    }

    if (node.type === 'DiagramComponent' && node?.properties?.diagramComponentId) {
      return `diagram-${node.properties.diagramComponentId}`;
    }

    if (node.type === 'CodeFunction' && node?.properties?.function) {
      return `func-args-${node.properties.function}`;
    }

    return null;
  }

  /**
   * Checks the arguments for validity.
   *
   * @private
   * @returns {Promise<void>}
   */
  async _checkArguments() {
    for (const {storageBlockId, propValue, block, callStack} of this.argumentsMap.values()) {
      const args = propValue?.properties?.arguments;

      if (!args) {
        continue;
      }

      const allStorageNodes = await StorageNode.query().where({module_id: this.diagram.module_id}).get() || [];

      const storageNodes = Object.fromEntries(
        allStorageNodes.filter(({block_id}) => block_id === storageBlockId)
          .map((node) => [node.name, node])
      );

      if (!Object.keys(storageNodes).length) {
        continue;
      }

      Object.entries(args).forEach(([argName, argValue]) => {
        if (!storageNodes[argName]) {
          this.skipStaticVars.add(`${block.id}:${argName}`);

          return;
        }

        this.dynamicTypes.set(`${block.id}:${argName}`, storageNodes[argName].type);

        // Check if the argument value is a variable.
        if (argValue?.valueType === 'variable') {
          const valueNode = allStorageNodes.find(({id}) => id === argValue?.nodeId);

          if (!valueNode?.id) {
            return;
          }

          // Check if the value type is compatible with the argument type.
          const argNode = storageNodes[argName];

          const allowedTypes = [
            argNode?.type,
            ...[this.compatibleTypes[argNode?.type] || []]
          ].flat().filter(Boolean);

          if (!allowedTypes.includes(valueNode.type)) {
            this.validationResult.push({
              type: 'Constant',
              message: `The selected value type (${valueNode.type}) does not match the allowed types (${allowedTypes.join(', ')}).`,
              data: {
                value: argValue?.value,
                type: propValue.type,
                block,
              },
              callStack,
            });
          }
        }
      });
    }
  }

  /**
   * Checks if the storage setter item is valid.
   *
   * @param {Object} item - The storage setter item to check.
   * @param {Object} item.variable - The variable to check.
   * @param {string} item.operation - The operation type.
   * @param {Object} item.setValue - The value to set.
   * @returns {Promise<boolean|Object>} False if valid, or an error object if invalid.
   */
  async _checkStorageSettersItem(item) {
    const {variable, operation, setValue} = item;

    if (variable?.valueType !== 'variable' || setValue?.valueType !== 'variable' || operation === 'reset') {
      return false;
    }

    const storageNode = await StorageNode.find(variable.nodeId);

    const allowedTypes = [
      storageNode?.type,
      ...(this.compatibleTypes[storageNode?.type] || [])
    ].filter(Boolean);

    if (!allowedTypes) {
      return false;
    }

    const setNode = await StorageNode.find(setValue.nodeId);

    if (!setNode?.id) {
      return false;
    }

    return allowedTypes.includes(setNode.type)
      ? false : {
        message: `The selected value type (${setNode.type}) does not match the allowed types (${allowedTypes.join(', ')}).`,
        type: storageNode?.type,
        value: setValue?.value,
      };
  }

  /**
   * Checks all storage setters for validity.
   *
   * @private
   * @returns {Promise<void>}
   */
  async _checkStorageSetters() {
    for (const {block, callStack, component} of this.storageSetters) {
      const variables = component?.properties?.variables || [];

      if (!variables.length) {
        continue;
      }

      for (const variable of variables) {
        const checkResult = await this._checkStorageSettersItem(variable);

        if (!checkResult) {
          continue;
        }

        this.validationResult.push({
          type: 'Constant',
          message: checkResult.message,
          data: {
            value: checkResult.value,
            type: checkResult.type,
            block,
          },
          callStack,
        });
      }
    }
  }

  /**
   * Validates the diagram by checking static variables and arguments.
   *
   * @returns {Promise<Array|boolean>} The validation result or false if no errors.
   */
  async validate() {
    try {
      this.validationResult = [];
      this.staticVarsMap.clear();
      this.argumentsMap.clear();
      this.storageSetters.clear();
      this.skipStaticVars.clear();

      await this._generateVarsMap();

      if (this.argumentsMap.size) {
        await this._checkArguments();
      }

      if (this.storageSetters.size) {
        await this._checkStorageSetters();
      }

      if (this.staticVarsMap.size) {
        this._checkStaticVars();
      }

      return this.validationResult.length ? this.validationResult : false;
    } catch (e) {
      console.error('Error validating diagram:', e);

      return false;
    }
  }
}
