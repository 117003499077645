<template>

  <div>
    <div class="text-subtitle1">Text shadow:</div>
    <div class="row">
      <q-select class="q-select q-px-sm" emit-value map-options :options="globals.options.levels" v-model="shadow.left" label="Left"/>
      <q-select class="q-select q-px-sm" emit-value map-options :options="globals.options.levels" v-model="shadow.top" label="Top"/>
      <color-picker title="Shadow color" v-model="shadow.color"/>
    </div>
  </div>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import ColorPicker from "@/components/ColorPicker/ColorPicker.vue";

export default {
  inject: ['canvas'],
  mixins: [propertiesMixins],
  name: "UiPropTextShadow",
  components: {ColorPicker},

  data: () => ({
    shadow: {
      left: 3,
      top: 3,
      color: "#333333ff"
    }
  }),

  created() {

    // Init current value
    if(this.currentValue) this.shadow = this.currentValue

    // Add watcher
    this.$watch('shadow', function (newVal) {
      this.currentValue = newVal
    }, {deep: true})
  },

  computed: {
    // Redefine prop name
    type: () => "textShadow"
  }
}

</script>

<style lang="scss" scoped>
.q-select {
  flex: 1;
  width: 100%;
  min-width: 60px;
}
</style>
