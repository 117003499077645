/**
 * CacheManager is a simple caching utility. It stores key-value pairs in memory and
 * automatically removes them after a specified time-to-live (TTL).
 */
export class CacheManager {
  static instance;

  /**
   * Constructs a new CacheManager instance.
   */
  constructor() {
    if (CacheManager.instance) {
      return CacheManager.instance;
    }

    this.cache = new Map();

    CacheManager.instance = this;
  }

  /**
   * Retrieves a value from the cache using its key.
   * @param {string} key - The key associated with the value to retrieve.
   * @returns {any} The cached value, or null if the key is not found in the cache.
   */
  get(key) {
    const cacheEntry = this.cache.get(key);

    return cacheEntry?.value || null;
  }

  /**
   * Stores a value in the cache associated with a key, and sets a timeout to remove it after the TTL.
   * If a value is already stored with the same key, the old value and its timeout are replaced.
   * @param {string} key - The key to associate with the value.
   * @param {any} value - The value to store in the cache.
   * @param {number} ttl - The time-to-live (TTL) of the cache entry, in seconds. Defaults to 60 seconds.
   */
  set(key, value, ttl = 60) {
    const oldTimeoutId = this.cache.get(key)?.timeoutId;
    if (oldTimeoutId) {
      clearTimeout(oldTimeoutId);
    }

    const timeoutId = setTimeout(() => {
      this.cache.delete(key);
    }, ttl * 1000);

    this.cache.set(key, { value, timeoutId });
  }

  /**
   * Removes a value from the cache using its key.
   * If a value is found, its timeout is also cleared.
   * @param {string} key - The key associated with the value to remove.
   */
  delete(key) {
    const cacheEntry = this.cache.get(key);

    if (cacheEntry) {
      clearTimeout(cacheEntry.timeoutId);
      this.cache.delete(key);
    }
  }

  /**
   * Checks if a key exists in the cache.
   *
   * @param {string} key - The key to check in the cache.
   * @returns {boolean} Returns true if the key exists in the cache, otherwise false.
   */
  has(key) {
    return this.cache.has(key);
  }
}
