<template>

    <ui-dynamic-props :block="block"/>

</template>

<script>

import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps";
export default {
    name: "FormPropsCmp",
    props: ['block'],
    components: {UiDynamicProps},
    data: () => ({
        defaultSchema: [
            {
                name: "name",
                type: "string"
            },
            {
                name: "value",
                type: "string"
            }
        ]
    }),
}

</script>

