<template>
    <ab-flow-base-cmp :movable="true" :block="block" class="loading-cmp justify-center">

        <ab-flow-link-connector :block="block" type="input" class="ev-start" event="start"/>

        <q-card>
            <q-card-section class="bg-secondary text-white">
                {{action}} loader
            </q-card-section>
        </q-card>

      <div class="results">
        <ab-flow-link-connector :block="block" type="output" event="success"/>
      </div>

    </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

export default {
    components: { AbFlowBaseCmp, AbFlowLinkConnector},
    props: ['block'],
    name: "LoadingEditorCmp",
    computed: {

        /**
         * Return title
         * @return {string}
         */
        action() {
            return this.block.properties?.action || "n/a";
        },
    }
}

</script>

<style lang="scss">

.loading-cmp {
  position: relative;

  .ev-start {
    left:-12px;
    position: absolute;
    top:calc(50% - 5px);
  }


  .results {
    position: absolute;
    right: -12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

</style>
