<template>

  <div>

    <ui-prop-string name="title" title="Operation title" :block="block"/>
    <ui-prop-select :block="block" name="dbId" title="Database" :options="dbsList"/>
    <ui-prop-select v-if="block?.properties?.dbId" :block="block" name="tableId" title="Table" :options="tablesList"/>
    <ui-prop-select v-if="block?.properties?.tableId" :block="block" name="saveMode" default-value="object" title="Save mode" :options="globals.options.dbSaveModes"/>

    <q-card class="q-mt-sm" v-if="block?.properties?.saveMode === 'fields'">
      <q-card-section class="bg-primary text-white">Fields list</q-card-section>
      <q-card-section v-if="block?.properties?.tableId" class="q-gutter-y-sm column">

        <div v-for="(field, key) of fieldsList" :key="key">
          <value-selector v-model="fields[field.name]" :title="`Field: ${field.name}`"
                          :only-var-type="convertDbType(field.type)"
                          :app-id="product_id" :module-id="module_id" :parent-diagram-id="diagram_id"
                          :block-id="block.id"
          />

          <q-separator spaced/>

        </div>

      </q-card-section>
    </q-card>

    <q-card class="q-mt-sm" v-if="block?.properties?.saveMode === 'object'">
      <ui-prop-value-selector :block="block" title="Object to save" name="object" value-type="getter" :parent-diagram-id="diagram_id"/>
    </q-card>

    <ui-prop-value-selector only-var-type="object,db-record" :block="block" title="Variable to save result" name="variableToSave" value-type="setter" :parent-diagram-id="diagram_id"/>

  </div>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import {DbModule} from "@/../../common/db/DbModule";
import {DbTable} from "@/../../common/db/DbTable";
import {DbTableField} from "@/../../common/db/DbTableField";
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";
import ValueSelector from "@/components/ValueSelector/ValueSelector.vue";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";

export default {
  mixins: [propertiesMixins],
  components: {UiPropValueSelector, ValueSelector, UiPropSelect, UiPropString},
  inject: ["currentModule"],
  props: ['block'],
  name: "DbSavePropsCmp",

  data: () => ({
    fields: {}
  }),

  created() {
    // Init value
    if (this.currentValue) this.fields = this.currentValue

    // Add watcher
    this.$watch('fields', function (newVal) {
      this.currentValue = newVal
    }, {deep: true})
  },

  methods: {

    /**
     * Convert db type to var type
     * @param type
     * @return {*|string}
     */
    convertDbType(type) {
      switch (type) {
        case "autogenerated":
          return "string";
        default:
          return type;
      }
    }
  },

  computed: {

    type: () => "fields",

    /**
     * Get dbs list
     * @return {*}
     */

    dbsList() {
      return this.wait("dbsList", async () => {
        return (await DbModule.query().where({app_id: this.product_id}).get())
          .filter((d) => parseInt(d.module_id) === parseInt(this.module_id) || this.currentModule.modules[d.module_id]?.type === "server")
          .map(d => ({
            value: d.id,
            label: d.name + " (" + this.currentModule.modules[d.module_id]?.name + ")"
          }));
      }, []);
    },


    /**
     * Get tables list
     * @return {*}
     */
    tablesList() {
      return this.wait("tablesList", async () => {
        return (await DbTable.query().where({db_id: this.block?.properties?.dbId || 0}).get()).map(d => ({
          value: d.id,
          label: d.name
        }));
      }, []);
    },

    /**
     * Get fields list
     * @return {*}
     */
    fieldsList() {
      return this.wait("fieldsList", DbTableField.query().where({table_id: this.block?.properties?.tableId || 0}).get(), []);
    }
  }
}

</script>
