import {AppIntegration} from "@/../../common/db/AppIntegration.js"

export const integrationsMixin = {

    data: () => ({
        app_id: false,
        module_id: false,
        isReady: false,
        properties: {},
        propertiesObj: false
    }),

    /**
     * On created
     * @return {Promise<void>}
     */
    async created() {

        // Get app id from the route
        this.app_id = this.$route.params.app_id
        this.module_id = this.$route.params.module_id

        // Subscribe for integration
        await AppIntegration.remote().subscribe('app-integration-by-name', {
            module_id: this.module_id,
            name: this.integration.name
        })

        // Load properties
        this.propertiesObj = await AppIntegration.query().where({module_id: this.module_id, name: this.integration.name}).first();

        // Set properties
        this.properties = this.propertiesObj?.props || {}

        // Set ready
        this.isReady = true
    },

    methods: {

        /**
         * Save properties
         */
        async saveProperties() {

            // Save properties
            this.propertiesObj = await AppIntegration.remote().save({
                id: this.propertiesObj?.id,
                app_id: this.app_id,
                module_id: this.module_id,
                unique_id: `${this.integration.name}-${this.app_id}`,
                name: this.integration.name,
                type: this.integration.type,
                props: this.properties
            })
        }
    }
}
