<template>
  <div class="ai-designer col column dg-media">
    <div class="column col fragment-editor-cmp dg-content-center dg-items-center">
      <div class="preview column col" :style="{width: width+'px'}">
        <iframe frameborder="0" class="col" :srcdoc="fragmentSource" :key="upKey"/>
      </div>
    </div>
    <div>
      <q-input v-model="prompt" title="Prompt" placeholder="Enter prompt">
        <template v-slot:append>
          <q-btn v-if="fragmentSource" label="Modify" flat color="primary" icon="draw" @click="generateCode(true)"/>
          <q-btn label="Generate" flat color="primary" icon="draw" @click="generateCode(false)"/>
          <!--q-btn label="SAve" flat color="accent" icon="save" @click="saveCode"/!-->
        </template>
      </q-input>
    </div>
  </div>
</template>

<script>

export default {
  name: "AiDesignGenerator",
  components: {},
  props: {
    width: {
      type: Number,
      default: 400
    },
    modelValue: {
      type: Object,
      default: () => ({})
    },
  },
  data: () => ({
    prompt: "",
    fragmentSource: "",
    upKey: 0
  }),
  created() {
    //this.fragmentSource = this.modelValue
  },

  methods: {

    /**
     * Save code
     */
    saveCode() {
      //this.$emit('update:modelValue', this.fragmentSource)
      this.$emit('close');
    },

    /**
     * Call controller method that will generate json code according to prompt
     * and return new json schema to be placed as fragment source
     */
    async generateCode(modify = false) {

      // Set loading
      this.generationInProgress = true

      try {

        // Show loading
        this.$q.loading.show()

        // Call controller method
        const code = await this.app.client.call('ai-designer', 'generateDesign', this.$route.params.module_id, modify ? this.fragmentSource : "", this.prompt)

        if(code?.length) {
          this.fragmentSource = code
        } else {
          this.$q.notify({
            message: 'No code generated',
            color: 'negative',
            position: 'top'
          })
        }

        // Update key
        this.upKey++
      } catch (ex) {
        console.error(ex)
      } finally {
        // Hide loading
        this.$q.loading.hide()
      }

      // Reset loading
      this.generationInProgress = false

      // Reset prompt
      //this.prompt = ''
    },

  }
}

</script>

<style lang="scss">

.ai-designer {

  .preview {
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 10px;
    height: 100%;
  }
}

</style>
