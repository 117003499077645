<template>
  <div>

    <div class="q-pa-sm bg-grey-2 q-mt-sm">
      <value-selector v-model="currentValue.firstOperand" :block-id="blockId" title="First operand" :parent-diagram-id="parentDiagramId" :app-id="appId" :module-id="moduleId"/>
    </div>

    <q-select label="Compare operation" v-model="currentValue.operation" :options="globals.options.comparison_operations" emit-value map-options/>

    <div class="q-pa-sm bg-grey-2 q-mt-sm">
      <value-selector v-model="currentValue.secondOperand" :block-id="blockId" title="Second operand" :parent-diagram-id="parentDiagramId" :app-id="appId" :module-id="moduleId"/>
    </div>

    <q-separator spaced/>
    <div class="q-pa-sm bg-green-1 q-mt-sm">
    <value-selector
      v-model="currentValue.trueValue"
      :block-id="blockId"
      :return-options="returnOptions"
      :only-var-type="onlyVarType"
      title="Success value"
      :parent-diagram-id="parentDiagramId"
      :app-id="appId"
      :module-id="moduleId"
      :value-type="valueType"
    />
    </div>

    <div class="q-pa-sm bg-red-1 q-mt-sm">
    <value-selector
      v-model="currentValue.elseValue"
      :block-id="blockId"
      :return-options="returnOptions"
      :only-var-type="onlyVarType"
      title="Else value"
      :parent-diagram-id="parentDiagramId"
      :app-id="appId"
      :module-id="moduleId"
      :value-type="valueType"
    />
    </div>

  </div>
</template>

<script>

import ValueSelector from "@/components/ValueSelector/ValueSelector.vue";

export default {
  name: "ValueCondition",
  components: {ValueSelector},
  props: {
    parentDiagramId: {},
    returnOptions: {},
    onlyVarType: {},
    blockId: {},
    appId: {
      default: false
    },
    moduleId: {
      default: false
    },
    modelValue: {
      default: null
    },
    valueType: {
      default: false
    }
  },

  data: () => ({
    currentValue: {}
  }),

  created() {
    // Init value
    if(this.modelValue) this.currentValue = this.modelValue

    // Add watcher
    this.$watch('currentValue', function (newVal) {
      this.$emit('update:modelValue', newVal)
    }, {deep: true})
  },
}

</script>
