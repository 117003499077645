/**
 * Array of validation rules.
 * Each rule is an object with a `value` and `label` property.
 * @type {Array<{value: string, label: string}>}
 */
const validationRules = [
  {value: 'required', label: 'Required'},
  {value: 'email', label: 'Email'},
  {value: 'numeric', label: 'Numeric'},
  {value: 'minLength', label: 'Min length'},
  {value: 'maxLength', label: 'Max length'},
  {value: 'date', label: 'Date'},
];

/**
 * Vue.js mixin for validatable form fields.
 * Provides computed properties related to validation rules.
 */
export default {
  computed: {
    /**
     * Returns an array of validation rules that are not already used in the current block.
     * @returns {Array<{value: string, label: string}>}
     */
    validationAvailableRules() {
      return validationRules.filter(
        (v) => !(this.block?.properties?.validationRules?.items || []).some((r) => r.rule === v.value),
      )
    },

    /**
     * Returns a string that concatenates the `value` property of all available validation rules, separated by ''.
     * @returns {string}
     */
    validationRulesKey() {
      return this.validationAvailableRules.map((v) => v.value).join('');
    },

    /**
     * Returns an array of objects representing the schema for validation rules.
     * Each object in the array represents a field in the schema.
     * @returns {Array<{name: string, title?: string, type: string, options?: Array<{value: string, label: string}>, displayAllow?: Function}>}
     */
    validationSchema() {
      return [
        {
          name: "rule",
          title: 'Rule',
          type: "select",
          options: this.validationAvailableRules,
        },
        {
          name: "length",
          type: "string",
          displayAllow(item) {
            return ['minLength', 'maxLength'].includes(item?.rule)
          }
        },
      ];
    },
  },
}
