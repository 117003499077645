<template>
  <ab-flow-base-cmp class="three-scene-cmp dg-extended" :draggable="false" :block="block" :is_container="true">
    <div ref="container"></div>

    <q-resize-observer @resize="onResize" />

    <ab-flow-components-renderer
      v-if="isInitialized"
      :items="children"
    />
  </ab-flow-base-cmp>
</template>

<script>
import * as THREE from 'three';
import AbFlowComponentsRenderer from "ab-flow-designer/src/components/Designer/AbFlowComponentsRenderer";
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import {provide, shallowRef} from 'vue';

export default {
  // Define the components used in this component
  components: {AbFlowComponentsRenderer, AbFlowBaseCmp},
  // Define the mixins used in this component
  mixins: [renderMixins],
  // Define the props passed to this component
  props: ['block'],
  // Define the name of this component
  name: "ThreeDSceneEditorCmp",

  // Define the injected properties
  inject: {
    // The canvas used for rendering
    canvas: {
      default: null
    },
    // The data injected into this component
    injectData: {
      default: {}
    },
  },

  // Setup function for the component
  setup() {
    // Define the scene, renderer, camera, and animation mixers
    const scene = shallowRef(null);
    const renderer = shallowRef(null);
    const camera = shallowRef(null);
    const animationMixers = shallowRef([]);

    // Function to add an animation mixer
    const addAnimationMixer = (mixer) => {
      animationMixers.value.push(mixer);
    }

    // Provide the scene and addAnimationMixer function to the component
    provide('scene', scene);
    provide('addAnimationMixer', addAnimationMixer);

    // Return the scene, renderer, camera, animation mixers, and addAnimationMixer function
    return { scene, renderer, camera, animationMixers, addAnimationMixer }
  },

  // Data function for the component
  data() {
    return {
      // Whether the component is initialized
      isInitialized: false,
      // The clock used for animations
      clock: new THREE.Clock(),
      // The raycaster used for mouse clicks
      raycaster: new THREE.Raycaster(),
      // The mouse position
      mouse: new THREE.Vector2(1, 1),
      // The size of the component
      size: {width: 100, height: 100},
    };
  },

  // Computed properties for the component
  computed: {
    /**
     * Children list
     * @return {*}
     */
    children() {
      // Return regular children
      return this.block.children
    },
    // The background color of the block
    bgColor() {
      return this?.block?.properties?.bgColor || '#eee';
    },
    // Compute the position of the camera
    cameraPosition() {
      return [
        this.block?.properties?.cameraPositionX || 0,
        this.block?.properties?.cameraPositionY || 3,
        this.block?.properties?.cameraPositionZ || 10,
      ];
    },
    // Compute the look at position of the camera
    cameraLookAt() {
      return [
        this.block?.properties?.cameraLookAtX || 0,
        this.block?.properties?.cameraLookAtY || 5,
        this.block?.properties?.cameraLookAtZ || 0,
      ];
    },
  },

  // Methods for the component
  methods: {
    // Function to handle resize events
    onResize ({width, height}) {
      this.size.width = width;
      this.size.height = height;
    },

    // Function to handle mouse click events
    onMouseClick(e) {
      // Calculate the mouse position
      this.mouse.x = (e.offsetX / this.size.width) * 2 - 1;
      this.mouse.y = -(e.offsetY / this.size.height) * 2 + 1;

      // Set the raycaster from the camera
      this.raycaster.setFromCamera(this.mouse, this.camera);

      // Intersect the raycaster with the scene children
      let intersects = this.raycaster.intersectObjects(this.scene.children, true);

      // Handle each intersect
      intersects.forEach((hit) => {
        let object = hit.object;

        // Find the parent object with a blockId
        while (object.parent && !object.userData.blockId) {
          object = object.parent;
        }

        // If the object has a blockId, select it
        if (object.userData.blockId) {
          e.stopPropagation();

          this.designer.selectObject(object.userData.blockId);
        }
      });
    },

    // Function to initialize the scene
    initScene() {
      // Create the scene
      this.scene = new THREE.Scene();
      this.scene.background = new THREE.Color(this.bgColor);

      // Create the camera
      this.camera = new THREE.PerspectiveCamera(75, this.size.width / this.size.height, 0.1, 1000);
      this.camera.position.set(...this.cameraPosition)
      this.camera.lookAt(...this.cameraLookAt);

      // Add lights to the scene
      const ambientLight = new THREE.AmbientLight(0xffffff, 2);
      this.scene.add(ambientLight);

      const light = new THREE.PointLight(0xffffff, 400);
      light.position.set(5, 20, 0);
      light.shadow.mapSize.width = 1024;
      light.shadow.mapSize.height = 1024;
      light.castShadow = true;
      this.scene.add(light);

      // Create the renderer
      this.renderer = new THREE.WebGLRenderer({antialias:true});
      this.renderer.setPixelRatio(window.devicePixelRatio);
      this.renderer.setSize(this.size.width, this.size.height);
      this.$refs.container.appendChild(this.renderer.domElement);

      // Start the animation
      this.animate();

      // Add the click event listener
      this.$refs.container.addEventListener('click', this.onMouseClick);

      // Set the component as initialized
      this.isInitialized = true;
    },
    // Function to animate the scene
    animate(t) {
      // Request the next animation frame
      requestAnimationFrame(this.animate);

      // Get the delta time
      const delta = this.clock.getDelta();

      // Update each animation mixer
      this.animationMixers.forEach((mixer) => {
        mixer.update(delta);
      });

      // Render each object in the scene
      this.scene.traverse((obj) => {
        if (obj.render) obj.render(t)
      });

      // Render the scene
      this.renderer.render(this.scene, this.camera);
    },
  },
  // Watchers for the component
  watch: {
    // Watch the background color and update the scene background when it changes
    bgColor(val) {
      this.scene.background = new THREE.Color(val);
    },
    // Watch the size and update the camera and renderer when it changes
    size: {
      handler() {
        this.camera.aspect = this.size.width / this.size.height;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.size.width, this.size.height);
      },
      deep: true,
    },
    // Watch the camera position and update the camera when it changes
    cameraPosition() {
      this.camera.position.set(...this.cameraPosition)
    },
    // Watch the camera look at position and update the camera when it changes
    cameraLookAt() {
      this.camera.lookAt(...this.cameraLookAt);
    },
  },

  // Lifecycle hook for when the component is mounted
  mounted() {
    try {
      this.initScene();
    } catch (e) {
      console.error('Error initializing scene:', e);
    }
  },
}
</script>

<style scoped lang="scss">
.three-scene-cmp {
  position: relative;
}
</style>
