<template>

  <div>
    <ui-prop-select title="State" name="state" default-value="off" :block="block" :options="states"/>
  </div>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropSelect from '@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue';

export default {
  mixins: [propertiesMixins],
  components: {UiPropSelect},
  props: ['block'],
  name: "KeepAwakePropsCmp",

  data() {
    return {
      states: [
        {label: 'On', value: 1},
        {label: 'Off', value: 0},
      ],
    };
  },
}

</script>
