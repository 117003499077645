<template>
  <ui-prop-value-selector :block="block" name="dataSource" title="Data source" :is-array="true" :only-var-type="['object', 'db-record', 'options', 'routing']" :parent-diagram-id="diagram_id"/>

  <ui-prop-checkbox name="dataIsWritable" title="Children can change the data" :block="block" :true-value="1" :false-value="0" :default-value="0"/>

  <ui-prop-value-selector
    :block="block"
    title="Variable to save selected item"
    name="varToSaveSelectedItem"
    value-type="setter"
    :parent-diagram-id="diagram_id"
  />

  <ui-dynamic-props :default-value="{}" :block="block" :product_id="product_id" :module_id="module_id" />
</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import {componentsMixin} from "@/components/DiagramDesigner/Editor/components/componentsMixin";
import UiPropCheckbox from "@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue";
import UiDynamicProps from '@/components/DiagramDesigner/Editor/properties/UiDynamicProps.vue';

export default {
  mixins: [propertiesMixins, componentsMixin],
  name: "ContentSwiperStackPropsCmp",
  props: ['block'],
  data: () => ({
    tab: "props",
  }),
  components: {UiDynamicProps, UiPropCheckbox, UiPropValueSelector},
}

</script>

