import {Diagram} from '../../../../../../common/db/Diagram';
import {treeHelper} from '../../../../../../common/utils/treeHelper';

/**
 * Service for handling operations related to diagrams.
 */
export class DiagramService {
  /**
   * Retrieves a list of diagrams that invoke a specific diagram component.
   *
   * @param {number} moduleId - The ID of the module.
   * @param {number} diagramId - The ID of the diagram component to search for.
   * @returns {Promise<Array>} A promise that resolves to an array of diagrams invoking the specified diagram component.
   */
  static async getDiagramInvocationList(moduleId, diagramId) {
    const diagrams = await Diagram.query().where({module_id: moduleId, status: 'active'}).get();

    const parentsDiagrams = new Set();

    for (const diagram of diagrams) {
      treeHelper.goDeeper(diagram?.source || {}, (tree) => {
        if (tree?.type === 'DiagramComponent' && tree?.properties?.diagramComponentId === diagramId) {
          parentsDiagrams.add(diagram);

          return true;
        }

        if (tree?.type === `Widget:${diagramId}`) {
          parentsDiagrams.add(diagram);

          return true;
        }
      });
    }

    return Array.from(parentsDiagrams);
  }
}
