<template>

  <ui-prop-section title="General context">
    <ui-prop-select :block="block" name="gcOperation" title="Operation" :options="globals.options.contextWindowOperations" default-value="add"/>
    <ui-prop-dynamic-string :block="block" title="Instructions for adding to the context" name="gcContext" untranslatable :multiple="true"/>
  </ui-prop-section>

  <!--ui-prop-params-list :block="block" name="contextVars" title="Context variables"/!-->

  <ui-prop-section title="Conversation history">
    <ui-prop-select :block="block" name="chOperation" title="Operation" :options="globals.options.contextWindowOperations"/>
    <ui-prop-dynamic-string :block="block" title="Instructions for adding to the context" name="chContext" untranslatable :multiple="true"/>
  </ui-prop-section>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropDynamicString from "@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";
import UiPropSection from "@/components/DiagramDesigner/Editor/properties/UiPropSection.vue";

export default {
  mixins: [propertiesMixins],
  components: {UiPropSection, UiPropSelect, UiPropDynamicString},
  props: ['block'],
  name: "ChatBotAiContextPropsCmp",
  data() {
    return {
    }
  },
}

</script>
