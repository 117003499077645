import {BaseComponentProcessor} from "@/components/DiagramDesigner/Editor/components/BaseComponentProcessor";
import {StorageNode} from "@/../../common/db/StorageNode"

export class WidgetProcessor extends BaseComponentProcessor {

    /**
     * Processor constructor
     * @param props
     */
    constructor(...props) {
        super(...props);
    }

    /**
     * Get arguments
     */

    async getArguments() {

        // Get id from type
        const widgetId = this.block.type?.split(":")?.[1]

        // Load child diagram
        return await StorageNode.getArguments(this.context.module_id, 'diagram-'+widgetId);
    }

}
