<template>

  <ab-flow-base-cmp class="day-calendar-cmp" :block="block" :class="classesString" :style="stylesString">

    <q-scroll-area class="daily-calendar col">
      <div class="calendar-hours" :style="cssVars">
        <div v-for="hour in hours" :key="hour" class="hour-slot">

          <div class="hour-label">{{ formatHour(hour) }}</div>

          <div class="hour-tasks">
            <div
              v-for="task in getTasksForHour(hour)"
              :key="task.id"
              class="task"
              :class="[`task_${task.status}`]"
              :style="getTaskStyles(task)"
            >
              <div class="task__title" v-text="task.title" />
              <div class="task__time">
                {{ formatTime(task.start_time) }} - {{ formatTime(task.end_time) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </q-scroll-area>

    <div class="flex connector-center events">
      <ab-flow-link-connector :block="block" type="output" event="add-task"/>
      <ab-flow-link-connector :block="block" type="output" event="task-details"/>
    </div>

  </ab-flow-base-cmp>

</template>

<script>
import moment from 'moment';
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";
import {renderMixins} from "../../../renderMixins";

export default {
  components: {AbFlowBaseCmp,AbFlowLinkConnector},
  inject: ['canvas'],
  mixins: [renderMixins],
  props: ['block'],
  name: "DayCalendarEditorCmp",
  data() {
    return {
      hours: Array.from({ length: 24 }, (_, i) => i),
      eventsList: [
        {
          title: 'Active event',
          start_time: '01:00',
          end_time: '02:00',
          status: 'active',
        },
        {
          title: 'Completed event',
          start_time: '03:00',
          end_time: '04:00',
          status: 'completed',
        },
        {
          title: 'Skipped event',
          start_time: '05:00',
          end_time: '06:00',
          status: 'skipped',
        },
      ],
    };
  },

  computed: {
    /**
     * Computed property to generate CSS variables for task colors and backgrounds.
     * These variables are based on the block properties or default values.
     *
     * @returns {Object} CSS variables for task colors and backgrounds.
     */
    cssVars() {
      return {
        '--task-color-active': `var(--foreground-color-${this.block?.properties?.taskColorActive || 'light'})`,
        '--task-color-completed': `var(--foreground-color-${this.block?.properties?.taskColorCompleted || 'light'})`,
        '--task-color-skipped': `var(--foreground-color-${this.block?.properties?.taskColorSkipped || 'light'})`,

        '--task-bg-active': `var(--background-color-${this.block?.properties?.taskBgActive || 'info'})`,
        '--task-bg-completed': `var(--background-color-${this.block?.properties?.taskBgCompleted || 'success'})`,
        '--task-bg-skipped': `var(--background-color-${this.block?.properties?.taskBgSkipped || 'secondary'})`,
      }
    },

    /**
     * Computed property to determine if the 24-hour format is enabled.
     *
     * @returns {boolean} True if 24-hour format is enabled, otherwise false.
     */
    enable24HourFormat() {
      return this.block?.properties?.enable24HourFormat === 1;
    },
  },

  methods: {
    /**
     * Formats the given hour based on the 24-hour format setting.
     *
     * @param {number} hour - The hour to format (0-23).
     * @returns {string} The formatted hour string.
     */
    formatHour(hour) {
      if (this.enable24HourFormat) {
        return `${String(hour).padStart(2, '0')}:${hour < 23 ? '00' : '59'}`;
      }

      return hour === 0 ? '12 AM' :
        hour < 12 ? `${hour} AM` :
          hour === 12 ? '12 PM' :
            `${hour - 12} PM`;
    },

    /**
     * Formats the given time string based on the 24-hour format setting.
     *
     * @param {string} time - The time string to format (in "HH:mm" format).
     * @returns {string} The formatted time string.
     */
    formatTime(time) {
      return moment(time, "HH:mm").format(this.enable24HourFormat ? "HH:mm" : "hh:mm A");
    },

    /**
     * Retrieves the tasks scheduled for a specific hour.
     *
     * @param {number} hour - The hour for which to retrieve tasks (0-23).
     * @returns {Array} The list of tasks scheduled for the given hour.
     */
    getTasksForHour(hour) {
      return this.eventsList.filter(task => {
        const [taskHour] = task.start_time.split(':').map(Number);

        return taskHour === hour;
      });
    },

    /**
     * Retrieves the styles for a given task.
     *
     * @param {Object} task - The task object containing start and end times.
     * @param {string} task.start_time - The start time of the task in "HH:mm" format.
     * @param {string} task.end_time - The end time of the task in "HH:mm" format.
     * @returns {Object} The styles for positioning and sizing the task element.
     */
    getTaskStyles(task) {
      const [startHour, startMinute] = task.start_time.split(':').map(Number);
      const [endHour, endMinute] = task.end_time.split(':').map(Number);

      const startPercentage = (startMinute / 60) * 100;
      const durationHours = endHour - startHour;
      const durationMinutes = endMinute - startMinute;
      const totalDurationMinutes = durationHours * 60 + durationMinutes;
      const heightPercentage = (totalDurationMinutes / 60) * 100;

      return {
        top: `${startPercentage}%`,
        height: `${heightPercentage}%`
      };
    }
  },
}
</script>

<style lang="scss">

.day-calendar-cmp {
  flex-direction: column;
  position: relative;

  .events {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    display: flex;
    column-gap: 5px;
  }

  .calendar-hours {
    height: 100%;
    position: relative;
  }

  .hour-slot {
    height: calc(100% / 24);
    border-bottom: 1px solid #f0f0f0;
    /* Highlight slots with tasks */
    /* Example: .hour-slot.has-tasks { background-color: #f9f9f9; } */
    display: flex;
  }

  .hour-label {
    width: 70px;
    padding: 5px;
    border-right: 1px solid #f0f0f0;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .hour-tasks {
    flex-grow: 1;
    position: relative;
    flex-direction: row;
    display: flex;
  }

  .task {
    flex-grow: 1;
    background-color: #e6f2ff;
    border: 1px solid #3399ff;
    border-radius: 4px;
    padding: 5px;
    box-sizing: border-box;
    overflow: hidden;

    &_active {
      color: var(--task-color-active);
      background-color: var(--task-bg-active);
      border-color: var(--task-bg-active);
    }

    &_completed {
      color: var(--task-color-completed);
      background-color: var(--task-bg-completed);
      border-color: var(--task-bg-completed);
    }

    &_skipped {
      color: var(--task-color-skipped);
      background-color: var(--task-bg-skipped);
      border-color: var(--task-bg-skipped);
    }

    &__title {
      font-weight: bold;
      margin-bottom: 3px;
    }

    &__time {
      font-size: 10px;
      color: inherit;
      /* Display task time range (e.g., 9:00 AM - 10:00 AM) */
    }
  }
}

</style>
