<template>

  <div class="panel-styles-manager">
    <q-dialog ref="editStyle">
      <q-card class="full-width full-height column">
        <q-card-section class="bg-primary text-white">
          Panel styles editor
        </q-card-section>

        <q-card-section class="col column">
          <div class="q-mb-md q-pa-md shadow-4  dg-media">
            <panel-style-preview :block="selectedPanel" class="panel-item"/>
          </div>

          <div class="col">
            <q-scroll-area style="height: 100%">
              <ui-prop-string :block="selectedPanel" name="name" title="Style name"/>
              <ui-dynamic-props :block="Object.assign({type: 'Container'}, selectedPanel)" :product_id="appId" :module_id="moduleId"/>
            </q-scroll-area>
          </div>

        </q-card-section>
      </q-card>
    </q-dialog>

    <div class="bg-grey-4 row q-pa-sm items-center">
      <div>Panel styles</div>
      <q-space/>
      <q-btn flat icon="add" @click="addPanel">Add</q-btn>
    </div>

    <div class="q-gutter-sm row wrap dg-media">
      <div v-for="(panel, k) of panels" :key="k" :class="{'panel-item': true,'selected': selectedPanel === panel}">
        <panel-style-preview :block="panel"
                             @click="selectedPanel = panel; $refs.editStyle.show();"
        />
        <q-btn icon="delete" flat @click="deletePanel(k)"/>
      </div>
    </div>

  </div>
</template>

<script>

import PanelStylePreview from "@/pages/workspace/modules/styles/panel/PanelStylePreview.vue";
import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps.vue";
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";
import {nanoid} from "nanoid";

export default {
  name: "PanelStyles",
  components: {UiPropString, UiDynamicProps, PanelStylePreview},
  provide() {
    return {
      canvas: this
    }
  },
  data: () => ({
    selectedPanel: false,
    moduleId: false,
    appId: false,
  }),
  props: {
    styles: {
      default: () => ({})
    }
  },
  created() {
    // eslint-disable-next-line
    if (!this.styles.panelStyles) this.styles.panelStyles = []

    // Get module id
    this.moduleId = this.$route.params.module_id
    this.appId = this.$route.params.app_id
  },
  computed: {
    panels() {
      // Get panel styles
      return this.styles?.panelStyles || []
    }
  },

  methods: {
    /**
     * Delete panel
     */
    deletePanel(k) {

      // Ask confirmation
      this.$q.dialog({
        title: 'Delete panel',
        message: `Are you sure you want to delete panel "${this.selectedPanel?.properties?.name}"?`,
        cancel: true,
        persistent: true
      }).onOk(() => {
        this.panels.splice(k, 1)
      })
    },

    /**
     * Set block property
     * @param block_id
     * @param type
     * @param data
     */
    setProperty(block_id, type, data) {

      // Get component
      const cmp = this.selectedPanel

      // Get or create properties
      if (!cmp.properties) cmp.properties = {}

      // Delete items
      if (data === null) {
        const opts = {}
        for (const pr of Object.keys(cmp.properties)) if (pr !== type) opts[pr] = cmp.properties[pr]
        cmp.properties = opts
      } else {
        // Set property
        cmp.properties[type] = data
      }
    },


    /**
     * Add panel
     */
    addPanel() {
      // Ask for panel styles name
      this.$q.dialog({
        title: 'Add panel',
        message: 'Enter panel name',
        prompt: {
          model: '',
          type: 'text'
        },
        cancel: true
      }).onOk((name) => {
        // Add panel
        // eslint-disable-next-line
        this.styles.panelStyles.push({
          properties: {
            id: nanoid(10),
            name
          }
        })
      })
    }
  }
}

</script>

<style lang="scss">
.panel-styles-manager {

  .panel-item {
    cursor: pointer;
    padding: 10px;
    margin: 10px;
    border: 1px dashed #ddd;
    min-width: 200px;
    min-height: 100px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;

    &.selected {
      border-color: red;
    }
  }
}
</style>
