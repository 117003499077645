<template>

  <div>
    <ui-prop-string name="title" title="Loading title" :block="block" />
    <ui-prop-select name="action" title="Loader action" :block="block" default-value="show" :options="showModes"/>
  </div>

</template>

<script>

import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";

export default {
  mixins: [propertiesMixins],
  components: {UiPropString, UiPropSelect},
  props: ['block'],
  name: "LoadingPropsCmp",
  data: () => ({
    showModes: [{value: "show", label: "Show loader"}, {value: "hide", label: "Hide loader"} ]
  })
}

</script>
