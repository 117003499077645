<template>
  <q-page class="q-pa-md" v-if="isReady">

    <div class="text-h6 q-mb-md">
      SengGrid settings
    </div>
    <q-form @submit="saveProperties">

      <q-input
        label="Api key"
        v-model="properties.apiKey"
      />

      <q-separator/>

      <div class="row">
        <q-btn type="submit" label="Save" color="primary" class="q-mt-md"/>
      </div>
    </q-form>

  </q-page>
</template>

<script>
import {AppModule} from '../../../../../../common/db/AppModule';
import {integrationsMixin} from "@/pages/workspace/integrations/integrationsMixin";

export default {
  mixins: [integrationsMixin],

  name: "SendGridIntegrationSettings",

  data() {
    return {};
  },

  computed: {
    /**
     * Return integration name
     */
    integration: () => ({name: "sendgrid", type: "sendgrid"}),

    /**
     * Current module
     * @return {*}
     */
    currentModule() {
      return this.wait("currentModule", AppModule.find(this.module_id), {})
    },
  },

  methods: {},
}

</script>
