<template>
  <div>
    <ui-prop-dynamic-string :block="block" name="productId" title="Product Id" untranslatable />
  </div>

</template>

<script>
import {adMixins} from "@/components/DiagramDesigner/Editor/components/UI/Monetization/adMixins";
import UiPropDynamicString from "@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue";

export default {
  mixins: [adMixins],
  components: {UiPropDynamicString},
  props: ['block'],
  name: "GetInAppPurchasedAmountPropsCmp",
}

</script>
