/**
 * User purchase model
 */
import {AccessManager} from '../utils/AccessManager.js';
import {AccessModel} from './AccessModel.js';
import {AppModule} from './AppModule.js';

export class Localization extends AccessModel {
    static entity = 'localizations'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        module_id: "int",
        alias: "string",
        type: "string",
        context: "string",
        max_length: "int",
    }

    /**
     * Handles server events by clearing the cache for the associated module.
     *
     * @async
     * @function serverEvent
     * @returns {Promise<void>}
     */
    async serverEvent() {
        const cacheManager = this.constructor?.applicationClient?.plugins?.schemaCache || null;

        if (!cacheManager) {
            return;
        }

        const row = await Localization.find(this.id);

        if (!row?.id) {
            return;
        }

        const module = await AppModule.find(row.module_id);

        if (module?.app_id) {
            cacheManager.clearCache(module.app_id);
        }
    }

    /**
     * Prepare data according to users access level
     * @return {boolean}
     * @param row
     * @param mode
     */
    static async prepareData(row, mode) {
        const accessManager = new AccessManager(this);

        return await accessManager.checkLocalizationAccess(await this.getAccessId(row, 'module_id'), row?.id) ? row : false;
    }

    /**
     * Functions list
     */
    async channels() {
        return {
            // Localizations for module
            'module-localizations': {
                subscribe: ({module_id}) => module_id,
                init: async ({module_id}) => {
                    return this.query().where(Array.isArray(module_id) ? Localization.sql().in('module_id', module_id) : {module_id}).get()
                },
            },
            // All localizations
            'localizations': {
                subscribe: () => 1,
                init: async () => this.query().get(),
            }
        }
    }
}
