<template>
  <ui-prop-string name="alias" title="Children block alias" :block="block"/>
</template>

<script>
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";
export default {
  name: "ChildrenContainerPropsCmp",
  props: ['block'],
  components: {UiPropString},
  created() {
  }
}

</script>

