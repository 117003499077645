<template>
  <q-page class="q-pa-md" v-if="isReady">

    <div class="text-h6">
      Ads settings
    </div>
    <q-form @submit="saveProperties">

      <div>
        <q-select
          class="col-2"
          map-options
          emit-value
          label="Active ad provider"
          v-model="properties.adProvider"
          :options="adProviders"
        />
      </div>

      <template v-if="properties?.adProvider !== 'none'">
        <div class="row full-width bg-grey-2 q-pa-sm">
          <q-input class="col" label="Android Admob application id" v-model="properties.appId"/>
          <q-input class="col" label="iOS Admob application id" v-model="properties.iosAppId"/>
        </div>

        <div  v-if="properties.adProvider==='Cas'">
          <q-input class="col" label="CAS Android application id" v-model="properties.casAppId"/>
          <q-input class="col" label="CAS iOS application id" v-model="properties.casIosAppId"/>
          <media-picker :product-id="app_id" :module-id="module_id" media-type="cas-cred" label="CAS App config" v-model="properties.casConfigFile"/>
        </div>

        <div class="row full-width q-gutter-x-md" v-if="properties.adProvider==='Appodeal'">
        <q-input class="col" label="Appodeal Android application Key" v-model="properties.appodealAppId"/>
        <q-input class="col" label="Appodeal iOS application Key" v-model="properties.appodealIosAppId"/>
        </div>

        <div class="q-ma-sm">

          <div class="q-mb-md row text-h6 items-center">
            Placements list:
            <q-space/>
            <q-btn label="Add placement" color="primary" class="q-mt-md" @click="addPlacement(k)" icon="add"/>
          </div>

          <q-banner v-if="!placements.length" class="bg-grey-3 q-ma-sm">
            No placements
          </q-banner>

          <q-card v-for="(placement, k) of placements" :key="k">
            <q-card-section class="row q-gutter-sm">
              <q-select class="col-2" map-options emit-value label="Placement type" v-model="placement.type" :options="globals.options.adTypes"/>
              <q-input label="Placement name" v-model="placement.name" class="col"/>
              <q-input class="col-3" label="Android placement id" v-model="placement.admobId"/>
              <q-input class="col-3" label="iOS placement id" v-model="placement.iosAdmobId"/>
              <q-btn label="Delete" flat @click="deletePlacement(k)"/>
            </q-card-section>
          </q-card>

        </div>

        <q-toggle label="Ads works in test mode" true-value="1" false-value="0" v-model="properties.testMode"/>
      </template>

      <q-separator/>
      <q-btn type="submit" label="Save" color="primary" class="q-mt-md"/>
    </q-form>

  </q-page>
</template>

<script>

import {nanoid} from "nanoid";
import {integrationsMixin} from "@/pages/workspace/integrations/integrationsMixin";
import MediaPicker from "@/components/MediaGallery/MediaPicker.vue";

export default {
  components: {MediaPicker},
  mixins: [integrationsMixin],
  name: "AdmobIntegrationSettings",
  data: () => ({
    placement: {},
  }),

  methods: {

    /**
     * Delete placement by number
     */
    deletePlacement(num) {

      this.$q.dialog({
        title: 'Delete placement',
        message: 'Are you sure you want to delete this placement?',
        cancel: true,
        persistent: true
      }).onOk(() => {

        // Remove placement from an array by number
        this.properties.placements.splice(num, 1)
      })
    },


    /**
     * Add new placement to list
     */
    addPlacement() {

      // Init placements
      if (!this.properties.placements) this.properties.placements = [];

      // Add new
      this.properties.placements.push({
        name: "",
        admobId: "",
        id: nanoid(10)
      })
    },
  },

  computed: {

    /**
     * Return integration name
     */
    integration: () => ({name: "admob", type: "ad-provider"}),

    /**
     * Return placements
     */
    placements() {
      return this.properties.placements || []
    },

    adProviders() {
      return [
        {value: 'none', label: 'None'},
        ...this.globals.options.adProviders,
      ];
    },
  }
}

</script>
