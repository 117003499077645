<template>

  <q-card>
    <q-card-section class="bg-primary text-white q-mt-sm">
      {{title}}
    </q-card-section>
    <q-card-section>
      <div class="q-gutter-sm">
        <div v-for="(keyPress, k) of currentKeyPress" :key="k" class="column q-pa-sm">

          <q-input v-model="keyPress.name" label="KeyPress name" class="col">
            <template v-slot:append>
              <q-btn icon="delete" size="sm" flat round @click="deleteKeyPress(k)"/>
            </template>
          </q-input>

        </div>

        <q-btn @click="addKeyPress" icon="add" label="Add keyPress" flat/>
      </div>
    </q-card-section>
  </q-card>



</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";

export default {
  mixins: [propertiesMixins],
  props: {
    manual: {
      default: false
    },
    block: {},
    title: {},
    name: {
      default: "keyEvents"
    }
  },
  name: "UiKeyPressProps",
  components: {},
  data: () => ({
    currentKeyPress: []
  }),
  created() {

    // Init value
    if(this.currentValue) this.currentKeyPress = this.currentValue

    // Add watcher
    this.$watch('currentKeyPress', function (newVal) {
      this.currentValue = newVal
    }, {deep: true})

  },

  methods: {

    /**
     * Add keyPress
     * @param e
     */
    addKeyPress() {
      // Add to keyPresss list
      this.currentKeyPress.push({
        name: "",
        mock_data: "{}"
      })
    },

    /**
     * Delete keyPress
     * @param k
     */
    deleteKeyPress(k) {
      console.log(k)
      this.currentKeyPress.splice(k,1);
    },
  },
}

</script>
