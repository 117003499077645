import {BaseComponentProcessor} from "@/components/DiagramDesigner/Editor/components/BaseComponentProcessor";
import {StorageNode} from "@/../../common/db/StorageNode"

/**
 * Code function processor
 */
export class CodeFunctionProcessor extends BaseComponentProcessor {

    /**
     * Processor constructor
     * @param props
     */
    constructor(...props) {
        super(...props);
    }

    /**
     * Get arguments
     */

    async getArguments() {

        // Storage block id block id
        const blId = `func-args-${this.block?.properties?.function}`

        // Load child diagram
        return await StorageNode.getArguments(this.context.module_id, blId, (node) => (node.parent_id === 0));
    }

}
