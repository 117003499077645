import {Diagram} from "@/../../common/db/Diagram"
import {App} from "@/../../common/db/App"
import {MediaGallery} from "@/../../common/db/MediaGallery"
import {CodeFunction} from "@/../../common/db/CodeFunction"
import {StorageNode} from "@/../../common/db/StorageNode"
import {DbModule} from "@/../../common/db/DbModule"
import {AppModule} from "@/../../common/db/AppModule"
import {AppRelease} from "@/../../common/db/AppRelease"
import {AppStyle} from "@/../../common/db/AppStyle"
import {UserApp} from "@/../../common/db/UserApp"
import {User} from "@/../../common/db/User"
import {AppIntegration} from "@/../../common/db/AppIntegration"
import {DbTable} from "@/../../common/db/DbTable"
import {DbTableField} from "@/../../common/db/DbTableField"
import {DbTableChannel} from "@/../../common/db/DbTableChannel"
import {ComponentBackup} from "@/../../common/db/ComponentBackup"
import {SchemaReleaseHistory} from "@/../../common/db/SchemaReleaseHistory"
import {AnalyticsFunnel} from "@/../../common/db/AnalyticsFunnel"
import {AnalyticsEvent} from "@/../../common/db/AnalyticsEvent"
import {WebBuild} from "@/../../common/db/WebBuild"
import {AnalyticsEventTemplate} from "@/../../common/db/AnalyticsEventTemplate"
import {Localization} from '../../common/db/Localization';
import {LocalizationMessage} from '../../common/db/LocalizationMessage';
import {DbTableIndex} from '../../common/db/DbTableIndex';

export default {

    // Save db
    save: async (data) => {
        console.log("Save", data.length)
    },

    // Load db
    load: async () => {
        console.log("Load data...")
        return false
    },

    webWasmFile: () => "/js/sql-wasm.wasm",

    models: [
        App,
        Diagram,
        MediaGallery,
        StorageNode,
        CodeFunction,
        DbModule,
        AppModule,
        AppRelease,
        AppStyle,
        AppIntegration,
        User,
        UserApp,
        WebBuild,
        AnalyticsEvent,
        AnalyticsEventTemplate,
        DbTable,
        SchemaReleaseHistory,
        DbTableField,
        AnalyticsFunnel,
        DbTableChannel,
        ComponentBackup,
        Localization,
        LocalizationMessage,
        DbTableIndex,
    ]
}
