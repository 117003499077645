<template>
  <div></div>
</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";

export default {
  mixins: [propertiesMixins],
  name: "UiPropHidden",
  props: ['value'],
  created() {
    this.$watch('value', (v) => {
      this.currentValue = v
    });
  }
}

</script>
