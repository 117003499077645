<template>
  <ui-prop-dynamic-string :block="block" title="Navigation link" name="navigationLink" untranslatable/>
  <ui-prop-dynamic-string :block="block" title="Title" name="windowTitle"/>
  <ui-prop-checkbox :block="block" title="Fullscreen" name="fullscreen" :true-value="1" :false-value="0" :default-value="0" />
  <ui-prop-checkbox :block="block" title="Open in a new browser window" name="openInBrowser" :true-value="1" :false-value="0" :default-value="0" />
</template>

<script>

import UiPropDynamicString from "@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue";
import UiPropCheckbox from '@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue';

export default {
  name: "DeviceNavigatorPropsCmp",
  props: ['block'],
  components: {UiPropDynamicString, UiPropCheckbox}
}

</script>

