<template>

  <q-dialog ref="dbStructure">
    <q-card>
      <q-card-section>
        <div class="text-h6">Required database tables</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <q-markup-table bordered dense>
          <thead>
          <tr>
            <th colspan="2">calendar_events</th>
          </tr>
          <tr>
            <th>Column</th>
            <th>Type</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <q-badge color="blue" label="id"/>
            </td>
            <td>Autoincrement/Autogenerated</td>
          </tr>
          <tr>
            <td>
              <q-badge color="blue" label="title"/>
            </td>
            <td>String</td>
          </tr>
          <tr>
            <td>
              <q-badge color="blue" label="description"/>
            </td>
            <td>Text</td>
          </tr>
          <tr>
            <td>
              <q-badge color="blue" label="start_time"/>
            </td>
            <td>String</td>
          </tr>
          <tr>
            <td>
              <q-badge color="blue" label="end_time"/>
            </td>
            <td>String</td>
          </tr>
          <tr>
            <td>
              <q-badge color="blue" label="recurrence_rules"/>
            </td>
            <td>JSON</td>
          </tr>
          <tr>
            <td>
              <q-badge color="blue" label="start_date"/>
            </td>
            <td>Datetime</td>
          </tr>
          </tbody>
        </q-markup-table>

        <q-markup-table class="q-mt-md" bordered dense>
          <thead>
          <tr>
            <th colspan="2">calendar_completed_events</th>
          </tr>
          <tr>
            <th>Column</th>
            <th>Type</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <q-badge color="blue" label="id"/>
            </td>
            <td>Autoincrement/Autogenerated</td>
          </tr>
          <tr>
            <td>
              <q-badge color="blue" label="event_id"/>
            </td>
            <td>Integer/String</td>
          </tr>
          <tr>
            <td>
              <q-badge color="blue" label="title"/>
            </td>
            <td>String</td>
          </tr>
          <tr>
            <td>
              <q-badge color="blue" label="description"/>
            </td>
            <td>Text</td>
          </tr>
          <tr>
            <td>
              <q-badge color="blue" label="start_time"/>
            </td>
            <td>String</td>
          </tr>
          <tr>
            <td>
              <q-badge color="blue" label="end_time"/>
            </td>
            <td>String</td>
          </tr>
          <tr>
            <td>
              <q-badge color="blue" label="start_date"/>
            </td>
            <td>Datetime</td>
          </tr>
          <tr>
            <td>
              <q-badge color="blue" label="status"/>
            </td>
            <td>String</td>
          </tr>
          </tbody>
        </q-markup-table>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="OK" color="primary" v-close-popup/>
      </q-card-actions>
    </q-card>
  </q-dialog>

  <q-dialog ref="recurrenceRules">
    <q-card>
      <q-card-section>
        <div class="text-h6">Recurrence rules structure</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <q-markup-table bordered dense>
          <thead>
          <tr>
            <th>Field</th>
            <th>Value</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <q-badge color="blue" label="cycle"/>
            </td>
            <td>none / week / month</td>
          </tr>
          <tr>
            <td>
              <q-badge color="blue" label="week_days"/>
            </td>
            <td>mo, tu, we, th, fr, sa, su</td>
          </tr>
          <tr>
            <td>
              <q-badge color="blue" label="month_days"/>
            </td>
            <td>1..31</td>
          </tr>
          <tr>
            <td>
              <q-badge color="blue" label="until_time"/>
            </td>
            <td>DateTime finish date</td>
          </tr>
          </tbody>
        </q-markup-table>
      </q-card-section>

      <q-card-actions align="right">
        <q-btn flat label="OK" color="primary" v-close-popup/>
      </q-card-actions>
    </q-card>
  </q-dialog>

  <div class="q-gutter-sm q-mb-md">
    <q-btn
      dense
      icon="question_mark"
      size="md"
      label="Required database tables"
      no-caps
      @click="$refs.dbStructure.show()"
    />

    <q-btn
      dense
      icon="question_mark"
      size="md"
      label="Recurrence rules structure"
      no-caps
      @click="$refs.recurrenceRules.show()"
    />
  </div>

  <ui-prop-value-selector :block="block" name="date" title="Date" only-var-type="datetime" :parent-diagram-id="diagram_id"/>

  <ui-prop-checkbox class="q-my-sm" name="enable24HourFormat" title="Enable 24-hour format" :block="block" :default-value="0"/>

  <q-list class="q-my-md">
    <q-expansion-item expand-separator label="Task: active" caption="Active task color settings">
      <q-card>
        <q-card-section>
          <ui-prop-select name="taskColorActive" title="Text color" :block="block" :options="moduleColors" default-value="light"/>

          <ui-prop-select name="taskBgActive" title="Background" :block="block" :options="moduleColors" default-value="info"/>
        </q-card-section>
      </q-card>
    </q-expansion-item>

    <q-expansion-item expand-separator label="Task: completed" caption="Completed task color settings">
      <q-card>
        <q-card-section>
          <ui-prop-select name="taskColorCompleted" title="Text color" :block="block" :options="moduleColors" default-value="light"/>

          <ui-prop-select name="taskBgCompleted" title="Background" :block="block" :options="moduleColors" default-value="success"/>
        </q-card-section>
      </q-card>
    </q-expansion-item>

    <q-expansion-item expand-separator label="Task: skipped" caption="Skipped task color settings">
      <q-card>
        <q-card-section>
          <ui-prop-select name="taskColorSkipped" title="Text color" :block="block" :options="moduleColors" default-value="light"/>

          <ui-prop-select name="taskBgSkipped" title="Background" :block="block" :options="moduleColors" default-value="secondary"/>
        </q-card-section>
      </q-card>
    </q-expansion-item>
  </q-list>

  <ui-dynamic-props :default-value="{}" :block="block" :product_id="product_id" :module_id="module_id" />

</template>

<script>
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";
import UiPropCheckbox from '@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue';

export default {
  mixins: [propertiesMixins],
  name: "DayCalendarPropsCmp",
  props: ['block'],
  components: {UiPropCheckbox, UiPropValueSelector, UiDynamicProps, UiPropSelect},
}

</script>

