<template>

  <ab-flow-base-cmp :block="block" :class="' web-browser-cmp ' + classesString" :style="stylesString">
    <iframe :src="url" class="col no-pointer-events"></iframe>
  </ab-flow-base-cmp>

</template>

<script>

import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";

export default {
  components: {AbFlowBaseCmp},
  mixins: [renderMixins],
  props: ['block'],
  name: "WebBrowserEditorCmp",
  computed: {

    /**
     * Value
     * @return {string}
     */
    url() {
      return this.getValue(this.block.properties?.url) || "https://google.com";
    },
  },
}

</script>

<style lang="scss">
.web-browser-cmp {
  position: relative;
}
</style>
