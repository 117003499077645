<template>

    <ab-flow-base-cmp :block="block" :class="' reach-text-cmp ' + classesString" :style="stylesString">
        <div class="reach-text" style="pointer-events: none;" v-html="getHtml"></div>
        <ab-flow-link-connector :block="block" class="reach-text-event" type="output" event="navigate"/>
    </ab-flow-base-cmp>

</template>

<script>

import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

export default {
    components: {AbFlowBaseCmp, AbFlowLinkConnector},
    mixins: [renderMixins],
    props: ['block'],
    name: "RichTextEditorCmp",
    computed: {

        /**
         * Value
         * @return {string}
         */
        getHtml() {
            return this.interpretString(this.block.properties?.html) || "Reach Text";
        },
    },
}

</script>

<style lang="scss">
.reach-text-cmp {
  position: relative;

  .reach-text {
    width: 100%;

    .ql-align-right {
      text-align: right;
    }

    .ql-align-center {
      text-align: center;
    }

    .ql-align-left {
      text-align: left;
    }
  }

  .output {
    position: absolute;
    left: 50%;
    top:50%;
  }
}
</style>
