<template>
  <icon-selector v-model="currentValue" :title="title"/>
</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import IconSelector from "@/components/IconSelector/IconSelector.vue";

export default {
  components: {IconSelector},
  mixins: [propertiesMixins],
  name: "UiPropIconSelector",
  props: ['options']
}

</script>
