<template>

  <div>
    <ui-prop-string name="title" title="Title" :block="block"/>

    <ui-prop-dynamic-string :multiple="true" :block="block" title="Text" name="text" untranslatable />

    <ui-prop-dynamic-string
      :multiple="true"
      :block="block"
      title="Instruction"
      name="instruction"
      untranslatable
      allow-outgoing-bindings
    />
  </div>

</template>

<script>
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";
import UiPropDynamicString from '@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue';

export default {
  components: {UiPropDynamicString, UiPropString},
  mixins: [propertiesMixins],
  props: ['block'],
  name: "TextGeneratorPropsCmp",
}

</script>
