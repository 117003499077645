export default {
  data() {
    return {
      /**
       * Object to manage tab communication.
       * @property {Object} tabCommunication - The tab communication object.
       * @property {BroadcastChannel|null} tabCommunication.channel - The communication channel.
       */
      tabCommunication: {
        channel: null,
      },
    }
  },

  methods: {
    /**
     * Sends a message to another tab.
     *
     * @param {string} type - The type of the message.
     * @param {Object|null} [content=null] - The content of the message.
     */
    sendMessageToTab(type, content = null) {
      if (!this.tabCommunication.channel) {
        return;
      }

      this.tabCommunication.channel.postMessage({ type, content });
    },

    /**
     * Handles incoming messages from other tabs.
     *
     * @param {MessageEvent} e - The message event.
     */
    handleTabMessage(e) {
      console.log('Message received from tab', e);

      console.error('You must implement the handleTabMessage method in your component');
    },
  },

  /**
   * Lifecycle hook called when the component is mounted.
   * Initializes the communication channel if it is not already set.
   */
  mounted() {
    if (this.tabCommunication.channel) {
      return;
    }

    this.tabCommunication.channel = new BroadcastChannel('tab-communication');

    this.tabCommunication.channel.addEventListener('message', this.handleTabMessage);
  },

  /**
   * Lifecycle hook called when the component is unmounted.
   * Cleans up the communication channel by removing event listeners and closing the channel.
   */
  unmounted() {
    if (this.tabCommunication.channel) {
      this.tabCommunication.channel.removeEventListener('message', this.handleTabMessage);

      this.tabCommunication.channel.close();
    }
  }
}
