<template>

  <div>
    <ui-prop-dynamic-string :block="block" name="route" title="Route name"/>
    <ui-prop-select :block="block" name="navigateMode" title="Navigation mode" :options="globals.options.routeNavigationModes" default-value="replace"/>

    <ui-prop-custom-list-props name="parameters" title="Route parameters" :block="block">
      <template v-slot:item="data">
        <q-input v-model="data.item.name" label="Parameter name"/>
        <value-selector v-model="data.item.value" :is-array="data.item.isArray" :title="data.item.name" :app-id="product_id" :only-var-type="data.item.type" :parent-diagram-id="diagram_id" :module-id="module_id"/>
      </template>
    </ui-prop-custom-list-props>


  </div>

</template>

<script>

import ValueSelector from "@/components/ValueSelector/ValueSelector.vue";
import UiPropCustomListProps from "@/components/DiagramDesigner/Editor/properties/UiPropCustomListProps.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";
import UiPropDynamicString from "@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue";

export default {
  mixins: [propertiesMixins],
  inject: ['product_id'],
  components: {UiPropDynamicString, UiPropSelect, UiPropCustomListProps, ValueSelector},
  props: ['block'],
  name: "NavigatePropsCmp",
  computed: {
  }
}

</script>
