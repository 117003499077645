<template>

  <div>
    <ui-prop-select :block="block" name="alarmType" title="Notification type" :options="alarmTypes"/>
  </div>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropSelect from '@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue';

export default {
  mixins: [propertiesMixins],
  components: {UiPropSelect},
  props: ['block'],
  name: "AlarmRequestPermissionPropsCmp",

  data() {
    return {
      alarmTypes: [
        {value: 'alarm', label: 'Alarm'},
        {value: 'push', label: 'Push notification'},
      ],
    }
  },
}

</script>
