<template>
  <div>
    <q-toolbar>
      <q-tabs v-model="tab">
        <q-tab content-class="q-px-lg" name="infinityLoop" label="Infinity loop" no-caps>
          <q-badge :color="infinityLoopErrors.length ? 'red' : 'grey'" floating :label="infinityLoopErrors.length"/>
        </q-tab>
        <q-tab content-class="q-px-lg" name="events" label="Required events" no-caps>
          <q-badge :color="eventsErrors.length ? 'red' : 'grey'" floating :label="eventsErrors.length"/>
        </q-tab>
      </q-tabs>
    </q-toolbar>

    <q-scroll-area style="height: 70vh">
      <q-tab-panels v-model="tab">
        <q-tab-panel name="infinityLoop">
          <q-list>
            <q-item v-if="!infinityLoopErrors.length">
              <q-item-section class="text-caption">
                <q-item-label>No errors</q-item-label>
              </q-item-section>
            </q-item>
            <template v-else>
              <q-item v-for="(item, idx) in infinityLoopErrors" :key="idx">
                <q-item-section>
                  <q-item-label>
                    <template v-for="(diagram, k) in item.diagrams" :key="`${idx}-${k}`">
                      <q-icon v-if="k > 0" name="arrow_forward"/>
                      <a :href="diagram.link" target="_blank">{{ diagram.title }}</a>
                    </template>
                  </q-item-label>
                </q-item-section>
              </q-item>
            </template>
          </q-list>
        </q-tab-panel>

        <q-tab-panel name="events">
          <q-markup-table>
            <thead>
              <tr>
                <th class="text-left">Block</th>
                <th class="text-left full-width">Required events</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="!eventsErrors.length">
                <td colspan="2" class="text-center text-grey">
                  No errors
                </td>
              </tr>
              <template v-else>
                <tr v-for="(item, idx) in eventsErrors" :key="idx">
                  <td>
                    <a :href="item.link" target="_blank">{{ item.title }}</a>
                  </td>
                  <td>
                    <q-badge v-for="event in item.events" :key="event" :label="event || 'n/a'" />
                  </td>
                </tr>
              </template>
            </tbody>
          </q-markup-table>
        </q-tab-panel>
      </q-tab-panels>
    </q-scroll-area>
  </div>
</template>

<script>
export default {
  name: 'SchemaValidationResult',
  props: {
    errors: {
      required: true,
      type: Array,
    },
  },

  data() {
    return {
      tab: 'infinityLoop',
    };
  },

  computed: {
    appId() {
      return this.$route.params.app_id;
    },

    moduleId() {
      return this.$route.params.module_id;
    },

    eventsErrors() {
      return this.errors.filter((error) => error.type === 'outgoing-events')
        .map((error) => ({
          title: error.block.title,
          link: `/#/workspace/${this.appId}/${this.moduleId}/components/${error.diagramId}?blockId=${error.block.id}`,
          events: error.events,
        }));
    },
    infinityLoopErrors() {
      return this.errors.filter((error) => error.type === 'infinity-loop')
        .map((error) => {
          const diagrams = [];

          return {
            diagrams: [...error.diagrams, error.diagrams[0]].map((diagram) => {
              diagrams.push(diagram.id);

              return {
                title: diagram.title,
                link: `/#/workspace/${this.appId}/${this.moduleId}/components/${diagrams.join(',')}`,
              };
            })
          };
        });
    },
  },
}
</script>
