export const treeHelper = {

    /**
     * Traverse tree with callback
     * @param tree
     * @param cb
     * @param max_level
     */
    traverseTree: (tree, cb, max_level) => {

        // Current level
        let level = 0

        // Searching for node in tree
        const goDeep = (node, level, parents) => {

            // Stop on limited depth
            if(max_level && level > max_level) return

            if (cb(node, parents)) return node
            if (node.children) for (const ch of node.children) {
                const rs = goDeep(ch, level + 1, [...parents, ...[node]])
                if (rs) return rs;
            }
        }

        // Start searching
        return goDeep(tree, level, [])
    },

    /**
     * Traverse tree with callback
     * @param {Object} tree - The tree to traverse
     * @param {Function} cb - The callback function to execute on each node
     * @param {number} [max_level] - The maximum depth to traverse
     * @returns {Object|undefined} - The node if found, otherwise undefined
     */
    goDeeper(tree, cb, max_level) {
        let level = 0;

        /**
         * Recursive function to traverse the tree
         * @param {Object} node - The current node
         * @param {number} level - The current depth level
         * @returns {Object|undefined} - The node if found, otherwise undefined
         */
        const goDeep = (node, level) => {
            if (max_level && level > max_level) {
                return;
            }

            if (cb(node)) {
                return node;
            }

            for (const prop of Object.values(node || {})) {
                if (typeof prop === 'object') {
                    const result = goDeep(prop, level + 1);

                    if (result) {
                        return result;
                    }
                }
            }
        };

        return goDeep(tree, level);
    },

    /**
     * Asynchronously traverses the tree and executes a callback on each node.
     * If the callback returns a Promise, it waits for all Promises to resolve.
     *
     * @param {Object} tree - The tree to traverse
     * @param {Function} cb - The callback function to execute on each node
     * @param {number} [max_level] - The maximum depth to traverse
     * @returns {Promise<void>} - A promise that resolves when all callbacks have been executed
     */
    async goDeeperAsync(tree, cb, max_level) {
        let level = 0;

        /**
         * Recursive function to traverse the tree
         * @param {Object} node - The current node
         * @param {number} level - The current depth level
         * @returns {Promise<Object|undefined>} - The node if found, otherwise undefined
         */
        const goDeep = async (node, level) => {
            if (max_level && level > max_level) {
                return;
            }

            if (await cb(node)) {
                return node;
            }

            for (const prop of Object.values(node || {})) {
                if (typeof prop === 'object') {
                    const result = await goDeep(prop, level + 1);

                    if (result) {
                        return result;
                    }
                }
            }
        };

        return goDeep(tree, level);
    },
}
