<template>

  <div class="properties-panel q-gutter-y-sm" v-if="block">
    <template v-for="(prop) of Object.keys(propertiesList)" :key="prop">

      <div v-if="getPropComponent(prop)" class="row no-wrap shadow-1 rounded-borders q-pa-sm">

        <div class="col">
          <component
            :is="getPropComponent(prop).component"
            :options="getPropComponent(prop).options"
            :label="getPropComponent(prop).description"
            :title="getPropComponent(prop).description"
            :default-value="getPropComponent(prop).defaultValue"
            property
            :name="prop"
            :product_id="product_id"
            :module_id="module_id"
            :parent-diagram-id="diagram_id"
            :block="block"
          />
        </div>

        <div class="q-ml-sm rounded-borders delete">
          <q-btn round icon="cancel" flat class="text-grey-6" size="md" @click.stop="deleteProperty(prop)"></q-btn>
        </div>

      </div>

    </template>

    <q-select v-if="allowedProperties.length" :model-value="componentToAdd" label="Add property" emit-value map-options
              @filter="propsFilter"
              use-input
              filled
              input-debounce="0"
              :options="propertiesToAdd" @update:model-value="addProperty">
      <template v-slot:prepend>
        <q-icon name="add"/>
      </template>
    </q-select>

  </div>

</template>

<script>

import designerPropertiesList from "@/components/DiagramDesigner/Editor/components/designerPropertiesList";

export default {
  inject: ['canvas', 'diagram_id'],
  props: ['block', 'product_id', 'module_id'],
  name: "UiDynamicProps",
  data: () => ({
    componentToAdd: null,
    componentsFilter: false,
  }),

  computed: {

    /**
     * Get props list
     * @return {*}
     */
    propertiesList() {
      return this.block.properties ? this.block.properties : {}
    },

    /**
     * Return allowed props
     * @return {string[]}
     */
    allowedProperties() {
      return Object.keys(designerPropertiesList).filter(k => (!designerPropertiesList[k].forbidden || !designerPropertiesList[k].forbidden.includes(this.block.type))).filter(k => (!designerPropertiesList[k].allowed || designerPropertiesList[k].allowed.includes(this.block.type)))
    },

    /**
     * Components to add
     * @return {{label: *, value: *}[]}
     */
    propertiesToAdd() {
      return this.allowedProperties
          .filter(k => !Object.keys(this.propertiesList).includes(k))
          .filter(v => designerPropertiesList[v].description.toLowerCase().indexOf(this.componentsFilter) > -1).map(prop => ({
            value: prop,
            label: designerPropertiesList[prop].description
          }))
    }
  },

  methods: {

    /**
     * Filter list
     * @param val
     * @param update
     */
    propsFilter(val, update) {
      this.componentsFilter = val
      update()
    },

    /**
     * Add new property
     * @param prop
     */
    async addProperty(prop) {
      this.canvas.setProperty(this.block.id, prop)
    },

    /**
     * Return component
     * @param prop
     * @return component
     */
    getPropComponent(prop) {

      // Check for prop allowed
      if (!this.allowedProperties.includes(prop)) return false

      // Return prop
      return designerPropertiesList[prop] ? designerPropertiesList[prop] : false
    },

    /**
     * Delete property
     * @param prop
     */
    deleteProperty(prop) {
      this.$q.dialog({
        message: "Are you sure want to delete property?"
      }).onOk(async () => {

        // Set empty property ~ delete
        this.canvas.setProperty(this.block.id, prop, null)
      })
    }
  }
}

</script>
