<template>

    <ui-prop-section :title="title">
      <div class="q-gutter-sm">
        <div v-for="(item, k) of currentItems" :key="k" class="row q-pa-sm no-wrap content-start items-start">
          <div class="col">
            <slot name="item" :item="item"/>
          </div>
          <q-btn icon="delete" size="sm" flat round @click="deleteItem(k)"/>
        </div>

        <q-btn @click="addItem" icon="add" label="Add item" flat/>
      </div>
    </ui-prop-section>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropSection from "@/components/DiagramDesigner/Editor/properties/UiPropSection.vue";

export default {
  mixins: [propertiesMixins],
  props: {
    block: {},
    title: {},
    name: {
      default: "customItems"
    }
  },
  name: "UiPropCustomListProps",
  components: {UiPropSection},
  data: () => ({
    currentItems: []
  }),
  created() {

    // Init value
    if(this.currentValue) this.currentItems = this.currentValue

    // Add watcher
    this.$watch('currentItems', function (newVal) {
      this.currentValue = newVal
    }, {deep: true})

  },

  methods: {

    /**
     * Add item
     * @param e
     */
    addItem() {
      // Add to items list
      this.currentItems.push({
      })
    },

    /**
     * Delete item
     * @param k
     */
    deleteItem(k) {
      this.currentItems.splice(k,1);
    },
  },
}

</script>
