<template>

  <ab-flow-base-cmp :block="block" :class="classesString" :style="stylesString">
    <q-slider 
      v-model="sliderModel" 
      :min="min" 
      :max="max" 
      :color="color"
      label
    />
  </ab-flow-base-cmp>

</template>

<script>

import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";

export default {
  components: {AbFlowBaseCmp},
  mixins: [renderMixins],
  props: ['block'],
  name: "SliderEditorCmp",
  data: () => ({
    sliderModel: 0
  }),
  computed: {
    /**
     * min
     * @return {number}
     */
     min() {
      return Number(this.block?.properties?.min) || 0;
    },
    /**
     * max
     * @return {number}
     */
     max() {
      return Number(this.block?.properties?.max) || 100;
    },
    /**
     * Color
     * @return {string}
     */
     color() {
      return this.block?.properties?.color || "";
    },
  }
}

</script>
