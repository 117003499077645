<template>
  <q-page class="q-pa-md">
    <q-card v-if="tab">
      <q-tabs
        v-model="tab"
        dense
        class="text-grey"
        active-color="primary"
        indicator-color="primary"
        align="justify"
        narrow-indicator
      >
        <q-tab
          v-for="tab in tabs"
          :key="tab.name"
          :name="tab.name"
          :label="tab.label"
        />
      </q-tabs>

      <q-separator />

      <q-tab-panels v-model="tab" animated>
        <q-tab-panel
          v-for="tab in tabs"
          :key="tab.name"
          :name="tab.name"
        ><health-check-logs :app-mode="tab.name" /></q-tab-panel>
      </q-tab-panels>
    </q-card>

  </q-page>
</template>

<style>
</style>

<script>
import {AppModule} from '../../../../../common/db/AppModule';
import HealthCheckLogs from '@/pages/workspace/health-check/HealthCheckLogs.vue';

export default {
  name: 'HealthCheck',

  components: {
    HealthCheckLogs,
  },

  provide() {
    return {
      moduleId: this.moduleId,
    };
  },

  data() {
    return {
      tab: null,
    };
  },

  computed: {
    // Module ID
    moduleId() {
      return this.$route.params.module_id || 0;
    },

    // Services checks
    servicesChecks() {
      return this.$route.name === 'services-health-check';
    },

    // Current module
    currentModule() {
      if (!this.moduleId) {
        return null;
      }

      return this.wait('currentModule', AppModule.find(this.moduleId), {})
    },

    // Tabs
    tabs() {
      if (this.servicesChecks) {
        return [
          {label: 'Release', name: 'release'}
        ];
      }

      const {healthCheckStage, healthCheckRelease, stage, release} = this.currentModule?.endpoint || {};

      const stageEnabled = (healthCheckStage === 1 && stage);
      const releaseEnabled = (healthCheckRelease === 1 && release);

      return [
        !stageEnabled ? null : {label: 'Stage', name: 'stage'},
        !releaseEnabled ? null : {label: 'Release', name: 'release'},
      ].filter(Boolean);
    },
  },

  watch: {
    // Set default tab
    tabs: {
      handler() {
        if (this.tab) {
          return;
        }

        this.tab = this.tabs[0]?.name;
      },
      deep: true,
      immediate: true,
    },
  },
}
</script>

