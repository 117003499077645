/**
 * DB table channel
 */
import {AccessManager} from '../utils/AccessManager.js';
import {AccessModel} from './AccessModel.js';
import {DbModule} from './DbModule.js';

export class DbTableChannel extends AccessModel {
    static entity = 'table_channels'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        table_id: "int",
        title: "string",
        description: "string",
        channel_name: "string",
        channel_query: "string",
        permissions: "json",
        unique_id: "string"
    }

    /**
     * Handles server events by clearing the cache for the associated application.
     *
     * @returns {Promise<void>}
     */
    async serverEvent() {
        const cacheManager = this.constructor?.applicationClient?.plugins?.schemaCache || null;

        if (!cacheManager) {
            return;
        }

        const row = await DbTableChannel.find(this.id);

        if (!row?.id) {
            return;
        }

        const db = await DbModule.query()
          .join('database_tables')
          .on('database_tables.db_id', 'app_databases.id')
          .where('database_tables.id', row.table_id)
          .first();

        if (db?.app_id) {
            cacheManager.clearCache(db.app_id);
        }
    }

    /**
     * Prepare data according to users access level
     * @return {boolean}
     * @param row
     * @param mode
     */
    static async prepareData(row, mode) {
        const accessManager = new AccessManager(this);

        return await accessManager.checkDbTableChannelAccess(await this.getAccessId(row, 'table_id'), row?.id) ? row : false
    }

    /**
     * Functions list
     */
    async channels() {
        return {
            'table-channels': {
                subscribe: ({table_id}) => table_id,
                init: async ({table_id}) => this.getList(table_id),
            },
            'app-table-channels': {
                subscribe: ({app_id}) => app_id,
                init: async ({app_id}) => this.getVersionChannels(app_id),
            },
            'db-table-channels': {
                subscribe: ({db_id}) => db_id,
                init: async ({db_id}) => this.getDbChannels(db_id),
            }
        }
    }

    /**
     * Get list
     */
    getList(table_id) {
        return this.query().where({table_id}).get()
    }


    /**
     * Get version list
     */
    getVersionChannels(app_id) {
        return this.query().
        join("database_tables").on("database_tables.id", "table_id").
        join("app_databases").on("app_databases.id", "db_id").
        where("app_databases.app_id", app_id).get()
    }

    /**
     * Get module channels
     */
    static getModuleChannels(module_id) {
        return DbTableChannel.query(['table_channels.*'])
          .join('database_tables').on('database_tables.id', 'table_channels.table_id')
          .join('app_databases').on('app_databases.id', 'database_tables.db_id')
          .where('app_databases.module_id', module_id)
          .get()
    }

    /**
     * Get database channels by database ID.
     * @param {number} db_id - The ID of the database.
     * @returns {Promise<Array>} - A promise that resolves to an array of database channels.
     */
    static getDbChannels(db_id) {
        return DbTableChannel.query(['table_channels.*'])
          .join('database_tables').on('database_tables.id', 'table_channels.table_id')
          .where('database_tables.db_id', db_id)
          .get()
    }

    /**
     * Get database channels by database ID.
     * @param {number} db_id - The ID of the database.
     * @returns {Promise<Array>} - A promise that resolves to an array of database channels.
     */
    getDbChannels(db_id) {
        return DbTableChannel.getDbChannels(db_id);
    }
}
