import {countryList} from "./countries.js";
import {currencyList} from "./currencies.js";
import {languageList} from "./languages.js";
import {marketingPixelEventsList} from './marketingPixelEvents.js';

export const optionsCollections = () => {
    const res = {
        countries: countryList,
        currencies: currencyList,
        languages: languageList,
        marketingPixelEvents: marketingPixelEventsList,

        years: [],
        months: [],
        days: [],
        hours: [],
        minutes: [],
        seconds: [],
        daysOfWeek: [],

        weightUnits: [],
        fluidUnits: [],
        lengthUnits: [],
        numbers100: [],
        numbers200: [],
        numbers500: [],
        numbers1000: [],

    };

    /**
     * Lead zero
     * @param val
     * @return {*|string}
     */
    const leadZero = (val) => {
        if(val < 10) return '0' + val;
        return val;
    }

    // Fill years list
    for (let i = 1930; i < 2024; i++) res.years.push({value: i.toString(), title: i});

    // Fill months list
    for (let i = 1; i <= 12; i++) res.months.push({value: i.toString(), title: leadZero(i)});

    // Fill days list
    for (let i = 1; i <= 31; i++) res.days.push({value: i.toString(), title: leadZero(i)});

    // Fill hours list
    for (let i = 0; i <= 23; i++) res.hours.push({value: i.toString(), title: leadZero(i)});

    // Fill minutes list
    for (let i = 0; i <= 59; i++) res.minutes.push({value: i.toString(), title: leadZero(i)});

    // Fill seconds list
    for (let i = 0; i <= 59; i++) res.seconds.push({value: i.toString(), title: leadZero(i)});

    // Fill days of week list
    res.daysOfWeek.push({value: 0, title: 'Sun'});
    res.daysOfWeek.push({value: 1, title: 'Mon'});
    res.daysOfWeek.push({value: 2, title: 'Tue'});
    res.daysOfWeek.push({value: 3, title: 'Wed'});
    res.daysOfWeek.push({value: 4, title: 'Thu'});
    res.daysOfWeek.push({value: 5, title: 'Fri'});
    res.daysOfWeek.push({value: 6, title: 'Sat'});

    // Weight units
    res.weightUnits.push({value: 'kg', title: 'kg'});
    res.weightUnits.push({value: 'lb', title: 'lb'});

    // Length units
    res.lengthUnits.push({value: 'cm', title: 'cm'});
    res.lengthUnits.push({value: 'ft', title: 'ft'});

    // Fluid units
    res.fluidUnits.push({value: 'ml', title: 'ml'});
    res.fluidUnits.push({value: 'oz', title: 'oz'});

    // Numbers
    for (let i = 1; i <= 100; i++) res.numbers100.push({value: i.toString(), title: i});
    for (let i = 1; i <= 200; i++) res.numbers200.push({value: i.toString(), title: i});
    for (let i = 1; i <= 500; i++) res.numbers500.push({value: i.toString(), title: i});
    for (let i = 1; i <= 1000; i++) res.numbers1000.push({value: i.toString(), title: i});

    // Return result
    return res;
}
