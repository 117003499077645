<template>
  <component :is="cardComponent" :details="details" />
</template>

<script>
import LogsCard from '@/pages/workspace/health-check/details-cards/LogsCard.vue';
import DiskSpaceCard from '@/pages/workspace/health-check/details-cards/DiskSpaceCard.vue';
import DbConnectionCard from '@/pages/workspace/health-check/details-cards/DbConnectionCard.vue';
import CustomCard from '@/pages/workspace/health-check/details-cards/CustomCard.vue';
import ErrorCard from '@/pages/workspace/health-check/details-cards/ErrorCard.vue';

export default {
  name: 'DetailsCard',
  components: {CustomCard, DbConnectionCard, DiskSpaceCard, LogsCard, ErrorCard},

  props: {
    details: {
      required: true,
      type: Object,
    },
  },

  computed: {
    // Extract the error message and stack from the details object
    errorMessage() {
      return this.details?.payload?.error?.message || null;
    },
    // Extract the error stack from the details object
    errorStack() {
      return this.details?.payload?.error?.stack || null;
    },
    // Check if the error message or stack is present
    hasError() {
      return !!this.errorMessage || !!this.errorStack;
    },
    // Determine the card component to render based on the details type
    cardComponent() {
      // If there is an error, render the ErrorCard component
      if (this.hasError && this.details?.type !== 'disk-space') {
        return ErrorCard;
      }

      // Otherwise, render the appropriate card component based on the details type
      switch (this.details.type) {
        case 'disk-space':
          return DiskSpaceCard;
        case 'database-connection':
          return DbConnectionCard;
        case 'logs':
          return LogsCard;
        default:
          return CustomCard;
      }
    },
  },
}
</script>
