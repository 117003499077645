<template>
  <q-page class="q-pa-md releases-list">

    <q-dialog ref="editRelease">
      <release-form
        :edit-release="editRelease"
        @saved="$refs.editRelease.hide()"
        @invalid-resources="onInvalidResources"
        @invalid-schema="onInvalidSchema"
      />
    </q-dialog>

    <modal-dialog ref="invalidResources" title="Invalid resources">
      <q-list>
        <template v-for="(item, idx) in releaseInvalidResources" :key="idx">
          <q-item>
            <q-item-section>
              <q-item-label>
                {{ item.title }}
                <a v-if="item.link" :href="item.link" target="_blank">Go to block</a>
              </q-item-label>
            </q-item-section>
          </q-item>
        </template>
      </q-list>
    </modal-dialog>

    <modal-dialog ref="schemaValidationErrors" title="Schema validation errors" max-width="900px">
      <schema-validation-result :errors="schemaValidationErrors" />
    </modal-dialog>

    <q-dialog ref="buildApp">
      <q-card class="full-width">
        <q-card-section class="row bg-primary text-white">
          <div class="text-subtitle1">Build app</div>
          <q-space/>
          <q-btn flat icon="close" @click="$refs.buildApp.hide();"/>
        </q-card-section>
        <q-card-section>
          <build-app :mode="buildMode" :release-id="currentReleaseId" @close="$refs.buildApp.hide();"/>
        </q-card-section>

      </q-card>
    </q-dialog>

    <q-table
        title="App module releases"
        :rows="releases"
        :columns="columns"
        flat
        row-key="name"
    >
      <template v-slot:body-cell-actions="props">
        <q-td :props="props">

          <template v-if="props.row.status === 'completed'">
            <q-btn flat @click="markReleased(props.row.id)">Mark as published</q-btn>

            <q-btn-dropdown color="primary" label="Build" v-if="props.row.code_version === lastCodeVersion" flat>

              <q-list>
                <q-item clickable v-close-popup @click="build(props.row, 'stage')">
                  <q-item-section>
                    <q-item-label>Stage</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item clickable v-close-popup @click="build(props.row, 'release')">
                  <q-item-section>
                    <q-item-label>Release</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-btn-dropdown>
          </template>
          <div v-else-if="props.row.status === 'released'" class="q-pa-sm">
            Application is published
            <div>
              <q-checkbox :model-value="props.row?.platforms?.Web" true-value="1" false-value="0" label="Web" @update:model-value="changePlatform(props.row, 'Web', $event)"/>
              <q-checkbox :model-value="props.row?.platforms?.Android" true-value="1" false-value="0" label="Android" @update:model-value="changePlatform(props.row, 'Android', $event)"/>
              <q-checkbox :model-value="props.row?.platforms?.iOS" label="iOS" true-value="1" false-value="0" @update:model-value="changePlatform(props.row, 'iOS', $event)"/>
            </div>
          </div>

          <div v-for="(build, k) of getBuilds(props.row)" :key="k" class="branch-build q-pa-sm">
            <template v-if="build.status === 'Completed'">
              {{build.platform}}, {{build.branch}}
              <template v-if="build.url">: <a target="_blank" :href="(build.url)">Open app</a></template>
            </template>
            <template v-else>
              {{build.platform}} {{build.branch}}: {{ build.status }}
            </template>
            <template v-if="build.log">(<a target="_blank" :href="(build.log)">Log</a>)</template>
          </div>

          <!--span v-for="(branch, k) of ['stage', 'release']" :key="k" class="branch-state">
            <template v-if="endpointList[branch]">
              {{branch}}: {{ endpointList[branch]?.status }}
              <q-btn icon="play_arrow" v-if="endpointList[branch]?.ready" color="info" size="sm" outline
                     @click="openWebApp(endpointList[branch]?.url)"/>
            </template>
          </span!-->

          <!--div v-if="moduleInfo?.type !== 'web' && props.row.status === 'released'" class="row content-start text-left">
            <div>
              <div>Schema version: <strong>{{ props.row.schema_version }}</strong>
                ({{ formatTime(props.row.schema_update_time) }})
              </div>
              <div>Rollout percent: <strong>{{ props.row.schema_rollout_percent }}%</strong>
                <q-popup-edit v-model="props.row.schema_rollout_percent" v-slot="scope">
                  <q-input v-model="scope.value" dense autofocus counter>
                    <template v-slot:after>
                      <q-btn
                          flat dense color="negative" icon="cancel"
                          @click.stop.prevent="scope.cancel"
                      />

                      <q-btn
                          flat dense color="positive" icon="check_circle"
                          @click.stop.prevent="updateRollout(props.row, scope.value);scope.set()"
                      />
                    </template>
                  </q-input>
                </q-popup-edit>
              </div>
            </div>
            <q-space/>
            <q-btn size="sm" flat @click="updateSchema(props.row)">Update schema</q-btn>
          </div!-->

        </q-td>
      </template>

      <template v-slot:body-cell-status="props">
        <q-td :props="props" :class="{'released': props.value === 'released'}">
          {{ props.value }}
        </q-td>
      </template>

      <template v-slot:body-cell-released_at="props">
        <q-td :props="props">
          {{ formatTime(props.value) }}
        </q-td>
      </template>
    </q-table>

    <q-page-sticky position="bottom-right" :offset="[18, 18]">
      <q-btn fab icon="add" color="primary"
             @click="editRelease={code_version: lastCodeVersion + 1, name: lastReleaseName,app_id, module_id};$refs.editRelease.show();releaseInvalidResources=[]"/>
    </q-page-sticky>

  </q-page>
</template>

<style>
</style>

<script>
import ReleaseForm from "./ReleaseForm";
import {AppRelease} from "@/../../common/db/AppRelease";
import moment from "moment";
import {AppModule} from "../../../../../common/db/AppModule";
import BuildApp from "@/components/DiagramDesigner/Editor/builder/BuildApp.vue";
import ModalDialog from '@/components/ModalDialog/ModalDialog.vue';
import SchemaValidationResult from '@/pages/workspace/releases/SchemaValidationResult.vue';

export default {
  name: 'ReleaseList',
  components: {SchemaValidationResult, ModalDialog, BuildApp, ReleaseForm},
  inject: ['currentModule'],
  data: () => ({
    editRelease: false,
    app_id: false,
    module_id: false,
    buildMode: "stage",
    currentReleaseId: 0,
    columns: [
      {name: 'code_version', align: 'center', label: 'Code version', field: 'code_version', sortable: true},
      {name: 'name', align: 'center', label: 'Version', field: 'name', sortable: true},
      {name: 'status', align: 'center', label: 'Status', field: 'status', sortable: true},
      {name: 'released_at', align: 'center', label: 'Released at', field: 'released_at', sortable: true},
      {name: 'actions', align: 'center', label: 'Actions', field: 'status', sortable: true},
    ],
    releaseInvalidResources: [],
    schemaValidationErrors: [],
  }),


  async created() {

    // Set app id
    this.app_id = this.$route.params.app_id || 0
    this.module_id = this.$route.params.module_id || 0

    // Subscribe to releases
    await AppRelease.remote().subscribe("module-releases", {module_id: this.module_id})
  },

  computed: {

    /**
     * Return urls list
     * @return {string|string|*|{}}
     */
    /*endpointList() {
      const res = {}, eps = this.currentModule?.currentModule?.endpoint || {}
      for(const b of ['stage', 'release']) {
        const tm = eps[`${b}_build_time`]
        res[b] = {
          status: tm === -1000 ? "Build failed" : tm === -1 ? "In progress" : !tm ? "Not built" : "Build completed",
          url: eps[b],
          ready: tm > 0
        }
      }

      // Return
      return res;
    },*/

    /**
     * Return last release name
     * @return {*|string}
     */
    lastReleaseName() {
      return this.releases.length ? this.releases[0].name : '1.0.0'
    },

    /**
     * Last code version
     * @return {*|string}
     */
    lastCodeVersion() {
      return this.releases.length ? this.releases[0].code_version : 1
    },

    // Return all releases from the store
    releases() {
      return this.wait("app-releases", AppRelease.query().where({module_id: this.module_id}).order("id desc").get(), [])
    },

    // Return all releases from the store
    moduleInfo() {
      return this.wait("moduleInfo", AppModule.find(this.module_id), {})
    }
  },

  methods: {

    /**
     * Change platform
     * @param release
     * @param platform
     * @param value
     */
    async changePlatform(release, platform, value) {
      release.platforms = release.platforms || {}
      release.platforms[platform] = value
      await AppRelease.remote().save(release)
    },

    /**
     * Return builds
     * @param release
     * @return {*[]}
     */
    getBuilds(release) {
      const res = []
      for(const pl of this.globals.options.platformList.map(p => p.value)) {
        for(const br of ["stage", "release"]) {
          // Check for build
          const time = release?.files?.[`${pl}_${br}_build_time`];
          const log = release?.files?.[`${pl}_${br}_log`];
          if(time === undefined) continue;

          // Set status
          let status = time > 0 ? "Completed": "Not built"

          // Calc status
          switch ( release?.files?.[`${pl}_${br}_build_time`]) {
            case -1:
              status = "In progress"
              break;
            case -1000:
              status = "Build failed"
              break;
          }

          // Get url
          let url = release?.files?.[`${pl}_${br}`];

          // Debug mode
          if (['web', 'server'].includes(this.moduleInfo.type) && this.moduleInfo.endpoint[br]) {
            url = this.moduleInfo.endpoint[br];
          } else if(url && url.toString().indexOf('http') === -1) {
            url = process.env.VUE_APP_PUBLISH_URL + url + "/index.html";
          }

          // Add result
          res.push({
            platform: pl,
            branch: br,
            status,
            url,
            log
          })

        }
      }
      return res
    },

    /**
     * Build release
     * @param item
     * @param type
     */
    build(item, type) {
      this.currentReleaseId = item.id
      this.buildMode = type
      this.$refs.buildApp.show()
      /*this.$q.dialog({
        title: 'Build confirmation',
        message: `Are you sure want to build ${item.name} ?`,
        cancel: true,
        persistent: true
      }).onOk(async () => {

        try {

          // Show loading
          this.$q.loading.show("Building release")

          // Update
          //await AppRelease.remote().save(this.editRelease)
          await AppModule.remote().call("app", "buildGitVersion", item.module_id, type)

          this.$q.notify({type: 'positive', message: "Building in progress"})
        } catch (e) {
          console.error("Build failed", e)
        }

        // Hide loading
        this.$q.loading.hide()

        // Hide dialog
        this.$emit('saved')

      })

      console.log("Build release", item, type)*/
    },

    /**
     * Open web app
     * @param url
     */
    /*openWebApp(url) {
      window.open(url, "_blank")
    },*/

    /**
     * Update rollout
     * @param rel
     * @param rv
     */
    /*async updateRollout(rel, rv) {
      await AppRelease.remote().save({
        id: rel.id,
        schema_rollout_percent: rv
      })
    },*/

    /**
     * Update schema
     * @param rel
     */
    /*updateSchema(rel) {

      // Ask for confirmation
      this.$q.dialog({
        title: 'Update schema confirmation',
        message: `Are you sure want to update schema for ${rel.name} ?`,
        cancel: true,
        persistent: true
      }).onOk(async () => {
        if (await AppRelease.remote().call("app", "updateReleaseSchema", rel.id)) {
          this.$q.notify({
            message: "Schema updated",
            color: "positive",
            icon: "check"
          });
        }
      })
    },*/

    /**
     * Format time
     * @param tm
     * @return {string}
     */
    formatTime(tm) {
      return tm ? moment(tm * 1000).format('YYYY-MM-DD HH:mm') : "n/a"
    },

    /**
     * Mark release as published
     * @param id
     */
    markReleased(id) {

      // Ask permission
      this.$q.dialog({
        title: 'Publish confirmation',
        message: `Are you sure want to publish this release ?`,
        cancel: true,
        persistent: true
      }).onOk(() => {
        AppRelease.remote().save({
          id,
          status: 'released'
        })
      })
    },

    /**
     * Delete release
     * @param release
     * @return {Promise<void>}
     */
    async deleteRelease(release) {
      this.$q.dialog({
        title: 'Delete confirmation',
        message: `Are you sure want to delete ${release.title} ?`,
        cancel: true,
        persistent: true
      }).onOk(() => {
        AppRelease.remote().delete(release.id)
      })
    },

    /**
     * Handles invalid resources by mapping them to a list of objects containing
     * their titles and links, and then displays the invalid resources dialog.
     *
     * @param {Array} resources - The list of invalid resources.
     */
    onInvalidResources(resources) {
      this.releaseInvalidResources = resources.map((resource) => {
        let blockLink = null;

        if (resource?.blockId && resource?.diagramId) {
          blockLink = `/#/workspace/${this.app_id}/${this.module_id}/components/${resource.diagramId}?blockId=${resource.blockId}`
        } else if (resource?.storageNode && (resource?.storageNode?.block_id || '').startsWith('diagram-')) {
          blockLink = `/#/workspace/${this.app_id}/${this.module_id}/components/${resource.storageNode.block_id.replace('diagram-', '')}`
        }

        return {
          link: blockLink,
          title: resource.title,
        };
      });

      this.$refs.invalidResources.show();
    },

    /**
     * Handles schema validation errors by mapping them to a list of objects containing
     * their titles, links, and events, and then displays the schema validation errors dialog.
     *
     * @param {Array} errors - The list of schema validation errors.
     */
    onInvalidSchema(errors) {
      this.schemaValidationErrors = errors;

      this.$refs.schemaValidationErrors.show();
    },
  }
}
</script>

<style lang="scss">

.releases-list {
  .released {
    background: #afa;
  }
  .branch-build {
    border-top: 1px solid #ddd;
    margin-right: 10px;
    padding-right: 10px;
  }
}

</style>
