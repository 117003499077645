<template>

  <q-card>
    <q-card-section>
      <div class="text-h6">Edit db</div>
    </q-card-section>

    <q-card-section class="q-pt-none">

      <q-form ref="dbForm" @submit="onSaveDb" class="q-gutter-md">
        <q-input
            v-model="mutate(editDb).name"
            label="Db name *"
            hint="title of db"
            lazy-rules
            :rules="[ val => val && val.length > 0 || 'Please type something']"
        />

        <!--q-checkbox v-model="mutate(editDb).is_auth_db" :true-value="1" :false-value="0" label="Db contains auth tables"/!-->

        <div>
          <q-btn label="Save" type="submit" color="primary"/>
        </div>
      </q-form>

    </q-card-section>

  </q-card>

</template>

<script>

import {DbModule} from "@/../../common/db/DbModule";
import {nanoid} from "nanoid";

export default {
  name: "DbForm",
  props: ['editDb'],
  emits: ['saved'],
  data: () => ({
    appId: false,
    moduleId: false
  }),

  created() {
    this.appId = this.$route.params.app_id
    this.moduleId = this.$route.params.module_id
  },

  methods: {

    /**
     * On form submit
     * @return {Promise<void>}
     */
    async onSaveDb() {

      if(!this.editDb.id) {
        this.mutate(this.editDb).unique_id = nanoid(10);
      }

      // Update
      await DbModule.remote().save(this.editDb)

      // Hide dialog
      this.$emit('saved')
    }
  },

  computed: {
  }
}

</script>
