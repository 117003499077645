<template>
  <ui-prop-select :block="block" name="outputDateUnit" title="Output date unit" :options="outputOptions"/>
</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";

export default {
  components: {UiPropSelect},
  mixins: [propertiesMixins],
  name: "DateTimeOperation",
  computed: {
    outputOptions() {
      return [...this.globals.options.dateConstantUnits, ...[{value: "date", label: "Date"}, {value: "datetime", label: "Date And Time"}]]
    }
  }
}

</script>
