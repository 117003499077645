<template>

  <div>
    <ui-prop-select :block="block" title="Channel" name="soundChannel" :options="channels"/>
  </div>

</template>

<script>
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";

export default {
  mixins: [propertiesMixins],
  inject: ['product_id'],
  components: {UiPropSelect},
  props: ['block'],
  name: "StopSoundPropsCmp",
  computed: {
    /**
     * Computed property that returns an array of sound channels.
     * It includes all the channels from the global soundManagerChannels options,
     * and adds a new channel with value "background" and label "Background".
     *
     * @name channels
     * @computed
     * @returns {Array} An array of sound channels.
     */
    channels() {
      return [
        ...this.globals.options.soundManagerChannels,
        {value: "background", label: "Background"},
      ];
    },
  }
}

</script>
