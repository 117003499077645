<template>

  <ab-flow-base-cmp :draggable="false" class="native-ads" :block="block" :is_container="true">
    <data-provider v-if="block.children && block.children.length" :storage-key="block?.title" :data="item">
      <ab-flow-components-renderer :items="block.children"/>
    </data-provider>
    <div v-else class="native-ad flex row items-center">
      <div class="native-ad__image"></div>

      <div class="native-ad__body q-ml-md">
        <div class="text-subtitle2">{{item.title}}</div>
        <div class="text-caption text-grey">{{item.description}}</div>
        <div class="text-subtitle2">{{item.cta}}</div>
      </div>
    </div>
  </ab-flow-base-cmp>

</template>

<script>

import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowComponentsRenderer from "ab-flow-designer/src/components/Designer/AbFlowComponentsRenderer";
import DataProvider from "@/components/DiagramDesigner/Editor/components/UI/Data/DataProvider/DataProvider.vue";

export default {
  components: {DataProvider, AbFlowBaseCmp, AbFlowComponentsRenderer},
  mixins: [renderMixins],

  inject: {
    schemaSource: {
      default: null,
    },
  },

  props: ['block'],
  name: "NativeAdsEditorCmp",

  data() {
    return {
      item: { // This is a mock object
        id: 1,
        title: 'Title',
        description: 'Description',
        image: 'https://placekitten.com/200',
        cta: 'Call to action',
        click_url: 'https://example.com'
      }
    };
  },
}

</script>


<style lang="scss">

.native-ads {
  flex-direction: inherit;
  flex-wrap: inherit;
  justify-content: inherit;
  align-items: inherit;
  width: 100%;
  position: relative;
}

.native-ad {
  &__image {
    width: 100px;
    height: 100px;
    background: url(@/assets/plugs/default-image.png) no-repeat center / contain;
  }
}

</style>
