<template>
  <q-page class="custom-diagram" >
    <div class="path bg-blue-7 q-pl-md items-center flex row">
      <router-link class="q-ml-sm q-mr-sm text-white" :to="{name: 'components'}">Components</router-link>
      <div class="text-white" v-for="(step, k) of pathSteps" :key="k">
        ::
        <router-link class="q-ml-sm q-mr-sm text-white" :to="{params: {id: step.ids}}">{{step.title}}</router-link>
      </div>
    </div>
    <diagram-designer :key="diagram.id" class="designer" v-if="diagram" :purpose="purpose" :diagram_id="diagram.id" :product_id="appId" :module_id="moduleId"/>
  </q-page>
</template>

<script>

import {Diagram} from "@/../../common/db/Diagram.js"
import DiagramDesigner from "@/components/DiagramDesigner/Editor/DiagramDesigner";

export default {
  name: "ComponentDiagram",
  components: {DiagramDesigner},
  inject: ['main'],
  data: () => ({
    diagram: false,
    diagramType: false,
    diagramId: false,
    purpose: "ui"
  }),

  methods: {
    /**
     * This method is used to confirm if the user wants to leave the page when there are unsaved changes.
     * Depending on the user's response to the dialog, it either allows the navigation or aborts it.
     *
     * @param {Function} next - This function must be called to resolve the navigation. The action depends on the arguments provided to next.
     */
    pageLeaveConfirm(next) {
      // Destructure the current version and diagram version from the current media designer of the app.
      const { current_version, diagram_version } = this.app?.currentMediaDesigner;

      // Check if the current version of the diagram is the same as the saved version.
      // If they are the same, it means there are no unsaved changes, so we call next() and allow the navigation.
      if (current_version !== diagram_version) {
        // If there are unsaved changes, we show a confirmation dialog to the user.
        this.$q.dialog({
          title: 'Unsaved changes',
          message: 'You have unsaved changes. Are you sure you want to leave?',
          ok: 'Yes',
          cancel: 'No'
        }).onOk(() => {
          // If the user confirms they want to leave, we call next() and allow the navigation.
          next();
        }).onCancel(() => {
          // If the user cancels, we call next(false) and abort the navigation.
          next(false);
        });
      } else {
        // If there are no unsaved changes, we call next() and allow the navigation.
        next();
      }
    },
  },

  /**
   * This method is a Vue Router navigation guard that gets called when the route that the component is in is about to be navigated away from.
   *
   * @param {Route} to - The target Route Object being navigated to.
   * @param {Route} from - The current route being navigated away from.
   * @param {Function} next - This function must be called to resolve the hook. The action depends on the arguments provided to next.
   */
  beforeRouteLeave(to, from, next) {
    this.pageLeaveConfirm(next);
  },

  /**
   * This method is a Vue Router navigation guard that gets called when the route that the component is in is about to be updated.
   *
   * @param {Route} to - The target Route Object being navigated to.
   * @param {Route} from - The current route being navigated away from.
   * @param {Function} next - This function must be called to resolve the hook. The action depends on the arguments provided to next.
   */
  beforeRouteUpdate(to, from, next) {
    this.pageLeaveConfirm(next);
  },

  /**
   * Created
   */
  created() {

    // Get diagram
    this.$watch("lastRouteId", async () => {
      this.diagram = await Diagram.find(this.lastRouteId)
      console.log(this.diagram)

      // Get diagram type and set purpose
      switch (this.diagram.diagram_type) {

          // Only logic here
        case "function":
          this.purpose = 'logic';
          break

          // UI
        default:
          break;
      }
    }, {immediate: true})

  },

  /**
   * Computed
   */
  computed: {

    /**
     * Current app id
     * @returns {boolean|*}
     */
    appId() {
      return this.$route.params?.app_id
    },

    /**
     * Current app version id
     * @returns {boolean|*}
     */
    moduleId() {
      return this.$route.params?.module_id
    },

    /**
     * Get last route id
     * @returns {*}
     */
    lastRouteId() {
      const ids = this.$route.params?.id.split(",")
      return ids ? parseInt(ids[ids.length - 1]) : undefined
    },

    /**
     * Ids path steps
     */
    pathSteps() {

      // Wait for steps
      return this.wait('pathSteps', async () => {

        // Result steps
        const resSteps = []

        // Get ids from route
        const ids = this.$route.params?.id.split(",").filter(id => id)

        // Load diagrams by ids and map to object with key equal to diagram id
        const diagrams = (await Diagram.query().where(Diagram.sql().in('id', ids)).get())?.reduce((acc, diagram) => {
          acc[diagram.id] = diagram
          return acc
        }, {})

        // Return array with ids and titles, where ids on each step is a part of previous step ids
        ids.map((id, k) => {
          const diagram = diagrams[id]
          resSteps.push({
            title: diagram.title,
            ids: ids.slice(0, k + 1).join(",")
          })
        })

        // Return result steps
        return resSteps
      })
    }
  }
}

</script>

<style lang="scss">

.custom-diagram {
  .path {
    //height: 30px;
  }
  .designer {
    height: calc(100vh - 100px)
  }
}

</style>
