<template>
  <ab-flow-base-cmp :movable="true" :block="block">

    <ab-flow-link-connector :block="block" type="input" class="ev-run" event="run"/>

    <q-card class="card">

      <q-card-section class="bg-blue-4 text-white q-py-sm">
        Voice chat
      </q-card-section>

    </q-card>

    <div class="results">
      <ab-flow-link-connector :block="block" type="output" event="no-permission"/>
      <ab-flow-link-connector :block="block" type="output" event="closed"/>
    </div>

  </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

export default {
  components: {AbFlowBaseCmp, AbFlowLinkConnector},
  inject: ['canvas'],
  props: ['block'],
  name: "VoiceChatEditorCmp",
}

</script>

