<template>

  <ui-prop-value-selector :block="block" name="dataSource" title="Data source" :is-array="true" :parent-diagram-id="diagram_id"/>
  <ui-prop-checkbox name="dataIsWritable" title="Children can change the data" :block="block" :true-value="1" :false-value="0" :default-value="0"/>
  <!--ui-data-props name="items" :block="block"/!-->

  <div>
    <ui-prop-checkbox
      name="virtualScrollEnable"
      title="Virtual scroll"
      :block="block"
      :true-value="1"
      :false-value="0"
      :default-value="0"
    />
  </div>

  <q-card v-if="block?.properties?.virtualScrollEnable" class="q-mt-sm">
    <q-card-section class="bg-primary text-white">Virtual scroll</q-card-section>

    <q-card-section>
      <div>
        <ui-prop-checkbox
          name="virtualScrollHorizontal"
          title="Horizontal"
          :block="block"
          :true-value="1"
          :false-value="0"
          :default-value="0"
        />

        <div class="text-caption">Make virtual list work in horizontal mode</div>
      </div>

      <ui-prop-string
        v-if="!block?.properties?.virtualScrollHorizontal"
        name="virtualScrollMaxHeight"
        title="Max height"
        :block="block"
        hint="Maximum height of the virtual list in pixels"
      />

      <ui-prop-string
        name="virtualScrollItemSize"
        title="Item size"
        :block="block"
        default-value="24"
        hint="Default size in pixels (height if vertical, width if horizontal) of an item; This value is used for rendering the initial list; Try to use a value close to the minimum size of an item"
      />

      <ui-prop-string
        name="virtualScrollSliceSize"
        title="Slice size"
        :block="block"
        default-value="10"
        hint="Minimum number of items to render in the virtual list"
      />

      <ui-prop-string
        name="virtualScrollSliceRatioBefore"
        title="Slice ratio before"
        :block="block"
        default-value="1"
        hint="Ratio of number of items in visible zone to render before it (from 0.1 to 1)"
      />

      <ui-prop-string
        name="virtualScrollSliceRatioAfter"
        title="Slice ratio after"
        :block="block"
        default-value="1"
        hint="Ratio of number of items in visible zone to render after it (from 0.1 to 1)"
      />
    </q-card-section>
  </q-card>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import {componentsMixin} from "@/components/DiagramDesigner/Editor/components/componentsMixin";
import UiPropCheckbox from "@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue";
import UiPropString from '@/components/DiagramDesigner/Editor/properties/UiPropString.vue';

export default {
  mixins: [propertiesMixins, componentsMixin],
  name: "RepeaterPropsCmp",
  props: ['block'],
  data: () => ({
    tab: "props",
  }),
  components: {UiPropString, UiPropCheckbox, UiPropValueSelector},
}

</script>

