<template>
  <div class="col">
    <q-select v-model="valueData.type" label="Constant type" map-options emit-value :options="globals.options.dateConstantOptions"/>
    <template v-if="valueData.type === 'customDate'">
      <date-picker v-model="valueData.customDate"/>
    </template>
    <div v-if="valueData.type === 'customUnit'" class="row q-gutter-x-sm">
      <q-input class="col" label="Amount" v-model="valueData.dateUnits"/>
      <q-select v-model="valueData.dateUnitType" label="Date unit" map-options emit-value :options="globals.options.dateConstantUnits"/>
    </div>

  </div>
</template>

<script>

import DatePicker from "@/components/DatePicker/DatePicker.vue";

export default {
  name: "DateTimeConstants",
  components: {DatePicker},
  props: ["modelValue"],
  data: () => ({
    valueData: {}
  }),
  created() {

    // Set the valueData to the modelValue
    this.valueData = typeof(this.modelValue) === 'object' ? this.modelValue : {};

    // Add watcher to update the modelValue when the valueData changes
    this.$watch('valueData', () => {
      let title = "";
      switch (this.valueData.type) {
        case "currentDate":
          title = 'Current date';
          break
        case "currentDateTime":
          title = 'Current date & time';
          break
        case "customDate":
          title = this.valueData.customDate;
          break
        case "customUnit":
          title = this.valueData.dateUnits+" " + this.block.properties.dateUnitType;
          break
      }

      // Store the title in the constantValue property
      this.valueData.valueTitle = title;
      this.$emit('update:modelValue', this.valueData);
    }, {deep: true})
  }
}

</script>
