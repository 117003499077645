<template>

  <div>
    <div class="text-subtitle1">{{title}}</div>

    <div class="column">
      <value-selector
        title="Mask image"
        only-var-type="image"
        v-model="currentValue"
        :app-id="product_id"
        :module-id="module_id"
        :parent-diagram-id="diagram_id"
        :block-id="block.id"
      />
    </div>
  </div>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import ValueSelector from "@/components/ValueSelector/ValueSelector.vue";

export default {
  inject: ['canvas'],
  mixins: [propertiesMixins],
  name: "UiPropBgColor",

  components: { ValueSelector},

  props: {
    defaultValue: {
      type: String,
      radius: 0
    },
    product_id: {
      default: null
    },
    module_id: {
      default: null
    },
  },
}

</script>
