<template>

  <div>
    <ui-prop-string name="title" title="Block title" :block="block" />

    <ui-prop-dynamic-string :block="block" title="Sender" name="from"/>
    <ui-prop-dynamic-string :block="block" title="Recipient" name="to"/>
    <ui-prop-dynamic-string :block="block" title="Subject" name="subject"/>
    <ui-prop-reach-text name="html" title="Body" :block="block"/>
  </div>

</template>

<script>

import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";
import UiPropDynamicString from '@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue';
import UiPropReachText from '@/components/DiagramDesigner/Editor/properties/UiPropReachText.vue';

export default {
  components: {UiPropReachText, UiPropDynamicString, UiPropString},
  props: ['block'],
  name: "SendEmailPropsCmp",
}

</script>
