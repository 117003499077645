<template>
  <slot/>
</template>

<script>

import _ from "lodash";

export default {
  name: "DataProvider",
  props: ['storageKey', 'data'],
  provide() {
    return {
      parentWidget: this
    }
  },
  computed: {
    storage() {
      return {
        get: (key) => {
          return _.get(this.data, key)
        }
      }
    }
  },
}

</script>
