<template>

  <q-form ref="componentForm" @submit="onSaveComponent" class="q-gutter-md">
    <q-input
        v-model="mutate(editComponent).title"
        label="Component title *"
        hint="title of component"
        lazy-rules
        :rules="[ val => val && val.length > 0 || 'Please type something']"
    />

    <q-input type="textarea"
             v-model="mutate(editComponent).description"
             label="Component description *"
             hint="description of component"
             lazy-rules
             :rules="[ val => val && val.length > 0 || 'Please type something']"
    >
      <template v-slot:append>
        <ai-text-generator v-model="mutate(editComponent).description"/>
      </template>
    </q-input>

    <q-input
        v-model="mutate(editComponent).alias"
        label="Component alias *"
        hint="Unique alias of component"
        lazy-rules
    />


    <q-select
        v-model="mutate(editComponent).diagram_type"
        label="Diagram type *"
        hint="Choose diagram type"
        emit-value
        map-options
        :options="diagramTypes"
        lazy-rules
    />
    <q-select
        v-model="mutate(editComponent).status"
        label="Diagram status *"
        hint="Choose diagram status"
        emit-value
        map-options
        :options="diagramStatuses"
        lazy-rules
    />

    <div>
      <q-btn label="Save" type="submit" color="primary"/>
    </div>
  </q-form>

</template>

<script>
import {Diagram} from "@/../../common/db/Diagram.js"
import {AppModule} from '@/../../common/db/AppModule';
import AiTextGenerator from "@/components/AI/AiTextGenerator.vue";

export default {
  name: "ComponentForm",
  components: {AiTextGenerator},
  props: ['editComponent'],
  emits: ['closed', 'saved'],
  data: () => ({
    product: false,
    diagramStatuses: [{value: "active", label: "Active"}, {value: "archived", label: "Archived"}]
  }),

  computed: {
    /**
     * Current module
     * @return {*}
     */
    currentModule() {
      return this.wait("currentModule", AppModule.find(this.editComponent.module_id), {})
    },

    /**
     * Computed property that returns an array of diagram types.
     * The array always includes "component", "widget", and "process" types.
     * If the current module's type is "server", it also includes the "function" type.
     *
     * @returns {Array} An array of objects, each object has a 'value' and a 'label' property.
     */
    diagramTypes() {
      const diagramTypes = [
        {value: "component", label: "Component"},
        {value: "widget", label: "Widget"},
        {value: "process", label: "Process"},
      ];

      if (this.currentModule?.type === 'server') {
        diagramTypes.push({value: "function", label: "Function"});
      }

      return diagramTypes;
    },
  },

  methods: {

    /**
     * On form submit
     * @return {Promise<void>}
     */
    async onSaveComponent() {

      // Update or created (depends on id field existence)
      const cmp = await Diagram.remote().save(this.editComponent)

      // Hide dialog
      this.$emit('closed')
      this.$emit('saved', cmp)
    }
  }
}

</script>
