import {Quasar} from "quasar"
import {createApp} from 'vue'
import App from '@/App.vue'
import AbApp from "ab-application/src/install"
import {routes} from "./routes";
import translations from "../../common/locales/translations";
import db from "./db";
import stateConfig from "@/config";
import {constants} from "../../common/constants/constants"
import "./fonts"
import quasarUserOptions from "@/quasar-user-options";
import {registerComponents} from "@/components";
import "../../common/assets/fonts/fonts.css"
import "./styles/styles.scss"

// Create app
const app = createApp(App);
app.use(Quasar, quasarUserOptions);

// Config
app.config.unwrapInjectedRef = true

// Register components
registerComponents(app)

// Init app
app.use(AbApp, {
        baseURL: process.env.VUE_APP_BACKEND_URL,
        authURL: process.env.VUE_APP_BACKEND_URL,
        socketURL: process.env.VUE_APP_SOCKET_URL,
        requestTimeout: 30*60*1000,
        routes,
        translations,
        config: stateConfig,
        db
    },
    constants, () => {
        app.mount('#app');
    })
