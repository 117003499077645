<template>
  <ButtonPropsCmp :block="block" />
</template>

<script>
import ButtonPropsCmp from "@/components/DiagramDesigner/Editor/components/UI/Basic/Button/ButtonPropsCmp.vue";

export default {
  name: "SubmitPropsCmp",
  props: ['block'],
  components: {ButtonPropsCmp}
}

</script>

