<template>

  <div>
    <label>{{title}}</label>
    <media-picker v-model="currentValue" :media-type="mediaType" :product-id="product_id" :module-id="module_id"/>
  </div>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import MediaPicker from "@/components/MediaGallery/MediaPicker";

export default {
  components: {MediaPicker},
  mixins: [propertiesMixins],
  name: "UiPropMediaPicker",
  props: {
    mediaType: {
      type: String,
      default: "image",
    }
  }
}

</script>
