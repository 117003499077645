<template>
  <q-page class="q-pa-md" v-if="isReady">

    <div class="text-h6 q-mb-md">
      Stripe settings
    </div>
    <q-form @submit="saveProperties">

      <q-input
        label="Secret key (stage)"
        v-model="properties.apiKeyStage"
      />

      <q-input
        label="Secret key (release)"
        v-model="properties.apiKeyRelease"
      />

      <q-separator/>

      <div class="row">
        <q-btn type="submit" label="Save" color="primary" class="q-mt-md"/>
      </div>
    </q-form>

  </q-page>
</template>

<script>
import {integrationsMixin} from "@/pages/workspace/integrations/integrationsMixin";

export default {
  mixins: [integrationsMixin],

  name: "StripeIntegrationSettings",

  data() {
    return {};
  },

  computed: {
    /**
     * Return integration name
     */
    integration: () => ({name: "stripe", type: "stripe"}),
  },

  methods: {},
}

</script>
