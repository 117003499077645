<template>
  <div>
    <q-splitter
      v-model="splitterModel"
      unit="px"
      style="height: 400px"
    >

      <template v-slot:before>
        <template
          v-for="(element, idx) in svgElements"
          :key="element.id"
        >
          <q-separator v-if="idx > 0" spaced />

          <media-icon-editor-props v-model="element.model" />
        </template>
      </template>

      <template v-slot:after>
        <div class="row justify-center q-py-sm">
          <div
            v-if="svg"
            class="svg-container"
            ref="svgContainer"
            v-html="svg"
          />
        </div>
      </template>

    </q-splitter>

    <q-separator spaced />

    <div class="row justify-end">
      <q-btn label="Save" @click="saveIcon" />
    </div>
  </div>
</template>

<script>
import {computed} from 'vue';
import {nanoid} from 'nanoid';
import {AppStyle} from '../../../../common/db/AppStyle';
import {AppModule} from '../../../../common/db/AppModule';
import MediaIconEditorProps from '@/components/MediaGallery/MediaIconEditorProps.vue';

export default {
  name: 'MediaIconEditor',
  components: {MediaIconEditorProps},

  provide() {
    return {
      moduleColors: this.moduleColors,
      colors: computed(() => this.colors),
    };
  },

  inject: {
    currentModule: {
      default: null
    },
  },

  props: {
    svg: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: 'Svg icon',
    },
  },

  emits: ['save'],

  data() {
    return {
      isInitialized: false,
      splitterModel: 478,
      svgElements: [],
      moduleStyles: {},
      defaultModuleStyles: null,
    };
  },

  computed: {
    /**
     * Retrieves the module ID from the route parameters.
     *
     * @returns {string} The module ID.
     */
    moduleId() {
      return this.$route.params.module_id;
    },

    /**
     * Retrieves the module colors from the current module settings.
     *
     * @returns {Array} The array of module colors.
     */
    moduleColors() {
      return this.currentModule?.currentModule?.settings?.styles?.colors || [];
    },

    /**
     * Retrieves the colors from the module styles.
     *
     * @returns {Object} An object mapping color keys to their foreground values.
     */
    colors() {
      return Object.fromEntries(
        Object.entries(this.moduleStyles?.source?.colors || {}).map(([key, value]) => [
          key,
          value.foreground,
        ])
      );
    },

    /**
     * Determines if the SVG contains multiple color elements.
     *
     * @returns {boolean} True if there are multiple color elements, false otherwise.
     */
    multiColor() {
      return this.svgElements.length > 1;
    },
  },

  methods: {
    /**
     * Creates a linear gradient in the SVG element.
     *
     * @param {string} id - The unique identifier for the gradient.
     * @param {number} [angle=0] - The angle of the gradient in degrees.
     * @param {string} [fromColor='#ff0000'] - The starting color of the gradient.
     * @param {string} [toColor='#000000'] - The ending color of the gradient.
     * @returns {string|null} The ID of the created gradient or null if the SVG element is not found.
     */
    createLinearGradient(id, angle = 0, fromColor = '#ff0000', toColor = '#000000') {
      const svg = this.$refs.svgContainer.querySelector('svg');

      if (!svg) {
        return;
      }

      const rad = (angle * Math.PI) / 180;
      const x1 = (Math.cos(rad) + 1) / 2;
      const y1 = (Math.sin(rad) + 1) / 2;
      const x2 = 1 - x1;
      const y2 = 1 - y1;

      let defs = svg.querySelector("defs");
      if (!defs) {
        defs = document.createElementNS("http://www.w3.org/2000/svg", "defs");
        svg.appendChild(defs);
      }

      const gradientId = `g-${id}`;

      let linearGradient = svg.getElementById(gradientId);

      if (!linearGradient) {
        linearGradient = document.createElementNS("http://www.w3.org/2000/svg", "linearGradient");
      }

      linearGradient.replaceChildren();

      linearGradient.setAttribute("id", gradientId);
      linearGradient.setAttribute("x1", x1);
      linearGradient.setAttribute("y1", y1);
      linearGradient.setAttribute("x2", x2);
      linearGradient.setAttribute("y2", y2);

      const stop1 = document.createElementNS("http://www.w3.org/2000/svg", "stop");
      stop1.setAttribute("offset", "0");
      stop1.setAttribute("stop-opacity", "1");
      stop1.setAttribute("stop-color", fromColor);

      const stop2 = document.createElementNS("http://www.w3.org/2000/svg", "stop");
      stop2.setAttribute("offset", "1");
      stop2.setAttribute("stop-opacity", "1");
      stop2.setAttribute("stop-color", toColor);

      linearGradient.appendChild(stop1);
      linearGradient.appendChild(stop2);

      defs.appendChild(linearGradient);

      return gradientId;
    },

    /**
     * Converts an SVG element to a Base64-encoded string.
     *
     * @param {SVGElement} svgElement - The SVG element to convert.
     * @returns {Promise<string|null>} The Base64-encoded string or null if an error occurs.
     */
    async svgToBase64(svgElement) {
      return new Promise((resolve) => {
        try {
          const serializer = new XMLSerializer();
          const svgString = serializer.serializeToString(svgElement);

          const svgBlob = new Blob([svgString], { type: 'image/svg+xml' });

          const reader = new FileReader();

          reader.onloadend = () => {
            resolve(reader.result.split(',')[1]);
          };

          reader.onerror = () => {
            resolve(null);
          };

          reader.readAsDataURL(svgBlob);
        } catch (e) {
          console.log('Error converting svg to base64', e);

          resolve(null);
        }
      });
    },

    /**
     * Saves the current SVG icon by converting it to a Base64-encoded string
     * and emitting a 'save' event with the encoded string.
     *
     * @async
     * @method
     */
    async saveIcon() {
      try {
        const base64Image = await this.svgToBase64(this.$refs.svgContainer.querySelector('svg'));

        if (!base64Image) {
          throw new Error('Error converting svg to base64');
        }

        // Save color settings
        await this.updateModuleSettings();

        this.$emit('save', base64Image);
      } catch (e) {
        console.error('Error saving icon', e);

        this.$q.notify({
          message: 'Error saving icon',
          color: 'negative',
          icon: 'error',
          position: 'top'
        });
      }
    },

    /**
     * Sets the default module styles based on the provided styles object.
     *
     * @param {Object} stylesObj - The styles object containing type, color, and gradient properties.
     * @param {string} stylesObj.type - The type of the style, either 'gradient' or 'color'.
     * @param {string} stylesObj.color - The color value if the type is 'color'.
     * @param {Object} stylesObj.gradient - The gradient object if the type is 'gradient'.
     * @param {string} stylesObj.gradient.from - The starting color of the gradient.
     * @param {string} stylesObj.gradient.to - The ending color of the gradient.
     * @param {number} stylesObj.gradient.radius - The angle of the gradient in degrees.
     */
    setDefaultModuleStyles(stylesObj) {
      const { type, color, gradient } = stylesObj;

      if (!type && !color && !gradient) {
        return;
      }

      let styles = [];

      if (type === 'gradient') {
        styles.push('gradient', gradient.from, gradient.to, gradient.radius);
      } else {
        styles.push('color', color);
      }

      this.defaultModuleStyles = styles.length ? styles.join('|') : null;
    },

    /**
     * Loads the default module styles from the database and sets them.
     *
     * @async
     * @method
     */
    async loadDefaultModuleStyles() {
      const module = await AppModule.find(this.moduleId);

      if (!module?.id) {
        return;
      }

      this.setDefaultModuleStyles(module.settings.styles?.iconsColor || {})
    },

    /**
     * Updates the module settings with the current background settings.
     *
     * @async
     * @method
     */
    async updateModuleSettings() {
      try {
        if (this.multiColor) {
          return;
        }

        const module = await AppModule.find(this.moduleId);

        if (!module?.id) {
          return;
        }

        const styles = module.settings.styles || {};

        const { type, color, gradient } = this.svgElements?.[0]?.model || {};

        styles.iconsColor = {
          type: type,
          color: color,
          gradient: gradient,
        };

        module.settings.styles = styles;

        await AppModule.remote().save({
          id: module.id,
          settings: module.settings,
        });

        this.setDefaultModuleStyles(styles.iconsColor);
      } catch (e) {
        console.error('Error updating module settings', e);
      }
    },

    /**
     * Prepares an SVG element by extracting its fill or stroke color.
     *
     * @param {Element} el - The SVG element to prepare.
     * @returns {Object|boolean} An object containing the type and color of the element, or false if no color is found.
     */
    prepareElement(el) {
      let fillColor = el.getAttribute('fill');

      if (!fillColor || fillColor === 'none') {
        fillColor = el.style.fill;
      }

      if (fillColor && fillColor !== 'none') {
        return {
          type: 'fill',
          color: fillColor,
        };
      }

      let strokeColor = el.getAttribute('stroke');

      if (!strokeColor || strokeColor === 'none') {
        strokeColor = el.style.stroke;
      }

      if (strokeColor && strokeColor !== 'none') {
        return {
          type: 'stroke',
          color: strokeColor,
        };
      }

      return false;
    },

    /**
     * Parses a linear gradient from the SVG element.
     *
     * @param {string} id - The unique identifier for the gradient.
     * @returns {Object|boolean} An object containing the gradient details (angle, from color, to color) or false if the gradient is not found.
     */
    parseGradient(id) {
      const svg = this.$refs.svgContainer.querySelector('svg');

      if (!svg) {
        return false;
      }

      const gradient = svg.getElementById(id);

      if (!gradient) {
        return false;
      }

      const x1 = gradient.getAttribute('x1');
      const y1 = gradient.getAttribute('y1');
      const x2 = gradient.getAttribute('x2');
      const y2 = gradient.getAttribute('y2');

      const angle = Math.atan2(y1 - y2, x1 - x2) * (180 / Math.PI);

      const [stop1, stop2] = gradient.querySelectorAll('stop');

      return {
        angle,
        from: stop1?.getAttribute('stop-color') || stop1?.style?.stopColor || '#000',
        to: stop2?.getAttribute('stop-color') || stop2?.style?.stopColor || '#ccc',
      };
    },

    /**
     * Clears the fill and stroke attributes and styles from the given SVG element.
     *
     * @param {Element} el - The SVG element to clear.
     */
    clearElement(el) {
      el.removeAttribute('fill');
      el.removeAttribute('stroke');
      el.style.removeProperty('fill');
      el.style.removeProperty('stroke');
    },

    /**
     * Clears the SVG element by removing all 'defs' and 'linearGradient' elements.
     *
     * @param {SVGElement} svg - The SVG element to clear.
     */
    clearSvg(svg) {
      svg.querySelectorAll('defs').forEach((el) => el.remove());
      svg.querySelectorAll('linearGradient').forEach((el) => el.remove());
    },

    /**
     * Builds a map of SVG elements and their associated colors or gradients.
     * This method processes the SVG elements, assigns unique IDs to colors and gradients,
     * and stores the original color information in the dataset of each element.
     */
    buildMap() {
      const svg = this.$refs.svgContainer.querySelector('svg');

      if (!svg || svg.dataset.mapped === '1') {
        return;
      }

      const elements = [
        svg,
        ...svg.querySelectorAll('*'),
      ];

      const colorsMap = new Map();

      elements.forEach((el) => {
        const data = this.prepareElement(el);

        if (!data) {
          return;
        }

        const gradientId = data.color.match(/url\(["']?#(.*?)["']?\)/)?.[1];

        let originalColor = [];
        let colorId;

        if (colorsMap.has(gradientId || data.color)) {
          colorId = colorsMap.get(gradientId || data.color);
        } else {
          colorId = nanoid(10);
          colorsMap.set(gradientId || data.color, colorId);
        }

        if (gradientId) {
          const { from, to, angle } = this.parseGradient(gradientId);

          originalColor.push('gradient', from, to, angle);
        } else {
          originalColor.push('color', data.color);
        }

        originalColor = originalColor.join('|');

        el.dataset.group = colorId;
        el.dataset.fillType = data.type;
        el.dataset.original = originalColor;

        this.clearElement(el);
      });

      this.clearSvg(svg);

      if (!this.$refs.svgContainer.querySelectorAll('[data-group]').length) {
        svg.dataset.group = nanoid(10);
        svg.dataset.fillType = 'fill';
        svg.dataset.original = 'color|primary';
      }

      svg.dataset.mapped = '1';
    },

    /**
     * Initializes the model for an SVG element.
     *
     * @param {Element} el - The SVG element to initialize.
     * @param {boolean} [multicolor=false] - Indicates if the SVG contains multiple colors.
     * @returns {Object} The initialized model containing type, color, and gradient information.
     */
    initModel(el, multicolor = false) {
      const {a2u, original} = el.dataset;

      const defaultStyles = multicolor ? null : this.defaultModuleStyles;

      const [type, from, to, radius] = (a2u || defaultStyles || original || '').split('|');

      return {
        type: type || 'color',
        color: from || 'primary',
        gradient: {
          from: from || 'primary',
          to: to || 'secondary',
          radius: radius || 180,
        },
      };
    },

    /**
     * Initializes the models for SVG elements.
     * This method processes the SVG elements, assigns unique IDs to colors and gradients,
     * and stores the original color information in the dataset of each element.
     */
    initModels() {
      const groups = Array.from(this.$refs.svgContainer.querySelectorAll('[data-group]'));

      const multicolor = new Set(groups.map(el => el.dataset.group)).size > 1;

      this.svgElements = Object.values(
        groups.reduce((acc, el) => {
            const group = el.dataset.group;

            if (acc[group]) {
              acc[group].elements.push(el);
            } else {
              acc[group] = {
                id: group,
                elements: [el],
                model: this.initModel(el, multicolor),
              };
            }

            return acc;
          }, {})
      );
    },

    /**
     * Retrieves the color value from the colors object.
     *
     * @param {string} color - The key of the color to retrieve.
     * @returns {string} The color value or the original color if not found in the colors object.
     */
    getColor(color) {
      return this.colors[color] || color;
    },

    /**
     * Updates the styles of the SVG elements based on their models.
     * This method iterates over each SVG element, determines its style (color or gradient),
     * and applies the appropriate style to the element.
     */
    updateSvgStyles() {
      this.svgElements.forEach(({id, elements, model}) => {
        let style;

        let a2uData = [];

        if (model.type === 'gradient') {
          const { from, to, radius } = model.gradient;

          const gradientId = this.createLinearGradient(id, radius, this.getColor(from), this.getColor(to));

          style = `url(#${gradientId})`;

          a2uData.push('gradient', from, to, radius);
        } else {
          style = this.getColor(model.color);

          a2uData.push('color', model.color);
        }

        a2uData = a2uData.join('|');

        elements.forEach((el) => {
          const fillType = el.dataset.fillType || 'fill';

          el.dataset.a2u = a2uData;
          el.setAttribute(fillType, style);
          el.setAttribute(fillType === 'fill' ? 'stroke' : 'fill', 'none');
        });
      });
    },
  },

  watch: {
    /**
     * Watches for changes in the background settings and updates the SVG styles.
     */
    svgElements: {
      handler() {
        if (!this.isInitialized) {
          return;
        }

        this.updateSvgStyles();
      },
      deep: true,
    },
  },

  async created() {
    await AppStyle.remote().subscribe('module-styles', {module_id: this.moduleId});
    await AppModule.remote().subscribe('module', {id: this.moduleId});

    this.moduleStyles = await AppStyle.query().where({module_id: this.moduleId}).first();

    await this.loadDefaultModuleStyles();

    this.buildMap();

    this.initModels();

    this.isInitialized = true;
  },

  beforeUnmount() {
    AppStyle.remote().unsubscribe('module-styles');
    AppModule.remote().unsubscribe('module');
  },
}
</script>

<style scoped lang="scss">
.svg-container {
  width: 250px;
  height: 250px;

  &:deep(svg) {
    width: 100%;
    height: 100%;
  }
}
</style>
