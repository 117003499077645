<template>
  <q-list dense bordered padding class="rounded-borders">
    <q-item-label header>Diagram invocation list</q-item-label>

    <template v-if="diagramsList.length">
      <q-item v-for="diagram in diagramsList" :key="diagram.id" v-ripple :to="{params: {id: getIds(diagram.id)}}">
        <q-item-section>
          {{ diagram.title }}
        </q-item-section>
      </q-item>
    </template>
    <q-item v-else>
      <q-item-label caption>
        No diagrams found
      </q-item-label>
    </q-item>
  </q-list>
</template>

<script>
import {DiagramService} from '../../../services/diagramService';

export default {
  name: 'DiagramInvocationList',

  props: ['moduleId', 'diagramId'],

  data() {
    return {
      diagramsList: [],
    };
  },

  methods: {
    /**
     * This method is used to generate a string of IDs for routing purposes.
     * It takes the current route's 'id' parameter, splits it by comma into an array, and adds the provided 'id' to the array.
     * Finally, it joins the array back into a string with commas separating the IDs.
     *
     * @param {string} id - The ID to be added to the current route's 'id' parameter.
     * @returns {string} A string of IDs separated by commas.
     */
    getIds(id) {
      return [...this.$route.params.id?.split(','), ...[id]].join(",")
    },

    /**
     * Fetches the list of diagram invocations from the DiagramService.
     * The list is stored in the `diagramsList` data property.
     * If an error occurs during the fetch, it logs the error to the console.
     */
    async getDiagramsList() {
      try {
        this.diagramsList = await DiagramService.getDiagramInvocationList(this.moduleId, this.diagramId);
      } catch (e) {
        console.error('Error while fetching diagrams list:', e);
      }
    },
  },

  async created() {
    await this.getDiagramsList();
  },
}
</script>

<style scoped lang="scss">

</style>
