<template>

  <ui-prop-dynamic-string name="label" title="Input label" :block="block"/>
  <ui-prop-dynamic-string name="hint" title="Input hint" :block="block"/>

  <ui-prop-select name="fileType" title="File type" :block="block" :options="types" default-value="other"/>

  <template v-if="block.properties?.fileType === 'image'">
    <ui-prop-checkbox name="imageFixedDimensions" title="Image with fixed dimensions" :true-value="1" :false-value="0" :default-value="0" :block="block"/>

    <template v-if="block.properties?.imageFixedDimensions === 1">
      <ui-prop-string name="imageWidth" title="Image width" :block="block"/>
      <ui-prop-string name="imageHeight" title="Image height" :block="block"/>
    </template>
  </template>

  <ui-prop-select name="controlTextColor" title="Text color" :block="block" :options="moduleColors" default-value="primary"/>
  <ui-prop-select name="controlFocusColor" title="Focus color" :block="block" :options="moduleColors" default-value="info"/>

  <ui-prop-checkbox name="storageUpload" title="Upload to storage" :true-value="1" :false-value="0" :block="block"/>
  <ui-prop-select v-if="block.properties?.storageUpload" :block="block" name="moduleId" title="Backend module to upload to" :options="moduleList"/>

  <ui-prop-value-selector :block="block" title="Field variable" name="field" value-type="setter" :parent-diagram-id="diagram_id"/>
  <ui-dynamic-props :default-value="{}" :block="block" :product_id="product_id" :module_id="module_id" />

</template>

<script>

import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps.vue";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";
import UiPropCheckbox from "@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue";
import {AppModule} from "../../../../../../../../../common/db/AppModule";
import UiPropDynamicString from '@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue';

export default {
  mixins: [propertiesMixins],
  name: "FileUploaderPropsCmp",
  props: ['block'],
  components: {UiPropDynamicString, UiPropCheckbox, UiPropSelect, UiDynamicProps, UiPropValueSelector, UiPropString},

  data() {
    return {
      types: [ // File types
        { label: 'Other', value: 'other' },
        { label: 'Image', value: 'image' },
      ],
    };
  },

  computed: {
    moduleList() {
      return this.wait("moduleList", async () => {
        return (await AppModule.query().where("app_id", this.product_id).get()).filter(m => m.type === 'server').map(m => ({value: m.id, label: m.name}))
      }, []);
    }
  }
}

</script>

