<template>
  <q-page class="q-pa-md">

    <q-dialog ref="editApp">
      <app-form :edit-app="editApp" @saved="$refs.editApp.hide()"/>
    </q-dialog>

    <div class="row q-gutter-md">

      <template v-for="(app, k) of apps" :key="k">

        <q-card class="col-md-2 col-xs-12">

          <q-card-section class="bg-blue-1 row">
            <q-btn icon="edit" flat size="sm" round @click="gotoApp(app)"/><div class="q-ml-sm text-subtitle1">{{ app.title }}</div>
          </q-card-section>

          <q-separator/>

          <q-card-actions align="right">
            <q-btn flat @click="editApp = app.data();$refs.editApp.show()">{{ $t('actions.edit') }}</q-btn>
            <q-btn flat @click="deleteApp(app)">{{ $t('actions.delete') }}</q-btn>
          </q-card-actions>
        </q-card>

      </template>

    </div>

    <q-page-sticky position="bottom-right" :offset="[18, 18]">
      <q-fab fab icon="add" color="primary" direction="left">
        <q-fab-action icon="add" label="Add" color="primary" @click="editApp={};$refs.editApp.show()"/>
        <!--q-fab-action icon="edit" label="AI Wizard" color="info" @click="$router.push({name: 'wizard'})"/!-->

      </q-fab>
    </q-page-sticky>

  </q-page>
</template>

<style>
</style>

<script>
import AppForm from "./AppForm";
import {App} from "@/../../common/db/App";

export default {
  name: 'AppsList',
  components: { AppForm},
  data: () => ({
    editApp: false
  }),

  computed: {
    // Return all apps from the store
    apps() {
        return this.wait("apps", App.query().get(), [])
    },

    /**
     * Check if current user is admin
     * @return {boolean}
     */
    isAdmin() {
      return this.app.auth().hasRole("admin")
    }
  },

  methods: {

    /**
     * Go to inside the application
     * @param app
     */
    gotoApp(app) {
      this.$router.replace({name: 'app-modules', params: {app_id: app.id}})
    },

    /**
     * Delete app
     * @param app
     * @return {Promise<void>}
     */
    async deleteApp(app) {
      this.$q.dialog({
        title: 'Delete confirmation',
        message: `Are you sure want to delete ${app.title} ?`,
        cancel: true,
        persistent: true
      }).onOk(() => {
        App.remote().delete(app.id)
      })
    },
  }
}
</script>
