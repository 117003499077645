<template>
  <q-page class="window-height window-width row justify-center items-center">
    <div class="column">
      <div class="row">
        <h5 class="text-h5 q-my-md">Login page</h5>
      </div>
      <div class="row">
        <q-card square bordered class="q-pa-lg shadow-6">
          <q-card-section>
            <q-form @submit="doLogin" class="q-gutter-md">
              <q-input clearable v-model="email" type="email" label="email" :error="!!errorMessage" >
                <template v-slot:error>
                  {{ errorMessage }}
                </template>
              </q-input>
              <q-input clearable v-model="password" type="password" label="password" />
              <q-btn label="Login" type="submit" color="primary"/>
            </q-form>
          </q-card-section>
        </q-card>
      </div>
    </div>
  </q-page>
</template>


<script>

export default {
  name: "LoginPage",
  data: () => ({
    email: "",
    password: "",
    errorMessage: ""
  }),

  created() {
    
    // Go inside if logged in
    //if(this.app.auth.isLoggedIn()) this.$router.replace({name: 'workspace'})

  },

  methods: {
    async doLogin() {
      try {
        this.errorMessage = "";
        const userObj = await this.app.auth().login(this.email, this.password);
        if(userObj) {
          this.$router.replace({name: "workspace"});
        } else {
          this.errorMessage = "Invalid credentials";
        }
      }
      catch (e) {
        this.$q.notify({
          color: 'red-5',
          textColor: 'white',
          icon: 'warning',
          message: e?.message
        });
      }
    }
  }
}

</script>
