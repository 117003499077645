<template>

  <div>
    <ui-prop-checkbox
      :block="block"
      name="backgroundPlay"
      title="Enable Background Play"
      :false-value="0"
      :true-value="1"
      :default-value="0"
    />

    <template v-if="block?.properties?.backgroundPlay !== 1">
      <ui-prop-select :block="block" title="Channel" name="soundChannel" :options="globals.options.soundManagerChannels"/>
    </template>
    <template v-else>
      <ui-prop-dynamic-string :block="block" name="toolbarTitle" title="Toolbar title" />
      <ui-prop-dynamic-string :block="block" name="toolbarDesc" title="Toolbar description" />
    </template>
    <ui-prop-checkbox :block="block" name="loop" title="Loop playing"/>
    <!--ui-prop-media-picker name="soundFile" :block="block" media-type="sound" :product-id="product_id"/!-->
    <ui-prop-value-selector :block="block" only-var-type="sound" title="Sound file" name="soundFile" value-type="getter" :parent-diagram-id="diagram_id"/>

    <ui-prop-dynamic-string :block="block" name="duration" title="Fixed play duration, sec" untranslatable />
  </div>

</template>

<script>
import UiPropCheckbox from "@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";
import UiPropDynamicString from "@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";

export default {
  mixins: [propertiesMixins],
  inject: ['product_id'],
  components: {UiPropValueSelector, UiPropDynamicString, UiPropSelect, UiPropCheckbox},
  props: ['block'],
  name: "PlaySoundPropsCmp",
  computed: {
  }
}

</script>
