<template>
  <q-dialog ref="generator">
    <q-card class="full-width">
      <q-card-section class="bg-primary text-white row justify-center items-center text-h6">
        Text generator
        <q-space/>
        <q-btn v-if="result" label="Apply" flat icon="send" @click="applyValue"/>
        <q-btn icon="close" label="close" v-close-popup flat/>
      </q-card-section>

      <q-card-section class="bg-grey-2"  v-if="result">
        <q-input type="textarea" autogrow v-model="result" hint="Generated answer"/>
      </q-card-section>

      <q-card-section>
        <q-input
            v-model="promptString"
            type="textarea"
            placeholder="Text prompt"
            autogrow
        >
          <template v-slot:append>
            <q-btn label="Generate" flat color="primary" icon="send" @click="generateText"/>
          </template>
        </q-input>
      </q-card-section>
    </q-card>

  </q-dialog>
  <q-btn icon="draw_collage" round size="sm" flat @click="$refs.generator.show()"/>
</template>

<script>

export default {
  name: "AiTextGenerator",
  props: ['modelValue', 'extraInfo', 'params', 'prompt'],
  emits: ['update:modelValue'],
  data: () => ({
    promptString: "",
    result: ""
  }),
  created() {
    this.promptString = this.prompt
  },
  methods: {
    applyValue() {
      // Update model
      this.$emit('update:modelValue', this.result)
      this.$emit('close')
      this.$refs.generator.hide()
    },

    /**
     * Generate text
     */
    async generateText() {

      // Show loading
      this.$q.loading.show()

      try {
        // Generate
        this.result = await this.app.client.call("ai-designer", "generateText", this.promptString + `\n\n${this.extraInfo ? '\n\n'+this.extraInfo : ""}`, this.params)
      } catch (e) {
        this.$q.notify({
          color: 'negative',
          message: e.message,
          icon: 'report_problem',
          position: 'top'
        })
      }

      // Hide
      this.$q.loading.hide();
    }
  }
}

</script>
