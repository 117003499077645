<template>
  <q-page class="q-pa-md" v-if="isReady">

    <div class="text-h6">
      Application loader settings
    </div>
    <q-form @submit="saveProperties">

      <media-picker :product-id="appId" media-type="lottie" label="Choose loading animation" :module-id="moduleId" v-model="properties.loadingAnimation"/>

      <q-input label="Max width (0%-100%)" hint="default: 70%" v-model="properties.maxWidth"/>

      <q-separator/>
      <q-btn type="submit" label="Save" color="primary" class="q-mt-md"/>
    </q-form>

  </q-page>
</template>

<script>

import {integrationsMixin} from "@/pages/workspace/integrations/integrationsMixin";
import MediaPicker from "@/components/MediaGallery/MediaPicker.vue";

export default {
  components: {MediaPicker},
  mixins: [integrationsMixin],
  name: "LoaderIntegrationSettings",
  data: () => ({
    appId: false,
    moduleId: false
  }),

  created() {
    this.appId = this.$route.params.app_id || 0
    this.moduleId = this.$route.params.module_id || 0
  },

  computed: {

    /**
     * Return integration name
     */
    integration: () => ({name: "loader", type: "app"}),
  }
}

</script>
