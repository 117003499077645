<template>
  <ab-flow-base-cmp :movable="true" :block="block" class="task-editor-cmp">

    <div class="ev-run column">
      <ab-flow-link-connector :block="block" type="input" event="run"/>
      <ab-flow-link-connector v-for="(e, k) of incomingEvents" :block="block" type="input" :event="e.name" :key="k"/>
    </div>

    <q-card>

      <q-card-section class="bg-yellow-8 text-black">
        Task: {{title}}
      </q-card-section>

    </q-card>

    <div class="results">
      <ab-flow-link-connector class="q-mt-sm" :block="block" type="output" event="completed"/>
      <ab-flow-link-connector v-for="(e, k) of outgoingEvents" :block="block" type="output" :event="e.name" :key="k"/>
    </div>

  </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";
import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";

export default {
  mixins: [renderMixins],
  components: { AbFlowBaseCmp, AbFlowLinkConnector},
  inject: ['canvas'],
  props: ['block'],
  name: "TaskEditorCmp",
  computed: {

    /**
     * Incoming events
     * @return {*|*[]}
     */
    incomingEvents() {
      return this.block.properties?.['incoming-events'] || []
    },

    /**
     * Outgoing events
     * @return {*|*[]}
     */
    outgoingEvents() {
      return this.block.properties?.['outgoing-events'] || []
    },

    /**
     * Task title
     * @return {{}}
     */
    title() {
      return this.interpretString(this.block.properties?.title) || "n/a"
    },
  }
}

</script>

<style lang="scss">

.task-editor-cmp {
  .ev-run {
    left:-12px;
    position: absolute;
    top:calc(50% - 5px);
  }

  .results {
    position: absolute;
    right: -12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

</style>
