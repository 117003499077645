<template>
  <q-select
    v-model="currentValue"
    :use-input="noFilter ? true : false"
    input-debounce="0"
    @filter="filterFn"
    emit-value
    map-options
    option-value="value"
    :label="title"
    :options="selectOptions"
    :multiple="multiply === 1"
    :clearable="clearable"
    @update:model-value="$emit('change', $event)"
  />
</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";

export default {
  mixins: [propertiesMixins],
  name: "UiPropSelect",
  props: ['options', 'noFilter', 'multiply', 'clearable'],
  data: () => ({
    selectOptions: []
  }),

  /**
   * Init options
   */
  created() {
    if (!this.currentValue && this.multiply === 1) {
      this.currentValue = [];
    }

    this.selectOptions = this.options
  },

  methods: {

    /**
     * Filter options
     * @param val
     * @param update
     */
    filterFn(val, update) {
      update(() => {
        const needle = val.toLowerCase()
        this.selectOptions = needle ? this.options.filter(v => v.label.toLowerCase().indexOf(needle) > -1) : this.options
      })
    }
  }
}

</script>
