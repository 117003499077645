<template>
  <div class="overflow-hidden q-pa-sm" :class="{'row no-wrap svg-editor-header': model.type === 'color'}">
    <div class="row no-wrap">
      <div class="color-preview q-mt-auto shadow-1" :style="previewStyles" />

      <q-select
        class="color-type"
        v-model="model.type"
        :options="backgroundTypes"
        label="Background type"
        emit-value
        map-options
      />
    </div>
    <div class="row no-wrap svg-editor-header">
      <q-select
        v-if="model.type === 'color'"
        v-model="model.color"
        :options="moduleColors"
        label="Color"
        emit-value
        map-options
      />
      <template v-else-if="model.type === 'gradient'">
        <q-select
          v-model="model.gradient.from"
          :options="moduleColors"
          label="From"
          emit-value
          map-options
        />
        <q-select
          v-model="model.gradient.to"
          :options="moduleColors"
          label="To"
          emit-value
          map-options
        />
        <q-input
          v-model.number="model.gradient.radius"
          label="Radius, 0-360°"
          type="number"
          :min="0"
          :max="360"
        />
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MediaIconEditorProps',

  inject: {
    moduleColors: {
      default: []
    },
    colors: {
      default: {},
    },
  },

  props: {
    modelValue: {
      required: true,
      type: Object,
      default: () => ({
        type: 'color',
        color: 'primary',
        gradient: {
          from: 'primary',
          to: 'secondary',
          radius: 180,
        },
      }),
    },
  },

  emits: ['update:modelValue'],

  data() {
    return {
      backgroundTypes: [
        {value: 'color', label: 'Color'},
        {value: 'gradient', label: 'Gradient'},
      ],
    };
  },

  computed: {
    model: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      },
    },

    /**
     * Computes the styles for the color preview based on the current model type.
     * If the model type is 'gradient', it returns a linear gradient background style.
     * Otherwise, it returns a solid color background style.
     *
     * @returns {Object} The style object for the color preview.
     */
    previewStyles() {
      if (this.model.type === 'gradient') {
        const { from, to, radius } = this.model.gradient;

        return {
          background: `linear-gradient(${radius}deg, ${this.getColor(from)}, ${this.getColor(to)})`,
        };
      }

      return {
        background: this.getColor(this.model.color),
      };
    },
  },

  methods: {
    /**
     * Retrieves the color value based on the provided name.
     * If the name exists in the `colors` object, it returns the corresponding value.
     * Otherwise, it returns the name itself.
     *
     * @param {string} name - The name of the color to retrieve.
     * @returns {string} - The color value or the name if not found in `colors`.
     */
    getColor(name) {
      return this.colors[name] || name;
    },
  },

  watch: {
    model: {
      deep: true,
      handler(value) {
        this.$emit('update:modelValue', value);
      },
    },
  },
}
</script>

<style scoped lang="scss">
.svg-editor-header {
  & > * {
    flex: 1;
  }
}
.color-preview {
  font-size: 2.7rem;
  width: 1em;
  height: 1em;
}
.color-type {
  flex: 1;
}
.row {
  column-gap: 8px;
}
</style>
