<template>

  <div>
    <div class="text-subtitle1">Box shadow:</div>
    <div class="row">
      <q-input class="box-shadow-field q-px-xs" dense type="number" hide-bottom-space label="Offset X" v-model="shadow.x" />
      <q-input class="box-shadow-field q-px-xs" dense type="number" hide-bottom-space label="Offset Y" v-model="shadow.y" />
      <q-input class="box-shadow-field q-px-xs" dense type="number" hide-bottom-space label="Blur" v-model="shadow.blur" />
      <q-input class="box-shadow-field q-px-xs" dense type="number" hide-bottom-space label="Spread" v-model="shadow.spread" />

      <color-picker title="Shadow color" v-model="shadow.color"/>
    </div>
  </div>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import ColorPicker from "@/components/ColorPicker/ColorPicker.vue";

export default {
  inject: ['canvas'],
  mixins: [propertiesMixins],
  name: "UiPropBoxShadow",
  components: {ColorPicker},

  data: () => ({
    shadow: {
      x: 3,
      y: 3,
      blur: 10,
      spread: -4,
      color: "#333333ff"
    }
  }),

  created() {

    // Init current value
    if(this.currentValue) {
      this.shadow = this.currentValue
    } else {
      this.currentValue = this.shadow;
    }

    // Add watcher
    this.$watch('shadow', function (newVal) {
      this.currentValue = newVal
    }, {deep: true})
  },

  computed: {
    // Redefine prop name
    type: () => "boxShadow"
  }
}

</script>

<style lang="scss" scoped>
.box-shadow-field {
  max-width: 25%;
}
</style>
