<template>

  <ab-flow-base-cmp
    class="roulette-selector"
    :block="block"
    :class="classesString"
    :style="`--roulette-line-height: ${lineHeight};--roulette-height: ${height};` + stylesString"
  >
      <ab-flow-link-connector :block="block" type="output" event="changed"/>
      <vue-scroll-picker ref="selector" class="no-pointer-events" :preview="true" :options="options" :stripes-color="stripesColor" background-color="transparent" :options-color="optionsColor" :selection-color="selectionColor"/>
  </ab-flow-base-cmp>

  </template>

  <script>
  import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
  import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
  import {VueScrollPicker} from "@/components/VueScrollPicker/VueScrollPicker";
  import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";
  import "@/components/VueScrollPicker/styles.css"

  export default {
    components: { AbFlowBaseCmp, VueScrollPicker, AbFlowLinkConnector },
    mixins: [renderMixins],
    props: ['block'],
    name: "RouletteSelectorEditorCmp",
    data: () => ({
      value: "",
      opts: [],
    }),

    methods: {

    },
    computed: {

      /**
       * Computed property that returns the position of extra options.
       * @return {string} The position of extra options. If the `manualOptionsState` property of the block is not defined, it returns 'none'.
       */
      extraOptionsPosition() {
        return this.block.properties?.manualOptionsState || 'none';
      },

      /**
       * Computed property that returns an array of extra options.
       * Each option is an object with `title` and `value` properties.
       * If the `extraOptionsPosition` is 'none', it returns an empty array.
       * If the `manualOptions` property of the block is not defined, it also returns an empty array.
       * @return {Array} An array of extra options.
       */
      extraOptions() {
        return (this.block.properties?.manualOptions?.items || [])
          .map((v) => ({name: v.label, value: v.value}));
      },

      /**
       * Options list
       * @return {*}
       */
      options() {
        // Main options
        const mainOptions = (this.getValue(this.block?.properties?.options) || []).map(v => ({name: v.title, value: v.value}));

        // Extra options position
        const extraOptsPos = this.extraOptionsPosition;

        // Options
        const opts = [];

        // If extra options should be before main options
        if (extraOptsPos === 'before' && this.extraOptions.length) {
          opts.push(...this.extraOptions);
        }

        // Main options
        opts.push(...mainOptions);

        // If extra options should be after main options
        if (extraOptsPos !== 'before' && this.extraOptions.length) {
          opts.push(...this.extraOptions);
        }

        // return options
        return opts;
      },

      /**
       * Options color
       * @return {*}
       */
      optionsColor() {
        return this.designer.currentStyle.getColor(this.block?.properties?.optionsColor || "primary", "foreground")
      },

      /**
       * Selection color
       * @return {*}
       */
      selectionColor() {
        return this.designer.currentStyle.getColor(this.block?.properties?.selectionColor || "info", "foreground")
      },

      /**
       * Stripes color
       * @return {*}
       */
      stripesColor() {
        return this.designer.currentStyle.getColor(this.block?.properties?.stripesColor || "primary", "foreground")
      },

      /**
       * Selectors list
       * @return {*}
       */
      selectorsList() {
        return this.block.properties.selectors || []
      },

      /**
       * Line height
       * @return {*}
       */
      lineHeight() {
        return `${this.block?.properties?.lineHeight || 1.2}em`;
      },

      /**
       * Height
       * @return {*}
       */
      height() {
        // Get line height
        const lineHeight = parseFloat(this.block?.properties?.lineHeight || 1.2);

        // Calculate height
        // 5 - default height
        // 1.2 - default line height
        const height = 5 / 1.2 * lineHeight;

        return `${height.toFixed(4)}em`;
      },
    },

    watch: {
      /**
       * After the line height is computed, it triggers a resize on the selector component.
       *
       * @return {string} The line height in em units.
       */
      lineHeight() {
        this.$refs.selector.resize();
      },
    },
  }

  </script>

<style lang="scss">

:root {
  --my-color: #ff0000; /* Your color in RGB */
  --my-alpha: 0.5; /* Your alpha value */
}

.roulette-selector {
  position: relative;
  display: flex;
  flex-grow: 1;

  .output {
    position: absolute;
    left: 50%;
    top: 50%;
  }

  .selector {
    .vue-scroll-picker-layer {
      //background: var(--background-color-primary);
    }
    .vue-scroll-picker-layer-bottom {
      //background: linear-gradient(0deg,rgba(red(var(--background-color-primary)), green(#ff0000), blue(#ff0000), 0.1), rgba(#ff0000, 0.7));
    }
  }

  .vue-scroll-picker {
    height: var(--roulette-height, 5em);
    mask-image: linear-gradient(to bottom, transparent, black calc(50% - 1em), black calc(100% - calc(50% - 1em)), transparent);
    mask-size: 100%;
    mask-position: 0 0;
    mask-repeat: no-repeat;
  }

  .vue-scroll-picker-layer-top, .vue-scroll-picker-layer-bottom {
    background: none !important;
  }

  .vue-scroll-picker-item {
    line-height: var(--roulette-line-height, 1.2em);
  }
}
</style>
