<template>

  <ab-flow-base-cmp class="navigation-panel row" :block="block" :class="classesString" :style="stylesString">
    <q-tabs v-model="tab" class="col" no-caps :switch-indicator="selPosition" :vertical="vertical" :inline-label="vertical" >
      <template v-for="(item, i) of itemsList" :key="item.title">
        <q-tab :icon="getIcon(item)" :name="item.value">
          <label>{{item.title}}</label>
          <ab-flow-link-connector class="link-connector" :key="i" :block="block" type="output" :event="item.value"/>
        </q-tab>
      </template>
    </q-tabs>
  </ab-flow-base-cmp>

</template>

<script>
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import {pathHelper} from '@/utils/pathHelper';

export default {
  components: { AbFlowBaseCmp, AbFlowLinkConnector },
  mixins: [renderMixins],
  props: ['block'],
  name: "NavigationPanelEditorCmp",
  data: () => ({
    tab: ""
  }),

  computed: {

    /**
     * If vertical direction
     * @return {boolean}
     */
    vertical() {
      return ['left', 'right'].includes(this.block?.properties?.position)
    },

    /**
     * If up position
     * @return {boolean}
     */
    selPosition() {
      return ['right', 'down'].includes(this.block?.properties?.position)
    },

    /**
     * Items list
     * @return {*}
     */
    itemsList() {

      // get items from node
      const dynItems = (this.getValue(this.block?.properties?.dynamicItems) || [])?.filter(item => item && typeof item === 'object')?.map(item => ({
          title: item.title || item.label || item.name || "No Name",
          value: item.value || item.id || "No value",
        }));

      // return items
      return [...(this.block?.properties?.items?.items || []).map((item) => ({
        ...item,
        title: this.interpretString(item.title),
      })), ...dynItems]
    },


    /**
     * Button color
     * @return {string}
     */
    buttonColor() {
      return this.block?.properties?.color || "";
    },

    /**
     * View mode
     * @return {string}
     */
    viewMode() {
      return this.block?.properties?.viewMode || 'icon';
    },
  },

  methods: {
    /**
     * Get icon
     * @param item
     * @return {string|undefined}
     */
    getIcon(item) {
      // If icon mode return icon
      if (this.viewMode === 'icon') {
        return typeof item.icon === 'string' ? item.icon : undefined;
      }

      // Get active image
      const activeImage = item.activeImage?.source_url || undefined;

      // If active tab and active image
      if (item.value === this.tab && activeImage) {
        return `img:${pathHelper.assetPath(activeImage)}`;
      }

      // Get image
      const image = item.image?.source_url || undefined;

      // Return image
      return image ? `img:${pathHelper.assetPath(image)}` : undefined;
    },
  },
}

</script>

<style lang="scss">
.navigation-panel {

  .q-tab {
    padding: 0 10px;
  }

  .q-tabs--vertical {
    .q-tab {
      justify-content: start;

      label {
        margin-left:5px;
      }

    }
  }

  .link-connector {
    z-index: 1;
    position: absolute;
    bottom: 5%;
  }
}
</style>
