<template>
  <q-page class="q-pa-md" v-if="isReady">

    <div class="text-h6">
      Marketing pixels
    </div>
    <q-form @submit="saveProperties">

      <q-input label="Facebook pixel ID" v-model="properties.facebookId"/>
      <q-input label="TikTok pixel ID" v-model="properties.tiktokId"/>

      <q-separator/>
      <q-btn type="submit" label="Save" color="primary" class="q-mt-md"/>
    </q-form>

  </q-page>
</template>

<script>

import {integrationsMixin} from "@/pages/workspace/integrations/integrationsMixin";

export default {
  components: {},
  mixins: [integrationsMixin],
  name: 'MarketingPixelsIntegrationSettings',
  data: () => ({
    placement: {},
  }),

  computed: {

    /**
     * Return integration name
     */
    integration: () => ({name: 'marketing-pixels', type: 'analytics'}),
  }
}

</script>
