<template>
  <ui-prop-section :title="bindName" :title-class="bgClass">
      <value-selector
        v-if="bindName"
        :app-id="appId"
        :module-id="moduleId"
        :block-id="blockId"
        :parent-diagram-id="parentDiagramId"
        v-model="value"
        :value-type="valueType"
      />
  </ui-prop-section>
</template>

<script>
import ValueSelector from "@/components/ValueSelector/ValueSelector.vue";
import UiPropSection from "@/components/DiagramDesigner/Editor/properties/UiPropSection.vue";

export default {
  name: "BindingCard",

  components: {UiPropSection, ValueSelector },

  props: {
    bindName: String,
    appId: [String, Number],
    moduleId: [String, Number],
    blockId: [String, Number],
    parentDiagramId: [String, Number],
    modelValue: [String, Object],
    bgClass: String,
    valueType: {
      type: String,
      default: undefined
    }
  },

  emits: ['update:modelValue'],

  computed: {
    value: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit('update:modelValue', value);
      }
    },
  },
}
</script>
