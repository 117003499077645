import {AccessModel} from './AccessModel.js';

/**
 * AnalyticsEvent model
 */
export class AnalyticsEvent extends AccessModel {
    static entity = 'analytics_events'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        app_id: "int",
        module_id: "int",
        title: "string",
        event_id: "string",
        is_kpi: "int",
        position: "int",
    }

    /**
     * Partitions list
     */
    async channels() {
        return {
            'module-events': {
                subscribe: ({module_id}) => module_id,
                init: async ({module_id}) => AnalyticsEvent.getList(module_id),
            },
            'event': {
                subscribe: ({event_id}) => event_id,
                init: async ({event_id}) => AnalyticsEvent.query().where({event_id}).first(),
            },
            'kpi-events': {
                subscribe: ({module_id}) => module_id,
                init: async ({module_id}) => AnalyticsEvent.query().where({module_id, is_kpi: 1}).orderBy('position').get(),
            }
        }
    }

    /**
     * Get list
     */
    static async getList(module_id) {
        return this.query().where({module_id}).get()
    }
}
