<template>
  <ab-flow-base-cmp :movable="true" :block="block" class="system-alarm-editor-cmp">

    <ab-flow-link-connector :block="block" type="input" class="ev-run" event="run"/>
    <div
      class="content column"
      :class="` dg-background-${backgroundColor} dg-foreground-${foregroundColor} alarm-type-${alarmType} `"
    >
      <div class="text-h4">{{title}}</div>
      <div class="text-h6">{{subTitle}}</div>

      <template v-if="alarmType !== 'push'">
        <div class="col column items-center justify-center relative-position">
          <img class="open-image" :src="openImage"/>

          <span
            v-if="openText"
            class="absolute-center text-no-wrap q-pa-xs bg-primary text-white rounded-borders"
            v-text="openText"
          />
        </div>
        <div class="relative-position">
          <img class="dismiss-image" :src="dismissImage"/>

          <span
            v-if="dismissText"
            class="absolute-center text-no-wrap q-pa-xs bg-accent text-white rounded-borders"
            v-text="dismissText"
          />
        </div>
      </template>
    </div>

    <div class="results">
      <ab-flow-link-connector class="q-mt-sm" :block="block" type="output" event="completed"/>
    </div>

  </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";
import {pathHelper} from "@/utils/pathHelper";

export default {
  mixins: [renderMixins],
  components: { AbFlowBaseCmp, AbFlowLinkConnector },
  inject: ['canvas'],
  props: ['block'],
  name: "SystemAlarmEditorCmp",
  computed: {

    /**
     * title
     * @return {string}
     */
    title() {
      return this.interpretString(this.block.properties?.title) || 'No text';
    },

    /**
     * Subtitle
     * @return {string}
     */
    subTitle() {
      return this.interpretString(this.block.properties?.subTitle) || "Alarm subtitle";
    },

    /**
     * Background color
     * @return {string}
     */
    backgroundColor() {
      return this.block.properties?.backgroundColor || "primary";
    },

    /**
     * Open image
     * @return {string}
     */
    openImage() {
      const imPath = this.block?.properties?.openImage?.source_url
      const path =  pathHelper.assetPath(imPath)
      return path ? path : require("@/assets/plugs/default-image.png");
    },

    /**
     * Dismiss image
     * @return {string}
     */
    dismissImage() {
      const imPath = this.block?.properties?.dismissImage?.source_url
      const path =  pathHelper.assetPath(imPath)
      return path ? path : require("@/assets/plugs/default-image.png");
    },

    /**
     * Foreground color
     * @return {string}
     */
    foregroundColor() {
      return this.block.properties?.foregroundColor || "primary";
    },

    alarmType() {
      return this.interpretString(this.block.properties?.alarmType) || "alarm";
    },

    /**
     * Open text
     * @return {string}
     */
    openText() {
      return this.interpretString(this.block.properties?.openText) || '';
    },

    /**
     * Dismiss text
     * @return {string}
     */
    dismissText() {
      return this.interpretString(this.block.properties?.dismissText) || '';
    },
  }
}

</script>

<style lang="scss">

.system-alarm-editor-cmp {
  .ev-run {
    left:-12px;
    position: absolute;
    top:calc(50% - 5px);
    z-index: 1;
  }

  .content {
    width: 300px;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.alarm-type-push {
      align-items: flex-start;
      height: auto;
      padding: 0 10px;
    }

    .open-image {
      width: 40%;
      height: 40%;
    }

    .dismiss-image {
      height: 50px;
      max-width: 90%;
    }
  }


  .results {
    position: absolute;
    right: -12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

</style>
