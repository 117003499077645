<template>

  <div class="column">
    <q-select label="Operation to call" v-model="editValue.operation" :options="globals.options.valueOperations" map-options emit-value class="col"/>

    <div>
      <template v-if="editValue.operation === 'find'">
        <value-selector title="Array" v-model="editValue.array" only-var-type="object" :app-id="appId" :parent-diagram-id="parentDiagramId" :block-id="blockId" :module-id="moduleId" :is-array="true"/>
        <q-input v-model="editValue.propertyPath" label="Property path"/>
        <q-select :options="globals.options.comparison_operations" v-model="editValue.comparison" emit-value map-options label="Comparison operation"/>
        <value-selector title="Operand" v-model="editValue.operand" value-type="getter" :app-id="appId" :parent-diagram-id="parentDiagramId" :block-id="blockId" :module-id="moduleId" :is-array="false"/>
        <q-input v-model="editValue.resultPath" label="Result path"/>
      </template>

      <template v-else-if="editValue.operation === 'filter'">
        Filter
      </template>

      <template v-else-if="editValue.operation === 'property'">
        property
      </template>
    </div>
  </div>
</template>

<script>

import ValueSelector from "@/components/ValueSelector/ValueSelector.vue";
import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";

export default {
  mixins: [renderMixins],
  name: "ValueSelectorOperation",
  components: {ValueSelector},
  props: {
    modelValue: {
      type: Object,
      default: () => ({})
    },
    appId: {},
    parentDiagramId: {},
    blockId: {},
    moduleId: {}
  },
  data: () => ({
    editValue: {}
  }),
  created() {

    // Init value
    this.editValue = this.modelValue

    // Save model value on change
    this.$watch('editValue', () => {
      this.$emit('update:modelValue', this.editValue)
    }, {deep: true})
  },
}

</script>
