<template>
  <q-layout view="lHh Lpr lFf" v-if="isReady">
    <q-header >
      <q-toolbar>
        <q-btn
            flat
            dense
            round
            @click="leftDrawerOpen = !leftDrawerOpen"
            aria-label="Menu"
            icon="menu"
        />

        <q-toolbar-title class="row items-center app-toolbar">

          <template v-if="currentApp?.id">
            {{currentApp?.title}} :: {{currentModule?.name}}
          </template>

        </q-toolbar-title>

        <component v-if="$route.meta.toolbar" :is="$route.meta.toolbar"/>

      </q-toolbar>
    </q-header>

    <q-drawer
        v-model="leftDrawerOpen"
        show-if-above
        bordered
        elevated
        class="bg-grey-2"
    >
      <workspace-navigation :key="currentModuleId"/>
    </q-drawer>

    <q-page-container>
      <router-view :key="upKey + $route.name"/>
    </q-page-container>
  </q-layout>
</template>

<script>
import {computed, ref} from 'vue'
import WorkspaceNavigation from "@/pages/workspace/components/WorkspaceNavigation";
import {App} from "@/../../common/db/App";
import {Diagram} from "@/../../common/db/Diagram";
import {StorageNode} from "@/../../common/db/StorageNode";
import {DbModule} from "@/../../common/db/DbModule";
import {AppModule} from "@/../../common/db/AppModule";
import {DbTable} from "@/../../common/db/DbTable";
import {DbTableField} from "@/../../common/db/DbTableField";
import {DbTableChannel} from "@/../../common/db/DbTableChannel";
import {CodeFunction} from "@/../../common/db/CodeFunction";

export default {
  name: 'ModuleTemplate',
  data: () => ({
    isReady: false,
    currentModuleId: false,
    currentAppId: false,
    upKey: 0
  }),

  provide: function () {
    return {
      currentModuleId: computed(() => this.currentModuleId),
      currentModule: this
      //currentModule: computed(() => this.currentModule)
    }
  },

  /**
   * On page created
   */
  async created() {

    // Load common data
    await this.loadCommonData();

    // Load data
    this.$watch("$route.params.module_id", async () => {
      await this.loadCommonData()
    })

    this.modules;

    // Loaded
    this.isReady = true

  },

  components: {
    WorkspaceNavigation
  },

  setup () {
    return {
      leftDrawerOpen: ref(false)
    }
  },

  computed: {

    /**
     * Current app name
     * @return {*}
     */
    currentApp() {
      return this.wait("app", App.find(this.$route.params.app_id), {})
    },

    /**
     * Current app name
     * @return {*}
     */
    currentModule() {
      return this.wait("module", AppModule.find(this.$route.params.module_id), {})
    },

    /**
     * Get modules object
     * @return {*}
     */
    modules() {
      return this.wait("modules", async () => {
        const modules = {};

        // Load modules
        for(const m of await AppModule.query().where({app_id: this.currentAppId}).get()) {
          modules[m.id] = m;
        }

        // Return modules
        return modules;
      }, {})
    }
  },

  methods: {

    /**
     * Load common data
     */
    async loadCommonData() {

      // Set current module id and app id
      this.currentModuleId = this.$route.params.module_id || 0;
      this.currentAppId = this.$route.params.app_id || 0;

      // Fetch all products and ideas
      await App.remote().subscribe('app', {id: this.currentAppId})

      // Load app diagrams
      await Diagram.remote().subscribe("app-module-diagrams", {module_id: this.currentModuleId})

      // Subscribe for storage
      await StorageNode.remote().subscribe('app-storage', {app_id: this.currentAppId})

      // Subscribe for databases
      await DbModule.remote().subscribe('app-dbs', {app_id: this.currentAppId})

      // Subscribe for tables
      await DbTable.remote().subscribe('app-tables', {app_id: this.currentAppId})

      // Subscribe for table fields
      await DbTableField.remote().subscribe('app-table-fields', {app_id: this.currentAppId})

      // Subscribe for table channels
      await DbTableChannel.remote().subscribe('app-table-channels', {app_id: this.currentAppId})

      // Subscribe to modules
      await AppModule.remote().subscribe("app-modules",{app_id: this.currentAppId})

      // Load app diagrams
      await CodeFunction.remote().subscribe("app-functions", {app_id: this.currentAppId})

      // Update key
      this.upKey++;
    },
  }
}
</script>

<style lang="scss">

.app-toolbar {
  .version-selector {
    height: 40px;
    background: white;
    padding-left:10px;
    padding-right:10px;
    border-radius: 10px;

    .q-field__control, .q-field__marginal, .q-field__native {
      height: 40px;
      min-height: 40px;
    }
  }
}

</style>
