<template>
  <div class="full-width">
    <q-editor
      v-if="type === 'html'"
      ref="editor"
      v-model="localValue"
      :dense="$q.screen.lt.md"
      class="dg-text-regular"
      :content-class="!localValue ? 'bg-warning' : null"
      :toolbar="[
        [
          'locale', 'style', 'color', 'left', 'center', 'right', 'justify', 'bold', 'italic', 'strike', 'underline', 'subscript', 'superscript',
          'hr', 'link',
          'quote', 'unordered', 'ordered', 'outdent', 'indent', 'removeFormat'
        ],
      ]"
    >
      <template v-if="label" #locale>
        <strong class="q-mr-sm">{{ label }}</strong>
      </template>

      <template v-slot:style>
        <q-btn-dropdown
          dense no-caps
          ref="styleRef"
          no-wrap
          unelevated
          label="Style"
          size="sm"
        >
          <q-list dense>
            <q-item v-for="(st) of globals.options.textStyles" tag="label" clickable @click="setStyle('dg-text-'+st.value, 'style')" :key="st.value">
              <q-item-section>{{st.label}}</q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </template>

      <template v-slot:color>
        <q-btn-dropdown
          dense no-caps
          ref="colorRef"
          no-wrap
          unelevated
          label="Color"
          size="sm"
        >
          <q-list dense>
            <q-item v-for="(st) of moduleColors" tag="label" clickable @click="setStyle('dg-foreground-' + st.value, 'color')" :key="st.value">
              <q-item-section>{{st.label}}</q-item-section>
            </q-item>
          </q-list>
        </q-btn-dropdown>
      </template>
    </q-editor>

    <q-input
      v-else
      :label="label"
      :type="multiple ? 'textarea' : 'text'"
      filled
      dense
      :bg-color="inputBgColor"
      v-model="localValue"
    />
  </div>
</template>

<script>
export default {
  name: 'LocalizationEditorField',

  props: ['modelValue', 'multiple', 'type', 'label', 'currentValue'],

  inject: {
    currentModule: {
      default: null
    },
  },

  computed: {
    localValue: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    /**
     * Computed property that returns the colors from the current module's settings styles.
     * It uses optional chaining to safely access nested properties.
     * If any of the properties in the chain is undefined, it will return an empty array.
     *
     * @returns {Array} - An array of colors or an empty array if not defined.
     */
    moduleColors() {
      return [
        {value: "", label: "None"},
        ...(this.currentModule?.currentModule?.settings?.styles?.colors || [])
      ];
    },

    /**
     * Computed property that determines the background color of the input field.
     *
     * This method checks the current value of the input field and returns a color based on its state:
     * - If the input field is empty, it returns 'warning'.
     * - If the current value of the input field does not match the original value, it returns 'positive'.
     * - If none of the above conditions are met, it returns null.
     *
     * @returns {string|null} The color for the input field background or null if no color should be applied.
     */
    inputBgColor() {
      if (!this.localValue) {
        return 'warning';
      }

      if (this.modelValue !== this.currentValue) {
        return 'positive';
      }

      return null
    },
  },

  methods: {
    /**
     * Add class to selection
     * @param style
     * @param type
     */
    setStyle(style, type = 'style') {
      const edit = this.$refs.editor;
      (type === 'style' ? this.$refs.styleRef : this.$refs.colorRef).hide()
      edit.caret.restore()

      const cl = (edit.caret.parent.className.split(" ") || []).filter(c => {
        if (type === 'style') {
          return c.indexOf('dg-text-') === -1
        } else {
          return c.indexOf('dg-foreground-') === -1
        }
      })

      // Add class
      cl.push(style)

      // Set class
      if (edit.caret.parent.textContent !== edit.caret.range.toString()) {
        edit.runCmd('insertHTML', `<span class="${style}">${edit.caret.range.toString()}</span>`)
      } else {
        edit.caret.parent.className = cl.join(" ")
      }
    }
  },
}
</script>

<style scoped lang="scss">

</style>
