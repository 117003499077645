<template>
  <ab-flow-base-cmp :block="block" class="separator-editor-cmp" :class="classesString" :style="stylesString">
      <div v-if="separatorStyle" :style="separatorStyleString" :class="separatorClassString"></div>
  </ab-flow-base-cmp>
</template>

<script>

import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";

export default {
  components: {AbFlowBaseCmp},
  mixins: [renderMixins],
  props: ['block'],
  name: "SeparatorEditorCmp",
  computed: {

    /**
     * Separator size
     * @return {string}
     */
    separatorSize() {
      const sz = this.block?.properties?.size || "sm";
      return this.globals.sizes_to_pixels[sz] + 'px'
    },

    /**
     * Separator styles
     */
    separatorStyleString() {
      return {'height': this.separatorDirection === 'horizontal' ? '1px' : '100%', 'width': this.separatorDirection === 'horizontal' ? '100%' : '1px'}
    },

    /**
     * Separator classes
     */
    separatorClassString() {
      return  'dg-background-'+(this.block?.properties?.color || 'primary');
    },

    /**
     * Style
     * @return {string}
     */
    separatorStyle() {
      return this.block?.properties?.style || "";
    },

    /**
     * Direction
     */
    separatorDirection() {
      return this.block?.properties?.direction || "horizontal";
    },
  }
}

</script>

<style lang="scss">

.separator-editor-cmp {
  //min-width: 5px !important;
  //min-height: 5px !important;
  align-items: center;
  justify-content: center;
}

</style>
