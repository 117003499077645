<template>
  <q-card class="image-editor bg-black column full-height full-width ab-mw-9" v-if="ppUrl">
    <q-dialog ref="generator" persistent>
      <image-generator :module-id="image.module_id" @close="$refs.generator.hide()" @send="addImageToEditor($event);$refs.generator.hide()"/>
    </q-dialog>
    <q-card-section class="bg-grey-10 q-pl-sm q-pa-xs text-white text-h6 row items-center">
      <q-input v-model="imageTitle" class="col" color="grey-6"/>
      <q-btn @click="$refs.generator.show()" flat icon="add" label="Generate" class="q-mr-md"/>
      <q-btn @click="saveImage" flat icon="save" label="Save" class="q-mr-md"/>
      <q-btn icon="close" round flat @click="$emit('close')"/>
    </q-card-section>
    <q-card-section class="col column column q-pa-none q-ma-none">
      <iframe @load="ppLoaded=true" ref="pp" class="col" :src="ppUrl" frameborder="0" :class="{hidden: !ppLoaded, absolute: !ppLoaded}"/>
      <div v-if="!ppLoaded" class="col column justify-center content-center items-center text-white text-center ">
        Loading...
      </div>
    </q-card-section>
  </q-card>
</template>

<script>

import {pathHelper} from "@/utils/pathHelper";
import {dataHelper} from "@/utils/dataHelper";
import ImageGenerator from "@/components/MediaGallery/ImageGenerator.vue";

export default {
  name: "MediaImageEditor",
  components: {ImageGenerator},
  emits: ['close', 'save'],
  props: {
    image: {},
  },
  data: () => ({
    ppUrl: false,
    ppAnswers: [],
    ppLoaded: false,
    extension: false,
    imageTitle: ""
  }),
  created() {

    // Get image name
    const imName = this.image.psd_url || this.image.source_url

    // Get extension from url
    this.extension = this.getExtension(imName)

    // Store title
    this.imageTitle = this.image.title.replace("."+this.extension, "").trim();

    // Generate config
    const config = {
      "files": this.image.temporary ? [] : [pathHelper.assetPath(imName)]
    }

    // Api url
    this.ppUrl = `https://www.photopea.com#${JSON.stringify(config)}`

    // Listen for messages
    window.addEventListener("message", this.listenPPMessage);

    // Confirm exit
    window.addEventListener("beforeunload", this.confirmExit)
  },

  /**
   * Before unmount
   */
  beforeUnmount() {
    window.removeEventListener("message", this.listenPPMessage);
    window.removeEventListener("beforeunload", this.confirmExit);
  },

  mounted() {
    this.pp = this.$refs.pp.contentWindow;
  },

  methods: {

    /**
     * Add image to editor
     * @param url
     */
    addImageToEditor(url) {
      this.sendPPCommand(`app.open('${url}')`)
    },

    /**
     * Get extension from url
     * @param imName
     * @return {string|any}
     */
    getExtension(imName) {
      const ext = imName.split(".").pop()
      switch (ext) {
        case "jpg":
        case "jpeg":
          return "jpg"
        case "png":
        case "gif":
        case "svg":
          return ext
        default:
          return "png"
      }
    },

    /**
     * Confirm exit
     * @return {string}
     */
    confirmExit(e) {
      // Ask to change page
      const confirmationMessage = 'Are you sure you want to leave page?';

      // Cross-browser compatibility
      (e || window.event).returnValue = confirmationMessage;
      return confirmationMessage;
    },


    /**
     * Listen for messages
     * @param e
     */
    listenPPMessage(e) {
      this.ppAnswers.push(e);
    },

    /**
     * Save image
     */
    saveImage() {

      // Ask for save
      this.$q.dialog({
        title: 'Save image',
        message: `Do you want to save image?`,
        cancel: true,
        persistent: true
      }).onOk(async () => {

        this.$q.loading.show()

        //console.log("Save image", `app.activeDocument.saveToOE("${this.extension || "png"}");`)
        // Get PSD first
        const psd = await this.sendPPCommand(`app.activeDocument.saveToOE("psd");`)
        const image = await this.sendPPCommand(`app.activeDocument.saveToOE("${this.extension || "png"}");`)

        // Emit save event
        this.$emit('save', {url: this.image.source_url, title: this.imageTitle, image, psd})
        this.$q.loading.hide();
      })
    },

    /**
     * Send command to PP
     * @param command
     */
    async sendPPCommand(command) {

      // Reset current answers
      this.ppAnswers = []

      // Send command
      this.pp.postMessage(command, "*");

      // Answer list
      const answers = []

      // Return promise
      await new Promise((resolve) => {

        let tm
        const interval = setInterval(() => {
          if (this.ppAnswers.length) {

            // Get last event
            const e = this.ppAnswers.shift()

            // Add to answers
            answers.push(e)

            // Check for done event
            if (e.data === "done") {
              clearInterval(interval)
              clearTimeout(tm)
              resolve();
            }
          }
        }, 100)

        // Timeout
        tm = setTimeout(() => {
          this.ppAnswers = []
          clearInterval(interval)
          resolve()
        }, 10000)
      })

      // Get first anwers
      const e = answers.shift()

      // Check for message event
      if (e.data && (e.data instanceof ArrayBuffer || e.data instanceof Uint8Array)) {
        return await dataHelper.arrayBufferToBase64(e.data)
      }
    }
  }
}

</script>

<style lang="scss">

.image-editor {
  input {
    color: white;
  }
}

</style>
