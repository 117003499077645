<template>
  <q-card>
    <q-card-section class="q-pa-sm">
      <div class="flex no-wrap items-center">
        <div class="text-subtitle2" v-text="details.type" />

        <q-separator spaced vertical/>

        <div>
          <health-check-status :status="details.status" />

          <div class="text-caption" v-text="details.message" />
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import HealthCheckStatus from '@/pages/workspace/health-check/HealthCheckStatus.vue';

export default {
  name: 'DbConnectionCard',
  components: {HealthCheckStatus},

  props: {
    details: {
      required: true,
      type: Object,
    },
  },
}
</script>
