<template>
  <div>
    <ui-prop-select
      v-if="nativeAdsPlacementsList?.length"
      :block="block"
      name="placement"
      title="Placement"
      :options="nativeAdsPlacementsList"
    />

    <q-separator spaced/>
    "Native ads" item variable will be filled with following properties: <br/>
    <ul>
      <li><div class="text-subtitle2">id</div></li>
      <li>
        <div class="text-subtitle2">title</div>
        <div class="text-caption text-grey">Title of the ad (up to 30 symbols)</div>
      </li>
      <li>
        <div class="text-subtitle2">description</div>
        <div class="text-caption text-grey">Description  (up to 150 symbols)</div>
      </li>
      <li>
        <div class="text-subtitle2">image</div>
        <div class="text-caption text-grey">Image url</div>
      </li>
      <li>
        <div class="text-subtitle2">cta</div>
        <div class="text-caption text-grey">A text string representing the title of the requested user action</div>
      </li>
      <li>
        <div class="text-subtitle2">click_url</div>
        <div class="text-caption text-grey">The URL that will be opened when the Native Ad block is clicked</div>
      </li>
    </ul>

  </div>

</template>

<script>
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropSelect from '@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue';
import {adMixins} from '@/components/DiagramDesigner/Editor/components/UI/Monetization/adMixins';

export default {
  mixins: [propertiesMixins, adMixins],
  components: {UiPropSelect},
  props: ['block'],
  name: "NativeAdsPropsCmp",

  data() {
    return {};
  },

  computed: {
    /**
     * Return placements list
     * @return {*}
     */
    nativeAdsPlacementsList() {
      return this.placementsList?.filter(p => p.type === 'nativeads')?.map(p => ({label: p.name, value: p.id}))
    }
  },
}

</script>
