<template>
  <q-card class="column full-height full-width ab-mw-9 no-wrap">
    <modal-dialog ref="svgEditor" title="Edit icon" persistent max-width="800px">
      <media-icon-editor :svg="svg.code" @save="saveIcon" />
    </modal-dialog>

    <q-card-section class="row bg-primary text-white">
      <div class="text-h6">Search icons</div>
      <q-space/>
      <q-btn flat dense round @click="$emit('close')" icon="close" aria-label="Close"/>
    </q-card-section>

    <q-card-section>
      <q-form ref="searchForm" class="search-header row items-start q-gutter-sm" @submit="searchIcons">
        <q-select
          class="search-header__select"
          v-model="color"
          :options="colors"
          label="Color"
          lazy-rules
          :rules="[ val => val && val.length > 0 || 'Please type something']"
          emit-value
          map-options
        />
        <q-select
          class="search-header__select"
          v-model="style"
          :options="filteredStyles"
          label="Style"
          lazy-rules
          :rules="[ val => val && val.length > 0 || 'Please type something']"
          emit-value
          map-options
        />
        <q-input
          class="search-header__input"
          v-model="searchKeyword"
          label="Search keyword"
          lazy-rules
          :rules="[ val => val && val.length > 0 || 'Please type something']"
          @keydown.enter="$refs.searchForm.submit()"
        >
          <template #after>
            <q-btn flat icon="search" @click="$refs.searchForm.submit()">Search</q-btn>
          </template>
        </q-input>
      </q-form>
    </q-card-section>

    <q-separator />

    <q-card-section v-if="icons.length" class="icons-list scroll">
      <div
        v-for="icon in icons"
        :key="icon.id"
        class="relative-position shadow-2 preview"
      >
        <div class="row justify-center q-pl-md q-pr-md q-pt-md q-pb-sm">
          <q-img :src="icon.thumbnail" :alt="icon.name" style="width: 128px;height: 128px;" />
        </div>
        <div class="text-center ellipsis label">
          {{ icon.name }}
        </div>

        <q-btn
          round
          size="xs"
          icon="edit"
          text-color="white"
          class="absolute-top-right bg-grey-9 q-ma-xs"
          @click="loadSvg(icon)"
        />
      </div>
    </q-card-section>

    <q-card-section v-else>
      <div class="text-h6 text-center">No icons found</div>
    </q-card-section>

    <q-card-section class="column items-center q-pa-sm">
      <q-pagination
        v-if="total"
        v-model="currentPage"
        direction-links
        :max="Math.ceil(total / perPage)"
        :max-pages="7"
      />
    </q-card-section>
  </q-card>
</template>

<script>
import {nanoid} from 'nanoid';
import {MediaGallery} from '../../../../common/db/MediaGallery';
import ModalDialog from '@/components/ModalDialog/ModalDialog.vue';
import MediaIconEditor from '@/components/MediaGallery/MediaIconEditor.vue';

export default {
  name: "MediaIcons",
  components: {MediaIconEditor, ModalDialog},

  inject: {
    targetSource: {
      default: 'storage',
    },
  },

  emits: ['close', 'save'],
  props: {
    image: {},
  },
  data() {
    return {
      styles: [
        {value: 'outline', label: 'Outline'},
        {value: 'fill', label: 'Fill'},
        {value: 'hand-drawn', label: 'Hand drawn'},
        {value: 'lineal-color', label: 'Lineal Color'},
      ],
      colors: [
        {value: 'solid-black', label: 'Solid black'},
        /*{value: 'gradient', label: 'Gradient'},*/
        {value: 'multicolor', label: 'Multicolor'},
      ],
      searchKeyword: '',
      style: 'outline',
      color: 'solid-black',
      icons: [],
      currentPage: 1,
      total: 0,
      perPage: 50,
      svg: {
        title: '',
        code: '',
      },
    };
  },

  computed: {
    /**
     * Retrieves the product ID from the route parameters.
     *
     * @returns {string} The product ID.
     */
    productId() {
      return this.$route.params.app_id;
    },

    /**
     * Retrieves the module ID from the route parameters.
     *
     * @returns {string} The module ID.
     */
    moduleId() {
      return this.$route.params.module_id;
    },

    filteredStyles() {
      if (this.color === 'multicolor') {
        return this.styles;
      } else if (this.color === 'gradient') {
        return this.styles.filter(style => style.value !== 'hand-drawn');
      }

      return this.styles.filter(style => style.value !== 'lineal-color');
    },
  },

  methods: {
    /**
     * Resets the state of the component.
     * Sets the total number of icons to 0, the current page to 1, and clears the icons array.
     */
    resetState() {
      this.total = 0;
      this.currentPage = 1;
      this.icons = [];
    },

    /**
     * Loads icons from the Freepik API based on the current search parameters.
     * Shows a loading indicator while fetching data.
     * Updates the icons array and pagination information.
     *
     * @returns {Promise<void>}
     */
    async loadIcons() {
      try {
        this.$q.loading.show();

        const {icons, pagination} = await this.app.client.call('freepik', 'searchIcons', {
          color: this.color,
          style: this.style,
          keyword: this.searchKeyword,
          page: this.currentPage,
          perPage: this.perPage,
        });

        this.icons = icons;
        this.total = pagination.total;
      } catch (e) {
        console.error('Error searching icons', e);

        this.$q.notify({
          color: 'negative',
          message: 'Error searching icons',
          position: 'top',
          timeout: 2000,
        });
      } finally {
        this.$q.loading.hide();
      }
    },

    /**
     * Searches for icons by resetting the state and then loading icons.
     *
     * @returns {Promise<void>}
     */
    async searchIcons() {
      this.resetState();

      await this.loadIcons();
    },

    /**
     * Loads the SVG data for a given icon and displays it in the SVG editor.
     *
     * @param {Object} icon - The icon object containing the ID and name.
     * @returns {Promise<void>}
     */
    async loadSvg(icon) {
      const {svg} = await this.app.client.call('freepik', 'getIcon', { id: icon.id });

      this.svg.code = svg;
      this.svg.title = icon.name;

      this.$refs.svgEditor.show();
    },

    /**
     * Saves the current SVG icon to the media gallery.
     * Converts the SVG to a Base64-encoded string and uploads it.
     * Displays a notification on success or failure.
     *
     * @returns {Promise<void>}
     */
    async saveIcon(base64Image) {
      try {
        this.$q.loading.show();

        const source_url = `${this.targetSource}:${nanoid(24)}.svg`;

        await MediaGallery.remote().save({
          title: "New image",
          source_url,
          type: 'image',
          app_id: this.productId,
          module_id: this.moduleId,
          unique_id: nanoid(10),
          temporary: 1
        });

        if(await this.app.client.call('media-gallery', 'updateFile', source_url, this.svg.title, base64Image)) {
          this.$q.notify({
            message: 'Image saved',
            color: 'positive',
            icon: 'done',
            position: 'top'
          });

          this.$refs.svgEditor.hide();

          this.$emit('save');
        }
      } catch (e) {
        console.error('Error saving icon', e);

        this.$q.notify({
          message: 'Error saving icon',
          color: 'negative',
          icon: 'error',
          position: 'top'
        });
      } finally {
        this.$q.loading.hide();
      }
    },
  },

  watch: {
    /**
     * Watches for changes in the current page and loads icons accordingly.
     */
    currentPage() {
      this.loadIcons();
    },

    style() {
      if (this.style && this.searchKeyword) {
        this.searchIcons();
      }
    },

    color() {
      this.style = 'outline';

      if (this.color && this.searchKeyword) {
        this.searchIcons();
      }
    },
  },

  mounted() {
    this.resetState();
    this.searchKeyword = '';
    this.style = 'outline';
  },
}

</script>

<style scoped lang="scss">
.search-header {
  flex-wrap: nowrap;

  &__select {
    min-width: 160px;
  }

  &__input {
    flex: 1;
  }
}

.icons-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  grid-gap: 1rem;
}

.preview {
  width: 180px;
  align-content: center;
  background: white;

  .label {
    background: #eee;
    padding: 10px;
    font-size: 0.8em;
  }
}
</style>
