<template>
  <div>
    <ui-prop-select name="groups" title="Groups" :block="block" :options="groups" :multiply="1"/>

    <div v-if="oldValue" class="q-pa-sm q-mt-md rounded-borders bg-grey-2">
      <div>Old value:</div>

      <div>{{ oldValue }}</div>
    </div>
  </div>
</template>

<script>
import {AppIntegration} from '../../../../../../../../../common/db/AppIntegration';
import {adMixins} from "@/components/DiagramDesigner/Editor/components/UI/Monetization/adMixins";
import UiPropSelect from '@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue';

export default {
  components: {UiPropSelect},

  name: "HasInAppPurchasePropsCmp",

  inject: {
    module_id: {
      default: null
    },
  },

  mixins: [adMixins],

  props: ['block'],

  computed: {
    // Get in-app-purchase products
    products() {
      return this.wait('groups', async () => {
        // Get in-app-purchase info
        const iapInfo = await AppIntegration.query().where({module_id: this.module_id, name: 'in-app-purchase'}).first();

        console.log('iap:', this.module_id)

        return iapInfo?.props?.products || []
      }, [])
    },

    // Get in-app-purchase groups
    groups() {
      return [...new Set(this.products.map((p) => p?.group || null).filter(Boolean))]
        .map((g) => ({label: g, value: g}));
    },

    // Get selected groups
    selectedGroups() {
      return this.block.properties?.groups || [];
    },

    // Get old value
    oldValue() {
      if (this.selectedGroups.length) {
        return null;
      }

      const {products} = this.block.properties || {};

      return products?.value || products || null;
    },
  },

  async created() {
    // Subscribe to in-app-purchase integration
    await AppIntegration.remote().subscribe('app-integration-by-name', {module_id: this.module_id, name: 'in-app-purchase'});
  },
}

</script>
