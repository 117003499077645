<template>
  <div>
    <ui-prop-select name="mode" title="Mode" default-value="check_request" :block="block" :options="modes"/>
  </div>
</template>

<script>
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropSelect from '@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue';

export default {
  mixins: [propertiesMixins],
  name: "ImagePickerPropsCmp",
  props: ['block'],
  components: {UiPropSelect},

  data() {
    return {
      modes: [
        {value: 'check_request', label: 'Check and request permissions'},
        {value: 'check', label: 'Check permissions'},
      ],
    };
  },
}

</script>

