<template>
  <div class="column content-center">
    <div :class="classesString" :style="stylesString">
      {{block?.properties?.name}}
    </div>
  </div>
</template>

<script>

import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";

export default {
  mixins: [renderMixins],
  name: "PanelStylePreview",
  props: {
    block: {
      default: () => ({})
    },
  },
}

</script>
