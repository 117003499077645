<template>
  <ab-flow-base-cmp class="children-container-editor-cmp" :parentComponent="this" :draggable="false" :block="block" :is_container="true">
    <ab-flow-components-renderer :items="children" />
  </ab-flow-base-cmp>
</template>

<script>
import AbFlowComponentsRenderer from "ab-flow-designer/src/components/Designer/AbFlowComponentsRenderer";
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";

export default {
  components: {AbFlowComponentsRenderer, AbFlowBaseCmp},
  mixins: [renderMixins],
  props: ['block'],
  name: "ChildrenContainerEditorCmp",
  inject: {
    parentWidget: {
      default: false
    },
  },
  computed: {

    /**
     * Children list
     * @return {*}
    */
    children() {

      // Check if parent widget is set
      if(this.parentWidget) {
        const ch = this.parentWidget.block?.children?.find(e => e.alias === this.block.properties?.alias)
        if(ch) return ch.children || [];
      }

      // Return regular children
      return this.block.children || []
    },
  }
}

</script>

<style lang="scss">
.children-container-editor-cmp {
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: all;
  width: 100%;
  height: 100%;
}

</style>
