<template>

  <div class="icon-selector">
    <div>{{title}}</div>
    <div class="row">
      <q-input name="iconName" label="Icon name" class="col" v-model="currentValue"/>
      <q-btn @click="showList" flat class="bg-blue-1">List</q-btn>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  props: {
    title: {},
    modelValue: {
      type: String,
    }
  },
  name: "IconSelector",
  methods: {

    /**
     * Show list of icons
     */
    showList() {
      window.open('https://fonts.google.com/icons', '_blank')
    }
  },
  computed: {
    currentValue: {
      get: function () {
        return this.modelValue
      },
      // setter
      set: function (newVal) {

        // Regular model
        this.$emit('update:modelValue', newVal)
        this.$emit('changed', newVal)
      }
    }
  }
}

</script>
