<template>
  <ab-flow-base-cmp :block="block" class="teleport-editor-cmp column relative-position" :class="classesString" :style="stylesString">
      <ab-flow-components-renderer :preview-mode="true" v-if="defaultFragment" :items="defaultFragment?.children"/>
      <div v-else class="bg-grey-3 col flex items-center justify-center">No default fragment set</div>
    <ab-flow-link-connector :block="block" type="output" class="connector-center" event="children"/>
  </ab-flow-base-cmp>
</template>

<script>

import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";
import AbFlowComponentsRenderer from "ab-flow-designer/src/components/Designer/AbFlowComponentsRenderer";
import {treeHelper} from "@/../../common/utils/treeHelper";
import {Diagram} from "@/../../common/db/Diagram.js"

export default {
  components: { AbFlowBaseCmp, AbFlowLinkConnector, AbFlowComponentsRenderer},
  mixins: [renderMixins],
  inject: {
    canvas: {
      default: false
    },
    animation_frame: {
      default: 0
    }
  },
  props: ['block'],
  name: "TeleportEditorCmp",
  computed: {

    /**
     * If is preview
     * @return {boolean}
     */
    isPreview() {
      return this.parentWidget ? true : false;
    },

    /**
     * Widget blocks
     * @return {*}
     */
    widgetBlocks() {
      return this.source?.children?.find(c => c.properties?.fragmentType === 'widget')?.children;
    },

    /**
     * Widget source
     * @return {*}
     */
    source() {
      return this.wait("widgetSource", async () => {
        return this.widgetId ? (await Diagram.find(this.widgetId))?.source : false
      }, {})
    },

    /**
     * Get default fragment
     * @return {*}
     */
    defaultFragment() {

      // Get default fragment
      return this.wait("defaultFragment", async () => {


        // Get default frame name
        const defaultFrameName = this.getValue(this.block.properties?.activeFrame);

        // Get parent widget
        const parentWidget = this.parentWidget || this.designer

        // Get node
        const node = treeHelper.traverseTree(parentWidget?.source, (node) => {
          if (['Fragment', 'DiagramComponent'].includes(node.type) && node.title === defaultFrameName) {
            return node
          }
        })

        // Check if node is diagram component
        if (node?.type !== 'DiagramComponent') {
          return node
        } else {
          const dg = (await Diagram.find(node?.properties?.diagramComponentId))?.source
          return dg?.children?.find(c => c.type === 'Widget') || dg?.children?.find(c => c.type === 'Fragment')
        }
      }, {})
    }
  }
}

</script>

<style lang="scss">

.teleport-editor-cmp {
  .output {
    z-index: 1;
  }
}

</style>
