<template>

  <div>

    <ui-prop-string name="title" title="Condition description" :block="block"/>
    <ui-prop-value-selector name="variable" :block="block" :parent-diagram-id="diagram_id"/>

    <div class="q-gutter-sm">
      <div v-for="(event, k) of currentEvents" :key="k" class="column shadow-2 q-pa-sm q-gutter-y-sm">

        <div class="row">
          <q-select class="col" v-model="event.conditionOperation" :options="globals.options.comparison_operations" emit-value
                    map-options label="Operation">
            <template v-slot:prepend>
              <q-btn icon="delete" size="sm" @click="deleteEvent(k)" flat/>
            </template>
          </q-select>
        </div>

        <template v-if="!['=== undefined', '!== undefined'].includes(event.conditionOperation)">
          <value-selector :only-var-type="mainValue.type" :app-id="product_id" :block-id="block.id" :parent-diagram-id="diagram_id" :module-id="module_id" v-model="event.conditionValue" label="Comparison value"/>
        </template>

        <q-input v-model="event.name" label="Event name" outlined/>

      </div>

      <q-btn @click="addEvent" icon="add" label="Add condition" flat/>

    </div>

  </div>

</template>

<script>

import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString";
import {componentsMixin} from "@/components/DiagramDesigner/Editor/components/componentsMixin";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import ValueSelector from "@/components/ValueSelector/ValueSelector.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";

export default {
  inject: {
    product_id: {
      default: false
    }
  },
  mixins: [componentsMixin, propertiesMixins],
  components: {ValueSelector, UiPropValueSelector, UiPropString},
  props: ['block'],
  name: "LogicConditionPropsCmp",
  created() {

    // Init events list
    if (!this.block.properties?.events) {
      // eslint-disable-next-line vue/no-mutating-props
      if (!this.block.properties) this.block.properties = {}
      // eslint-disable-next-line vue/no-mutating-props
      this.block.properties.events = []
    }
  },
  computed: {
    currentEvents: {
      get() {
        return this.block.properties?.events || []
      },
      set(value) {
        console.log("val", value)
        // eslint-disable-next-line vue/no-mutating-props
        this.block.properties.events = value
      }
    },

    /**
     * Get main value
     */
    mainValue() {
      return this.block?.properties?.variable || {}
    }
  },
  methods: {

    /**
     * Add event
     * @param e
     */
    addEvent() {
      // Add to events list
      this.currentEvents.push({
        name: "",
        mock_data: "{}"
      })
    },

    /**
     * Delete event
     * @param k
     */
    deleteEvent(k) {
      this.$q.dialog({
        title: 'Delete event',
        message: 'Are you sure you want to delete this event?',
        cancel: true,
        persistent: true
      }).onOk(() => {
        this.currentEvents.splice(k, 1);
      })
    },
  }
}

</script>
