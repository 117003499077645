<template>

  <div>

    <q-tabs v-model="tab">
      <q-tab name="function">Function</q-tab>
      <q-tab name="test">Test result</q-tab>
    </q-tabs>

    <q-tab-panels v-model="tab">
      <q-tab-panel name="function">
        <ui-prop-string :multiple="true" name="description" title="Function description" :block="block"/>
        <function-selector v-model="mutate(block).properties" :app-id="product_id" :parent-diagram-id="diagram_id" :block-id="block.id" :module-id="module_id"/>
      </q-tab-panel>

      <q-tab-panel name="test">
        <ui-prop-json :block="block" title="Test result" name="test_result" :default-value="defaultResult"/>
      </q-tab-panel>

    </q-tab-panels>

    <ui-prop-checkbox name="loading" title="Show loader during execution" :block="block" :default-value="0"/>

  </div>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropJson from "@/components/DiagramDesigner/Editor/properties/UiPropJson.vue";
import FunctionSelector from "@/components/FunctionSelector/FunctionSelector.vue";
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";
import UiPropCheckbox from "@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue";

export default {
  mixins: [propertiesMixins],
  components: {UiPropCheckbox, UiPropString, FunctionSelector, UiPropJson},
  props: ['block'],
  name: "CodePropsCmp",
  data: () => ({
    json: "",
    tab: "function",
    defaultResult: {error: {}, result: {}, references: {}}
  }),
  created() {
    if(!this.block?.properties) this.mutate(this.block).properties = {}
  }
}

</script>
