<template>

  <div>
    <ui-prop-select
      name="provider"
      title="Auth provider"
      :block="block"
      :options="providers"
      default-value="default"
    />

    <template v-if="provider === 'default'">
      <ui-prop-value-selector :block="block" title="User email" name="email" :parent-diagram-id="diagram_id"/>
      <ui-prop-value-selector :block="block" title="User password" name="password" :parent-diagram-id="diagram_id"/>
    </template>

    <template v-else-if="provider === 'promo-code'">
      <ui-prop-value-selector :block="block" title="User email" name="email" :parent-diagram-id="diagram_id"/>

      <ui-prop-value-selector
        :block="block"
        title="Variable to save promo code"
        name="variableToSavePromoCode"
        value-type="setter"
        :parent-diagram-id="diagram_id"
      />
    </template>

    <ui-prop-checkbox
      v-if="currentModule?.currentModule?.type === 'mobile'"
      class="q-mt-sm"
      :block="block"
      name="saveFcmToken"
      title="Save the FCM token"
      :true-value="1"
      :false-value="0"
      :default-value="0"
    />
  </div>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import UiPropSelect from '@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue';
import UiPropCheckbox from '@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue';

export default {
  mixins: [propertiesMixins],
  components: {UiPropCheckbox, UiPropSelect, UiPropValueSelector},
  props: ['block'],
  name: "SignUpPropsCmp",

  data() {
    return {
      providers: [
        {value: 'default', label: 'Default'},
        {value: 'facebook', label: 'Facebook'},
        {value: 'google', label: 'Google'},
        {value: 'apple', label: 'Apple'},
        {value: 'promo-code', label: 'Promo code'},
      ],
    };
  },

  computed: {
    provider() {
      return this.block?.properties?.provider || 'default';
    },
  },
}

</script>
