<template>

  <q-card>

    <q-card-section class="bg-primary text-white">Animations</q-card-section>

    <q-card-section>
    <q-select map-options emit-value label="Animation" v-model="currentAnimation.animationName" :options="globals.options.animationsList"/>
		<template v-if="animationName">
      <q-input label="Duration, ms" v-model="currentAnimation.duration"/>

      <template v-if="animationName==='rotation'">
        <q-input label="From angle" v-model="currentAnimation.fromAngle"/>
        <q-input label="To angle" v-model="currentAnimation.toAngle"/>
      </template>

    </template>
    </q-card-section>

  </q-card>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";

export default {
  mixins: [propertiesMixins],
  props: ['block'],
  name: "UiAnimation",
  components: {},
  data: () => ({
    currentAnimation: {
      animationName: "",
      duration: 1000
    }
  }),
  created() {

    // Init current value
    if(this.currentValue) this.currentAnimation = this.currentValue

    // Add watcher
    this.$watch('currentAnimation', function (newVal) {
        this.currentValue = newVal
    }, {deep: true})

  },

  methods: {

  },

  computed: {

    /**
     * Return current animation name
     * @return {string}
     */
    animationName() {
      return this.currentAnimation.animationName
    },

    /**
     * Getting type of prop
     */
    type() {
      return "animation"
    },
  }
}

</script>
