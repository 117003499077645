<template>
  <div>

    <ui-prop-value-selector  :block="block" name="dataSource" title="Data source" :is-array="true" hint="Json data source or array of blocks"
                             :only-var-type="['object']" :parent-diagram-id="diagram_id"/>

    <ui-prop-checkbox :block="block" name="returnJson" title="Use json as value" :true-value="1" :false-value="0"
                      :default-value="1"/>

    <ui-prop-checkbox :block="block" name="isMultyLine" title="Multiply lines" :true-value="1" :false-value="0"
                      :default-value="1"/>

    <ui-dynamic-props :default-value="{}" :block="block" :product_id="product_id" :module_id="module_id"/>
  </div>
</template>

<script>
import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps";
import UiPropCheckbox from "@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";

export default {
  mixins: [propertiesMixins],
  name: "ContentEditorPropsCmp",
  props: ['block'],
  components: {UiPropValueSelector, UiPropCheckbox, UiDynamicProps},
}

</script>

