<template>

  <div>

    <ui-prop-string name="title" title="Ab test title" :block="block"/>
    <ui-prop-string name="uniqueId" title="Unique test id" :block="block"/>

    <div class="q-gutter-sm">
      <div v-for="(test, k) of currentTests" :key="k" class="row shadow-2 q-pa-sm q-gutter-x-sm items-start">
        <div class="col q-gutter-y-sm">
          <q-input v-model="test.name" label="Test name" outlined/>
          <q-input v-model="test.weight" label="Test weight, %" outlined/>
        </div>

        <q-btn icon="delete" size="sm" flat round @click="deleteTest(k)"/>
      </div>

      <q-btn @click="addTest" icon="add" label="Add test" flat/>

    </div>

  </div>

</template>

<script>

import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString";
import {componentsMixin} from "@/components/DiagramDesigner/Editor/components/componentsMixin";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";

export default {
  inject: {
    product_id: {
      default: false
    }
  },
  mixins: [componentsMixin, propertiesMixins],
  components: {UiPropString},
  props: ['block'],
  name: "AbTestPropsCmp",
  created() {

    // Init tests list
    if (!this.block.properties?.tests) {
      // eslint-disable-next-line vue/no-mutating-props
      if (!this.block.properties) this.block.properties = {}
      // eslint-disable-next-line vue/no-mutating-props
      this.block.properties.tests = []
    }
  },
  computed: {
    currentTests: {
      get() {
        return this.block.properties?.tests || []
      },
      set(value) {
        // eslint-disable-next-line vue/no-mutating-props
        this.block.properties.tests = value
      }
    },

    /**
     * Get main value
     */
    mainValue() {
      return this.block?.properties?.variable || {}
    }
  },
  methods: {

    /**
     * Add test
     * @param e
     */
    addTest() {
      // Add to tests list
      this.currentTests.push({
        name: "",
        mock_data: "{}"
      })
    },

    /**
     * Delete test
     * @param k
     */
    deleteTest(k) {
      this.$q.dialog({
        title: 'Delete test',
        message: 'Are you sure you want to delete this test?',
        cancel: true,
        persistent: true
      }).onOk(() => {
        this.currentTests.splice(k, 1);
      })
    },
  }
}

</script>
