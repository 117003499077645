<template>
  <ab-flow-base-cmp :block="block" class="video-editor-cmp relative-position" :class="classesString" :style="stylesString">
    <video :key="video_source" class="player" ref="player" :style="videoStyles" controls playsinline crossorigin="anonymous" :muted="muted">
      <source :src="video_source"/>

      Your browser does not support the video tag.
    </video>

    <div v-if="subtitlesTrack" class="subtitles">
      <div class="subtitles__track">Subtitles example</div>
    </div>

    <q-btn
      v-if="displaySoundControlIcon"
      class="sound-control-btn absolute-bottom-right q-mr-sm q-mb-md"
      :class="soundControlIconClasses"
      round
      :icon="muted ? 'volume_off' : 'volume_up'"
      size="sm"
      @click="muted = !muted"
    />

    <div class="events">
    <ab-flow-link-connector :block="block" type="input" event="restart"/>
    <ab-flow-link-connector :block="block" type="output" event="finished"/>
    </div>
  </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import {pathHelper} from "@/utils/pathHelper";
import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

export default {
  mixins: [renderMixins],
  components: {AbFlowBaseCmp, AbFlowLinkConnector},
  inject: {
    canvas: {
      default: false
    },
    animation_frame: {
      default: 0
    }
  },
  props: ['block'],
  name: "VideoEditorCmp",

  data() {
    return {
      muted: true,
    };
  },

  created() {

    // Set video time according to animation time
    this.$watch('animation_frame', (val) => {
      this.$refs.player.currentTime = val
    })
  },

  computed: {

    /**
     * Get video styles
     */
    videoStyles() {
      const styles = {}
      if(this.block.properties) for(const prop of Object.keys(this.block.properties)) {
        const val = this.block.properties[prop]
        if(val !== undefined) switch (prop) {
          case "video_fitting":
            styles['object-fit'] = val
            break
        }
      }

      // Return styles list
      return styles
    },

    /**
     * Video source
     * @return {boolean|*|boolean}
     */
    video_source() {

      // Image path
      const vidPath = this.getValue(this.block?.properties?.video)?.source_url

      // Get path
      const path =  pathHelper.assetPath(vidPath)

      // Return path
      return path ? path : require("@/assets/plugs/default-image.png");
    },

    /**
     * Get the current locale from the app client configuration.
     * @return {string|null} The current locale if it exists, otherwise null.
     */
    currentLocale() {
      return this.designer.currentLocale;
    },

    /**
     * Get the video assets based on the current or main locale.
     * @return {object|null} The video assets if they exist, otherwise null.
     */
    videoAssets() {
      const items = (this.block?.properties?.items?.items || []);

      const assets = items.find((item) => item.language === (this.currentLocale || 'en'));

      return assets || items[0] || null;
    },

    /**
     * Get the subtitles track from the video assets.
     * @return {string|null} The subtitles track if it exists, otherwise null.
     */
    subtitlesTrack() {
      return this.videoAssets ? pathHelper.assetPath(this.videoAssets?.subtitles?.source_url) : null;
    },

    /**
     * Determines if the sound control icon should be displayed.
     * @return {boolean} True if the sound control icon should be displayed, otherwise false.
     */
    displaySoundControlIcon() {
      return this.block?.properties?.displaySoundControlIcon === 1;
    },

    /**
     * Computes the CSS classes for the sound control icon.
     * @return {Array} An array of CSS class names.
     */
    soundControlIconClasses() {
      return [
        `dg-background-${this.block?.properties?.soundIconBackground || 'dark'}`,
        `dg-foreground-${this.block?.properties?.soundIconColor || 'light'}`,
        {animated: this.muted},
      ];
    },
  },
}

</script>

<style lang="scss">
@keyframes pulse {
  0%, 100% {
    transform: scale(1); /* Начальное и конечное состояние */
  }
  10% {
    transform: scale(1.15); /* Увеличение на 10% в середине анимации */
  }
}

.sound-control-btn.animated {
  animation: pulse 2s infinite;
}

.video-editor-cmp {
  display: flex;
  overflow: hidden;
  position: relative;

  .player {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  // Click event
  .events {
    position: absolute;
    left: calc(50% - 5px);
    top: calc(50% - 5px);
  }

  .subtitles {
    position: absolute;
    bottom: 60px;
    left: 0;
    right: 0;
    padding: 0.4em;
    pointer-events: none;

    &__track {
      text-align: center;
      white-space: pre-line;
    }
  }
}

</style>
