<template>

  <!--media-picker v-model="background.image" :product_id="canvas.product_id" :media-type="background.type"/!-->
  <!--ui-prop-media-picker :block="block" name="image" media-type="image" title="Image file" :product-id="product_id"/!-->
  <ui-prop-value-selector :block="block" name="image" title="Image" only-var-type="image" :parent-diagram-id="diagram_id" @updated="updateImage"/>
  <ui-prop-select :block="block" name="fitting" title="Image fitting" :options="globals.options.mediaFitting" default-value="contain"/>
  <ui-prop-select :block="block" name="position" title="Image position" :options="globals.options.mediaPosition" default-value="center"/>
  <ui-prop-dynamic-string property name="width" title="Width" :block="block"/>
  <ui-prop-dynamic-string property name="height" title="Height" :block="block"/>
  <!--ui-events-props :block="block"/!-->
  <ui-dynamic-props :default-value="{}" :block="block" :product_id="product_id" :module_id="module_id" />

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import UiPropDynamicString from "@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue";

export default {
  inject: {
    product_id: {
      default: 0
    },
    designer: {
      default: false
    }
  },
  mixins: [propertiesMixins],
  name: "ImagePropsCmp",
  props: ['block'],
  components: {
    UiPropDynamicString,
    UiPropValueSelector,
    UiPropSelect, UiDynamicProps},
  methods: {

    /**
     * Update image on page
     */
    updateImage() {
      this.designer.sendEvent?.(this.block.id, "update");
    }
  }
}

</script>

