<template>

  <div>
    <ui-prop-string name="title" title="Title" :block="block"/>
    <ui-prop-dynamic-string name="schedule" title="Frequency, min" :block="block" :default-value="30"/>
    <ui-prop-checkbox name="runImmediately" title="Run immediately" :block="block" :default-value="0"/>
  </div>

</template>

<script>

import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";
import UiPropDynamicString from "@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue";
import UiPropCheckbox from "@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue";

export default {
  components: {UiPropCheckbox, UiPropDynamicString, UiPropString},
  props: ['block'],
  name: "EventSchedulerPropsCmp",
  computed: {
  }
}

</script>
