<template>

    <ab-flow-base-cmp :movable="true" class="web-browser-cmp" :block="block" :class="classesString" :style="stylesString">
        <q-card>

            <q-card-section class="bg-primary text-white">
                Device navigator
            </q-card-section>

            <q-card-section>
                <a class="link" target="_blank">{{ navigationLink || 'Dynamic link' }}</a>
            </q-card-section>
        </q-card>

        <ab-flow-link-connector :block="block" type="input" event="navigate"/>
    </ab-flow-base-cmp>

</template>

<script>
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";
import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";

export default {
    components: {AbFlowBaseCmp, AbFlowLinkConnector},
    mixins: [renderMixins],
    props: ['block'],
    name: "DeviceNavigatorEditorCmp",
    computed: {
        /**
         * Link
         * @return {string}
         */
        navigationLink() {
          return this.interpretString(this.block.properties?.navigationLink) || 'No link';
        },
    }
}

</script>

<style lang="scss" scoped>
.web-browser-cmp {
  .input{
    position: absolute;
    left: -12px;
    top:calc(50% - 5px);
  }
}
</style>
