<template>
  <div>
    <ui-prop-dynamic-string :block="block" title="Bot token" name="telegramToken" untranslatable/>
  </div>
</template>

<script>

import UiPropDynamicString from "@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue";

export default {
  name: "TelegramMessengerSettings",
  components: {UiPropDynamicString},
  props: ['block'],
}

</script>
