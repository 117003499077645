<template>
  <q-page class="justify-center items-center row window-height">
  </q-page>
</template>

<script>

export default {
  name: "HomePage"
}

</script>
