<template>
  <q-page class="q-pa-md" v-if="isReady">

    <div class="text-h6">
      Android app settings
    </div>
    <q-form @submit="saveProperties">

      <q-input class="col" label="Developer email" v-model="properties.developerEMail"/>
      <q-input class="col" label="Application specific password" v-model="properties.appSpecificPassword"/>

      <media-picker :product-id="app_id" :module-id="module_id" media-type="ios-creds" label="Development certificate" v-model="properties.iosDevelopmentCertificate"/>
      <media-picker :product-id="app_id" :module-id="module_id" media-type="ios-creds" label="Distribution certificate" v-model="properties.iosDistributionCertificate"/>
      <media-picker :product-id="app_id" :module-id="module_id" media-type="ios-creds" label="Distribution provision" v-model="properties.iosDistributionProvision"/>

      <q-input label="The Firebase App ID, found in the Firebase console" v-model="properties.firebaseAppId"/>
      <q-input label="The API SECRET generated in the Google Analytics UI" v-model="properties.analyticsApiSecret"/>

      <q-separator/>
      <q-btn type="submit" label="Save" color="primary" class="q-mt-md"/>
    </q-form>

  </q-page>
</template>

<script>

import {integrationsMixin} from "@/pages/workspace/integrations/integrationsMixin";
import MediaPicker from "@/components/MediaGallery/MediaPicker.vue";

export default {
  components: {MediaPicker},
  mixins: [integrationsMixin],
  name: "IosIntegrationSettings",
  data: () => ({
    placement: {},
    appId: false
  }),

  created() {
    this.appId = this.$route.params.app_id || 0
    this.moduleId = this.$route.params.module_id || 0
  },

  computed: {

    /**
     * Return integration name
     */
    integration: () => ({name: "ios", type: "os"}),
  }
}

</script>
