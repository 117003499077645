<template>
  <ab-flow-base-cmp :block="block" class="icon-editor-cmp" :class="classesString" :style="stylesString">
    <q-icon :name="iconName" :size="iconSize"/>
  </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";

export default {
  mixins: [renderMixins],
  components: {AbFlowBaseCmp},
  inject: {
    canvas: {
      default: false
    },
    animation_frame: {
      default: 0
    }
  },
  props: ['block'],
  name: "IconEditorCmp",

  computed: {

    /**
     * Icon name
     * @return {boolean|*|boolean}
     */
    iconName() {

      // Image path
      return this.getValue(this.block.properties?.iconName) || "question_mark";
    },
    /**
     * Icon size
     * @return {boolean|*|boolean}
     */
    iconSize() {

      // Image path
      return this.block?.properties?.iconSize || "md";
    },
  }
}

</script>

<style lang="scss">

.icon-editor-cmp {
  position: relative;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

</style>
