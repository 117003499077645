<template>

    <div>
        <ui-prop-select name="mode" title="Mode" :block="block" default-value="alert" :options="globals.options.dialogMods"/>
        <ui-prop-dynamic-string name="title" title="Title" :block="block" />
        <ui-prop-dynamic-string v-if="block.properties?.mode !== 'prompt'" :multiple="true" name="message" title="Message" :block="block"/>
        <ui-prop-dynamic-string name="confirmLabel" title="Confirm Label" :block="block" />
        <ui-prop-dynamic-string v-if="block.properties?.mode !== 'alert'" name="cancelLabel" title="Cancel Label" :block="block" />
    </div>

</template>

<script>
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect";
import UiPropDynamicString from '@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue';

export default {
    components: {UiPropDynamicString, UiPropSelect},
    props: ['block'],
    name: "DialogPropsCmp",
}

</script>
