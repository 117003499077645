<template>

  <div>
    <ui-prop-value-selector :block="block" name="email" only-var-type="string" title="email" :parent-diagram-id="diagram_id"/>

    <ui-prop-dynamic-string :block="block" title="Subject" name="subject"/>
  </div>

</template>

<script>

import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropDynamicString from '@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue';

export default {
  mixins: [propertiesMixins],
  components: {UiPropDynamicString, UiPropValueSelector},
  props: ['block'],
  name: "OpenEmailPropsCmp",
  computed: {
  }
}

</script>
