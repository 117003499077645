<template>
  <q-page class="q-pa-md" v-if="isReady">

    <div class="text-h6">
      Web app settings
    </div>
    <q-form @submit="saveProperties">

      <q-input label="The Firebase App ID, found in the Firebase console" v-model="properties.firebaseAppId"/>

      <q-separator/>
      <q-btn type="submit" label="Save" color="primary" class="q-mt-md"/>
    </q-form>

  </q-page>
</template>

<script>

import {integrationsMixin} from "@/pages/workspace/integrations/integrationsMixin";

export default {
  mixins: [integrationsMixin],
  name: "WebIntegrationSettings",
  data: () => ({
    placement: {},
    appId: false
  }),

  created() {
    this.appId = this.$route.params.app_id || 0
    this.moduleId = this.$route.params.module_id || 0
  },

  computed: {

    /**
     * Return integration name
     */
    integration: () => ({name: "web", type: "os"}),
  }
}

</script>
