<template>

  <div>
    <div class="text-subtitle1">Paddings:</div>
    <div class="row">
      <q-select class="q-select q-px-sm" emit-value map-options :options="sizes" v-model="paddings.left" label="Left"/>
      <q-select class="q-select q-px-sm" emit-value map-options :options="sizes" v-model="paddings.top" label="Top"/>
      <q-select class="q-select q-px-sm" emit-value map-options :options="sizes" v-model="paddings.right" label="Right"/>
      <q-select class="q-select q-px-sm" emit-value map-options :options="sizes" v-model="paddings.bottom" label="Bottom"/>
    </div>
  </div>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";

export default {
  inject: ['canvas'],
  mixins: [propertiesMixins],
  name: "UiPropPaddings",
  components: {},

  data: () => ({
    paddings: {
      left: "",
      top: "",
      right: "",
      bottom: "",
    }
  }),

  created() {

    // Init current value
    if(this.currentValue) this.paddings = this.currentValue

    // Add watcher
    this.$watch('paddings', function (newVal) {
      this.currentValue = newVal
    }, {deep: true})
  },

  computed: {
    // Redefine prop name
    type: () => "padding",

    /**
     * Computed property that returns an array of sizes.
     * It spreads the sizes from the global options and adds a new size object with value 'sa' and label 'SA'.
     * @return {Array} The array of sizes.
     */
    sizes() {
      return [
        ...this.globals.options.sizes,
        { value: 'sa', label: 'SA' }, // SA -> Safe area
      ];
    },
  }
}

</script>

<style lang="scss" scoped>
.q-select {
  flex: 1;
  width: 100%;
  min-width: 60px;
}
</style>
