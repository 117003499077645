<template>
  <ab-flow-base-cmp :movable="true" :block="block" class="request-review-editor-cmp">

    <ab-flow-link-connector :block="block" type="input" class="ev-run" event="run"/>

    <q-card>

      <q-card-section class="bg-primary text-white">
        Request app review
      </q-card-section>
    </q-card>
  </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

export default {
  components: { AbFlowBaseCmp, AbFlowLinkConnector},
  inject: ['canvas'],
  props: ['block'],
  name: "RequestAppReviewEditorCmp",
  computed: {

    /**
     * Return permission name
     * @return {{}}
     */
    permission() {
      return this.block.properties ? this.block.properties.permission : "NoName"
    },
  }
}

</script>

<style lang="scss">

.request-review-editor-cmp {
  .ev-run {
    left:-12px;
    position: absolute;
    top:calc(50% - 5px);
  }
}

</style>
