<template>

  <div>
    <ui-prop-select :block="block" name="processorDiagramId" title="Chat diagram" :options="diagramsList"/>
    <ui-prop-dynamic-string :block="block" title="Chat bot id" name="chatBotId" :default-value="block.id" untranslatable/>
    <ui-prop-select :block="block" title="Messenger" name="messenger" untranslatable :options="globals.options.messengers" />
    <component v-if="block.properties?.messenger && ['telegram'].includes(block.properties?.messenger)" :is="`${block.properties.messenger}-messenger-settings`" :block="block" />
  </div>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";
import TelegramMessengerSettings
  from "@/components/DiagramDesigner/Editor/components/UI/ChatBot/MessengerService/settings/TelegramMessengerSettings.vue";
import {Diagram} from "../../../../../../../../../common/db/Diagram";
import UiPropDynamicString from "@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue";

export default {
  mixins: [propertiesMixins],
  components: {UiPropDynamicString, UiPropSelect, TelegramMessengerSettings},
  props: ['block'],
  name: "MessengerBotPropsCmp",
  computed: {

    /**
     * Get diagrams list
     * @return {*}
     */
    diagramsList() {
      return this.wait("diagramsList", async () => {
        return (await Diagram.query().where({module_id: this.module_id, diagram_type: 'process'}).get())
            .map(d => ({
              value: d.id,
              label: d.title
            }));
      }, []);
    },
  }
}

</script>
