<template>
  <q-page class="q-pa-sm q-pb-lg">
    <div class="text-h6">Audience</div>
    <analytics-filter
      :module-id="moduleId"
      v-model:required-events="requiredEvents"
      v-model:codeVersion="codeVersion"
      v-model:appPlatform="appPlatform"
      v-model:schemaVersion="schemaVersion"
      v-model:date-from="dateFrom"
      v-model:date-to="dateTo"
      v-model:countries="countries"
      v-model:bigQuery="bigQuery"
      v-model:utm-source="utmSource"
      v-model:utm-medium="utmMedium"
      v-model:utm-campaign="utmCampaign"
      v-model:utm-content="utmContent"
    />

    <q-card class="q-mt-lg">
      <q-card-section>
        <div class="text-h6">Users</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <LineChart class="q-mt-lg" :chartData="chartDataUsers" :options="chartOptions" />
      </q-card-section>
    </q-card>

    <q-card class="q-mt-lg">
      <q-card-section>
        <div class="text-h6">Revenue</div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <LineChart class="q-mt-lg" :chartData="chartDataRevenue" :options="chartRevenueOptions" />
        <div class="row q-mt-sm q-gutter-x-md justify-end text-weight-bold">
          <div>Total: {{chartDataRevenue.totals.total?.toFixed(2)}}$</div>
          <div>Ad: {{chartDataRevenue.totals.ad?.toFixed(2)}}$</div>
          <div>IAP: {{chartDataRevenue.totals.iap?.toFixed(2)}}$</div>
        </div>
      </q-card-section>
    </q-card>
  </q-page>
</template>

<script>

import AnalyticsFilter from "@/pages/workspace/analytics/AnalyticsFilter.vue";
import moment from "moment";
import _ from "lodash";
import { LineChart } from "vue-chart-3";
import { Chart, registerables } from 'chart.js';
import {hasFiltersMixin} from '@/pages/workspace/analytics/hasFiltersMixin';

Chart.register(...registerables);

export default {
  name: "AnalyticsAudience",
  components: {AnalyticsFilter, LineChart},

  mixins: [hasFiltersMixin],

  data: () => ({
    moduleId: false,
    versionSource: null,
    retStat: {},
  }),
  async created() {
    // Set module id
    this.moduleId = this.$route.params.module_id
  },

  computed: {
    /**
     * Get days
     * @return {string[]}
     */
    days() {
      // List of days between dateFrom and dateTo
      const days = [];
      const day = moment(this.dateFrom);
      while(day.isSameOrBefore(this.dateTo)) {
        days.push(day.format("YYYY-MM-DD"));
        day.add(1, "day");
      }

      return days;
    },

    /**
     * Computed property that generates the data for the Users line chart.
     * It uses the 'days' computed property to generate the labels and the 'retStat' data property to generate the data points.
     * Each data point corresponds to the number of users for a specific day.
     * If there is no data for a specific day, it defaults to 0.
     * The line chart has a red color.
     *
     * @returns {Object} An object containing the labels and datasets for the Users line chart.
     */
    chartDataUsers() {
      return {
        labels: this.days,
        datasets: [
          {
            label: 'Users',
            data: this.days.map((day) => this.retStat[day]?.users || 0),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgb(255, 99, 132, .5)',
          },
        ]
      };
    },

    /**
     * Computed property that generates the options for the line chart.
     * It sets the interaction mode to 'index' and disables intersection.
     * It also customizes the tooltip label to display the number of users and the ratio of events to users for each data point.
     * If there are no events for a specific data point, the ratio defaults to 0.
     *
     * @returns {Object} An object containing the options for the line chart.
     */
    chartOptions() {
      return {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (item) => {
                const users = item.raw || 0;
                const events = this.retStat[item.label]?.events || null;

                return `${users} (${users && events ? Math.ceil(events / users) : 0})`;
              },
            }
          }
        }
      };
    },

    /**
     * Computed property that generates the data for the Revenue line chart.
     * It uses the 'days' computed property to generate the labels and the 'retStat' data property to generate the data points.
     * Each data point corresponds to the total revenue, ad revenue, and IAP revenue for a specific day.
     * If there is no data for a specific day, it defaults to 0.
     * The line chart has different colors for total revenue (red), ad revenue (blue), and IAP revenue (green).
     *
     * @returns {Object} An object containing the labels and datasets for the Revenue line chart.
     */
    chartDataRevenue() {
      return {
        labels: this.days,
        datasets: [
          {
            label: 'Total revenue',
            data: this.days.map((day) => this.retStat[day]?.total_revenue || 0),
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgb(255, 99, 132, .5)',
          },
          {
            label: 'Ad revenue',
            data: this.days.map((day) => this.retStat[day]?.ad_revenue || 0),
            borderColor: 'rgb(54, 162, 235)',
            backgroundColor: 'rgb(54, 162, 235, .5)',
          },
          {
            label: 'IAP revenue',
            data: this.days.map((day) => this.retStat[day]?.iap_revenue || 0),
            borderColor: 'rgb(75, 192, 192)',
            backgroundColor: 'rgb(75, 192, 192, .5)',
          },
        ],
        totals: {
          // Calc sum of total, ad, iap revenue
          total: this.days.reduce((acc, day) => acc + (this.retStat[day]?.total_revenue || 0), 0),
          ad: this.days.reduce((acc, day) => acc + (this.retStat[day]?.ad_revenue || 0), 0),
          iap: this.days.reduce((acc, day) => acc + (this.retStat[day]?.iap_revenue || 0), 0),
        }
      };
    },

    /**
     * Computed property that generates the options for the Revenue line chart.
     * It sets the interaction mode to 'index' and disables intersection.
     * It also customizes the tooltip label to display the label and the formatted value of each data point.
     *
     * @returns {Object} An object containing the options for the Revenue line chart.
     */
    chartRevenueOptions() {
      return {
        interaction: {
          intersect: false,
          mode: 'index',
        },
        plugins: {
          tooltip: {
            callbacks: {
              label: (item) => `${item.dataset.label}: ${item.formattedValue}$`,
            }
          }
        }
      };
    },
  },

  methods: {
    /**
     * Update source
     */
    updateStatistics: _.debounce(async function() {
      try {
        if(!(this.dateFrom && this.dateTo && this.moduleId)) {
          return
        }

        this.$q.loading.show({message: "Updating statistics..."});

        const res = await this.app.client.call(
          "app-statistics",
          "getAudience",
          this.appPlatform,
          this.dateFrom,
          this.dateTo,
          this.moduleId,
          this.codeVersion,
          this.schemaVersion,
          this.requiredEvents,
          this.countries,
          this.bigQuery,
          this.utmSource,
          this.utmMedium,
          this.utmCampaign,
          this.utmContent,
        );

        this.retStat = {}
        for(const r of res) {
          this.retStat[r.event_date] = {
            users: r.users,
            events: r.events,
            total_revenue: r.total_revenue,
            ad_revenue: r.ad_revenue,
            iap_revenue: r.iap_revenue,
          }
        }
      } finally {
        this.$q.loading.hide()
      }
    }, 300),
  },
}

</script>
