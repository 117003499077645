<template>
    <ab-flow-base-cmp :block="block" class="ab-intersection-editor-cmp bg-amber">

        <div class="results">
            <ab-flow-link-connector :block="block" type="output" event="visible"/>
        </div>

    </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

export default {
    components: { AbFlowBaseCmp, AbFlowLinkConnector},
    inject: ['canvas'],
    props: ['block'],
    name: "IntersectionEditorCmp",
}

</script>

<style lang="scss">

.ab-intersection-editor-cmp {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 5px;
  min-height: 5px !important;

  .results {
    display: flex;
    justify-content: center;
    column-gap: 12px;
  }
}

</style>
