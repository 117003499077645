<template>
  <ui-prop-string name="width" title="Width" :block="block"/>
  <ui-prop-string name="height" title="Height" :block="block"/>
  <ui-prop-checkbox name="hasArrows" title="Has arrows" :block="block"/>
  <ui-prop-checkbox name="hasNavigation" title="Has navigation" :block="block"/>

  <div class="row">
  <ui-prop-checkbox name="isAutoSlide" title="Auto sliding" :block="block" :true-value="1" :false-value="0" :default-value="0"/>
  <ui-prop-string class="q-ml-md" v-if="block.properties?.isAutoSlide" name="autoSlideInterval" title="Slide interval, sec" :block="block"/>
  </div>

  <ui-prop-select
    name="slideSource"
    title="Slide source"
    default-value="navigation"
    :block="block"
    :options="slideSources"
  />

  <ui-prop-value-selector
    v-if="block?.properties?.slideSource === 'dataSource'"
    :block="block"
    name="dataSource"
    title="Data source"
    :is-array="true"
    :parent-diagram-id="diagram_id"
  />

  <ui-dynamic-props class="q-mt-md" :default-value="{}" :block="block" :product_id="product_id" :module_id="module_id"/>
</template>

<script>


import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps.vue";
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";
import UiPropCheckbox from "@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue";
import UiPropSelect from '@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue';
import UiPropValueSelector from '@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue';
import {propertiesMixins} from '@/components/DiagramDesigner/Editor/properties/propertiesMixins';
import {componentsMixin} from '@/components/DiagramDesigner/Editor/components/componentsMixin';

export default {
  components: {UiPropValueSelector, UiPropSelect, UiPropCheckbox, UiPropString, UiDynamicProps},
  props: ['block'],
  name: "CarouselPropsCmp",
  mixins: [propertiesMixins, componentsMixin],

  data() {
    return {
      slideSources: [
        {label: 'Navigation', value: 'navigation'},
        {label: 'Data source', value: 'dataSource'},
      ]
    };
  },
}

</script>
