<template>

  <ab-flow-base-cmp class="toolbar-cmp" :block="block" :class="classesString" :style="stylesString">
    <q-toolbar :class="bgClass">
      <div v-if="isBackButton">
        <ab-flow-link-connector class="link-connector" :block="block" type="output" event="back"/>
        <q-btn flat round dense icon="arrow_back"></q-btn>
      </div>
      <div v-if="menuPosition === 'left'">
        <q-btn flat round dense icon="menu" />
        <ab-flow-link-connector class="link-connector" :block="block" type="output" event="menu"/>
      </div>
      <q-toolbar-title>{{ title }}</q-toolbar-title>
      <div v-if="menuPosition === 'right'">
        <q-btn flat round dense icon="menu" />
        <ab-flow-link-connector class="link-connector" :block="block" type="output" event="menu"/>
      </div>

      <div v-if="hasCloseButton">
        <q-btn flat round dense icon="close" />
        <ab-flow-link-connector class="link-connector" :block="block" type="output" event="close"/>
      </div>


    </q-toolbar>



  </ab-flow-base-cmp>

  </template>

  <script>
  import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
  import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

  import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";

  export default {
    components: { AbFlowBaseCmp, AbFlowLinkConnector },
    mixins: [renderMixins],
    props: ['block'],
    name: "AppHeaderEditorCmp",
    computed: {
      /**
       * Title
       * @return {string}
       */
      title() {
        return this.interpretString(this.block.properties?.title) || "";
      },

      /**
       * Menu position
       * @return {string}
       */
      menuPosition() {
        return this.block.properties?.menuPosition || "";
      },

      /**
       * Is back button
       * @return {boolean}
       */
      isBackButton() {
        return this.block.properties?.isBackButton || false;
      },

      /**
       * Has close button
       * @return {boolean}
       */
      hasCloseButton() {
        return this.block.properties?.hasCloseButton || false;
      },

      /**
       * Toolbar background class color
       * @return {string}
       */
      bgClass() {
        return this.block?.properties?.color ? `bg-${this.block.properties.color}` : "";
      }
    },
  }

  </script>

<style lang="scss">
.toolbar-cmp {}
</style>
