<template>
  <q-page class="q-pa-md" v-if="isReady">

    <div class="text-h5">
      Facebook SDK settings
    </div>

    <hr>

    <q-form @submit="saveProperties">
      <div class="text-h6">
        IOS
      </div>

      <q-input label="Facebook ID" v-model="properties.iosFacebookId"/>
      <q-input label="Facebook Client token" v-model="properties.iosFacebookClientToken"/>
      <q-input label="Facebook App name" v-model="properties.iosFacebookAppName"/>

      <div class="text-h6 q-mt-lg">
        Android
      </div>

      <q-input label="Facebook ID" v-model="properties.androidFacebookId"/>
      <q-input label="Facebook Client token" v-model="properties.androidFacebookClientToken"/>
      <q-input label="Facebook App name" v-model="properties.androidFacebookAppName"/>

      <q-separator/>
      <q-btn type="submit" label="Save" color="primary" class="q-mt-md"/>
    </q-form>

  </q-page>
</template>

<script>

import {integrationsMixin} from "@/pages/workspace/integrations/integrationsMixin";

export default {
  components: {},
  mixins: [integrationsMixin],
  name: "FacebookIntegrationSettings",
  data: () => ({
    placement: {},
  }),

  computed: {

    /**
     * Return integration name
     */
    integration: () => ({name: "facebook", type: "analytics"}),
  }
}

</script>
