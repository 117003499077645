<template>
  <ab-flow-base-cmp :block="block" class="native-component-editor-cmp" :class="classesString" :style="stylesString">

    <div class="preview" v-if="previewImage" :style="{'background-image': 'url('+previewImage+')'}">

    </div>
    <div v-else class="description">
      {{ description }}
    </div>


    <div class="links-wrapper">
      <div class="in">
        <ab-flow-link-connector v-for="(e, k) of inEvents" :key="'in-'+k" :block="block" type="input" :event="e.name" />
      </div>

      <div class="out">
        <ab-flow-link-connector v-for="(e, k) of outEvents" :key="'out-' + k" :block="block" type="output" :event="e.name" />
      </div>
    </div>

  </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";
import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import {pathHelper} from "@/utils/pathHelper";

export default {
  mixins: [renderMixins],
  components: {AbFlowBaseCmp, AbFlowLinkConnector},
  props: ['block'],
  name: "NativeComponentEditorCmp",
  computed: {

    /**
     * Return title
     * @return {{}}
     */
    title() {
      // Validate
      return this.block.properties?.title
    },

    /**
     * Return description
     * @return {{}}
     */
    description() {
      // Validate
      return this.block.properties?.description
    },

    /**
     * Check for preview image
     * @return {{}}
     */
    previewImage() {
      // Validate
      return pathHelper.assetPath(this.block.properties?.previewImage?.source_url);
    },

    /**
     * Return native component name
     * @return {{}}
     */
    name() {
      // Validate
      return this.block.properties?.name
    },

    /**
     * Outgoing events
     * @return {[]}
     */
    outEvents() {
      return this.block.properties?.outgoing || []
    },

    /**
     * Incoming events
     * @return {[]}
     */
    inEvents() {
      return this.block.properties?.incoming || []
    },
  },
}

</script>

<style lang="scss">

.native-component-editor-cmp {
  position: relative;


  .description {
    padding: 10px;
    overflow: auto;
    color: black;
    background: repeating-linear-gradient(
            30deg,
            #eee,
            #eee 10px,
            #ddd 10px,
            #ddd 20px
    );
  }

  .preview {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
  }

  justify-content: center;
  align-items: center;

  .links-wrapper {
    column-gap: 8px;

    .in, .out {
      display: flex;
      column-gap: 4px;
    }

    .in {
      .input {
        visibility: visible;
      }
    }
  }
}


</style>
