<template>

  <ab-flow-base-cmp class="treeview-cmp" :block="block" :class="classesString" :style="stylesString">

    <div class="events">
      <ab-flow-link-connector class="q-mt-sm" :block="block" type="output" event="expand"/>
      <ab-flow-link-connector class="q-mt-sm" :block="block" type="output" event="select"/>
      <ab-flow-link-connector class="q-mt-sm" :block="block" type="output" event="item-slot"/>
      <ab-flow-link-connector class="q-mt-sm" :block="block" type="output" event="no-children-slot"/>
    </div>

    <div>
      <q-tree
          :nodes="items"
          v-model:selected="selectedId"
          label-key="title"
          no-selection-unset
          default-expand-all
          node-key="id"
      />

    </div>

  </ab-flow-base-cmp>

</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import {renderMixins} from "../../../renderMixins";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";
import {StorageNode} from "../../../../../../../../../common/db/StorageNode";

export default {
  components: {AbFlowBaseCmp, AbFlowLinkConnector},
  inject: ['canvas'],
  mixins: [renderMixins],
  props: ['block'],
  name: "TreeViewEditorCmp",
  data: () => ({
    selectedId: false
  }),
  computed: {

    /**
     * Get mock items
     * @return {*}
     */
    items() {

      return this.wait("items", async () => {

        // Unpack json
        const nodeId = this.block.properties?.dataSource?.nodeId

        // Load node default value
        const nd = await StorageNode.find(nodeId)

        // Return value
        return nd?.value !== undefined ? Array.isArray(nd?.value) ? nd?.value : [nd?.value] : []
      })
    }
  },
}
</script>

<style lang="scss">

.treeview-cmp {
  flex-direction: column;
  position: relative;

  .events {
    position: absolute;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
    z-index: 1;
  }
}

</style>
