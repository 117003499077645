<template>

  <div>
    <ui-prop-dynamic-string :block="block" name="title" title="Task title"/>
    <ui-prop-dynamic-string :block="block" name="description" :multiple="true" title="Task description"/>

    <ui-data-props title="Assignees" name="assignees" :default-schema="defaultSchema" :block="block"/>
    <ui-data-props title="Acceptors" name="acceptors" :default-schema="defaultSchema" :block="block"/>

    <div :class="{'bg-grey-3 q-mt-sm q-pa-sm': block.properties?.customDiagramProcessor}">
      <ui-prop-checkbox name="customDiagramProcessor" title="Use custom task processor" :true-value="1" :false-value="0" :block="block" :default-value="0"/>
      <template v-if="block.properties?.customDiagramProcessor">
        <ui-prop-select :block="block" name="customModuleId" title="Module" :options="modules" @change="resetDiagramId"/>
        <ui-prop-select :block="block" name="customDiagramId" title="Diagram" :options="moduleDiagrams"/>
      </template>
    </div>

    <ui-events-props :block="block" title="Incoming events" name="incoming-events" :manual="true"/>
    <ui-events-props :block="block" title="Outgoing events" name="outgoing-events" :manual="true"/>
  </div>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropDynamicString from "@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue";
import UiEventsProps from "@/components/DiagramDesigner/Editor/properties/UiEventsProps.vue";
import UiDataProps from "@/components/DiagramDesigner/Editor/properties/UiDataProps.vue";
import {Diagram} from "../../../../../../../../../common/db/Diagram";
import {AppModule} from "../../../../../../../../../common/db/AppModule";
import UiPropCheckbox from "@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";

export default {
  mixins: [propertiesMixins],
  inject: ['product_id'],
  components: {UiPropSelect, UiPropCheckbox, UiDataProps, UiEventsProps, UiPropDynamicString},
  props: ['block'],
  name: "TaskPropsCmp",
  data: () => ({
    defaultSchema: [
      {
        title: "Position alias",
        name: "alias",
        type: "string"
      },
    ]
  }),
  created() {
    // Watch this.block.properties?.customModuleId and subscribe to module diagrams
    this.$watch(() => this.block.properties?.customModuleId, async (newVal) => {
      if(newVal) {
        // Subscribe to module diagrams
        Diagram.remote().fetch("app-module-diagrams", {module_id: newVal})
      }
    }, {immediate: true})
  },
  computed: {

    /**
     * Module list
     * @returns {*}
     */
    modules() {
      return this.wait("modules", async () => {
        return (await AppModule.query().where({app_id: this.product_id}).get())
            .filter((m) => m?.type !== "server")
            .map(m => ({value: m.id, label: m.name}))
      }, [])
    },

    /**
     * Computed property that fetches all diagrams of type 'function' for the current app.
     * It waits for the diagrams to be fetched from the database using the app_id and diagram_type.
     *
     * @returns {Array} An array of diagrams if they exist, an empty array otherwise.
     */
    moduleDiagrams() {
      return this.wait("diagrams", async () => {
        return (await Diagram.query().where({app_id: this.product_id, module_id: this.block.properties?.customModuleId || 0}).get()).map(m => ({value: m.id, label: m.title}))
      }, [])
    },
  },

  methods: {
    resetDiagramId() {
      // eslint-disable-next-line
      this.block.properties.customDiagramId = null
    }
  }
}

</script>
