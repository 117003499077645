<template>
  <q-page class="q-pa-md" v-if="isReady">

    <div class="text-h6">
      Localization settings
    </div>

    <q-card class="q-mt-md">
      <q-tabs
        v-model="tab"
        dense
        align="center"
        narrow-indicator
      >
        <q-tab name="general" label="General" />
        <q-tab name="translations" label="Translations" />
        <q-tab name="progress" label="Progress" />
      </q-tabs>

      <q-separator />

      <q-tab-panels v-model="tab" animated>
        <q-tab-panel name="general">
          <q-form @submit="saveProperties">

            <q-select
              class="q-mt-md"
              v-model="properties.mainLocale"
              label="Development language"
              map-options
              emit-value
              filled
              :options="globals.options.languages"
            />

            <q-select
              class="q-mt-md"
              v-model="properties.additionalLocales"
              label="App languages"
              multiple
              map-options
              emit-value
              filled
              use-chips
              :options="globals.options.languages"
            />

            <q-separator/>
            <q-btn type="submit" label="Save" color="primary" class="q-mt-md"/>
          </q-form>
        </q-tab-panel>

        <q-tab-panel name="translations">
          <translations-table />
        </q-tab-panel>

        <q-tab-panel name="progress">
          <translation-progress />
        </q-tab-panel>
      </q-tab-panels>
    </q-card>

  </q-page>
</template>

<script>
import {computed} from 'vue';
import {integrationsMixin} from "@/pages/workspace/integrations/integrationsMixin";
import TranslationsTable from '@/pages/workspace/integrations/localizations/components/TranslationsTable.vue';
import TranslationProgress from '@/pages/workspace/integrations/localizations/components/TranslationProgress.vue';

export default {
  mixins: [integrationsMixin],
  name: "LocalizationsIntegrationSettings",

  components: {
    TranslationsTable,
    TranslationProgress,
  },

  provide() {
    return {
      module_id: computed(() => this.module_id),
    };
  },

  data: () => ({
    tab: 'general',
  }),

  computed: {
    /**
     * Return integration name
     */
    integration: () => ({name: "localizations", type: "localizations"}),
  },

  async created() {
    // Reindexing localizations
    await this.app.client.call('localizations', 'reindexingLocalizations', this.module_id);
  },
}

</script>

<style scoped lang="scss">
.translation-select {
  min-width: 130px;
}
</style>
