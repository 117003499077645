<template>

  <div>
    <ui-prop-dynamic-string :block="block" title="Message" name="message"/>
    <ui-prop-select title="Message type" name="contentType" :block="block" :options="contentTypes" default-value="text" />

    <template v-if="currentType === 'text'">
      <ui-prop-select title="Recipient" name="recipient" :block="block" :options="recipientTypes" default-value="user" />
    </template>

    <template v-if="['image', 'video', 'sound'].includes(currentType)">
      <ui-prop-value-selector :block="block" name="mediaFile" :only-var-type="currentType+',string'" title="Media file" :parent-diagram-id="diagram_id"/>
      <ui-prop-dynamic-string :block="block" title="Meta data" name="metaData"/>
    </template>

    <ui-prop-checkbox title="Add message to chat history" name="addToHistory" :block="block" :default-value="1" />

    <ui-prop-custom-list-props name="options" title="Message options" :block="block" v-if="currentType === 'options'">
      <template v-slot:item="data">
        <q-select
            v-model="data.item.type"
            :options="parameterTypes"
            map-options
            emit-value
            label="Option type"
        />

        <template v-if="data.item.type === 'answer'">
          <q-input v-model="data.item.text" label="Answer"  />
          <q-input v-model="data.item.event" label="Event"  />
        </template>
        <template v-if="data.item.type === 'ui-event'">
          <q-input v-model="data.item.text" label="Answer"  />
          <q-input v-model="data.item.event" label="Event"  />
        </template>
        <template v-else-if="data.item.type === 'url'">
          <q-input v-model="data.item.text" label="Button text"  />
          <q-input v-model="data.item.address" label="URL"  />
          <q-checkbox v-model="data.item.asWebapp" label="Open as webapp" :false-value="0" :true-value="1" />
        </template>

      </template>
    </ui-prop-custom-list-props>
  </div>

</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropDynamicString from "@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue";
import UiPropCustomListProps from '@/components/DiagramDesigner/Editor/properties/UiPropCustomListProps.vue';
import UiPropCheckbox from "@/components/DiagramDesigner/Editor/properties/UiPropCheckbox.vue";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";

export default {
  mixins: [propertiesMixins],
  components: {UiPropValueSelector, UiPropSelect, UiPropCheckbox, UiPropCustomListProps, UiPropDynamicString},
  props: ['block'],
  name: "ChatBotSendMessagePropsCmp",

  data() {
    return {
      contentTypes: [
        {value: 'text', label: 'Text'},
        {value: 'options', label: 'Options'},
        {value: 'image', label: 'Image'},
        {value: 'video', label: 'Video'},
        {value: 'sound', label: 'Audio'},
      ],
      parameterTypes: [
        {value: "answer", label: "Answer"},
        {value: "ui-event", label: "UI Event"},
        {value: "url", label: "URL"},
      ],
      recipientTypes: [
        {value: 'user', label: 'User'},
        {value: 'instruction', label: 'Instruction'},
      ]
    }
  },

  computed: {
    /**
     * Get current type
     * @return {HTMLElement|string|((type: string) => *)|*|string}
     */
    currentType() {
      return this.block.properties?.contentType|| 'text';
    }
  },
}

</script>
