<template>

  <q-card>

    <q-card-section>
      <div class="text-h6">Edit user</div>
    </q-card-section>

    <q-card-section class="q-pt-none">

      <q-form ref="userForm" @submit="onSaveUser" class="q-gutter-md">

        <q-input
            filled
            v-model="mutate(editUser).first_name"
            label="First name *"
            lazy-rules
            :rules="[ val => val && val.length > 0 || 'Please type something']"
        />

        <q-input
            filled
            v-model="mutate(editUser).last_name"
            label="Second name *"
            lazy-rules
            :rules="[ val => val && val.length > 0 || 'Please type something']"
        />

        <q-select :options="appsOptions" map-options emit-value multiple v-model="usersApps" clearable/>

        <div>
          <q-btn label="Save" type="submit" color="primary"/>
        </div>
      </q-form>

    </q-card-section>

  </q-card>

</template>

<script>

import {User} from "@/../../common/db/User";
import {UserApp} from "@/../../common/db/UserApp";
import {App} from "@/../../common/db/App";

export default {
  name: "UserForm",
  props: ['editUser'],
  emits: ['saved'],
  data: () => ({
    usersApps: []
  }),

  async created() {

    // Fetch all apps
    await App.remote().subscribe('admin-apps')

    // Subscribe to the users-apps collection
    await UserApp.remote().subscribe('user-apps', {user_id: this.editUser.id})

    // Load user's apps
    this.usersApps = (await UserApp.query().where({user_id: this.editUser.id}).get())?.map(ua => ua.app_id)
  },

  methods: {

    /**
     * On form submit
     * @return {Promise<void>}
     */
    async onSaveUser() {

      // Update
      const user = await User.remote().save(this.editUser);

      // Load current users apps list
      const currentApps = await UserApp.query().where({user_id: user.id}).get();

      // Delete all not selected apps
      for(const ua of currentApps.filter(ua => !(this.usersApps || []).includes(ua.app_id))) {
        await ua.remote().delete()
        console.log("!del", ua);
      }

      // Add new apps
      for (const ua of (this.usersApps || []).filter(ua => !currentApps.map(ca => ca.id).includes(ua))) {
        await UserApp.remote().save({
          user_id: user.id,
          app_id: ua,
          deleted: 0
        });
      }

      // Hide dialog
      this.$emit('saved')
    }
  },

  computed: {
    // Return all apps from the store
    appsOptions() {
      return this.wait("apps", async () => {
        return (await App.query().get()).map(a => ({value: a.id, label: a.title}))
      }, [])
    }
  },
}

</script>
