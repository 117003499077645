<template>
  <q-page class="q-pa-md">

    <q-dialog ref="editUser">
      <user-form :edit-user="editUser" @saved="$refs.editUser.hide()"/>
    </q-dialog>

    <div class="row q-gutter-md">

      <template v-for="(user, k) of users" :key="k">

        <q-card class="col-md-2 col-xs-12">

          <q-card-section class="bg-blue-1">
            <div class="text-subtitle1">{{ user.first_name }} {{ user.last_name }}</div>
          </q-card-section>

          <q-separator/>

          <q-card-actions align="right">
            <q-btn flat @click="editUser = user.data();$refs.editUser.show()">{{ $t('actions.edit') }}</q-btn>
            <q-btn flat @click="deleteUser(user)">{{ $t('actions.delete') }}</q-btn>
          </q-card-actions>
        </q-card>

      </template>

    </div>

    <q-page-sticky position="bottom-right" :offset="[18, 18]">
      <q-btn fab icon="add" color="primary" @click="editUser={};$refs.editUser.show()"/>
    </q-page-sticky>

  </q-page>
</template>

<style>
</style>

<script>
import UserForm from "./UserForm";
import {User} from "@/../../common/db/User";

export default {
  name: 'UsersList',
  components: { UserForm},
  data: () => ({
    editUser: false
  }),

  created() {
    User.remote().subscribe('admin-users')
  },

  computed: {
    // Return all users from the store
    users() {
        return this.wait("users", User.query().get(), [])
    }
  },

  methods: {

    /**
     * Delete user
     * @param user
     * @return {Promise<void>}
     */
    async deleteUser(user) {
      this.$q.dialog({
        title: 'Delete confirmation',
        message: `Are you sure want to delete ${user.title} ?`,
        cancel: true,
        persistent: true
      }).onOk(() => {
        User.remote().delete(user.id)
      })
    },
  }
}
</script>
