<template>
  <ab-flow-base-cmp :movable="true" :block="block" class="storage-get-editor-cmp">

    <ab-flow-link-connector :block="block" type="input" class="ev-run" event="run"/>

    <q-card class="card">

      <q-card-section class="bg-green-9 text-white">
        {{title}}
      </q-card-section>

    </q-card>

    <div class="results">
      <ab-flow-link-connector class="q-mt-sm" :block="block" type="output" event="result"/>
    </div>

  </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

export default {
  components: {AbFlowBaseCmp, AbFlowLinkConnector},
  inject: ['canvas'],
  props: ['block'],
  name: "StorageGetValueEditorCmp",
  computed: {

    /**
     * Return name
     * @return {{}}
     */
    title() {
      return this.block.properties?.title || 'Get value';
    },
  }
}

</script>

<style lang="scss">

.storage-get-editor-cmp {
  position: relative;

  .card {
    max-width: 200px;
    word-wrap: break-word;
  }
  .ev-run {
    left: -12px;
    position: absolute;
    z-index: 1;
    top: calc(50% - 5px);
  }
  .results {
    right: -12px;
    position: absolute;
    z-index: 1;
    top: calc(50% - 10px);
  }
}

</style>
