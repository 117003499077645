<template>

  <ab-flow-base-cmp class="banner-cmp" :block="block" :class="classesString" :style="stylesString">
    <div class="banner-container" :style="bannerStylesObj">
      <ab-flow-components-renderer :items="children"/>
    </div>
  </ab-flow-base-cmp>

</template>

<script>

import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowComponentsRenderer from "ab-flow-designer/src/components/Designer/AbFlowComponentsRenderer";

export default {
  components: {AbFlowBaseCmp, AbFlowComponentsRenderer},
  mixins: [renderMixins],
  props: ['block'],
  name: "BannerEditorCmp",
  computed: {
    /**
     * Placement name
     * @return {string}
     */
     placementName() {
      return this.block?.properties?.placementName || "Banner";
    },
    /**
     * Banner width
     * @return {string}
     */
     bannerWidth() {
      return this.block?.properties?.bannerWidth || "100%";
    },
    /**
     * Banner height
     * @return {string}
     */
     bannerHeight() {
      return this.block?.properties?.bannerHeight || "50px";
    },
    bannerStylesObj() {
      return ({
        "width": this.bannerWidth,
        "height": this.bannerHeight,
      })
    },

    /**
     * Children list
     * @return {*}
     */
    children() {
      // Return regular children
      return this.block.children
    },
  }
}

</script>

<style lang="scss" scoped>
.banner-cmp {
  display: flex;
  justify-content: center;
  align-items: center;
  .banner-container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
