<template>

  <div>

    <ui-prop-dynamic-string :block="block" title="Voice chat title" name="title"/>
    <ui-prop-value-selector :block="block" title="Chat background" name="backgroundImage" only-var-type="image" :parent-diagram-id="diagram_id"/>
    <ui-prop-value-selector :block="block" title="Opponent's gender(man/woman)" name="botGender" only-var-type="string" :parent-diagram-id="diagram_id"/>

    <ui-prop-dynamic-string :block="block" title="Channel" name="channel" untranslatable/>
    <ui-prop-dynamic-string  :block="block" title="ABChat Bot id" name="abChatBotId" untranslatable/>
    <ui-prop-params-list :block="block" title="Component parameters" name="chatArgs"/>

  </div>

</template>

<script>

import UiPropDynamicString from '@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue';
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropParamsList from "@/components/DiagramDesigner/Editor/properties/UiPropParamsList.vue";
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";

export default {
  mixins: [propertiesMixins],
  components: {UiPropValueSelector, UiPropParamsList, UiPropDynamicString},
  props: ['block'],
  name: "VoiceChatPropsCmp",
  data: () => ({
      }
  ),
  computed: {
  },
}

</script>
