export const countriesList = [
    {
        "criterion": 2004,
        "label": "Afghanistan",
        "value": "AF"
    },
    {
        "criterion": 2008,
        "label": "Albania",
        "value": "AL"
    },
    {
        "criterion": 2010,
        "label": "Antarctica",
        "value": "AQ"
    },
    {
        "criterion": 2012,
        "label": "Algeria",
        "value": "DZ"
    },
    {
        "criterion": 2016,
        "label": "American Samoa",
        "value": "AS"
    },
    {
        "criterion": 2020,
        "label": "Andorra",
        "value": "AD"
    },
    {
        "criterion": 2024,
        "label": "Angola",
        "value": "AO"
    },
    {
        "criterion": 2028,
        "label": "Antigua and Barbuda",
        "value": "AG"
    },
    {
        "criterion": 2031,
        "label": "Azerbaijan",
        "value": "AZ"
    },
    {
        "criterion": 2032,
        "label": "Argentina",
        "value": "AR"
    },
    {
        "criterion": 2036,
        "label": "Australia",
        "value": "AU"
    },
    {
        "criterion": 2040,
        "label": "Austria",
        "value": "AT"
    },
    {
        "criterion": 2044,
        "label": "The Bahamas",
        "value": "BS"
    },
    {
        "criterion": 2048,
        "label": "Bahrain",
        "value": "BH"
    },
    {
        "criterion": 2050,
        "label": "Bangladesh",
        "value": "BD"
    },
    {
        "criterion": 2051,
        "label": "Armenia",
        "value": "AM"
    },
    {
        "criterion": 2052,
        "label": "Barbados",
        "value": "BB"
    },
    {
        "criterion": 2056,
        "label": "Belgium",
        "value": "BE"
    },
    {
        "criterion": 2064,
        "label": "Bhutan",
        "value": "BT"
    },
    {
        "criterion": 2068,
        "label": "Bolivia",
        "value": "BO"
    },
    {
        "criterion": 2070,
        "label": "Bosnia and Herzegovina",
        "value": "BA"
    },
    {
        "criterion": 2072,
        "label": "Botswana",
        "value": "BW"
    },
    {
        "criterion": 2076,
        "label": "Brazil",
        "value": "BR"
    },
    {
        "criterion": 2084,
        "label": "Belize",
        "value": "BZ"
    },
    {
        "criterion": 2090,
        "label": "Solomon Islands",
        "value": "SB"
    },
    {
        "criterion": 2096,
        "label": "Brunei",
        "value": "BN"
    },
    {
        "criterion": 2100,
        "label": "Bulgaria",
        "value": "BG"
    },
    {
        "criterion": 2104,
        "label": "Myanmar (Burma)",
        "value": "MM"
    },
    {
        "criterion": 2108,
        "label": "Burundi",
        "value": "BI"
    },
    /*{
        "criterion": 2112,
        "label": "Belarus",
        "value": "BY"
    },*/
    {
        "criterion": 2116,
        "label": "Cambodia",
        "value": "KH"
    },
    {
        "criterion": 2120,
        "label": "Cameroon",
        "value": "CM"
    },
    {
        "criterion": 2124,
        "label": "Canada",
        "value": "CA"
    },
    {
        "criterion": 2132,
        "label": "Cape Verde",
        "value": "CV"
    },
    {
        "criterion": 2140,
        "label": "Central African Republic",
        "value": "CF"
    },
    {
        "criterion": 2144,
        "label": "Sri Lanka",
        "value": "LK"
    },
    {
        "criterion": 2148,
        "label": "Chad",
        "value": "TD"
    },
    {
        "criterion": 2152,
        "label": "Chile",
        "value": "CL"
    },
    {
        "criterion": 2156,
        "label": "China",
        "value": "CN"
    },
    {
        "criterion": 2162,
        "label": "Christmas Island",
        "value": "CX"
    },
    {
        "criterion": 2166,
        "label": "Cocos (Keeling) Islands",
        "value": "CC"
    },
    {
        "criterion": 2170,
        "label": "Colombia",
        "value": "CO"
    },
    {
        "criterion": 2174,
        "label": "Comoros",
        "value": "KM"
    },
    {
        "criterion": 2178,
        "label": "Republic of the Congo",
        "value": "CG"
    },
    {
        "criterion": 2180,
        "label": "Democratic Republic of the Congo",
        "value": "CD"
    },
    {
        "criterion": 2184,
        "label": "Cook Islands",
        "value": "CK"
    },
    {
        "criterion": 2188,
        "label": "Costa Rica",
        "value": "CR"
    },
    {
        "criterion": 2191,
        "label": "Croatia",
        "value": "HR"
    },
    {
        "criterion": 2196,
        "label": "Cyprus",
        "value": "CY"
    },
    {
        "criterion": 2203,
        "label": "Czechia",
        "value": "CZ"
    },
    {
        "criterion": 2204,
        "label": "Benin",
        "value": "BJ"
    },
    {
        "criterion": 2208,
        "label": "Denmark",
        "value": "DK"
    },
    {
        "criterion": 2212,
        "label": "Dominica",
        "value": "DM"
    },
    {
        "criterion": 2214,
        "label": "Dominican Republic",
        "value": "DO"
    },
    {
        "criterion": 2218,
        "label": "Ecuador",
        "value": "EC"
    },
    {
        "criterion": 2222,
        "label": "El Salvador",
        "value": "SV"
    },
    {
        "criterion": 2226,
        "label": "Equatorial Guinea",
        "value": "GQ"
    },
    {
        "criterion": 2231,
        "label": "Ethiopia",
        "value": "ET"
    },
    {
        "criterion": 2232,
        "label": "Eritrea",
        "value": "ER"
    },
    {
        "criterion": 2233,
        "label": "Estonia",
        "value": "EE"
    },
    {
        "criterion": 2239,
        "label": "South Georgia and the South Sandwich Islands",
        "value": "GS"
    },
    {
        "criterion": 2242,
        "label": "Fiji",
        "value": "FJ"
    },
    {
        "criterion": 2246,
        "label": "Finland",
        "value": "FI"
    },
    {
        "criterion": 2250,
        "label": "France",
        "value": "FR"
    },
    {
        "criterion": 2258,
        "label": "French Polynesia",
        "value": "PF"
    },
    {
        "criterion": 2260,
        "label": "French Southern and Antarctic Lands",
        "value": "TF"
    },
    {
        "criterion": 2262,
        "label": "Djibouti",
        "value": "DJ"
    },
    {
        "criterion": 2266,
        "label": "Gabon",
        "value": "GA"
    },
    {
        "criterion": 2268,
        "label": "Georgia",
        "value": "GE"
    },
    {
        "criterion": 2270,
        "label": "The Gambia",
        "value": "GM"
    },
    {
        "criterion": 2276,
        "label": "Germany",
        "value": "DE"
    },
    {
        "criterion": 2288,
        "label": "Ghana",
        "value": "GH"
    },
    {
        "criterion": 2296,
        "label": "Kiribati",
        "value": "KI"
    },
    {
        "criterion": 2300,
        "label": "Greece",
        "value": "GR"
    },
    {
        "criterion": 2308,
        "label": "Grenada",
        "value": "GD"
    },
    {
        "criterion": 2316,
        "label": "Guam",
        "value": "GU"
    },
    {
        "criterion": 2320,
        "label": "Guatemala",
        "value": "GT"
    },
    {
        "criterion": 2324,
        "label": "Guinea",
        "value": "GN"
    },
    {
        "criterion": 2328,
        "label": "Guyana",
        "value": "GY"
    },
    {
        "criterion": 2332,
        "label": "Haiti",
        "value": "HT"
    },
    {
        "criterion": 2334,
        "label": "Heard Island and McDonald Islands",
        "value": "HM"
    },
    {
        "criterion": 2336,
        "label": "Vatican City",
        "value": "VA"
    },
    {
        "criterion": 2340,
        "label": "Honduras",
        "value": "HN"
    },
    {
        "criterion": 2348,
        "label": "Hungary",
        "value": "HU"
    },
    {
        "criterion": 2352,
        "label": "Iceland",
        "value": "IS"
    },
    {
        "criterion": 2356,
        "label": "India",
        "value": "IN"
    },
    {
        "criterion": 2360,
        "label": "Indonesia",
        "value": "ID"
    },
    {
        "criterion": 2368,
        "label": "Iraq",
        "value": "IQ"
    },
    {
        "criterion": 2372,
        "label": "Ireland",
        "value": "IE"
    },
    {
        "criterion": 2376,
        "label": "Israel",
        "value": "IL"
    },
    {
        "criterion": 2380,
        "label": "Italy",
        "value": "IT"
    },
    {
        "criterion": 2384,
        "label": "Cote d'Ivoire",
        "value": "CI"
    },
    {
        "criterion": 2388,
        "label": "Jamaica",
        "value": "JM"
    },
    {
        "criterion": 2392,
        "label": "Japan",
        "value": "JP"
    },
    {
        "criterion": 2398,
        "label": "Kazakhstan",
        "value": "KZ"
    },
    {
        "criterion": 2400,
        "label": "Jordan",
        "value": "JO"
    },
    {
        "criterion": 2404,
        "label": "Kenya",
        "value": "KE"
    },
    {
        "criterion": 2410,
        "label": "South Korea",
        "value": "KR"
    },
    {
        "criterion": 2414,
        "label": "Kuwait",
        "value": "KW"
    },
    {
        "criterion": 2417,
        "label": "Kyrgyzstan",
        "value": "KG"
    },
    {
        "criterion": 2418,
        "label": "Laos",
        "value": "LA"
    },
    {
        "criterion": 2422,
        "label": "Lebanon",
        "value": "LB"
    },
    {
        "criterion": 2426,
        "label": "Lesotho",
        "value": "LS"
    },
    {
        "criterion": 2428,
        "label": "Latvia",
        "value": "LV"
    },
    {
        "criterion": 2430,
        "label": "Liberia",
        "value": "LR"
    },
    {
        "criterion": 2434,
        "label": "Libya",
        "value": "LY"
    },
    {
        "criterion": 2438,
        "label": "Liechtenstein",
        "value": "LI"
    },
    {
        "criterion": 2440,
        "label": "Lithuania",
        "value": "LT"
    },
    {
        "criterion": 2442,
        "label": "Luxembourg",
        "value": "LU"
    },
    {
        "criterion": 2450,
        "label": "Madagascar",
        "value": "MG"
    },
    {
        "criterion": 2454,
        "label": "Malawi",
        "value": "MW"
    },
    {
        "criterion": 2458,
        "label": "Malaysia",
        "value": "MY"
    },
    {
        "criterion": 2462,
        "label": "Maldives",
        "value": "MV"
    },
    {
        "criterion": 2466,
        "label": "Mali",
        "value": "ML"
    },
    {
        "criterion": 2470,
        "label": "Malta",
        "value": "MT"
    },
    {
        "criterion": 2478,
        "label": "Mauritania",
        "value": "MR"
    },
    {
        "criterion": 2480,
        "label": "Mauritius",
        "value": "MU"
    },
    {
        "criterion": 2484,
        "label": "Mexico",
        "value": "MX"
    },
    {
        "criterion": 2492,
        "label": "Monaco",
        "value": "MC"
    },
    {
        "criterion": 2496,
        "label": "Mongolia",
        "value": "MN"
    },
    {
        "criterion": 2498,
        "label": "Moldova",
        "value": "MD"
    },
    {
        "criterion": 2499,
        "label": "Montenegro",
        "value": "ME"
    },
    {
        "criterion": 2504,
        "label": "Morocco",
        "value": "MA"
    },
    {
        "criterion": 2508,
        "label": "Mozambique",
        "value": "MZ"
    },
    {
        "criterion": 2512,
        "label": "Oman",
        "value": "OM"
    },
    {
        "criterion": 2516,
        "label": "Namibia",
        "value": "NA"
    },
    {
        "criterion": 2520,
        "label": "Nauru",
        "value": "NR"
    },
    {
        "criterion": 2524,
        "label": "Nepal",
        "value": "NP"
    },
    {
        "criterion": 2528,
        "label": "Netherlands",
        "value": "NL"
    },
    {
        "criterion": 2531,
        "label": "Curacao",
        "value": "CW"
    },
    {
        "criterion": 2534,
        "label": "Sint Maarten",
        "value": "SX"
    },
    {
        "criterion": 2535,
        "label": "Caribbean Netherlands",
        "value": "BQ"
    },
    {
        "criterion": 2540,
        "label": "New Caledonia",
        "value": "NC"
    },
    {
        "criterion": 2548,
        "label": "Vanuatu",
        "value": "VU"
    },
    {
        "criterion": 2554,
        "label": "New Zealand",
        "value": "NZ"
    },
    {
        "criterion": 2558,
        "label": "Nicaragua",
        "value": "NI"
    },
    {
        "criterion": 2562,
        "label": "Niger",
        "value": "NE"
    },
    {
        "criterion": 2566,
        "label": "Nigeria",
        "value": "NG"
    },
    {
        "criterion": 2570,
        "label": "Niue",
        "value": "NU"
    },
    {
        "criterion": 2574,
        "label": "Norfolk Island",
        "value": "NF"
    },
    {
        "criterion": 2578,
        "label": "Norway",
        "value": "NO"
    },
    {
        "criterion": 2580,
        "label": "Northern Mariana Islands",
        "value": "MP"
    },
    {
        "criterion": 2581,
        "label": "United States Minor Outlying Islands",
        "value": "UM"
    },
    {
        "criterion": 2583,
        "label": "Federated States of Micronesia",
        "value": "FM"
    },
    {
        "criterion": 2584,
        "label": "Marshall Islands",
        "value": "MH"
    },
    {
        "criterion": 2585,
        "label": "Palau",
        "value": "PW"
    },
    {
        "criterion": 2586,
        "label": "Pakistan",
        "value": "PK"
    },
    {
        "criterion": 2591,
        "label": "Panama",
        "value": "PA"
    },
    {
        "criterion": 2598,
        "label": "Papua New Guinea",
        "value": "PG"
    },
    {
        "criterion": 2600,
        "label": "Paraguay",
        "value": "PY"
    },
    {
        "criterion": 2604,
        "label": "Peru",
        "value": "PE"
    },
    {
        "criterion": 2608,
        "label": "Philippines",
        "value": "PH"
    },
    {
        "criterion": 2612,
        "label": "Pitcairn Islands",
        "value": "PN"
    },
    {
        "criterion": 2616,
        "label": "Poland",
        "value": "PL"
    },
    {
        "criterion": 2620,
        "label": "Portugal",
        "value": "PT"
    },
    {
        "criterion": 2624,
        "label": "Guinea-Bissau",
        "value": "GW"
    },
    {
        "criterion": 2626,
        "label": "Timor-Leste",
        "value": "TL"
    },
    {
        "criterion": 2634,
        "label": "Qatar",
        "value": "QA"
    },
    {
        "criterion": 2642,
        "label": "Romania",
        "value": "RO"
    },
    /*{
        "criterion": 2643,
        "label": "Russia",
        "value": "RU"
    },*/
    {
        "criterion": 2646,
        "label": "Rwanda",
        "value": "RW"
    },
    {
        "criterion": 2652,
        "label": "Saint Barthelemy",
        "value": "BL"
    },
    {
        "criterion": 2654,
        "label": "Saint Helena, Ascension and Tristan da Cunha",
        "value": "SH"
    },
    {
        "criterion": 2659,
        "label": "Saint Kitts and Nevis",
        "value": "KN"
    },
    {
        "criterion": 2662,
        "label": "Saint Lucia",
        "value": "LC"
    },
    {
        "criterion": 2663,
        "label": "Saint Martin",
        "value": "MF"
    },
    {
        "criterion": 2666,
        "label": "Saint Pierre and Miquelon",
        "value": "PM"
    },
    {
        "criterion": 2670,
        "label": "Saint Vincent and the Grenadines",
        "value": "VC"
    },
    {
        "criterion": 2674,
        "label": "San Marino",
        "value": "SM"
    },
    {
        "criterion": 2678,
        "label": "Sao Tome and Principe",
        "value": "ST"
    },
    {
        "criterion": 2682,
        "label": "Saudi Arabia",
        "value": "SA"
    },
    {
        "criterion": 2686,
        "label": "Senegal",
        "value": "SN"
    },
    {
        "criterion": 2688,
        "label": "Serbia",
        "value": "RS"
    },
    {
        "criterion": 2690,
        "label": "Seychelles",
        "value": "SC"
    },
    {
        "criterion": 2694,
        "label": "Sierra Leone",
        "value": "SL"
    },
    {
        "criterion": 2702,
        "label": "Singapore",
        "value": "SG"
    },
    {
        "criterion": 2703,
        "label": "Slovakia",
        "value": "SK"
    },
    {
        "criterion": 2704,
        "label": "Vietnam",
        "value": "VN"
    },
    {
        "criterion": 2705,
        "label": "Slovenia",
        "value": "SI"
    },
    {
        "criterion": 2706,
        "label": "Somalia",
        "value": "SO"
    },
    {
        "criterion": 2710,
        "label": "South Africa",
        "value": "ZA"
    },
    {
        "criterion": 2716,
        "label": "Zimbabwe",
        "value": "ZW"
    },
    {
        "criterion": 2724,
        "label": "Spain",
        "value": "ES"
    },
    {
        "criterion": 2728,
        "label": "South Sudan",
        "value": "SS"
    },
    {
        "criterion": 2736,
        "label": "Sudan",
        "value": "SD"
    },
    {
        "criterion": 2740,
        "label": "Surititle",
        "value": "SR"
    },
    {
        "criterion": 2748,
        "label": "Eswatini",
        "value": "SZ"
    },
    {
        "criterion": 2752,
        "label": "Sweden",
        "value": "SE"
    },
    {
        "criterion": 2756,
        "label": "Switzerland",
        "value": "CH"
    },
    {
        "criterion": 2762,
        "label": "Tajikistan",
        "value": "TJ"
    },
    {
        "criterion": 2764,
        "label": "Thailand",
        "value": "TH"
    },
    {
        "criterion": 2768,
        "label": "Togo",
        "value": "TG"
    },
    {
        "criterion": 2772,
        "label": "Tokelau",
        "value": "TK"
    },
    {
        "criterion": 2776,
        "label": "Tonga",
        "value": "TO"
    },
    {
        "criterion": 2780,
        "label": "Trinidad and Tobago",
        "value": "TT"
    },
    {
        "criterion": 2784,
        "label": "United Arab Emirates",
        "value": "AE"
    },
    {
        "criterion": 2788,
        "label": "Tunisia",
        "value": "TN"
    },
    {
        "criterion": 2792,
        "label": "Türkiye",
        "value": "TR"
    },
    {
        "criterion": 2795,
        "label": "Turkmenistan",
        "value": "TM"
    },
    {
        "criterion": 2798,
        "label": "Tuvalu",
        "value": "TV"
    },
    {
        "criterion": 2800,
        "label": "Uganda",
        "value": "UG"
    },
    /*{
        "criterion": 2804,
        "label": "Ukraine",
        "value": "UA"
    },*/
    {
        "criterion": 2807,
        "label": "North Macedonia",
        "value": "MK"
    },
    {
        "criterion": 2818,
        "label": "Egypt",
        "value": "EG"
    },
    {
        "criterion": 2826,
        "label": "United Kingdom",
        "value": "GB"
    },
    {
        "criterion": 2831,
        "label": "Guernsey",
        "value": "GG"
    },
    {
        "criterion": 2832,
        "label": "Jersey",
        "value": "JE"
    },
    {
        "criterion": 2834,
        "label": "Tanzania",
        "value": "TZ"
    },
    {
        "criterion": 2840,
        "label": "United States",
        "value": "US"
    },
    {
        "criterion": 2854,
        "label": "Burkina Faso",
        "value": "BF"
    },
    {
        "criterion": 2858,
        "label": "Uruguay",
        "value": "UY"
    },
    {
        "criterion": 2860,
        "label": "Uzbekistan",
        "value": "UZ"
    },
    {
        "criterion": 2862,
        "label": "Venezuela",
        "value": "VE"
    },
    {
        "criterion": 2876,
        "label": "Wallis and Futuna",
        "value": "WF"
    },
    {
        "criterion": 2882,
        "label": "Samoa",
        "value": "WS"
    },
    {
        "criterion": 2887,
        "label": "Yemen",
        "value": "YE"
    },
    {
        "criterion": 2894,
        "label": "Zambia",
        "value": "ZM"
    }
]
