<template>
  <q-card>
    <q-card-section class="q-pa-sm">
      <div class="flex no-wrap items-center">
        <div class="text-subtitle2" v-text="details.type" />

        <q-separator spaced vertical/>

        <div>
          <health-check-status :status="details.status" />

          <div class="text-caption" v-text="details.message" />
        </div>
      </div>
    </q-card-section>

    <template v-if="hasError">
      <q-separator spaced />

      <q-card-section v-if="errorMessage" class="q-pa-sm">
        <div class="text-caption" v-text="errorMessage" />
      </q-card-section>
      <q-card-section v-if="errorStack" class="q-pa-sm">
        <pre class="text-caption q-ma-none" v-text="errorStack" />
      </q-card-section>
    </template>
  </q-card>
</template>

<script>
import HealthCheckStatus from '@/pages/workspace/health-check/HealthCheckStatus.vue';

export default {
  name: 'DiskSpaceCard',
  components: {HealthCheckStatus},

  props: {
    details: {
      required: true,
      type: Object,
    },
  },

  computed: {
    // If the error message is not present, return null
    errorMessage() {
      return this.details?.payload?.error?.message || null;
    },
    // If the error stack is not present, return null
    errorStack() {
      return this.details?.payload?.error?.stack || null;
    },
    // If there is an error message or error stack, return true
    hasError() {
      return !!this.errorMessage || !!this.errorStack;
    },
  },
}
</script>
