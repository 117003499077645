<template>
  <ui-prop-reach-text class="reach-text" name="html" title="Reach text" :block="block"/>
  <ui-dynamic-props :default-value="{}" :block="block" />
</template>

<script>
import UiPropReachText from "@/components/DiagramDesigner/Editor/properties/UiPropReachText";
import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps";

export default {
  name: "RichTextPropsCmp",
  props: ['block'],
  components: {UiDynamicProps, UiPropReachText},
}

</script>

