<template>

    <div>
        <ui-prop-string name="name" title="Event name" :block="block" @keydown="preventSpace"/>
        <ui-prop-select title="Type" name="eventType" :block="block" :options="globals.options.event_types"/>
    </div>

</template>

<script>

import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString";
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue";

export default {
    components: {UiPropSelect, UiPropString},
    props: ['block'],
    name: "CustomEventPropsCmp",
    computed: {
    },

  methods: {
    /**
     * Prevents the default action of adding a space character when the spacebar is pressed.
     *
     * @param {KeyboardEvent} event - The keyboard event triggered by pressing a key.
     */
    preventSpace(event) {
      if (event.key === ' ') {
        event.preventDefault();
      }
    },
  },
}

</script>
