<template>
  <q-input ref="inp" :readonly="readonlyState" :loading="loadingState" @update:model-value="initValue"
           :type="multiple ? 'textarea' : 'input'" :placeholder="placeholder"
           :autogrow="multiple ? true : undefined"
           :hint="hint ? hint : undefined" hide-bottom-space :label="title" v-model="currentValue">
  </q-input>
</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";

export default {
  components: {},
  mixins: [propertiesMixins],
  name: "UiPropString",
  props: ['multiple'],
  data: () => ({
    loadingState: false,
    readonlyState: false
  }),
  created() {
    this.$watch('currentValue', (val) => {
      if (val !== undefined) {
        this.loadingState = false
        this.readonlyState = false
      }
    })
  },
  methods: {
    initValue() {
      if (this.currentValue === undefined) {
        this.loadingState = true
        this.readonlyState = true
      }
    }
  }
}

</script>
