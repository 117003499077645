<template>
  <ui-prop-color :block="block" title="Scene background color" name="bgColor" default-value="#eee"/>

  <q-card class="q-my-md">
    <q-card-section class="bg-primary text-white">
      Camera: position
    </q-card-section>

    <q-card-section class="q-pt-none flex q-gutter-sm no-wrap">
      <ui-prop-string :block="block" name="cameraPositionX" title="X" default-value="0" />
      <ui-prop-string :block="block" name="cameraPositionY" title="Y" default-value="3" />
      <ui-prop-string :block="block" name="cameraPositionZ" title="Z" default-value="10" />
    </q-card-section>
  </q-card>

  <q-card class="q-my-md">
    <q-card-section class="bg-primary text-white">
      Camera: look at
    </q-card-section>

    <q-card-section class="q-pt-none flex q-gutter-sm no-wrap">
      <ui-prop-string :block="block" name="cameraLookAtX" title="X" default-value="0" />
      <ui-prop-string :block="block" name="cameraLookAtY" title="Y" default-value="5" />
      <ui-prop-string :block="block" name="cameraLookAtZ" title="Z" default-value="0" />
    </q-card-section>
  </q-card>
</template>

<script>
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropColor from '@/components/DiagramDesigner/Editor/properties/UiPropColor.vue';
import UiPropString from '@/components/DiagramDesigner/Editor/properties/UiPropString.vue';

export default {
  mixins: [propertiesMixins],
  name: "ThreeDScenePropsCmp",
  props: ['block'],
  components: {UiPropString, UiPropColor},
}
</script>

