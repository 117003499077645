<template>
  <div class="q-gutter-y-md">
    <div class="row">
    <q-select class="col" label="Target platform" :options="platforms" v-model="targetPlatform" map-options emit-value/>
    <!--q-checkbox v-if="targetPlatform !== 'web'" label="Upload to App store" v-model="uploadToStore" :true-value="1" :false-value="0"/!-->
    </div>
    <div>Last build time: {{lastBuildTitle}}</div>
    <q-separator spaced/>
    <div class="row">
      <q-btn v-if="mode==='debug' || lastBuildTime > 0" color="info" icon="play_arrow" @click="runVersion()">Run</q-btn>

      <q-space/>

      <q-btn
        v-if="lastBuildTime > 0 && currentModule.type === 'server'"
        class="q-mr-md"
        color="warning"
        icon="restart_alt"
        @click="restartApp"
      >Restart</q-btn>

      <q-btn v-if="mode !=='debug'" color="primary" @click="buildApp">Build</q-btn>
    </div>
  </div>
</template>

<script>

import moment from "moment";
import {AppRelease} from "../../../../../../common/db/AppRelease";
import {AppModule} from '../../../../../../common/db/AppModule';

export default {
  name: "BuildApp",
  emits: ["close"],
  props: {
    mode: {
      default: "stage"
    },
    releaseId: {
      default: null
    }
  },

  data: () => ({
    targetPlatform: "web",
    uploadToStore: 0,
    moduleId: null,
  }),

  async created() {
    this.moduleId=this.$route.params.module_id
  },

  methods: {
    /**
     * Build application
     */
    async buildApp() {

      // Show loading
      this.$q.loading.show();

      const res = await AppRelease.remote().call("app", "buildGitVersion", this.release.id, this.mode, this.targetPlatform, this.uploadToStore);
      if(res?.files?.[this.targetPlatform+"_"+this.mode + "_build_time"] > 0) {
        this.$q.notify("Build completed...", "success")
      } else if(res?.files?.[this.targetPlatform+"_"+this.mode + "_build_time"] === -1) {
        this.$q.notify("Build is running...", "success")
      } else {
        this.$q.notify("Build failed...", "success")
      }

      // Show error
      if(res?.message) {
        this.$q.dialog({
          title: "Build error",
          message: res.message,
          html: true
        })
      }

      // Show loading
      this.$q.loading.hide();
    },

    /**
     * Run version
     */
    runVersion() {

      // Get url
      let url = this.release?.files?.[`${this.targetPlatform}_${this.mode}`] || this.release?.files?.[`${this.mode}`]

      // Debug mode
      if(this.mode === 'debug' || (url && url.toString().indexOf('http') === -1 ) ) url = process.env.VUE_APP_PUBLISH_URL + url + "/index.html";

      // Open build in new tab
      if(url) window.open(url); else {
        this.$q.notify("Build not found", "error")
      }
    },

    /**
     * Asynchronously restarts the application.
     * This method initiates the application restart process by making a remote call to the `restartApp` function
     * with the current module ID and mode as parameters. It displays a loading indicator at the start of the process.
     * If the restart is successful, a positive notification is shown to the user. In case of failure, a negative
     * notification is displayed with the error message. The loading indicator is hidden at the end of the process,
     * regardless of the outcome.
     */
    async restartApp() {
      this.$q.loading.show();

      try {
        const res = await AppRelease.remote().call('app', 'restartApp', this.moduleId, this.mode);

        if (res.status !== 'success') {
          throw 'Failed to restart app';
        }

        this.$q.notify({
          message: 'App restarted successfully',
          color: 'positive',
          icon: 'done',
          position: 'top'
        })
      } catch (e) {
        this.$q.notify({
          message: e.message || e || 'Failed to restart app',
          color: 'negative',
          icon: 'error',
          position: 'top'
        });
      } finally {
        this.$q.loading.hide();
      }
    },
  },

  computed: {

    /**
     * Return release
     * @return {*}
     */
    release() {
      return this.wait("release", AppRelease.find(this.releaseId), {})
    },

    /**
     * Return release builds
     * @return {string|string}
     */
    lastBuildTime() {
      return this.release?.files?.[`${this.targetPlatform}_${this.mode}_build_time`] || this.release?.files?.[`${this.mode}_build_time`]
    },

    /**
     * Return last build title
     * @return {string|boolean}
     */
    lastBuildTitle() {
      switch (this.lastBuildTime) {
        case -1:
          return "In progress";
        case -1000:
          return "Build failed";
        default:
          return this.lastBuildTime ? moment(this.lastBuildTime * 1000).format("YYYY-MM-DD HH:mm:ss") : "Never";
      }
    },

    /**
     * Current module
     * @return {*}
     */
    currentModule() {
      return this.wait("currentModule", AppModule.find(this.moduleId), {})
    },

    /**
     * Dynamically generates the list of platforms available for the application build.
     * This method filters the platforms based on the current module's type. If the module type
     * is neither 'web' nor 'server', all platforms listed in `globals.options.platformList` are available.
     * Otherwise, it excludes 'ios' and 'android' from the list, effectively limiting the options
     * to web-based platforms for web and server modules.
     *
     * @returns {Array} An array of platform options that are available for the current module.
     */
    platforms() {
      if (!['web', 'server'].includes(this.currentModule?.type)) {
        return this.globals.options.platformList;
      }

      return this.globals.options.platformList.filter((p) => !['ios', 'android'].includes(p.value));
    },
  }
}

</script>
