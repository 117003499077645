<template>
  <q-card>
    <q-card-section class="q-pa-sm">
      <div class="flex no-wrap items-center">
        <div class="text-subtitle2" v-text="details.type" />

        <q-separator spaced vertical/>

        <div>
          <health-check-status :status="details.status" />

          <div class="text-caption" v-text="details.message" />
        </div>

        <q-separator spaced vertical/>

        <div class="text-grey-8">
          {{ freeSpace }} / {{ totalSpace }}
        </div>
      </div>
    </q-card-section>

    <template v-if="hasError">
      <q-separator spaced />

      <q-card-section v-if="errorMessage" class="q-pa-sm">
        <div class="text-caption" v-text="errorMessage" />
      </q-card-section>
      <q-card-section v-if="errorStack" class="q-pa-sm">
        <pre class="text-caption q-ma-none" v-text="errorStack" />
      </q-card-section>
    </template>
  </q-card>
</template>

<script>
import HealthCheckStatus from '@/pages/workspace/health-check/HealthCheckStatus.vue';

/**
 * Formats a file size from bytes to a human-readable string.
 *
 * @param {number} bytes - The file size in bytes.
 * @returns {string} The formatted file size as a string with appropriate units.
 */
function formatFileSize(bytes) {
  if (bytes === 0) {
    return '0 Byte';
  }

  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  const size = bytes / Math.pow(k, i);

  return `${size.toFixed(2)} ${sizes[i]}`;
}

export default {
  name: 'DiskSpaceCard',
  components: {HealthCheckStatus},

  props: {
    details: {
      required: true,
      type: Object,
    },
  },

  computed: {
    // Extracts the free space from the details payload and formats it as a human-readable string.
    freeSpace() {
      return formatFileSize(this.details?.payload?.free || 0);
    },
    totalSpace() {
      // Extracts the total space from the details payload and formats it as a human-readable string.
      return formatFileSize(this.details?.payload?.total || 0);
    },
    // Extracts the error message from the details payload.
    errorMessage() {
      return this.details?.payload?.error?.message || null;
    },
    // Extracts the error stack from the details payload.
    errorStack() {
      return this.details?.payload?.error?.stack || null;
    },
    // Checks if the details payload contains an error message or stack.
    hasError() {
      return !!this.errorMessage || !!this.errorStack;
    },
  },
}
</script>
