<template>
  <ab-flow-base-cmp :movable="true" :block="block" class="system-alarm-editor-cmp">

    <ab-flow-link-connector :block="block" type="input" class="ev-run" event="run"/>

    <q-card>
      <q-card-section class="chat-bot-purchase bg-red-10 text-white">
        <div class="text-no-wrap ellipsis">
          Purchase: {{message}}
        </div>
      </q-card-section>
    </q-card>

    <div class="results">
      <ab-flow-link-connector class="q-mt-sm" :block="block" type="output" event="success"/>
      <ab-flow-link-connector class="q-mt-sm" :block="block" type="output" event="bought"/>
      <ab-flow-link-connector class="q-mt-sm" :block="block" type="output" event="error"/>
    </div>

  </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

export default {
  mixins: [renderMixins],
  components: { AbFlowBaseCmp, AbFlowLinkConnector },
  inject: ['canvas'],
  props: ['block'],
  name: "ChatBotPurchaseEditorCmp",
  computed: {

    /**
     * title
     * @return {string}
     */
    message() {
      return this.interpretString(this.block.properties?.message) || '';
    },
  }
}

</script>

<style lang="scss">

.chat-bot-purchase {
  max-width: 250px;
}

</style>
