<template>
  <q-page class="q-pa-md">

    <q-dialog ref="editDb">
      <db-form :edit-db="editDb" @saved="$refs.editDb.hide()"/>
    </q-dialog>

    <div class="row q-gutter-md">

      <template v-for="(db, k) of dbs" :key="k">

        <q-card class="col-md-2 col-xs-12">

          <q-card-section class="bg-blue-1 row" @click="gotoTablesList(db.id)">
            <q-btn icon="edit" round size="sm" flat class="q-mr-sm"/>
            <div class="text-subtitle1">{{ db.name }}</div>
          </q-card-section>

          <q-card-section>
            <div>{{ db.description || 'No description' }}</div>
          </q-card-section>

          <q-separator/>

          <q-card-actions align="right">
            <q-btn flat @click="editDb = db.data();$refs.editDb.show()">{{ $t('actions.edit') }}</q-btn>
            <q-btn flat @click="deleteDb(db)">{{ $t('actions.delete') }}</q-btn>
          </q-card-actions>
        </q-card>

      </template>

    </div>

    <q-page-sticky position="bottom-right" :offset="[18, 18]">
      <q-btn fab icon="add" color="primary" @click="editDb={app_id, module_id};$refs.editDb.show()"/>
    </q-page-sticky>

  </q-page>
</template>

<style>
</style>

<script>
import DbForm from "./DbForm";
import {DbModule} from "@/../../common/db/DbModule";

export default {
  name: 'DbsList',
  components: { DbForm},
  data: () => ({
    editDb: false,
    app_id: false,
    module_id: false
  }),

  async created() {
    // Get app id from route
    this.app_id = this.$route.params.app_id
    this.module_id = this.$route.params.module_id

    // Subscribe to dbs
    await DbModule.remote().subscribe("app-dbs",{app_id: this.app_id});
  },

  computed: {
    // Return all dbs from the store
    dbs() {
      return this.wait("app-dbs", DbModule.query().where({module_id: this.module_id}).get(), [])
    }
  },

  methods: {

    /**
     * Go to tables list
     * @param id
     */
    gotoTablesList(id) {
      this.$router.push({name: 'db-tables', params: {db_id: id}})
    },

    /**
     * Delete db
     * @param db
     * @return {Promise<void>}
     */
    async deleteDb(db) {
      this.$q.dialog({
        title: 'Delete confirmation',
        message: `Are you sure want to delete ${db.title} ?`,
        cancel: true,
        persistent: true
      }).onOk(() => {
        DbModule.remote().delete(db.id)
      })
    },
  }
}
</script>
