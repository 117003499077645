<template>
    <ab-flow-base-cmp :movable="true" :block="block" class="ab-test-editor-cmp">
        <ab-flow-link-connector :block="block" type="input" class="ev-run" event="run"/>

        <q-card>

            <q-card-section class="bg-cyan text-white">
                Pixel event: {{event}}
            </q-card-section>
        </q-card>

    </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

export default {
    components: { AbFlowBaseCmp, AbFlowLinkConnector},
    inject: ['canvas'],
    props: ['block'],
    name: "MarketingEventEditorCmp",
    computed: {
        /**
         * Return name
         * @return {{}}
         */
        event() {
            return this.block.properties?.event || "n/a"
        },
    }
}

</script>

<style lang="scss">

.ab-test-editor-cmp {
  .ev-run {
    left:-12px;
    position: absolute;
    top:calc(50% - 5px);
  }
}

</style>
