<template>
  <div class="full-width full-height module-desc">
    <q-form @submit="onSaveModule">
        <q-input
          v-model="mutate(editModule).features"
          type="textarea"
          label="Module features"
          hint="features of module"
          autogrow
        >
          <template v-slot:append>
            <ai-text-generator v-model="mutate(editModule).features"/>
          </template>
        </q-input>

      <q-btn type="submit" color="primary" class="q-mt-md">Save</q-btn>
    </q-form>
  </div>
</template>

<script>

import {AppModule} from "../../../../../common/db/AppModule";
import AiTextGenerator from "@/components/AI/AiTextGenerator.vue";

export default {
  name: "ModuleFeatures",
  components: {AiTextGenerator},
  props: ['editModule'],
  methods: {

    /**
     * On form submit
     * @return {Promise<void>}
     */
    async onSaveModule() {

      // Update
      await AppModule.remote().save(this.editModule)
    }
  }
}

</script>

<style lang="scss">

.module-desc {
  padding: 20px;
}

</style>
