<template>
  <q-page v-if="currentModule" class="column col">
    <q-tabs v-model="tab">
      <q-tab name="settings" label="Settings"></q-tab>
      <q-tab name="description" label="Description"></q-tab>
      <q-tab name="features" label="Features"></q-tab>
    </q-tabs>

    <q-tab-panels v-model="tab" class="col">
      <q-tab-panel name="settings">
          <module-form :edit-module="currentModule"/>
      </q-tab-panel>
      <q-tab-panel name="description" class="col">
          <module-description :edit-module="currentModule"/>
      </q-tab-panel>
      <q-tab-panel name="features" class="col">
          <module-features :edit-module="currentModule"/>
      </q-tab-panel>
    </q-tab-panels>
  </q-page>
</template>

<script>

import ModuleForm from "@/pages/workspace/modules/ModuleForm.vue";
import {AppModule} from "../../../../../common/db/AppModule";
import ModuleDescription from "@/pages/workspace/modules/ModuleDescription.vue";
import ModuleFeatures from "@/pages/workspace/modules/ModuleFeatures.vue";

export default {
  name: "ModuleDetails",
  components: {ModuleFeatures, ModuleDescription, ModuleForm},
  data:() => ({
    tab: 'settings',
    moduleId: false,
  }),
  created() {
    this.moduleId=this.$route.params.module_id
  },
  computed: {
    /**
     * Return current module
     * @return {*}
     */
    currentModule() {
      return this.wait("currentModule", AppModule.find(this.moduleId), {})
    },
  }
}

</script>
