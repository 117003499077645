<template>

  <ab-flow-base-cmp :movable="true" :block="block" class="image-picker-editor-cmp">

    <ab-flow-link-connector :block="block" type="input" class="ev-run" event="run"/>

    <q-card>
      <q-card-section class="bg-primary text-white">{{ title }}</q-card-section>
    </q-card>

    <div class="results">
      <ab-flow-link-connector :block="block" type="output" event="result"/>
      <ab-flow-link-connector :block="block" type="output" event="cancel"/>
      <ab-flow-link-connector :block="block" type="output" event="error"/>
    </div>

  </ab-flow-base-cmp>

</template>

<script>
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";
import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";

export default {
  components: {AbFlowBaseCmp, AbFlowLinkConnector},
  mixins: [renderMixins],
  props: ['block'],
  name: "ImagePickerEditorCmp",
  computed: {

    /**
     * Value
     * @return {string}
     */
    title() {
      return this.block?.properties?.title || 'Image picker';
    },
  },
}

</script>

<style lang="scss">
.image-picker-editor-cmp {
  .ev-run {
    left:-12px;
    position: absolute;
    top:calc(50% - 5px);
  }

  .results {
    position: absolute;
    right: -12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
</style>
