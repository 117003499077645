<template>

    <div>
      Close modal options, to do: close effects...
    </div>

</template>

<script>

export default {
    components: {},
    props: ['block'],
    name: "CloseModalPropsCmp",
}

</script>
