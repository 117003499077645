<template>
  <q-select
    v-model="currentValue"
    :use-input="noFilter ? true : false"
    input-debounce="0"
    @filter="filterFn"
    emit-value
    map-options
    option-value="value"
    :label="title"
    :options="moduleColors"
  />
</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";

export default {
  mixins: [propertiesMixins],
  name: "UiPropSelect",
  props: ['options', 'noFilter'],
  data: () => ({
    selectOptions: []
  }),

  /**
   * Init options
   */
  created() {
    this.selectOptions = this.options
  },

  methods: {

    /**
     * Filter options
     * @param val
     * @param update
     */
    filterFn(val, update) {
      update(() => {
        const needle = val.toLowerCase()
        this.selectOptions = needle ? this.options.filter(v => v.label.toLowerCase().indexOf(needle) > -1) : this.options
      })
    }
  }
}

</script>
