<template>

  <ab-flow-base-cmp class="block-editor-cmp" :block="block" :transparent="true" :class="classesString" :style="stylesString">
    <div class="content">
      <label>Block editor</label>
      Some content here...
    </div>

    <div class="links-wrapper">
      <ab-flow-link-connector :block="block" type="output" event="focus" class="q-mr-sm"/>
      <ab-flow-link-connector :block="block" type="output" event="changed" class="q-mr-sm"/>
      <ab-flow-link-connector :block="block" type="output" event="save-block" class="q-mr-sm"/>
      <ab-flow-link-connector v-for="(e, k) of block.properties?.keypress || []" class="q-mr-xs" :block="block" type="output" :event="`press-${e.name}`" :key="k"/>
    </div>

  </ab-flow-base-cmp>

</template>

<script>

import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

export default {
  components: {AbFlowBaseCmp, AbFlowLinkConnector},
  mixins: [renderMixins],
  inject: {
    canvas: {
      default: false
    },
    animation_frame: {
      default: 0
    }
  },
  props: ['block'],
  name: "ContentEditorEditorCmp",
  computed: {

    /**
     * is multyline
     * @return {string}
     */
    isMultyLine() {
      return !!this.block.properties?.isMultyLine
    },
  }
}

</script>

<style lang="scss">

.block-editor-cmp {
  position: relative;

  .links-wrapper {
    right: 0;
    left: auto;
  }

  .content {
    label {
      font-size: 1.5em;
      color: #777;
      margin-bottom: 5px;
      display: block;
    }
  }
}


</style>
