<template>
  <ab-flow-base-cmp :movable="true" :block="block" class="has-in-app-editor-cmp">

    <ab-flow-link-connector :block="block" type="input" class="ev-run" event="run"/>

    <q-card>

      <q-card-section class="bg-blue-4 text-black">
        User bought: {{groups || products}}?
      </q-card-section>

    </q-card>

    <div class="results">
      <ab-flow-link-connector :block="block" type="output" event="yes"/>
      <ab-flow-link-connector :block="block" type="output" event="no"/>
      <ab-flow-link-connector :block="block" type="output" event="error"/>
    </div>

  </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";
import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";

export default {
  mixins: [renderMixins],
  components: { AbFlowBaseCmp, AbFlowLinkConnector},
  inject: ['canvas'],
  props: ['block'],
  name: "HasInAppPurchaseEditorCmp",
  data: () => ({
  }),
  computed: {

    /**
     * Return name
     * @return {{}}
     */
    products() {
      return this.interpretString(this.block.properties?.products) || "n/a"
    },

    // Get in-app-purchase groups
    groups() {
      return (this.block.properties?.groups || []).join(', ');
    },
  }
}

</script>

<style lang="scss">

.has-in-app-editor-cmp {
  .ev-run {
    left:-12px;
    position: absolute;
    top:calc(50% - 5px);
  }

  .results {
    position: absolute;
    right: -12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
  }
}

</style>
