<template>
  <q-page class="q-pa-md" v-if="isReady">

    <div class="text-h6 q-mb-md">
      Auth settings
    </div>
    <q-form @submit="saveProperties">

      <q-card>
        <q-card-section class="bg-primary text-white">
          Facebook
        </q-card-section>

        <q-card-section>
          <q-toggle
            v-model="properties.facebookEnable"
            color="green"
            label="Enable"
            :true-value="1"
            :false-value="0"
          />

          <template v-if="properties.facebookEnable === 1 && ['mobile', 'web'].includes(currentModule.type)">
            <q-input
              label="App ID"
              v-model="properties.facebookAppId"
            />

            <q-input
              label="Client token"
              v-model="properties.facebookClientToken"
            />
          </template>
        </q-card-section>
      </q-card>

      <q-card class="q-mt-md">
        <q-card-section class="bg-primary text-white">
          Google
        </q-card-section>

        <q-card-section>
          <q-toggle
            v-model="properties.googleEnable"
            color="green"
            label="Enable"
            :true-value="1"
            :false-value="0"
          />

          <template v-if="properties.googleEnable === 1">
            <q-input
              label="Client ID"
              v-model="properties.googleClientId"
            />
          </template>
        </q-card-section>
      </q-card>

      <q-card class="q-mt-md">
        <q-card-section class="bg-primary text-white">
          Apple
        </q-card-section>

        <q-card-section>
          <q-toggle
            v-model="properties.appleEnable"
            color="green"
            label="Enable"
            :true-value="1"
            :false-value="0"
          />

          <template v-if="properties.appleEnable === 1">
            <q-input
              label="Client ID"
              v-model="properties.appleClientId"
            />
          </template>
        </q-card-section>
      </q-card>

      <q-card class="q-mt-md">
        <q-card-section class="bg-primary text-white">
          Promo code
        </q-card-section>

        <q-card-section>
          <q-toggle
            v-model="properties.promoCodeEnable"
            color="green"
            label="Enable"
            :true-value="1"
            :false-value="0"
          />
        </q-card-section>
      </q-card>

      <q-separator/>

      <div class="row">
        <q-btn type="submit" label="Save" color="primary" class="q-mt-md"/>
      </div>
    </q-form>

  </q-page>
</template>

<script>
import {AppModule} from '../../../../../../common/db/AppModule';
import {integrationsMixin} from "@/pages/workspace/integrations/integrationsMixin";

export default {
  mixins: [integrationsMixin],

  name: "AuthIntegrationSettings",

  data() {
    return {};
  },

  computed: {
    /**
     * Return integration name
     */
    integration: () => ({name: "social-auth", type: "social-auth"}),

    /**
     * Current module
     * @return {*}
     */
    currentModule() {
      return this.wait("currentModule", AppModule.find(this.module_id), {})
    },
  },

  methods: {},
}

</script>
