export const currencyList = [
    {
        "title": "USD",
        "value": "USD"
    },
    {
        "title": "EUR",
        "value": "EUR"
    },
    {
        "title": "NGN",
        "value": "NGN"
    },

]
