<template>
  <q-card class="image-gen column full-height full-width ab-mw-9">
    <q-card-section class="bg-grey-10 q-pl-sm q-pa-xs text-white text-h6 row items-center">
      <q-btn icon="close" round flat @click="$emit('close')"/>
    </q-card-section>
    <q-card-section class="col q-pa-none q-ma-none row">
      <div class="prompt q-ml-md q-mr-md column">

        <q-input type="textarea" hint="Type prompt at least 10 letters" v-model="promptString" label="Image generation prompt" class="col"/>
        <div>
          <q-checkbox label="Use module info" v-model="moduleInfo"/>
        </div>

      </div>
      <div class="preview">
        <div class="image" v-if="currentImage" :style="{background: `url('${currentImage}')`}"/>
        <div v-else>
          Please enter prompt string to generate image
        </div>
      </div>
    </q-card-section>
    <q-card-section class="row">
      <q-btn label="Generate" icon="add" @click="generateImage" color="primary"/>
      <q-space/>
      <q-btn label="Add to editor" @click="addToEditor" icon="add" flat color="primary"/>
    </q-card-section>
  </q-card>
</template>
<script>

import {pathHelper} from "@/utils/pathHelper";

export default {
  name: "ImageGenerator",
  props: ['moduleId'],
  emits: ['send', 'close'],
  data: () => ({
    currentImage: false,
    promptString: "",
    moduleInfo: true
  }),
  methods: {

    /**
     * Send current image to editor
     */
    addToEditor() {
      this.$emit("send", this.currentImage)
    },

    /**
     * Generate image
     */
    async generateImage() {

      if(this.promptString?.length < 10) {
        // Show error
        this.$q.notify({
          message: "Please enter prompt string at least 10 letters",
          color: "negative"
        })
        return;
      }

      this.$q.loading.show()
      // Get image
      try {
        this.currentImage = pathHelper.assetPath(await this.app.client.call("ai-designer", "generateImage", this.promptString, this.moduleInfo ? this.moduleId : undefined));
      } catch (e) {
        this.$q.notify({
          message: e.message,
          color: "negative"
        })
      }
      this.$q.loading.hide()
    }
  }
}

</script>


<style lang="scss">

.image-gen {

  .prompt {
    flex-grow: 1;
    .q-field__inner, .q-field__control {
      display: flex;
      flex-grow: 1;
    }
  }

  .preview {
    flex-grow: 1;
    background: #eee;
    display: flex;
    overflow: hidden;
    padding: 10px;

    .image {
      min-width: 500px;
      flex-grow: 1;
      background-repeat: no-repeat !important;
      background-position: center center !important;
      background-size: contain !important;
    }
  }
}

</style>
