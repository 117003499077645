/**
 * DbModule model
 */
import {AccessModel} from './AccessModel.js';

export class AppIntegration extends AccessModel {
    static entity = 'app_integrations'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        module_id: "int",
        name: "string",
        type: "string",
        description: "string",
        app_id: "int",
        props: "json",
        unique_id: "string"
    }

    /**
     * Clears the cache for the current app integration.
     * This method checks if the cache manager is available and clears the cache
     * for the app integration identified by `app_id`.
     */
    serverEvent() {
        const cacheManager = this.constructor?.applicationClient?.plugins?.schemaCache || null;

        if (!cacheManager) {
            return;
        }

        cacheManager.clearCache(this.app_id);
    }

    /**
     * Functions list
     */
    async channels() {
        return {
            'app-integrations': {
                subscribe: ({module_id}) => module_id,
                init: async ({module_id}) => this.getList(module_id),
            },
            'app-integration-by-name': {
                subscribe: ({module_id, name}) => `${module_id}-${name}`,
                init: async ({module_id, name}) => this.getIntegrationRowByName(module_id, name),
            }
        }
    }

    /**
     * Get integration by name
     */
    async getIntegrationRowByName(module_id, name) {
        return AppIntegration.query().where({module_id, name}).first()
    }

    /**
     * Get integration by name
     */
    static async getIntegrationByName(module_id, name) {
        return (await AppIntegration.query().where({module_id, name}).first())?.props || {}
    }

    /**
     * Get list
     */
    getList(module_id) {
        return this.query().where({module_id}).get()
    }
}
