<template>

  <q-card class="full-width">
    <q-card-section>
      <div class="text-h6">Edit db channel</div>
    </q-card-section>

    <q-card-section class="q-pt-none">

      <q-form ref="dbChannelForm" @submit="onSaveDbChannel">

        <q-input
            v-model="mutate(editChannel).title"
            label="Channel title *"
            lazy-rules
            :rules="[ val => val && val.length > 0 || 'Please type something']"
        />

        <q-input
            v-model="mutate(editChannel).channel_name"
            label="Channel name *"
            lazy-rules
            :rules="[ val => val && val.length > 0 || 'Please type something']"
        />

        <q-input
            v-model="mutate(editChannel).channel_query"
            type="textarea"
            label="Channel query *"
            lazy-rules
            :rules="[ val => val && val.length > 0 || 'Please type something']"
        />

        <db-permission-settings v-model="mutate(editChannel).permissions" :permission-list="['read']"/>

        <div>
          <q-btn label="Save" type="submit" color="primary"/>
        </div>
      </q-form>

    </q-card-section>

  </q-card>

</template>

<script>

import {DbTableChannel} from "@/../../common/db/DbTableChannel";
import {nanoid} from "nanoid";
import DbPermissionSettings from "@/pages/workspace/dbs/DbPermissionSettings.vue";

export default {
  name: "TableChannelForm",
  components: {DbPermissionSettings},
  props: ['editChannel'],
  emits: ['saved'],
  methods: {

    /**
     * On form submit
     * @return {Promise<void>}
     */
    async onSaveDbChannel() {

      if(!this.editChannel.id) {
        this.mutate(this.editChannel).unique_id = nanoid(10);
      }

      // Update
      await DbTableChannel.remote().save(this.editChannel)

      // Hide dialog
      this.$emit('saved')
    }
  }
}

</script>
