<template>
  <div>
    <ui-prop-value-selector :block="block" name="product" title="Product id to buy" :parent-diagram-id="diagram_id"/>

<!--    <ui-prop-select
      v-if="isWeb"
      class="q-mt-md"
      name="checkoutMode"
      title="Checkout mode"
      default-value="hosted"
      :block="block"
      :options="checkoutModes"
    />-->
  </div>

</template>

<script>
import UiPropValueSelector from "@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue";
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";

export default {
  mixins: [propertiesMixins],
  components: {UiPropValueSelector},
  props: ['block'],
  name: "InAppPurchasePropsCmp",

  data() {
    return {
      // Checkout mode options
/*      checkoutModes: [
        {
          label: 'Stripe-hosted page',
          value: 'hosted'
        },
        {
          label: 'Embedded form',
          value: 'embedded'
        },
      ],*/
    };
  },

  computed: {
    // Check if current module is web
    isWeb() {
      return this.currentModule?.currentModule?.type === 'web';
    },
  },
}

</script>
