<template>
  <q-page class="q-pa-sm analytics-fun">
    <div class="text-h6">Analytics</div>

    <q-dialog ref="editFunnel">
      <funnel-form :edit-funnel="editFunnel" @saved="$refs.editFunnel.hide()"/>
    </q-dialog>

    <analytics-filter :module-id="moduleId" v-model:codeVersion="codeVersion" v-model:required-events="requiredEvents" v-model:schemaVersion="schemaVersion" v-model:date-from="dateFrom" v-model:date-to="dateTo"/>
    <div class="col ret-diagram" v-if="isReady">

      <q-tabs align="right" dense v-model="currentFunnelId">
        <q-tab v-for="(f, k) of funnelsList" :key="k" :name="f.id">
          <div class="row items-center">
            {{f.title}}
            <q-btn round size="sm" flat icon="menu" @click.stop.prevent class="q-ml-sm">
              <q-menu>
                <q-list style="min-width: 100px">
                  <q-item clickable v-close-popup @click="doEditFunnel(f.id)">
                    <q-item-section>Edit</q-item-section>
                  </q-item>
                  <q-item clickable v-close-popup @click="doDeleteFunnel(f.id)">
                    <q-item-section>Delete</q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>
          </div>
        </q-tab>
      </q-tabs>

      <div class="funnel">
        <funnel-page :date-from="dateFrom" :required-events="requiredEvents" :date-to="dateTo" :funnel-id="currentFunnelId" :module-id="moduleId" :code-version="codeVersion" :schema-version="schemaVersion" :key="`${currentFunnelId}-${codeVersion}-${schemaVersion}-${dateFrom}-${dateTo}-${JSON.stringify(requiredEvents)}`"/>
      </div>
    </div>

    <q-page-sticky position="bottom-right" :offset="[18, 18]">
      <q-fab fab icon="add" color="primary" @click="editFunnel={module_id: moduleId, app_id: this.appId};$refs.editFunnel.show()"/>
    </q-page-sticky>

  </q-page>
</template>

<script>

import AnalyticsFilter from "@/pages/workspace/analytics/AnalyticsFilter.vue";
import moment from "moment";
import {AnalyticsFunnel} from "../../../../../../common/db/AnalyticsFunnel";
import FunnelForm from "@/pages/workspace/analytics/funnels/FunnelForm.vue";
import FunnelPage from "@/pages/workspace/analytics/funnels/FunnelPage.vue";

export default {
  name: "AnalyticsFunnels",
  components: {FunnelPage, FunnelForm, AnalyticsFilter},
  data: () => ({
    moduleId: false,
    editFunnel: {},
    codeVersion: null,
    dateFrom: null,
    requiredEvents: [],
    dateTo: null,
    currentFunnelId: null,
    appId: null,
    versionSource: null,
    schemaVersion: null,
    isReady: false,
    retStat: {}
  }),

  async created() {

    // Set module id
    this.moduleId = this.$route.params.module_id
    this.appId = this.$route.params.app_id

    // Subscribe to funnels
    await AnalyticsFunnel.remote().subscribe("module-funnels", {module_id: this.moduleId})
    this.$watch("funnelsList", () => {
          if(!this.currentFunnelId) this.currentFunnelId = this.funnelsList[0]?.id
        }, {deep: true})

    this.isReady = true;
  },

  computed: {

    /**
     * Get funnels list
     * @return {*}
     */
    funnelsList() {
      return this.wait("funnelsList", AnalyticsFunnel.getList(this.moduleId), [])
    },

    /**
     * Get days
     * @return {number}
     */
    days() {
      // List of days between dateFrom and dateTo
      const days = [];
      const day = moment(this.dateFrom);
      while(day.isSameOrBefore(this.dateTo)) {
        days.push(day.format("YYYY-MM-DD"));
        day.add(1, "day");
      }

      return days;
    }
  },

  methods: {

    /**
     * Edit funnel
     * @param id
     */
    doEditFunnel(id) {
      this.editFunnel = this.funnelsList.find(f => f.id === id);
      this.$refs.editFunnel.show();
    },

    /**
     * Delete funnel
     * @param id
     */
    doDeleteFunnel(id) {
      this.$q.dialog({
        title: "Delete funnel",
        message: "Are you sure you want to delete this funnel?",
        ok: {
          label: "Delete",
        },
        cancel: {
          label: "Cancel",
        }
      }).onOk(async () => {
        await AnalyticsFunnel.remote().delete(id);
      })
    },

    /**
     * Get cell color
     * @param count
     * @param total
     */
    getCellColor(count, total) {
      const pc = parseInt(count)
      const pt = parseInt(total)
      return pc && pt ? `rgba(0, 200, 0, ${Math.min(1, Math.max(0,pc / pt))})` : '';
    },
  },
}

</script>

<style lang="scss">

.analytics-fun {
  .ret-diagram {
  }
}

</style>
