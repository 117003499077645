<template>
  <ab-flow-base-cmp :movable="true" :block="block" class="for-each-editor-cmp">

    <ab-flow-link-connector :block="block" type="input" class="ev-run" event="start"/>

    <q-card>

      <q-card-section class="bg-secondary text-white">
        <div>{{ title }}</div>
        Repeat each: {{ schedule }} min
      </q-card-section>
    </q-card>

    <div class="results">
      <ab-flow-link-connector class="q-mt-sm" :block="block" type="output" event="run"/>
    </div>

  </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";
import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";

export default {
  components: {AbFlowBaseCmp, AbFlowLinkConnector},
  mixins: [renderMixins],
  inject: ['canvas'],
  props: ['block'],
  name: "EventSchedulerEditorCmp",
  computed: {

    /**
     * Return name
     * @return {{}}
     */
    title() {
      return this.block.properties?.title || "NoName"
    },

    /**
     * Delay time
     * @return {{}}
     */
    schedule() {
      return this.interpretString(this.block.properties?.schedule) || 30;
    },
  }
}

</script>

<style lang="scss">

.for-each-editor-cmp {
  .ev-run {
    left: -12px;
    position: absolute;
    top: calc(50% - 5px);
    z-index: 1;
  }

  .results {
    z-index: 1;
    position: absolute;
    right: -12px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

</style>
