import {AccessModel} from './AccessModel.js';

/**
 * Diagram model
 */
export class ComponentBackup extends AccessModel {
    static entity = 'component_backups'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        app_id: "int",
        module_id: "int",
        component_id: "int",
        diagram_version: "int",
        component_type: "string",
        timestamp: "int",
        source: "json"
    }

    /**
     * Backups list
     */
    async channels() {
        return {
            'backup-version': {
                subscribe: ({id}) => id,
                init: async ({id}) => this.find(id),
            },
            'component-backups': {
                subscribe: ({component_id}) => component_id,
                init: async ({component_id}) => this.getBackupList(component_id),
            }
        }
    }

    /**
     * Get backups list
     * @param component_id
     * @return {Promise<*>}
     */
    async getBackupList(component_id) {
        return this.query(["id", "app_id", "timestamp","component_id"]).where({component_id}).get()
    }
}
