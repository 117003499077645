<template>
  <ab-flow-base-cmp :movable="true" :block="block" class="system-alarm-editor-cmp">

    <ab-flow-link-connector :block="block" type="input" class="ev-run" event="run"/>

    <q-card>
      <q-card-section class="send-message-view bg-info text-white">
        <div class="text-no-wrap ellipsis" v-text="message" />

        <q-list>
          <q-item v-for="(option, idx) in options" :key="`${idx}-${option}`" dense>
            <q-item-section>
              <q-item-label class="text-no-wrap ellipsis">{{ option }}</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>
    </q-card>

    <div class="results">
      <ab-flow-link-connector v-if="outEvents?.length" class="q-mt-sm" :block="block" type="output" event="other"/>
      <ab-flow-link-connector v-if="!outEvents?.length" class="q-mt-sm" :block="block" type="output" event="success"/>
      <ab-flow-link-connector v-for="(e, k) of outEvents" class="q-mt-sm" :block="block" type="output" :event="e" :key="k"/>
    </div>

  </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import {renderMixins} from "@/components/DiagramDesigner/Editor/components/renderMixins";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

export default {
  mixins: [renderMixins],
  components: { AbFlowBaseCmp, AbFlowLinkConnector },
  inject: ['canvas'],
  props: ['block'],
  name: "ChatBotSendMessageEditorCmp",
  computed: {

    /**
     * message
     * @return {string}
     */
    message() {
      return this.interpretString(this.block.properties?.message) || 'Send message';
    },

    /**
     * Options list
     * @return {{name: string, event: string}[]}
     */
    options() {
      return (this.block?.properties?.options || [])
          .map((v) => v.type === 'answer' ? v.text : v.address);
    },

    /**
     * Events list
     * @return {string[]}
     */
    outEvents() {
      return (this.block?.properties?.options || [])
          .filter((v) => ['answer', 'ui-event'].includes(v.type) && v.event)
          .map((v) => v.event);
    }
  }
}

</script>

<style lang="scss">

.send-message-view {
  max-width: 150px;
  min-height: 60px;
}

</style>
