<template>
  <q-page class="window-height window-width row justify-center items-center">
    <div class="column">
      <div class="row">
        <h5 class="text-h5 q-my-md">Signup page</h5>
      </div>
      <div class="row">
        <q-card square bordered class="q-pa-lg shadow-6">
          <q-card-section>
            <q-form @submit="doSignup" class="q-gutter-md">
              <q-input clearable v-model="email" type="email" label="email" />
              <q-input clearable v-model="password" type="password" label="password" />

              <q-btn label="Sign up" type="submit" color="primary"/>
            </q-form>
          </q-card-section>
        </q-card>
      </div>
    </div>
  </q-page>
</template>


<script>

export default {
  name: "SignupPage",
  data: () => ({
    email: "",
    password: ""
  }),

  methods: {
    async doSignup() {
      if(await this.app.auth.signup(this.email, this.password)) {
        this.$router.replace({name: "login"})
      } else {
        alert("Error signing up")
      }
    }
  }
}

</script>
