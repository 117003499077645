<template>
  <Vue3JsonEditor :title="title" v-model="currentValue" @json-change="onJsonChange" :expandedOnStart="true"/>
</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import { Vue3JsonEditor } from 'vue3-json-editor'

export default {
  mixins: [propertiesMixins],
  name: "UiPropJson",
  props: ['multiple'],
  components: { Vue3JsonEditor },
  created() {
    if(!this.currentValue || Object.keys(this.currentValue).length === 0) {
      this.currentValue = this.defaultValue
    }
  },
  methods: {
    onJsonChange(json) {
      this.currentValue = json
    }
  }
}

</script>
