import ValueSelectorOperation from "@/components/ValueSelector/ValueSelectorOperation.vue";
import ValueSelector from "@/components/ValueSelector/ValueSelector.vue";
import UiPropArguments from "@/components/DiagramDesigner/Editor/properties/UiPropArguments.vue";
import UiTemplatesDiagramsList from '@/components/DiagramDesigner/Editor/UiTemplatesDiagramsList.vue';

export const registerComponents = (app) => {
    app.component("value-selector-operation", ValueSelectorOperation)
    app.component("value-selector", ValueSelector)
    app.component("ui-prop-arguments", UiPropArguments)
    app.component("ui-templates-diagrams-list", UiTemplatesDiagramsList);
}
