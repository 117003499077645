<template>
  <q-page class="q-pa-md column">
    <q-dialog
      v-model="filter.dialog"
    >
      <q-card style="width: 300px">
        <q-card-section>
          <div class="text-h6" v-text="filter.field" />
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-input v-model="filter.value"/>
        </q-card-section>

        <q-card-actions align="right">
          <q-btn flat label="Cancel" v-close-popup />
          <q-btn flat label="Apply" color="primary" @click="applyFilter" />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-breadcrumbs>
      <q-breadcrumbs-el label="DB" :to="{name: 'dbs', params: {app_id: $route.params.app_id, module_id: $route.params.module_id}}" />
      <q-breadcrumbs-el label="Tables" :to="{name: 'db-tables', params: {app_id: $route.params.app_id, module_id: $route.params.module_id, db_id: $route.params.db_id}}" />
      <q-breadcrumbs-el :label="table.name" />
    </q-breadcrumbs>

    <q-separator class="q-my-md"/>

    <div class="text-h4 q-mb-md">Table content: {{table.name}}</div>

    <div v-if="appliedFilters.length" class="row">
      <div class="row wrap">
        <q-chip
          v-for="filter in appliedFilters"
          :key="filter.field"
          removable
          @remove="resetFilter(filter.field)"
          color="primary"
          text-color="white"
          :label="`${filter.field}: ${filter.value}`"
        />
      </div>
      <q-btn class="q-ml-auto" label="Reset all filters" @click="resetAllFilters" />
    </div>

    <div class="row">
      <template v-for="(field, kf) of fields" :key="kf">
        <div class="col row items-center">
          <q-btn
            v-if="!nonFilterableFields.includes(field.type)"
            :icon="filters[field.name] ? 'filter_alt' : 'filter_alt_off'"
            flat
            dense
            size="sm"
            :color="filters[field.name] ? 'primary' : 'grey'"
            class="q-mr-md"
            @click="showFilterDialog(field.name)"
          />

          <div
            class="cell-header"
            :class="{
              'cell-header_sort': !!sort[field.name],
              'cursor-pointer': !nonFilterableFields.includes(field.type),
            }"
            @click="sortBy(field.name)"
          >
            <q-icon v-if="field.key_field === 1" name="key" />

            {{field.name}}
            <q-icon
              v-if="!nonFilterableFields.includes(field.type)"
              class="cell-header__icon"
              :name="sort[field.name] !== 'desc' ? 'arrow_upward_alt' : 'arrow_downward_alt'"
            />
          </div>
        </div>
      </template>
    </div>

    <div v-for="(row, k) of sortedTableContent" :key="k" class="row q-gutter-x-sm">

      <template v-for="(field, kf) of fields" :key="kf">
        <media-picker
          v-if="['image', 'sound', 'lottie', 'video'].includes(field.type)"
          v-model="row[field.name]"
          :product-id="app_id"
          :module-id="module_id"
          :media-type="field.type"
          class="col"
          :error-message="!validateField(field, row[field.name]) ? 'This field is required' : undefined"
        />
        <q-input
          v-else-if="field.type === 'localizedString'"
          v-model="row[field.name].value"
          :placeholder="field.name"
          class="col"
          :error="!validateField(field, row[field.name])"
          error-message="This field is required"
        />
        <q-field
          v-else-if="field.type === 'bool'"
          class="col"
          :error="!validateField(field, row[field.name])"
          error-message="This field is required"
        >
          <q-checkbox v-model="row[field.name]" :true-value="1" :false-value="0" :label="field.name" />
        </q-field>
        <q-input
          v-else
          v-model="row[field.name]"
          :placeholder="field.name"
          class="col"
          :error="!validateField(field, row[field.name])"
          error-message="This field is required"
        />
      </template>
      <div class="justify-center items-center flex">
        <q-btn icon="delete" round flat size="sm" @click="deleteRow(k)"/>
      </div>

    </div>

    <q-checkbox
        v-model="table.is_static_data"
        label="Rewrite initial data on each app start"
        :true-value="1"
        :false-value="0"
    />


    <div class="q-mt-sm row">
      <q-btn icon="save" color="primary" label="Save" @click="saveContent"/>

      <q-space/>

      <table-csv-import class="q-mr-sm" @import="importCsv" />

      <q-btn icon="add" color="primary" flat label="Add row" @click="addRow"/>
    </div>

  </q-page>
</template>

<style>
</style>

<script>
import {computed} from 'vue';
import {nanoid} from 'nanoid';
import {DbTable} from "@/../../common/db/DbTable";
import {DbTableField} from "../../../../../common/db/DbTableField";
import MediaPicker from "@/components/MediaGallery/MediaPicker.vue";
import TableCsvImport from '@/pages/workspace/dbs/TableCsvImport.vue';

export default {
  name: 'DbTableContent',
  components: {TableCsvImport, MediaPicker},

  provide() {
    return {
      fields: computed(() => this.fields),
      tableContent: computed(() => this.tableContent),
    }
  },

  data() {
    return {
      db_id: false,
      table_id: false,
      app_id: false,
      module_id: false,
      table: false,
      fields: false,
      testData: false,
      tableContent: [],
      nonFilterableFields: ['secret', 'lottie', 'image', 'sound', 'file'],
      sort: {},
      filters: {},
      filter: {
        dialog: false,
        field: null,
        value: '',
      },
    };
  },

  computed: {
    /**
     * Computed property that returns an array of fields that are either of type 'autogenerated', 'autoincrement' or are key fields.
     * A field is considered a key field if its 'key_field' property is equal to 1.
     * @returns {Array} An array of required fields.
     */
    requiredFields() {
      return this.fields.filter((f) => ['autogenerated', 'autoincrement'].includes(f.type) || f.key_field === 1);
    },

    /**
     * Computed property that returns an array of currently applied filters.
     * Each filter is an object with 'field' and 'value' properties.
     * Filters with empty or null values are excluded.
     * @returns {Array} An array of applied filters.
     */
    appliedFilters() {
      return Object.entries(this.filters).map(([field, value]) => ({field, value})).filter((v) => v.value);
    },

    /**
     * Computed property that returns the filtered table content based on the applied filters.
     * If no filters are applied, it returns the original table content.
     * If filters are applied, it returns the table content that matches all the applied filters.
     * Each filter is an object with 'field' and 'value' properties.
     * For each row in the table content, it checks if the row's field value matches the filter's value.
     * If the row's field value is an object, it checks the 'value' property of the field value.
     * If the row does not match any one of the filters, it is excluded from the result.
     * @returns {Array} An array of filtered table content.
     */
    filteredTableContent() {
      if (!this.appliedFilters.length) {
        return this.tableContent;
      }

      return this.tableContent.filter((row) => {
        for (const {field, value} of this.appliedFilters) {
          if ((typeof row[field] === 'object' && row[field]?.value !== value) || row[field] !== value) {
            return false;
          }
        }

        return true;
      });
    },

    /**
     * Computed property that returns the sorted table content based on the applied sorting.
     * If no sorting is applied, it returns the filtered table content.
     * If sorting is applied, it returns the table content that matches all the applied sorting.
     * Each sorting is an object with 'field' and 'direction' properties.
     * For each row in the table content, it checks if the row's field value matches the sorting's value.
     * If the row's field value is an object, it checks the 'value' property of the field value.
     * If the row does not match any one of the sorting, it is excluded from the result.
     * @returns {Array} An array of sorted table content.
     */
    sortedTableContent() {
      if (!Object.keys(this.sort).length) {
        return this.filteredTableContent;
      }

      return this.filteredTableContent.slice().sort((a, b) => {
        for (const [field, direction] of Object.entries(this.sort)) {
          const dirMultiplier = direction === 'asc' ? 1 : -1;

          const valA = a[field]?.value || a[field];
          const valB = b[field]?.value || b[field];

          if (valA < valB) {
            return -1 * dirMultiplier;
          }

          if (valA > valB) {
            return 1 * dirMultiplier;
          }
        }
        return 0;
      })
    },
  },

  async created() {
    // Get params
    this.app_id = this.$route.params.app_id
    this.module_id = this.$route.params.module_id
    this.db_id = this.$route.params.db_id
    this.table_id = this.$route.params.table_id
    this.testData = this.$route.params?.test === 'test'

    // Subscribe to dbTables
    this.table = await DbTable.find(this.table_id);
    this.fields = await DbTableField.query().where({table_id: this.table_id}).get();

    const localizedFields = this.fields.filter((f) => f.type === 'localizedString').map((f) => f.name);
    const jsonFields = this.fields.filter((f) => f.type === 'json').map((f) => f.name);

    // Get table content
    this.tableContent = ((this.testData ? this.table.test_table_data : this.table.table_data) || [{}]).map((data) => {
      // Add missing localized fields
      for (const field of localizedFields) {
        if (!data[field]) {
          data[field] = {
            value: '',
            isLocalizable: true,
            localeAlias: nanoid(10),
          };
        } else if (typeof data[field] !== 'object') {
          data[field] = {
            value: data[field],
            isLocalizable: true,
            localeAlias: nanoid(10),
          };
        }
      }

      // Process json fields
      for (const field of jsonFields) {
        if (typeof data[field] !== 'string') {
          data[field] = JSON.stringify(data[field]);
        }
      }

      return data;
    });
  },

  methods: {

    /**
     * Delete row
     * @param k
     */
    deleteRow(k) {
      // Ask user
      this.$q.dialog({
        title: 'Delete row',
        message: 'Are you sure you want to delete this row?',
        cancel: true,
        persistent: true
      }).onOk(() => {
        // Delete row
        this.tableContent.splice(k, 1)
      })
    },

    /**
     * This method validates all the fields in the table content.
     * It first checks if there are any required fields. If there are none, it returns true.
     * If there are required fields, it iterates over each row in the table content.
     * For each row, it iterates over each required field and checks if the field is valid by calling the `validateField` method.
     * If the field is not valid, it returns false.
     * If all fields in all rows are valid, it returns true.
     * @returns {boolean} Returns true if all required fields in all rows are valid, otherwise returns false.
     */
    validateFields() {
      if (!this.requiredFields.length) {
        return true;
      }

      for (const row of this.tableContent) {
        for (const field of this.requiredFields) {
          if (!this.validateField(field, row[field.name])) {
            return false;
          }
        }
      }

      return true;
    },

    /**
     * Save content
     */
    async saveContent() {
      // Validate fields
      if (!this.validateFields()) {
        this.$q.notify({
          message: 'Please fill all required fields',
          color: 'negative',
          icon: 'report_problem',
          position: 'top',
        });

        return;
      }

      this.$q.loading.show();

      // Json fields
      const jsonFields = this.fields.filter((f) => f.type === 'json').map((f) => f.name);

      // Process json fields
      const content = !jsonFields.length ? this.tableContent : this.tableContent.map((row) => {
        const newRow = {...row};

        for (const fld of jsonFields) {
          if (typeof row[fld] === 'string') {
            try {
              newRow[fld] = JSON.parse(row[fld]);
            } catch (e) {
              console.error(`Invalid JSON: ${fld}`, e)

              newRow[fld] = '';
            }
          }
        }

        return newRow;
      });

      await DbTable.remote().save(Object.assign({id: this.table.id, is_static_data: this.table.is_static_data},
          this.testData ? {test_table_data: content} : {table_data: content}
      ))
      this.$q.loading.hide()
    },

    /**
     * Adds a new row to the table content.
     * If there are no fields of type 'localizedString', an empty object is added to the table content.
     * If there are fields of type 'localizedString', a new object is created with each 'localizedString' field as a key.
     * Each key's value is an object with properties: 'value' (an empty string), 'isLocalizable' (true), and 'localeAlias' (a random string).
     */
    addRow() {
      // If there are no localizedString fields, add an empty object to the table content
      if (!this.fields.some((f) => f.type === 'localizedString')) {
        this.tableContent.push({});

        return;
      }

      // If there are localizedString fields, add an object with each localizedString field as a key
      this.tableContent.push(
        this.fields.filter((f) => f.type === 'localizedString').reduce((acc, f) => {
          acc[f.name] = {
            value: '',
            isLocalizable: true,
            localeAlias: nanoid(10),
          };

          return acc;
        }, {}),
      );
    },

    /**
     * This method sorts the table content based on the field provided.
     * If the field is not currently being sorted, it sets the sort direction to 'asc'.
     * If the field is currently being sorted in ascending order, it changes the sort direction to 'desc'.
     * If the field is currently being sorted in descending order, it removes the field from the sort object.
     * @param {string} field - The field to sort by.
     */
    sortBy(field) {
      if (!this.sort[field]) {
        this.sort[field] = 'asc';
        return;
      }

      if (this.sort[field] === 'asc') {
        this.sort[field] = 'desc';
        return;
      }

      delete this.sort[field];
    },

    /**
     * This method is used to show the filter dialog.
     * It sets the filter's field to the provided field and the filter's value to the value of the field in the filters object.
     * If the field does not exist in the filters object, the filter's value is set to an empty string.
     * Finally, it sets the filter's dialog property to true to show the dialog.
     * @param {string} field - The field to filter by.
     */
    showFilterDialog(field) {
      this.filter.field = field;
      this.filter.value = this.filters[field] || '';
      this.filter.dialog = true;
    },

    /**
     * This method is used to apply the filter.
     * It sets the value of the filter's field in the filters object to the filter's value.
     * Then, it sets the filter's dialog property to false to hide the dialog.
     */
    applyFilter() {
      this.filters[this.filter.field] = this.filter.value;

      this.filter.dialog = false;
    },

    /**
     * This method is used to reset a filter.
     * It deletes the provided field from the filters object.
     * @param {string} field - The field to reset.
     */
    resetFilter(field) {
      delete this.filters[field];
    },

    /**
     * This method is used to reset all filters and sorting.
     * It sets the filters object to an empty object and the sort object to an empty object.
     */
    resetAllFilters() {
      this.filters = {};
      this.sort = {};
    },

    /**
     * This method validates a field's value.
     * It first checks if there are any required fields and if the provided field is a required field. If there are no required fields or the provided field is not a required field, it returns true.
     * If the field is of type 'bool', it checks if the value is either 1 or 0 and returns the result.
     * If the field is of type 'localizedString', it checks if the value's 'value' property is truthy and returns the result.
     * For all other field types, it checks if the value is truthy and returns the result.
     * @param {Object} field - The field to validate. It is an object with 'name' and 'type' properties.
     * @param {any} value - The value to validate.
     * @returns {boolean} Returns true if the value is valid, otherwise returns false.
     */
    validateField(field, value) {
      if (!this.requiredFields.length || !this.requiredFields.some((f) => f.name === field.name)) {
        return true;
      }

      if (field.type === 'bool') {
        return [1, 0].includes(value);
      } else if (field.type === 'localizedString') {
        return !!value?.value;
      }

      return !!value;
    },

    /**
     * This method is used to import CSV data into the table content.
     * It replaces the current table content with the provided data.
     *
     * @param {Array} data - The data to import. It is an array of objects, where each object represents a row in the table.
     */
    importCsv(data) {
      this.tableContent = data;
    },
  }
}
</script>

<style scoped lang="scss">
.cell-header {
  .cell-header__icon {
    opacity: 0;
    transition: opacity .2s ease;
  }

  &:hover {
    .cell-header__icon {
      opacity: .5;
    }
  }

  &.cell-header_sort {
    .cell-header__icon {
      opacity: 1;
    }
  }
}
</style>
