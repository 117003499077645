<template>
  <ab-flow-base-cmp :movable="true" :block="block" class="custom-event-cmp">
    <ab-flow-link-connector :block="block" type="input" event="start"/>
    <ab-flow-link-connector :block="block" type="output" event="start"/>

    <div class="content">
      {{name}}
    </div>
  </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";

export default {
  components: { AbFlowBaseCmp, AbFlowLinkConnector},
  inject: ['canvas'],
  props: ['block'],
  name: "CustomEventEditorCmp",
  computed: {

    /**
     * Return name
     * @return {{}}
     */
    name() {
      return this.block.properties ? this.block.properties.name : "NoName"
    },
  }
}

</script>

<style lang="scss">

.custom-event-cmp {
  .content {
    width: 50px;
    height: 50px;
    background: #efe;
    border: 1px solid #ddd;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .output, .input {
    position: absolute;
    left: calc(50% - 5px);
    top: calc(50% - 5px);
    z-index: 1;
  }
}

</style>
