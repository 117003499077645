import {AccessModel} from './AccessModel.js';

/**
 * AnalyticsEvent model
 */
export class AnalyticsEventTemplate extends AccessModel {
    static entity = 'analytics_event_templates'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        app_id: "int",
        module_id: "int",
        title: "string",
        events: "json",
    }

    /**
     * Partitions list
     */
    async channels() {
        return {
            'module-event-templates': {
                subscribe: ({module_id}) => module_id,
                init: async ({module_id}) => AnalyticsEventTemplate.getList(module_id),
            },
        }
    }

    /**
     * Get list
     */
    static async getList(module_id) {
        return this.query().where({module_id}).get()
    }
}
