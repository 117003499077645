<template>
  <q-page class="column col q-pa-md module-info">

    <div class="text-h4  bg-grey-2 q-pa-xs">{{appInfo.app?.title}}: {{appInfo?.module?.name}}</div>
    <div class="desc q-mt-sm">{{appInfo?.module?.description}}</div>

    <div class="text-h4 q-mt-md bg-grey-2 q-pa-xs">Screens list</div>

    <template v-for="(d, k) of appInfo.screens" :key="k">
      <div class="text-h5 q-mt-md">{{d.title}}</div>
      <div class="desc q-mt-sm">{{d?.description}}</div>

      <q-separator class="q-mt-md" spaced/>
    </template>


    <div class="text-h4 q-mt-md bg-grey-2 q-pa-xs">Widgets list</div>

    <template v-for="(d, k) of appInfo.widgets" :key="k">
      <div class="text-h5 q-mt-md">{{d.title}}</div>
      <div class="desc q-mt-sm">{{d?.description}}</div>

      <q-separator class="q-mt-md" spaced/>
    </template>

  </q-page>
</template>

<script>

import {Diagram} from "../../../../../common/db/Diagram";

export default {
  name: "ModuleInfo",
  components: {},
  inject: {
    currentModule: {},
  },
  data:() => ({
  }),
  created() {

  },
  computed: {

    /**
     * Get application info
     */
    appInfo() {

      return this.wait("app-info", async () => {
        // Get apps diagram list
        const gds = (await Diagram.query().where({module_id: this.currentModule?.currentModule?.id}).get()) || [];

        return {
          app: this.currentModule?.currentApp,
          module: this.currentModule?.currentModule,
          screens: gds.filter(d => d.diagram_type==='component' && d.status==='active').map(d => ({
            id: d.id,
            title: d.title,
            description: d.description
          })),
          widgets: gds.filter(d => d.diagram_type==='widget' && d.status==='active').map(d => ({
            id: d.id,
            title: d.title,
            description: d.description
          }))
        }
      }, {})
    }
  }
}

</script>

<style lang="scss">

.module-info {
  .desc {
    display: block;
    word-wrap: break-word;
    white-space: pre-line;
  }
}

</style>
