<template>
  <q-dialog ref="editIndex">
    <table-index-form :edit-index="editIndex" @saved="$refs.editIndex.hide()"/>
  </q-dialog>

  <div class="">
    <template v-for="(index, idx) of indexes" :key="idx">
      <div class="row items-center col">
        <div>
          <strong>{{index.name}}</strong> ({{index.type}})
          <div class="text-caption">
            <span v-for="(field, k) in index.fields" :key="field" :class="{'text-negative': !fields.includes(field)}">
              {{field}}{{k < index.fields.length - 1 ? ', ' : ''}}
            </span>
          </div>
        </div>
        <q-space/>
        <div>
          <q-btn flat @click="editIndex = index.data();$refs.editIndex.show()" icon="edit" size="sm" round/>
          <q-btn flat @click="deleteIndex(index)" icon="delete" size="sm" round/>
        </div>

      </div>

    </template>

  </div>

  <q-separator/>

  <div class="column items-end q-pt-sm">
    <q-btn icon="add" flat label="Add index" size="sm" @click="editIndex={table_id: tableId};$refs.editIndex.show()"/>
  </div>

</template>

<style>
</style>

<script>
import TableIndexForm from "./TableIndexForm.vue";
import {DbTableIndex} from "@/../../common/db/DbTableIndex";
import {DbTableField} from '../../../../../common/db/DbTableField';

export default {
  name: 'TableIndexesList',
  components: {TableIndexForm},
  props: {
    tableId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      editIndex: false,
      module_id: false
    };
  },

  computed: {
    // Return all fields from the store
    fields() {
      return this.wait("table-fields", async () => {
        const fields = await DbTableField.query().where({table_id: this.tableId}).get() || [];

        return fields.map(field => field.name);
      }, [])
    },

    indexes() {
      return this.wait("table-indexes", DbTableIndex.query().where({table_id: this.tableId}).get(), [])
    },
  },

  methods: {
    async deleteIndex(index) {
      this.$q.dialog({
        title: 'Delete confirmation',
        message: `Are you sure want to delete ${index.title}?`,
        cancel: true,
        persistent: true
      }).onOk(() => {
        DbTableIndex.remote().delete(index.id)
      })
    },
  },

  async created() {
    // Subscribe to fields
    await DbTableField.remote().subscribe("table-fields",{table_id: this.tableId});

    await DbTableIndex.remote().subscribe("table-indexes",{table_id: this.tableId});
  },
}
</script>
