<template>
  <q-card class="q-mt-sm">
    <q-card-section class="bg-primary text-white" v-if="title">
      {{title}}
      <div>{{hint}}</div>
    </q-card-section>
    <q-card-section>
      <value-selector :is-array="isArray" :block-id="block?.id" :title="title" :value-type="valueType" :only-var-type="onlyVarType" :parent-diagram-id="parentDiagramId" :app-id="product_id" :module-id="module_id" v-model="currentValue" :return-options="returnOptions" @updated="$emit('updated')"/>
    </q-card-section>
  </q-card>
</template>

<script>

import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import ValueSelector from "@/components/ValueSelector/ValueSelector.vue";

export default {
  components: {ValueSelector},
  emits: ['updated'],
  props: {
    title: {
      default: false
    },
    returnOptions: {
      type: Array,
      default: null
    },
    parentDiagramId: {
      default: null
    },
    onlyVarType: {
      default: false
    },
    isArray: {
      default: false
    },
    valueType: {
      default: false
    }
  },
  mixins: [propertiesMixins],
  name: "UiPropValueSelector",
  data: () => ({
    selectedVar: ""
  }),
}

</script>
