export class BaseComponentProcessor {
    /**
     * Processor constructor
     * @param context
     * @param block
     */
    constructor(context, block) {
        this.context = context;
        this.block = block;
    }

}
