<template>

  <div>
    <ui-prop-string name="title" title="Title" :block="block"/>

    <ui-prop-dynamic-string :block="block" title="Chat ID" name="chatId" untranslatable />

    <ui-prop-dynamic-string
      :multiple="true"
      :block="block"
      title="Instructions"
      name="instructions"
      untranslatable
      store-value-on-backend
    />

    <ui-prop-select
      name="messageType"
      title="Message type"
      default-value="text"
      :block="block"
      :options="messageTypes"
    />

    <ui-prop-dynamic-string
      v-if="messageType === 'text'"
      :multiple="true"
      :block="block"
      title="Message"
      name="message"
      untranslatable
    />

    <ui-prop-value-selector
      v-else
      :block="block"
      name="image"
      title="Image"
      only-var-type="image"
      :parent-diagram-id="diagram_id"
    />

    <ui-prop-value-selector
      :block="block"
      title="Variable to save job ID"
      name="varToSaveJobId"
      value-type="setter"
      :parent-diagram-id="diagram_id"
    />

    <div class="q-pa-sm q-mt-md rounded-borders bg-grey-2">
      <div>WebSocket channel: <a class="text-bold cursor-pointer text-primary" @click="copyToClipboard('ai-assistant:answer')">ai-assistant:answer</a></div>

      <div class="q-mt-md">The data structure received in the socket connection is as follows:</div>
      <pre>{{ dataJson }}</pre>
    </div>

    <ui-data-props
      name="outgoingEvents"
      title="Outgoing events"
      :default-schema="outgoingEventsSchema"
      :block="block"
    />

    <template v-if="outgoingEvents.length">
      <div class="q-pa-sm q-mt-md rounded-borders bg-grey-2">
        <div>WebSocket channels:</div>
        <div
          v-for="event in outgoingEvents"
          :key="event"
        >
          <a class="text-bold cursor-pointer text-primary" @click="copyToClipboard(`ai-assistant:events:${event}`)">ai-assistant:events:{{ event }}</a>
        </div>

        <div class="q-mt-md">The data structure received in the socket connection is as follows:</div>
        <pre>{{ outgoingEventDataJson }}</pre>
      </div>
    </template>

  </div>

</template>

<script>
import { copyToClipboard } from 'quasar';
import {propertiesMixins} from "@/components/DiagramDesigner/Editor/properties/propertiesMixins";
import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";
import UiPropValueSelector from '@/components/DiagramDesigner/Editor/properties/UiPropValueSelector.vue';
import UiPropDynamicString from '@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue';
import UiDataProps from '@/components/DiagramDesigner/Editor/properties/UiDataProps.vue';
import UiPropSelect from '@/components/DiagramDesigner/Editor/properties/UiPropSelect.vue';

export default {
  components: {UiPropSelect, UiDataProps, UiPropDynamicString, UiPropValueSelector, UiPropString},
  mixins: [propertiesMixins],
  props: ['block'],
  name: "AskAssistantPropsCmp",

  data() {
    return {
      outgoingEventsSchema: [
        {
          type: 'string',
          name: 'event',
          title: 'Event name',
        },
        {
          type: 'string',
          name: 'instructions',
          title: 'Instructions',
        },
      ],
      messageTypes: [
        { value: 'text', label: 'Text' },
        { value: 'image', label: 'Image' },
      ],
    };
  },

  computed: {
    /**
     * Converts a predefined object to a JSON string with indentation.
     *
     * @returns {string} The JSON string representation of the predefined object.
     */
    dataJson() {
      return JSON.stringify({
        job_id: 'Job ID',
        user_id: 'User ID',
        chat_id: 'Chat ID',
        message: 'Message',
        sent_at: 'Sent at',
      }, null, 2)
    },

    /**
     * Extracts the event names from the block's outgoing events properties.
     *
     * @returns {Array<string>} An array of event names.
     */
    outgoingEvents() {
      return (this.block?.properties?.outgoingEvents?.items || []).map((e) => e.event);
    },

    /**
     * Converts a predefined object representing outgoing event data to a JSON string with indentation.
     *
     * @returns {string} The JSON string representation of the outgoing event data.
     */
    outgoingEventDataJson() {
      return JSON.stringify({
        job_id: 'Job ID',
        user_id: 'User ID',
        chat_id: 'Chat ID',
        event_name: 'Event name',
        info: 'Info',
        sent_at: 'Sent at',
      }, null, 2)
    },

    messageType() {
      return this.block?.properties?.messageType || 'text';
    },
  },

  methods: {
    /**
     * Copies the specified value to the clipboard.
     * This method attempts to copy the provided value to the user's clipboard using the `copyToClipboard` function.
     * If the operation is successful, a notification with a positive message is displayed to the user.
     * In case of an error (e.g., the browser does not support clipboard operations), the error is logged to the console.
     *
     * @param {string} val - The value to be copied to the clipboard.
     */
    copyToClipboard(val) {
      try {
        copyToClipboard(val);

        this.$q.notify({
          message: 'Copied to clipboard',
          color: 'positive',
        })
      } catch (e) {
        console.error(e);
      }
    },
  },
}

</script>
