<template>
  <div class="column full-width">
    <q-table
      flat bordered
      ref="tableRef"
      :title="`Logs for ${appMode} mode`"
      :rows="formattedRows"
      :columns="columns"
      row-key="id"
      v-model:pagination="pagination"
      :loading="isBusy"
      binary-state-sort
      @request="loadLogs"
    >
      <template #body-cell-status="{value}">
        <q-td class="text-right">
          <health-check-status :status="value" />
        </q-td>
      </template>
      <template #body-cell-actions="{row}">
        <q-td class="text-right">
          <q-btn v-if="row.payload && row.payload.length" @click="showDetails(row)">Details</q-btn>
        </q-td>
      </template>
    </q-table>

    <q-dialog
      v-model="detailsDialog"
      persistent
      maximized
    >
      <q-card>
        <q-card-section class="details-card scroll">
          <template v-if="selectedDetails">
            <details-card
              v-for="(row, idx) in selectedDetails"
              :key="idx"
              :details="row"
              class="q-my-md"
            />
          </template>
        </q-card-section>

        <q-separator/>

        <q-card-actions align="right" class="text-primary">
          <q-btn flat label="Ok" @click="hideDetails" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import moment from 'moment';
import HealthCheckStatus from '@/pages/workspace/health-check/HealthCheckStatus.vue';
import DetailsCard from '@/pages/workspace/health-check/DetailsCard.vue';

export default {
  name: 'HealthCheckLogs',

  components: {
    HealthCheckStatus,
    DetailsCard,
  },

  inject: ['moduleId'],

  props: {
    appMode: {
      required: true,
      type: String,
    },
  },

  data() {
    return {
      isBusy: false,
      pagination: {
        page: 1,
        rowsPerPage: 10,
        rowsNumber: 10
      },
      columns: [
        { name: 'id', label: 'ID', field: 'id', sortable: false },
        { name: 'status', label: 'Status', field: 'status', sortable: false },
        { name: 'message', label: 'Message', field: 'message', sortable: false },
        { name: 'checked_at', label: 'Checked at', field: 'checked_at', sortable: false },
        { name: 'actions', label: '', field: '', sortable: false },
      ],
      rows: [],
      selectedDetails: null,
      detailsDialog: false,
    };
  },

  computed: {
    // Format rows for the table
    formattedRows() {
      return this.rows.map((row) => ({
        ...row,
        checked_at: moment.utc(row.checked_at).local().format('YYYY-MM-DD HH:mm'),
      }));
    },

    service() {
      return this.$route?.params?.service || null;
    },
  },

  methods: {
    /**
     * Loads logs from the server and updates the table data.
     *
     * @param {Object} props - The properties object containing pagination info.
     * @param {Object} props.pagination - The pagination details.
     * @param {number} props.pagination.page - The current page number.
     * @param {number} props.pagination.rowsPerPage - The number of rows per page.
     */
    async loadLogs(props) {
      if (this.isBusy) {
        return;
      }

      this.isBusy = true;

      try {
        const { page, rowsPerPage } = props.pagination;

        const { items, total } = await this.app.client.call(
          'health-check',
          'logs',
          this.service ? 0 : this.moduleId,
          this.service ? 0 : this.appMode,
          page,
          rowsPerPage,
          this.service,
        );

        this.pagination.rowsNumber = total;
        this.pagination.page = page;
        this.pagination.rowsPerPage = rowsPerPage;
        this.rows = items;
      } catch (e) {
        console.error('Failed to load logs', e);
      } finally {
        this.isBusy = false;
      }
    },

    /**
     * Displays the details dialog for a specific log entry.
     *
     * @param {Object} row - The log entry row data.
     */
    showDetails(row) {
      this.selectedDetails = row?.payload || null;
      this.detailsDialog = true;
    },

    /**
     * Hides the details dialog.
     */
    hideDetails() {
      this.detailsDialog = false;
      this.selectedDetails = null;
    },
  },

  mounted() {
    // Load logs on component mount
    this.$refs.tableRef.requestServerInteraction();
  },
}
</script>

<style scoped lang="scss">
.details-card {
  height: calc(100vh - 60px);
}
</style>
