<template>
  <div class="funnel-details">

    <q-dialog ref="eventForm">
      <q-card class="full-width">
        <q-card-section class="row text-white bg-primary text-h6">
          Event form
          <q-space/>
          <q-btn icon="close" flat round dense @click="$refs.eventForm.hide()"/>
        </q-card-section>
        <q-card-section>
          <q-form @submit="onAddEvent">
            <q-select v-model="currentEvent" :options="eventsList" map-options emit-value/>
            <div>
              <q-btn label="Add" type="submit" color="primary" v-close-popup/>
            </div>
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>

    <div class="events-list row q-gutter-md q-pa-sm q-ma-sm" v-if="isReady">

      <template v-for="(event, k) of funnelEvents" :key="k">
        <div class="content-center full-height flex">
          <q-btn icon="add" size="xs" round outline @click="eventPosition = k;$refs.eventForm.show()"/>
        </div>

        <div class="event relative-position">

          <div class="bar">
            <div class="users">
              <div class="line"  :style="{height: eventsStatistics[event]?.usersPercent+'%'}"></div>
              <label>
                {{eventsStatistics[event]?.usersPercent}}%<br/>
                {{eventsStatistics[event]?.users}}
              </label>
            </div>
            <div class="events">
              <div class="line" :style="{height: eventsStatistics[event]?.eventsPercent+'%'}"></div>
              <label>
                {{eventsStatistics[event]?.eventsPercent}}%<br/>
                {{eventsStatistics[event]?.events}}
              </label>
            </div>
          </div>
          <div class="label ellipsis-2-lines">
            <q-btn round size="sm" flat icon="menu" class="q-ml-xs q-mr-xs">
              <q-menu>
                <q-list style="min-width: 100px">
                  <q-item clickable v-close-popup @click="deleteEvent(k)">
                    <q-item-section>Delete</q-item-section>
                  </q-item>
                </q-list>
              </q-menu>
            </q-btn>


            {{ getEventById(event)?.label }}
          </div>
        </div>
      </template>

      <div class="content-center full-height flex">
        <q-btn icon="add" size="xs" round outline @click="eventPosition = funnelEvents?.length;$refs.eventForm.show()"/>
      </div>

    </div>
  </div>
</template>

<script>

import {AnalyticsFunnel} from "../../../../../../common/db/AnalyticsFunnel";
import {AnalyticsEvent} from "../../../../../../common/db/AnalyticsEvent";

export default {
  props: ['funnelId', 'codeVersion', 'schemaVersion', 'moduleId', 'dateFrom', 'dateTo', 'requiredEvents'],
  inject: ['main'],
  name: "FunnelPage",
  data: () => ({
    funnel: false,
    eventPosition: 0,
    currentEvent: "",
    versionSource: null,
    eventsStatistics: false,
    isReady: false
  }),

  async created() {

    // Load funnel
    this.funnel = await AnalyticsFunnel.find(this.funnelId)

    // Init funnel schema
    if (!this.funnel.funnel_schema) this.funnel.funnel_schema = {
      events: []
    }

    // Save funnel on change
    this.$watch('funnel', async function (newVal) {
      // Save funnel
      await AnalyticsFunnel.remote().save(newVal)

      // Update statistics
      await this.updateStatistics();

    }, {deep: true, immediate: true})

    // Ready
    this.isReady = true
  },

  computed: {

    /**
     * Get events list
     * @return {{}|MediaTrackSettings|*}
     */
    eventsList() {
      return this.wait("eventsList", async () => {
        return (await AnalyticsEvent.query().where({module_id: this.moduleId}).get()).map(e => ({
          value: e.event_id,
          label: e.title
        }))
      }, [])
    },


    /**
     * Get funnel events
     * @return {*|*[]}
     */
    funnelEvents() {
      return this.funnel?.funnel_schema?.events || []
    }
  },
  methods: {

    /**
     * Update source
     */
    async updateStatistics() {

      try {
        if (!(this.dateFrom && this.dateTo && this.moduleId && this.codeVersion && this.schemaVersion && this.funnelEvents?.length)) return
        this.$q.loading.show({message: "Updating statistics..."});
        this.eventsStatistics = {};
        (await this.app.client.call("app-statistics", "getLinksStatistics", this.funnelEvents, this.dateFrom, this.dateTo, this.moduleId, this.codeVersion, this.schemaVersion, this.requiredEvents) || [])?.map(e => {
          this.eventsStatistics[e.id] = e
        })

        // Calc max users and events
        const maxUsers = Math.max(...Object.values(this.eventsStatistics).map(e => e.users))
        const maxEvents = Math.max(...Object.values(this.eventsStatistics).map(e => e.events))

        // Calc users and events percents
        Object.values(this.eventsStatistics).forEach(e => {
          e.usersPercent = (e.users / maxUsers * 100).toFixed(0)
          e.eventsPercent = (e.events / maxEvents * 100).toFixed(0)
        })

      } finally {
        this.$q.loading.hide()
      }
    },

    /**
     * Get event by id
     * @param id
     * @return {*}
     */
    getEventById(id) {
      return (this.eventsList || []).find(e => e.value === id) || {}
    },

    /**
     * On add event
     */

    onAddEvent() {
      // Add new event at position eventPosition
      this.funnel.funnel_schema.events.splice(this.eventPosition, 0, this.currentEvent)
    },

    /**
     * Delete event by index
     * @param id
     */
    deleteEvent(id) {
      // Ask for confirmation
      this.$q.dialog({
        title: 'Delete confirmation',
        message: `Are you sure want to delete event ?`,
        cancel: true,
        persistent: true
      }).onOk(() => {
        // Delete from events list by index
        this.funnel.funnel_schema.events.splice(id, 1)
      })
    },
  }
}

</script>

<style lang="scss">

.funnel-details {
  .events-list {
    height: 300px;
    display: flex;

    .event {
      height: 100%;
      text-align: center;
      justify-content: center;
      flex-direction: column;
      display: flex;
      width: 200px;

      .label {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        background: #eee;
        text-align: left;
      }

      .bar {
        border:1px solid #eee;
        height: 100%;
        min-height: 10px;
        display: flex;
        flex-direction: row;
        align-items: end;
        font-size: 0.8em;

        .users {
          height: 100%;
          flex-direction: column;
          flex-grow: 1;
          display: flex;
          justify-content: end;
          .line {
            background: #afa;
            width: 100%;
          }
          label {
            color: white;;
            background: #3a3;
          }
        }

        .events {
          height: 100%;
          flex-grow: 1;
          flex-direction: column;
          display: flex;
          justify-content: end;
          .line {
            background: #faa;
            width: 100%;
          }
          label {
            color: white;
            background: #c55;
          }
        }
      }
    }
  }

}

</style>
