<template>

  <ui-prop-dynamic-string name="title" title="Title" :block="block"/>
  <ui-prop-checkbox title="Has back button" name="isBackButton" :block="block"/>
  <ui-prop-checkbox title="Has close button" name="hasCloseButton" :block="block"/>
  <ui-prop-select name="menuPosition" title="Menu" :block="block" :options="globals.options.toolbarMenuPosition"/>
  <ui-dynamic-props :default-value="{}" :block="block" />

</template>

<script>
import UiPropSelect from "@/components/DiagramDesigner/Editor/properties/UiPropSelect";
import UiDynamicProps from "@/components/DiagramDesigner/Editor/properties/UiDynamicProps";
import UiPropCheckbox from "@/components/DiagramDesigner/Editor/properties/UiPropCheckbox";
import UiPropDynamicString from "@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue";
export default {
  name: "AppHeaderPropsCmp",
  props: ['block'],
  components: {UiPropDynamicString, UiDynamicProps, UiPropSelect, UiPropCheckbox},
}

</script>

