/**
 * DB table model
 */
import {AccessManager} from '../utils/AccessManager.js';
import {AccessModel} from './AccessModel.js';
import {DbModule} from './DbModule.js';

export class DbTableIndex extends AccessModel {
    static entity = 'table_indexes'
    static primaryKey = ['id']
    static fields = {
        id: "int",
        table_id: "int",
        name: "string",
        type: "string",
        fields: "json",
    }

    /**
     * Handles server events by clearing the cache for the associated application.
     *
     * @async
     * @function serverEvent
     * @returns {Promise<void>}
     */
    async serverEvent() {
        const cacheManager = this.constructor?.applicationClient?.plugins?.schemaCache || null;

        if (!cacheManager) {
            return;
        }

        const row = await DbTableIndex.find(this.id);

        if (!row?.id) {
            return;
        }

        const db = await DbModule.query()
          .join('database_tables')
          .on('database_tables.db_id', 'app_databases.id')
          .where('database_tables.id', row.table_id)
          .first();

        if (db?.app_id) {
            cacheManager.clearCache(db.app_id);
        }
    }

    /**
     * Prepare data according to users access level
     * @return {boolean}
     * @param row
     * @param mode
     */
    static async prepareData(row, mode) {
        const accessManager = new AccessManager(this);

        return await accessManager.checkDbTableFieldAccess(await this.getAccessId(row, 'table_id'), row?.id) ? row : false
    }

    /**
     * Functions list
     */
    async channels() {
        return {
            'table-indexes': {
                subscribe: ({table_id}) => table_id,
                init: async ({table_id}) => this.getList(table_id),
            },
            'app-table-indexes': {
                subscribe: ({app_id}) => app_id,
                init: async ({app_id}) => DbTableIndex.getAppIndexes(app_id),
            },
            'db-table-indexes': {
                subscribe: ({db_id}) => db_id,
                init: async ({db_id}) => this.getDbIndexes(db_id),
            }
        }
    }

    /**
     * Get list
     */
    getList(table_id) {
        return this.query().where({table_id}).get()
    }


    /**
     * Get version list
     */
    static getAppIndexes(app_id) {
        return DbTableIndex.query().
        join("database_tables").on("database_tables.id", "table_id").
        join("app_databases").on("app_databases.id", "db_id").
        where("app_databases.app_id", app_id).get()
    }

    /**
     * Get module indexes
     */
    static getModuleIndexes(module_id) {
        return DbTableIndex.query(['table_indexes.*'])
          .join('database_tables').on('database_tables.id', 'table_indexes.table_id')
          .join('app_databases').on('app_databases.id', 'database_tables.db_id')
          .where('app_databases.module_id', module_id)
          .get()
    }

    /**
     * Get indexes for a specific database.
     *
     * @param {number} db_id - The ID of the database.
     * @returns {Promise<Array>} - A promise that resolves to an array of indexes.
     */
    static getDbIndexes(db_id) {
        return DbTableIndex.query(['table_indexes.*'])
          .join('database_tables').on('database_tables.id', 'table_indexes.table_id')
          .where('database_tables.db_id', db_id)
          .get();
    }

    /**
     * Get indexes for a specific database.
     *
     * @param {number} db_id - The ID of the database.
     * @returns {Promise<Array>} - A promise that resolves to an array of indexes.
     */
    getDbIndexes(db_id) {
        return DbTableIndex.getDbIndexes(db_id);
    }
}
