<template>
    <ab-flow-base-cmp :movable="true" :block="block" class="dialog-editor-cmp justify-center">

        <ab-flow-link-connector :block="block" type="input" class="ev-start" event="start"/>

        <q-card>
            <q-card-section class="bg-primary text-white">
                {{title}}
            </q-card-section>
            <q-card-section v-if="mode === 'prompt'">
                <q-input dense v-model="promptModel" autofocus />
            </q-card-section>
            <q-card-section v-else>
                {{message}}
            </q-card-section>

            <q-card-actions align="right">
                <div v-if="mode !== 'alert'">
                    <q-btn flat :label="cancelLabel" color="primary" />
                    <ab-flow-link-connector class="q-mt-sm" :block="block" type="output" event="cancel"/>
                </div>
                <div>
                    <q-btn flat :label="confirmLabel" color="primary" />
                    <ab-flow-link-connector class="q-mt-sm" :block="block" type="output" event="ok"/>
                </div>
            </q-card-actions>

        </q-card>

    </ab-flow-base-cmp>
</template>

<script>

import AbFlowBaseCmp from "ab-flow-designer/src/components/Designer/AbFlowBaseCmp";
import AbFlowLinkConnector from "ab-flow-designer/src/components/Designer/AbFlowLinkConnector";
import {renderMixins} from '@/components/DiagramDesigner/Editor/components/renderMixins';

export default {
    components: { AbFlowBaseCmp, AbFlowLinkConnector},

  mixins: [renderMixins],

    inject: ['canvas'],
    props: ['block'],
    name: "DialogEditorCmp",
    data: () => ({
        promptModel: "",
    }),
    computed: {

        /**
         * Return title
         * @return {string}
         */
        title() {
            return this.interpretString(this.block.properties?.title) || "Type title...";
        },

        /**
         * Return confirm label
         * @return {string}
         */
        confirmLabel() {
            return this.interpretString(this.block.properties?.confirmLabel);
        },

        /**
         * Return cancelLabel
         * @return {string}
         */
        cancelLabel() {
            return this.interpretString(this.block.properties?.cancelLabel);
        },

        /**
         * Return message
         * @return {string}
         */
        message() {
            return this.interpretString(this.block.properties?.message) || "Type message..."
        },

        /**
         * Return mode
         * @return {string}
        */
        mode() {
            return this.block.properties?.mode || "alert"
        },
    }
}

</script>

<style lang="scss">

.dialog-editor-cmp {
  max-width: 300px;
  .ev-start {
    left:-12px;
    position: absolute;
    top:calc(50% - 5px);
  }
}

</style>
