<template>

  <div>
    <ui-prop-string name="title" title="Block title" :block="block" />

    <ui-prop-dynamic-string :block="block" title="User E-Mail" name="userEmail"/>

    <q-card class="q-my-sm">
      <q-card-section class="bg-primary text-white">E-Mail settings</q-card-section>

      <q-card-section class="q-pt-none">
        <ui-prop-dynamic-string :block="block" title="Sender" name="from"/>
        <ui-prop-dynamic-string :block="block" title="Subject" name="subject"/>
        <ui-prop-reach-text name="html" title="Body" :block="block"/>

        <div class="text-caption q-mt-sm" v-pre>
          You can insert any field from the "users" table into the email template by enclosing it in double curly braces. For example: {{password}}
        </div>
      </q-card-section>
    </q-card>
  </div>

</template>

<script>

import UiPropString from "@/components/DiagramDesigner/Editor/properties/UiPropString.vue";
import UiPropDynamicString from '@/components/DiagramDesigner/Editor/properties/UiPropDynamicString.vue';
import UiPropReachText from '@/components/DiagramDesigner/Editor/properties/UiPropReachText.vue';

export default {
  components: {UiPropReachText, UiPropDynamicString, UiPropString},
  props: ['block'],
  name: "ForgotPasswordPropsCmp",
}

</script>
