export const languagesList = [
    {
        "label": "Arabic",
        "value": "ar",
        "criterion": 1019
    },
    {
        "label": "Bengali",
        "value": "bn",
        "criterion": 1056
    },
    {
        "label": "Bulgarian",
        "value": "bg",
        "criterion": 1020
    },
    {
        "label": "Catalan",
        "value": "ca",
        "criterion": 1038
    },
    {
        "label": "Chinese (simplified)",
        "value": "zh_CN",
        "criterion": 1017
    },
    {
        "label": "Chinese (traditional)",
        "value": "zh_TW",
        "criterion": 1018
    },
    {
        "label": "Croatian",
        "value": "hr",
        "criterion": 1039
    },
    {
        "label": "Czech",
        "value": "cs",
        "criterion": 1021
    },
    {
        "label": "Danish",
        "value": "da",
        "criterion": 1009
    },
    {
        "label": "Dutch",
        "value": "nl",
        "criterion": 1010
    },
    {
        "label": "English",
        "value": "en",
        "criterion": 1000
    },
    {
        "label": "Estonian",
        "value": "et",
        "criterion": 1043
    },
    {
        "label": "Filipino",
        "value": "tl",
        "criterion": 1042
    },
    {
        "label": "Finnish",
        "value": "fi",
        "criterion": 1011
    },
    {
        "label": "French",
        "value": "fr",
        "criterion": 1002
    },
    {
        "label": "German",
        "value": "de",
        "criterion": 1001
    },
    {
        "label": "Greek",
        "value": "el",
        "criterion": 1022
    },
    {
        "label": "Gujarati",
        "value": "gu",
        "criterion": 1072
    },
    {
        "label": "Hebrew",
        "value": "iw",
        "criterion": 1027
    },
    {
        "label": "Hindi",
        "value": "hi",
        "criterion": 1023
    },
    {
        "label": "Hungarian",
        "value": "hu",
        "criterion": 1024
    },
    {
        "label": "Icelandic",
        "value": "is",
        "criterion": 1026
    },
    {
        "label": "Indonesian",
        "value": "id",
        "criterion": 1025
    },
    {
        "label": "Italian",
        "value": "it",
        "criterion": 1004
    },
    {
        "label": "Japanese",
        "value": "ja",
        "criterion": 1005
    },
    {
        "label": "Kannada",
        "value": "kn",
        "criterion": 1086
    },
    {
        "label": "Korean",
        "value": "ko",
        "criterion": 1012
    },
    {
        "label": "Latvian",
        "value": "lv",
        "criterion": 1028
    },
    {
        "label": "Lithuanian",
        "value": "lt",
        "criterion": 1029
    },
    {
        "label": "Malay",
        "value": "ms",
        "criterion": 1102
    },
    {
        "label": "Malayalam",
        "value": "ml",
        "criterion": 1098
    },
    {
        "label": "Marathi",
        "value": "mr",
        "criterion": 1101
    },
    {
        "label": "Norwegian",
        "value": "no",
        "criterion": 1013
    },
    {
        "label": "Persian",
        "value": "fa",
        "criterion": 1064
    },
    {
        "label": "Polish",
        "value": "pl",
        "criterion": 1030
    },
    {
        "label": "Portuguese",
        "value": "pt",
        "criterion": 1014
    },
    {
        "label": "Punjabi",
        "value": "pa",
        "criterion": 1110
    },
    {
        "label": "Romanian",
        "value": "ro",
        "criterion": 1032
    },
    {
        "label": "Russian",
        "value": "ru",
        "criterion": 1031
    },
    {
        "label": "Serbian",
        "value": "sr",
        "criterion": 1035
    },
    {
        "label": "Slovak",
        "value": "sk",
        "criterion": 1033
    },
    {
        "label": "Slovenian",
        "value": "sl",
        "criterion": 1034
    },
    {
        "label": "Spanish",
        "value": "es",
        "criterion": 1003
    },
    {
        "label": "Swedish",
        "value": "sv",
        "criterion": 1015
    },
    {
        "label": "Tamil",
        "value": "ta",
        "criterion": 1130
    },
    {
        "label": "Telugu",
        "value": "te",
        "criterion": 1131
    },
    {
        "label": "Thai",
        "value": "th",
        "criterion": 1044
    },
    {
        "label": "Turkish",
        "value": "tr",
        "criterion": 1037
    },
    {
        "label": "Ukrainian",
        "value": "uk",
        "criterion": 1036
    },
    {
        "label": "Urdu",
        "value": "ur",
        "criterion": 1041
    },
    {
        "label": "Vietnamese",
        "value": "vi",
        "criterion": 1040
    }
]
