import {AppIntegration} from '@/../../common/db/AppIntegration.js'

export const adMixins = {

    /**
     * On created
     * @return {Promise<void>}
     */
    async created() {
        // Subscribe for integration
        await AppIntegration.remote().subscribe('app-integration-by-name', {
            module_id: this.module_id,
            name: "admob"
        })
    },

    computed: {
        /**
         * Return placements list
         */
        placementsList() {
            return this.wait("placementsList", async () => {
                const aInt = await AppIntegration.query().where({module_id: this.module_id, name: "admob"}).first();
                return aInt?.props?.placements || [];
            })
        }
    }
}
